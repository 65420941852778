import React from "react";


export default function SvgText({
                                    id,
                                    className,
                                    text,
                                    fillColor,
                                    strokeColor,
                                    fontSize,
                                    textDecoration,
                                    textLength,
                                    opacity,
                                    x,
                                    y,
                                    rotationAngle,
                                    renderApproach,
                                    htmlHeight,
                                    htmlWidth,
}) {
    if (renderApproach === "html") {
        return <>
            <foreignObject
                id={id}
                className={className}
                x={x}
                y={y}
                width={htmlWidth}
                height={htmlHeight}
                transform={`rotate(${(rotationAngle || 0)} ${x} ${y})`}
            >
                <div
                    xmlns="http://www.w3.org/1999/xhtml"
                    className="symbol-text"
                    style={{
                        color: fillColor,
                        webkitTextStrokeColor: strokeColor,
                        fontSize,
                        textDecoration,
                        opacity,
                        strokeWidth: 0,
                        display: "flex",
                        height: "inherit",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        margin: "0 5%",
                    }}
                >
                    {text}
                </div>
            </foreignObject>
        </>;
    }
    return <>
        <symbol id={`${id}-symbol-text`}>
            <text
                className="symbol-text"
                stroke={strokeColor}
                fill={fillColor}
                fontSize={fontSize}
                textDecoration={textDecoration}
                opacity={opacity}
                x={0}
                y="38"
                style={{strokeWidth: 0}}
                textLength={textLength}
            >
                {text}
            </text>
        </symbol>
        <use
            id={id}
            className={className}
            href={`#${id}-symbol-text`}
            x={x}
            y={y - 15}
            transform={`rotate(${(rotationAngle || 0)} ${x} ${y})`}
        />
    </>;
}

import React, {useState, useEffect} from "react";

import {useLocalization} from "../../customHooks/useContextLocalization";
import {useCurrentProject} from "../customHooks/useContextCurrentProject";


function Link (props) {
    return <a href={props.link} target="_blank" rel="noopener noreferrer">{props.content}</a>;
}

export default function useOnboardingParams() {
    const [steps, setSteps] = useState([]);

    const {get: lang} = useLocalization();
    const {get: currentProject} = useCurrentProject();

    useEffect(() => {
        let steps_ = [
            {
                target: "body",
                content: lang.studio.onboarding.baseSteps.welcomeTo,
                disableBeacon: true,
                placement: "center"
            },
            {
                target: '.top-menu-container',
                content: <>
                    {lang.studio.onboarding.baseSteps.menu}
                    <Link
                        link={`/docs/${lang.abbr.toLowerCase()}/start/1-map-editor/`}
                        content={lang.studio.onboarding.manual}
                    />
                </>,
            },
            {
                target: '.map-title-header',
                content: <>
                    {lang.studio.onboarding.baseSteps.mapName}
                    <br/>
                    <Link
                        link={`/docs/${lang.abbr.toLowerCase()}/map-studio/1-common-information/`}
                        content={lang.studio.onboarding.learnMore}
                    />
                </>,
            },
            {
                target: '.svg-canvas',
                content: <>
                    {lang.studio.onboarding.baseSteps.canvas}
                    <Link
                        link={`/docs/${lang.abbr.toLowerCase()}/map-studio/6-canvas/`}
                        content={lang.studio.onboarding.manual}
                    />
                </>,
            },
            {
                target: '.figure-bar',
                content: <>
                    {lang.studio.onboarding.baseSteps.toolbar}
                    <Link
                        link={`/docs/${lang.abbr.toLowerCase()}/map-studio/5-toolbar/`}
                        content={lang.studio.onboarding.manual}
                    />
                </>,
            },
            {
                target: '.lang-current',
                content: <>
                    {lang.studio.onboarding.baseSteps.localization}
                    <br/>
                    <Link
                        link={`/docs/${lang.abbr.toLowerCase()}/examples/3-change-lang/`}
                        content={lang.studio.onboarding.learnMore}
                    />
                </>,
            },
            {
                target: '#scale-slider',
                content: <>
                    {lang.studio.onboarding.baseSteps.scaleSlider}
                    <br/>
                    <Link
                        link={`/docs/${lang.abbr.toLowerCase()}/map-studio/4-resizing-and-scaling/`}
                        content={lang.studio.onboarding.learnMore}
                    />
                </>,
            }
        ];

        if ((currentProject.config?.hiddenElements || {}).watchMode !== "all") {
            steps_ = steps_.concat([
                {
                    target: '.editor-mode-switch',
                    content: lang.studio.onboarding.baseSteps.editorMode,
                },
                {
                    target: '#editor-redacting-mode',
                    content: lang.studio.onboarding.baseSteps.editingMode,
                },
                {
                    target: '#editor-watch-mode',
                    content: <>
                        {lang.studio.onboarding.baseSteps.watchMode}
                        <br/>
                        <Link
                            link={`/docs/${lang.abbr.toLowerCase()}/map-studio/3-map-view/`}
                            content={lang.studio.onboarding.learnMore}
                        />
                    </>,
                }
            ]);
        }

        steps_ = steps_.concat([
            {
                target: '.first-tool-block',
                content: lang.studio.onboarding.baseSteps.firstToolBlock,
            },
            {
                target: '.second-tool-block',
                content: lang.studio.onboarding.baseSteps.secondToolBlock,
            },
            {
                target: '#header-outline-switcher',
                content: lang.studio.onboarding.baseSteps.secondToolBlockOutline,
                placement: "right",
            },
            {
                target: '#header-layers-button',
                content: lang.studio.onboarding.baseSteps.secondToolBlockLayers,
                placement: "right",
            },
            {
                target: '#header-copy-style-button',
                content: lang.studio.onboarding.baseSteps.secondToolBlockCopyStyle,
                placement: "right",
            },
            {
                target: '#header-paste-style-button',
                content: lang.studio.onboarding.baseSteps.secondToolBlockPasteStyle,
                placement: "right",
            },
            {
                target: '#header-background-button',
                content: lang.studio.onboarding.baseSteps.secondToolBlockBackground,
                placement: "right",
            },
            {
                target: '#header-grid-switcher',
                content: lang.studio.onboarding.baseSteps.secondToolBlockGrid,
                placement: "right",
            },
            {
                target: '#header-center-button',
                content: lang.studio.onboarding.baseSteps.secondToolBlockCenter,
                placement: "right",
            }
        ]);

        if ((currentProject.config?.hiddenElements || {}).figureSelector !== "all") {
            steps_ = steps_.concat([
                {
                    target: '.figure-bar-right-container',
                    content: lang.studio.onboarding.baseSteps.rightContainer,
                },
                // {
                //     target: '#figure-bar-partition-0',
                //     content: <>
                //         {lang.studio.onboarding.baseSteps.polylines}
                //         <Link
                //             link={`/docs/${lang.abbr.toLowerCase()}/examples/37-draw-object-with-pencil/`}
                //             content={lang.studio.onboarding.withPencil}
                //         />
                //         {lang.studio.onboarding.and}
                //         <Link
                //             link={`/docs/${lang.abbr.toLowerCase()}/examples/38-draw-object-with-polylines/`}
                //             content={lang.studio.onboarding.withPolylines}
                //         />
                //     </>,
                // },
                // {
                //     target: '#figure-bar-partition-1',
                //     content: <>
                //         {lang.studio.onboarding.baseSteps.polygons}
                //         <Link
                //             link={`/docs/${lang.abbr.toLowerCase()}/examples/37-draw-object-with-pencil/`}
                //             content={lang.studio.onboarding.withPencil}
                //         />
                //         {lang.studio.onboarding.and}
                //         <Link
                //             link={`/docs/${lang.abbr.toLowerCase()}/examples/38-draw-object-with-polylines/`}
                //             content={lang.studio.onboarding.withPolylines}
                //         />
                //     </>,
                // },
                // {
                //     target: '#figure-bar-partition-2',
                //     content: <>
                //         {lang.studio.onboarding.baseSteps.points}
                //         <br/>
                //         <Link
                //             link={`/docs/${lang.abbr.toLowerCase()}/examples/36-draw-point-figure/`}
                //             content={lang.studio.onboarding.learnMore}
                //         />
                //     </>,
                // },
            ]);
        }

        if ((currentProject.config?.hiddenElements || {}).floatingMenu !== "all") {
            steps_ = steps_.concat([
                {
                    target: '.floating-menu',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenu}
                        <br/>
                        <Link
                            link={`/docs/${lang.abbr.toLowerCase()}/map-studio/7-editing-objects/`}
                            content={lang.studio.onboarding.learnMore}
                        />
                    </>,
                    placement: "right",
                },
                {
                    target: '.floating-menu-header',
                    content: lang.studio.onboarding.baseSteps.toolMenuDnD,
                    placement: "right",
                },
                {
                    target: '#floating-menu-mode-container',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuModes}
                        <Link
                            link={`/docs/${lang.abbr.toLowerCase()}/examples/20-set-cursor-to-pencil-mode/`}
                            content={lang.studio.onboarding.manual}
                        />
                    </>,

                    placement: "right",
                },
                {
                    target: '#floating-menu-item-0',
                    content: lang.studio.onboarding.baseSteps.toolMenuEditMode,
                    placement: "right",
                },
                {
                    target: '#floating-menu-item-2',
                    content: lang.studio.onboarding.baseSteps.toolMenuGroup,
                    placement: "right",
                },
                {
                    target: '#floating-menu-item-3',
                    content: lang.studio.onboarding.baseSteps.toolMenuUngroup,
                    placement: "right",
                },
                {
                    target: '#floating-menu-container',
                    content: lang.studio.onboarding.baseSteps.toolMenuParameters,
                    placement: "right",
                },
                {
                    target: '#floating-menu-item-4',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuName}
                        <br/>
                        <Link
                            link={`/docs/${lang.abbr.toLowerCase()}/examples/23-change-figure-name/`}
                            content={lang.studio.onboarding.learnMore}
                        />
                    </>,
                    placement: "right",
                },
                {
                    target: '#floating-menu-item-5',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuDescription}
                        <br/>
                        <Link
                            link={`/docs/${lang.abbr.toLowerCase()}/examples/24-describe-object/`}
                            content={lang.studio.onboarding.learnMore}
                        />
                    </>,
                    placement: "right",
                },
                {
                    target: '#floating-menu-item-6',
                    content: lang.studio.onboarding.baseSteps.toolMenuLink,
                    placement: "right",
                },
                {
                    target: '#floating-menu-item-7',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuEmojis}
                        <br/>
                        <Link
                            link={`/docs/${lang.abbr.toLowerCase()}/examples/25-show-impressions-of-object/`}
                            content={lang.studio.onboarding.learnMore}
                        />
                    </>,
                    placement: "right",
                },
                {
                    target: '#floating-menu-item-8',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuColor}
                        <br/>
                        <Link
                            link={`/docs/${lang.abbr.toLowerCase()}/examples/29-change-object-color/`}
                            content={lang.studio.onboarding.learnMore}
                        />
                    </>,
                    placement: "right",
                },
                {
                    target: '#floating-menu-item-9',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuTransparency}
                        <br/>
                        <Link
                            link={`/docs/${lang.abbr.toLowerCase()}/examples/32-change-object-transparency/`}
                            content={lang.studio.onboarding.learnMore}
                        />
                    </>,
                    placement: "right",
                },
                {
                    target: '#floating-menu-item-10',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuThickness}
                        <br/>
                        <Link
                            link={`/docs/${lang.abbr.toLowerCase()}/examples/33-change-contour-object-thickness/`}
                            content={lang.studio.onboarding.learnMore}
                        />
                    </>,
                    placement: "right",
                },
                {
                    target: '#floating-menu-item-11',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuResize}
                        <br/>
                        <Link
                            link={`/docs/${lang.abbr.toLowerCase()}/examples/28-change-point-figure-size/`}
                            content={lang.studio.onboarding.learnMore}
                        />
                    </>,
                    placement: "right",
                },
                {
                    target: '#floating-menu-item-12',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuDash}
                        <br/>
                        <Link
                            link={`/docs/${lang.abbr.toLowerCase()}/examples/27-change-contour-object-dash-array/`}
                            content={lang.studio.onboarding.learnMore}
                        />
                    </>,
                    placement: "right",
                },
                {
                    target: '#floating-menu-item-13',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuFlag}
                        <br/>
                        <Link
                            link={`/docs/${lang.abbr.toLowerCase()}/examples/34-add-flag-to-settlement/`}
                            content={lang.studio.onboarding.learnMore}
                        />
                    </>,
                    placement: "right",
                }
            ]);
        }

        steps_.push({
            target: "body",
            content: lang.studio.onboarding.baseSteps.conclusion,
            disableBeacon: true,
            placement: "center"
        });

        setSteps(steps_);
    }, [lang, currentProject.config]);

    return {
        showProgress: true,
        locale: lang.studio.onboarding.joyrideLocale,
        showSkipButton: true,
        steps: steps
    };
}

import React from 'react';


export default function ConnectorLine(props) {
    return <line
        x1={props.x1}
        y1={props.y1}
        x2={props.x2}
        y2={props.y2}
        strokeWidth="2"
        stroke="#FF983D"
        opacity={props.opacity}
    />;
}

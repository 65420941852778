import {useDrop} from "react-dnd";

import dndItems from "../dndItems";


export default function useSubpartitionDropTarget(ref, tabIndex, partitionIndex, subpartitionIndex, dispatchTabs, tabs) {
    const [{handlerId, sourcePartitionIndex, sourceSubpartitionId}, drop] = useDrop({
        accept: dndItems.subpartition,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
                sourcePartitionIndex: monitor.getItem()?.partitionIndex,
                sourceSubpartitionId: monitor.getItem()?.subpartitionId,
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = tabs[item.tabIndex]
                .children[item.partitionIndex].children.map(child=>child.id).indexOf(item.subpartitionId);
            const hoverIndex = subpartitionIndex;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Prevent moving between partitions
            if (item.partitionIndex !== partitionIndex) {
                return;
            }

            dispatchTabs({type: "moveSubpartition", payload: {
                tIndex: tabIndex,
                dragIndex: dragIndex,
                hoverIndex: hoverIndex,
                pIndex: partitionIndex,
            }});
        },
    });

    return [{handlerId, sourcePartitionIndex, sourceSubpartitionId}, drop];
}
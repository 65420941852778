import React from "react";
import {useDispatch} from "react-redux";
import {validate as uuidValidate} from "uuid";

import useCooTransformation from "./useCooTransformation";
import {useCanvasBoundingClientRect} from "../../../customHooks/useContextCanvasBoundingClientRect";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import {uuidArraySchema} from "../../../utils/validators";
import Path from "../../../utils/path";


export default function useContextMenu() {
    const dispatch = useDispatch();
    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const {scale, figures, figureCenter, isContextMenuShow, openContextMenuTool, selectedFigureUuids} = useReduxData();
    const {transformXForCanvas, transformYForCanvas} = useCooTransformation();

    const getFigureCenterCoo = figureUuid => {
        let x, y;
        const figure = figures.get(figureUuid);
        const figureType = figure.get("tool").split("-")[0];
        if (figureType === "a") {
            x = transformXForCanvas((figure.get("toX") + figure.get("fromX")) / 2);
            y = transformYForCanvas((figure.get("toY") + figure.get("fromY")) / 2);
        } else if (figureType === "i") {
            x = transformXForCanvas(figure.get("x") + 2 * figure.get("volume"));
            y = transformYForCanvas(figure.get("y") + 1.5 * figure.get("volume"));
        } else {
            x = transformXForCanvas(figure.get("x"));
            y = transformYForCanvas(figure.get("y"));
            if (figureType.startsWith("p")) {
                let {centerX, centerY} = Path.getCenter(figure.get("points"));
                x += centerX * scale;
                y += centerY * scale;
            } else if (figureType === "r") {
                x += figure.get("width") * scale / 2;
                y += figure.get("height") * scale / 2;
            }
        }

        return {x: x + canvasBoundingClientRect.get.left, y: y + canvasBoundingClientRect.get.top};
    }

    const openContextMenu = (event, selectObject) => {
        let targetFigureUuid = event.target.id.split("|")[0];
        if (!uuidValidate(targetFigureUuid)) {
            targetFigureUuid = event.target.parentNode.parentNode.id.split("|")[0];
        }

        if (!uuidValidate(targetFigureUuid)) {  // click on empty space
            if (isContextMenuShow !== "no") {
                dispatch({type: "isContextMenuShow", value: "no"});
            }
            return;
        }

        if (canvasBoundingClientRect) {
            const {x, y} = getFigureCenterCoo(targetFigureUuid);
            if (x !== figureCenter.x || y !== figureCenter.y) {
                dispatch({type: "figureCenter", value: {x, y}});
            }
        }

        if (isContextMenuShow === "no") {
            let targetClass = event.target.className.baseVal;
            if (!["figure", "figure-neighborhood", "text"].includes(targetClass)) {
                targetClass = event.target.parentNode.parentNode.className.baseVal;
            }
            dispatch({type: "isContextMenuShow", value: targetClass});

            if (selectedFigureUuids.length === 0) {
                let {type: selectionStatus, payload: selectionPayload} = selectObject(event);
                if (selectionStatus === "resetSelection") {
                    dispatch({type: "selectedFigureUuids", value: []});
                } else if (selectionStatus === "simpleSelection" && uuidArraySchema.isValidSync(selectionPayload)) {
                    dispatch({type: "selectedFigureUuids", value: selectionPayload});
                }
            }
        }
    }

    const closeContextMenu = event => {
        if (openContextMenuTool !== "no") {
            dispatch({type: "openContextMenuTool", value: "no"});
        }
        if (isContextMenuShow !== "no" && event.button !== 2) {
            dispatch({type: "isContextMenuShow", value: "no"});
        }
    }

    return {closeContextMenu, openContextMenu};
}

import React, {useState, useContext} from "react";


const ShouldHideToolbarContext = React.createContext();


export const ShouldHideToolbarProvider = ({children}) => {
    const [get, set] = useState(undefined);

    return <ShouldHideToolbarContext.Provider value={{get, set}}>
        {children}
    </ShouldHideToolbarContext.Provider>;
};


export const useShouldHideToolbar = () => useContext(ShouldHideToolbarContext);

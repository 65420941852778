import React from 'react';

import styles from './footerPattern.module.css';


export default function (props) {
    return <div className={styles.footer}>
        <div className={styles.title}>
            {props.renderIcon({
                viewBox: props.viewBox,
                onClick: props.onClick,
            })}
            <span className={styles.name}>{props.logo.content}</span>
        </div>
        <div className={styles.desc}>
            {props.description}
        </div>
        <div className={styles.copyright}>
            {props.copyright}
        </div>
        {props.specificItems &&
            props.specificItems.map((item, i) => (
                <div
                    className={styles[item.className]} key={i}>
                    <a key={i} href={item.link || ''}>{item.content || ''}</a>
                </div>
            ))}
        <div className={styles.about}>
            {props.baseItems &&
                props.baseItems.map((item, i) => (
                    <a key={i} href={item.link}>{item.content}</a>
                ))}
        </div>
        {props.socialComponent && props.socialComponent}
    </div>;
}

import React from "react";
import {v4 as uuid} from "uuid";
import {useDispatch} from "react-redux";

import {useReduxData} from "../../../customHooks/useContextReduxData";
import {useCanvasBoundingClientRect} from "../../../customHooks/useContextCanvasBoundingClientRect";
import {useIconSet} from "../../../../customHooks/useContextIconSet";
import useCooTransformation from "./useCooTransformation";
import getTimeElapsedSince from "../../getTimeElapsedSince";
import {textObjectSchema} from "../../../utils/validators";


export default function() {
    const dispatch = useDispatch();
    const {fontColor, fontSize, startTime, tool, transparency} = useReduxData();
    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const {get: {parameters}} = useIconSet();
    const {transformYForMap, transformXForMap} = useCooTransformation();

    const createText = event => {
        let canvasX = canvasBoundingClientRect.get.left;
        let canvasY = canvasBoundingClientRect.get.top;
        if (typeof canvasX !== "number" || typeof canvasY !== "number") {
            return;
        }
        let newFigureUuid = uuid();
        let newFigureObject = {
            x: transformXForMap(event.clientX - canvasX),
            y: transformYForMap(event.clientY - canvasY),
            tool,
            uuid: newFigureUuid,
            fontColor,
            fontSize,
            transparency,
            name: "",
            link: "",
            description: "",
            layout: +parameters[tool]?.layout || 50,
            orderIndex: Math.round(new Date().getTime())
        };
        if (!textObjectSchema.isValidSync(newFigureObject)) {
            return;
        }
        dispatch({...newFigureObject, type: "addText", time: getTimeElapsedSince(startTime)});
        dispatch({type: "selectedFigureUuids", value: [newFigureUuid]});
    }

    return {createText};
}

import React, {useState, useContext, useCallback} from "react";
import RModal from 'react-modal';

import {SignupForm} from "../forms/signup";
import {SigninForm} from "../forms/signin";
import {ResetPasswordForm} from "../forms/reset";
import {ForgotPasswordForm} from "../forms/forgot";
import {EditProfileForm} from "../forms/edit_profile";
import {DeleteProfileForm} from "../forms/delete_profile";
import {BuyError} from "../forms/buy_error";
import LicenseAgreement from "../studio/components/modalWindows/LicenseAgreement";
import MyMaps from "../studio/components/modalWindows/MyMaps";
import UsersMaps from "../studio/components/modalWindows/UsersMaps";
import BackgroundLoading from "../studio/components/modalWindows/BackgroundLoading";
import Survey from "../studio/components/modalWindows/survey/Survey";
import PostcardEditor from "../studio/components/modalWindows/postcardEditors/PostcardEditor";
import OAuthAgreement from "../forms/OAuthAgreement";
import ProductConfiguring from "../forms/ProductConfiguring";
import Sharing from "../studio/components/modalWindows/Sharing";
import Layers from "../studio/components/modalWindows/Layers";
import AccessRights from "../studio/components/modalWindows/AccessRights";


const ModalContext = React.createContext();

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== 'test') { //some tests failed without this condition, see: https://github.com/reactjs/react-modal/issues/632
    RModal.setAppElement('#modal');
}

const modals = {
    signup: {sizes: [], component: SignupForm},
    signin: {sizes: [], component: SigninForm},
    forgot: {sizes: [], component: ForgotPasswordForm},
    reset_password: {sizes: [], component: ResetPasswordForm},
    edit_profile: {sizes: [], component: EditProfileForm},
    delete_profile: {sizes: [], component: DeleteProfileForm},
    license_agreement: {sizes: ["621px", "420px", "621px", "420px"], component: LicenseAgreement},
    my_maps: {sizes: ["60vw", "40vh"], component: MyMaps},
    users_maps: {sizes: ["60vw", "40vh"], component: UsersMaps},
    survey: {sizes: [], component: Survey},
    background_loading: {sizes: [], component: BackgroundLoading},
    buy_error: {sizes: [], component: BuyError},
    vk_oauth_license_agreement: {sizes: ["40rem", "15.5rem", "40rem", "15.5rem"], oAuthProviderName: "vk", component: OAuthAgreement},
    facebook_oauth_license_agreement: {sizes: ["40rem", "15.5rem", "40rem", "15.5rem"], oAuthProviderName: "facebook", component: OAuthAgreement},
    ok_oauth_license_agreement: {sizes: ["40rem", "15.5rem", "40rem", "15.5rem"], oAuthProviderName: "ok", component: OAuthAgreement},
    configure_product: {sizes: [], component: ProductConfiguring},
    sharing: {sizes: ["auto", "auto", "24rem", "20rem"], component: Sharing},
    access_rights: {sizes: ["auto", "auto", "24rem", "20rem"], component: AccessRights},
    layers: {sizes: [], component: Layers},

    postcard_editor: {
        sizes: ["56.25rem", "51.875rem", "56.25rem", "51.875rem"],
        background: "#9CC6EA",
        padding: "0",
        component: PostcardEditor
    }, // used in custom project
};

export const ModalProvider = ({children}) => {
    const [get, set] = useState("");

    const closeModal = useCallback(() => set(""), []);

    const styles = {
        overlay: {
            'zIndex': 2100,
            // #144066 with opacity 0.3. Opacity cannot be set separately, cause it will be applied to content also
            backgroundColor: 'rgba(20, 64, 102, 0.3)'
        },
        content: {
            background: modals[get]?.background || "#C4DEF5",
            border: "0.03125 solid #122E73",
            borderRadius: "0.625rem",
            padding: modals[get]?.padding || "1.25rem",
            position: "absolute",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            margin: "auto",
            opacity: "1",
            overflow: ["survey", "sharing", "access_rights"].includes(get) ? "visible" : "auto",
            width: modals[get] && modals[get].sizes[0],
            height: modals[get] && modals[get].sizes[1],
            minWidth: modals[get] && modals[get].sizes[2],
            minHeight: modals[get] && modals[get].sizes[3],
            // overflow: "hidden"
        }
    };

    const getModal = () => get && modals[get] ? <RModal
        isOpen={!!get}
        onRequestClose={(!["license_agreement", "survey"].includes(get)) ? closeModal : null}
        style={styles}
    >
        {get !== "survey" ? <input
            type="button"
            className="modal-button-exit"
            onClick={get !== "license_agreement" ? closeModal : null}
        /> : null}
        <div>
            {React.createElement(modals[get].component, {
                oAuthProviderName: modals[get].oAuthProviderName,
                close: closeModal,
                closeButton: <input
                    type="button"
                    className="modal-button-exit"
                    onClick={get !== "license_agreement" ? closeModal : null}
                />
            })}
        </div>
    </RModal> : null;

    return <ModalContext.Provider value={{getModal, set}}>
        {children}
    </ModalContext.Provider>;
};

export const useModal = () => useContext(ModalContext);

import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {v4 as uuid} from "uuid";

import Rectangle from "../Rectangle";
import {useCanvasBoundingClientRect} from "../../../customHooks/useContextCanvasBoundingClientRect";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import useCooTransformation from "./useCooTransformation";
import getTimeElapsedSince from "../../getTimeElapsedSince";
import {rectangleSchema} from "../../../utils/validators";


export default function useRectangleDrawing() {
    const dispatch = useDispatch();
    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const {color, fontColor, fontSize, scale, startTime, tool, transparency} = useReduxData();
    const {transformYForMap, transformXForMap} = useCooTransformation();

    const [rectangleX, setRectangleX] = useState(0);
    const [rectangleY, setRectangleY] = useState(0);
    const [rectangleWidth, setRectangleWidth] = useState(0);
    const [rectangleHeight, setRectangleHeight] = useState(0);
    const [isRectangleDrawingActive, setIsRectangleDrawingActive] = useState(false);

    const startRectangleDrawing = (xCoo, yCoo) => {
        setIsRectangleDrawingActive(true);
        setRectangleX(xCoo - canvasBoundingClientRect.get.left);
        setRectangleY(yCoo - canvasBoundingClientRect.get.top);
    };

    const changeRectanglePreview = (newXCoo, newYCoo) => {
        if (isRectangleDrawingActive) {
            setRectangleWidth(newXCoo - rectangleX - canvasBoundingClientRect.get.left);
            setRectangleHeight(newYCoo - rectangleY - canvasBoundingClientRect.get.top);
        }
    };

    const finishRectangleDrawing = () => {
        setIsRectangleDrawingActive(false);
        setRectangleX(0);
        setRectangleY(0);
        setRectangleHeight(0);
        setRectangleWidth(0);

        let x = rectangleX,
            y = rectangleY,
            width = rectangleWidth,
            height = rectangleHeight;

        if (width < 0) {
            x += width;
            width *= -1;
        }
        if (height < 0) {
            y += height;
            height *= -1;
        }

        const newRectangleUuid = uuid();
        const newFigureObject = {
            uuid: newRectangleUuid,
            parentUuid: undefined,
            height: Math.round(height) / scale,
            width: Math.round(width) / scale,
            x: Math.round(transformXForMap(x)),
            y: Math.round(transformYForMap(y)),
            tool,
            color,
            fontColor,
            fontSize,
            transparency,
            name: "",
            link: "",
            description: "",
            layout: 50,
            orderIndex: Math.round(new Date().getTime())
        };
        if (!rectangleSchema.isValidSync(newFigureObject)) {
            return;
        }
        dispatch({...newFigureObject, type: "addRectangle", time: getTimeElapsedSince(startTime)});
        dispatch({type: "selectedFigureUuids", value: [newRectangleUuid]});
    };

    const createRectangleInstantly = (image, width, height, x, y, layout = 50) => {
        const newRectangleUuid = uuid();
        const newFigureObject = {
            uuid: newRectangleUuid,
            parentUuid: undefined,
            height: Math.round(height) / scale,
            width: Math.round(width) / scale,
            x,
            y,
            tool: "r-s-74",
            color: "#000000",
            image,
            fontColor,
            fontSize,
            transparency,
            name: "",
            link: "",
            description: "",
            layout,
            orderIndex: Math.round(new Date().getTime())
        };
        if (!rectangleSchema.isValidSync(newFigureObject)) {
            return;
        }
        dispatch({...newFigureObject, type: "addRectangle", time: getTimeElapsedSince(startTime)});
    };

    return {
        rectanglePreview: <Rectangle
            x={rectangleX}
            y={rectangleY}
            width={rectangleWidth}
            height={rectangleHeight}
            stroke={"red"}
        />,
        startRectangleDrawing,
        changeRectanglePreview,
        finishRectangleDrawing,
        createRectangleInstantly
    }
}

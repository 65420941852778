import * as Yup from "yup";


const ARCHIVE_SIZE = 10485760; //10Mb
const SUPPORTED_FORMATS = {
    zip: 'application/zip',
    png: "image/png",
    jpeg: "image/jpeg",
    svg: "image/svg+xml",
    json: "application/json",
};

const createValidationSchema = (additionalFieldLanguages, locale, id, categoryNames) => (Yup.object().shape({
        name_ru: Yup.string()
            .when("$other", {
                is: () => additionalFieldLanguages.includes("russian") || locale?.get?.name === "russian",
                then: Yup.string()
                    .max(50, `${locale?.get?.store.product.form.validation.stringTooLong} 50`)
                    .min(3, locale?.get?.store.product.form.validation.stringTooShort)
                    .required(locale?.get?.store.product.form.validation.nameRequired)
            }),
        name_en: Yup.string()
            .when("$other", {
                is: () => additionalFieldLanguages.includes("english") || locale?.get?.name === "english",
                then: Yup.string()
                    .max(50, `${locale?.get?.store.product.form.validation.stringTooLong} 50`)
                    .min(3, locale?.get?.store.product.form.validation.stringTooShort)
                    .required(locale?.get?.store.product.form.validation.nameRequired)
            }),
        name_cn: Yup.string()
            .when("$other", {
                is: () => additionalFieldLanguages.includes("chinese") || locale?.get?.name === "chinese",
                then: Yup.string()
                    .max(50, `${locale?.get?.store.product.form.validation.stringTooLong} 50`)
                    .min(3, locale?.get?.store.product.form.validation.stringTooShort)
                    .required(locale?.get?.store.product.form.validation.nameRequired)
            }),
        coauthors: Yup.array().of(Yup.string()
            .max(100, `${locale?.get?.store.product.form.validation.stringTooLong} 100`)
            .min(3, locale?.get?.store.product.form.validation.stringTooShort)
        ),
        tags_ru: Yup.array()
            .when("$other", {
                is: () => additionalFieldLanguages.includes("russian") || locale?.get?.name === "russian",
                then: Yup.array()
                    .required(locale?.get?.store.product.form.validation.tagsRequired)
                    .min(1, locale?.get?.store.product.form.validation.tagsRequired)
                    .of(Yup.string().test(
                        "tag test",
                        value => `"${value.value}"${locale?.get?.store.product.form.validation.tagWrong}`,
                        value => !/.*,.*/.test(value)
                    ))
            }),
        tags_en: Yup.array()
            .when("$other", {
                is: () => additionalFieldLanguages.includes("english") || locale?.get?.name === "english",
                then: Yup.array()
                    .required(locale?.get?.store.product.form.validation.tagsRequired)
                    .min(1, locale?.get?.store.product.form.validation.tagsRequired)
                    .of(Yup.string().test(
                        "tag test",
                        value => `"${value.value}"${locale?.get?.store.product.form.validation.tagWrong}`,
                        value => !/.*,.*/.test(value)
                    ))
            }),
        tags_cn: Yup.array()
            .when("$other", {
                is: () => additionalFieldLanguages.includes("chinese") || locale?.get?.name === "chinese",
                then: Yup.array()
                    .required(locale?.get?.store.product.form.validation.tagsRequired)
                    .min(1, locale?.get?.store.product.form.validation.tagsRequired)
                    .of(Yup.string().test(
                        "tag test",
                        value => `"${value.value}"${locale?.get?.store.product.form.validation.tagWrong}`,
                        value => !/.*,.*/.test(value)
                    ))
            }),
        category: Yup.mixed().when("$other", {
            is: () => isNaN(+id),
            then: Yup.string().required(locale?.get?.store.product.form.validation.categoryRequired)
        }),
        description_ru: Yup.string()
            .max(1000, `${locale?.get?.store.product.form.validation.stringTooLong} 1000`),
        description_en: Yup.string()
            .max(1000, `${locale?.get?.store.product.form.validation.stringTooLong} 1000`),
        description_cn: Yup.string()
            .max(1000, `${locale?.get?.store.product.form.validation.stringTooLong} 1000`),
        file: Yup.mixed()
            .test('fileExist', locale?.get?.store.product.form.validation.fileRequired, value => value)
            .test(
                'fileSize',
                `${locale?.get?.store.product.form.validation.maximumFileSize} 10${locale?.get?.store.product.form.validation.Mb}`,
                value => value && value.size <= ARCHIVE_SIZE
            ).when("category", {
                is: category => categoryNames.indexOf(category) === 0,
                then: Yup.mixed().test(
                    "iconArchiveType",
                    `${locale?.get?.store.product.form.validation.unsupportedFileFormat} zip`,
                    value => SUPPORTED_FORMATS.zip === value?.type,
                ),
                otherwise: Yup.mixed().when("category", {
                    is: category => categoryNames.indexOf(category) === 1,
                    then: Yup.mixed().test(
                        "backgroundType",
                        `${locale?.get?.store.product.form.validation.unsupportedFileFormat} png, jpeg`,
                        value => [SUPPORTED_FORMATS.png, SUPPORTED_FORMATS.jpeg].includes(value?.type),
                    ),
                    otherwise: Yup.mixed().when("category", {
                        is: category => categoryNames.indexOf(category) === 2,
                        then: Yup.mixed().test(
                            "polygonType",
                            `${locale?.get?.store.product.form.validation.unsupportedFileFormat} svg`,
                            value => SUPPORTED_FORMATS.svg === value?.type
                        ),
                        otherwise: Yup.mixed().when("category", {
                            is: category => categoryNames.indexOf(category) === 3,
                            then: Yup.mixed().test(
                                "mapType",
                                `${locale?.get?.store.product.form.validation.unsupportedFileFormat} json`,
                                value => SUPPORTED_FORMATS.json === value?.type
                            ),
                        }),
                    }),
                }),
            }),
        license_type: Yup.string().required(locale?.get?.store.product.form.validation.licenseTypeRequired),
        price: Yup.number()
            .min(0, locale?.get?.store.product.form.validation.priceBelowZero)
            .max(100000, locale?.get?.store.product.form.validation.priceTooHigh)
            .required(locale?.get?.store.product.form.validation.priceRequired),
        card_photo_type: Yup.mixed().when("category", {
            is: category => categoryNames.indexOf(category) === 0,
            then: Yup.number()
                .min(0, locale?.get?.store.product.form.validation.cardPhotoTypeRequired)
                .max(1, locale?.get?.store.product.form.validation.cardPhotoTypeRequired)
                .required(locale?.get?.store.product.form.validation.cardPhotoTypeRequired),
        }),
        card_photo: Yup.mixed().when(["file"], {
            is: file => file,
            then: Yup.string().required(locale?.get?.store.product.form.validation.cardPhotoTypeRequired)
                .test(
                    "imageTest",
                    locale?.get?.store.product.form.validation.cardPhotoShouldBeImage,
                    value => value
                        && (value.startsWith("data:image/") || value.startsWith("<svg") || value.startsWith("/maps"))
                ),
        }),
        card_photo_icon_names: Yup.string().when(["card_photo_type", "category"], {
            is: (type, category) => (type === 1 && categoryNames.indexOf(category) === 0),
            then: Yup.string().required(locale?.get?.store.product.form.validation.cardPhotoFromSelectedItemsRequired)
        }),
        publicity: Yup.number()
            .min(0, locale?.get?.store.product.form.validation.publicityTypeRequired)
            .max(1, locale?.get?.store.product.form.validation.publicityTypeRequired)
            .required(locale?.get?.store.product.form.validation.publicityTypeRequired),
        users_able_see: Yup.array().of(Yup.string()
            .max(100, `${locale?.get?.store.product.form.validation.stringTooLong} 100`)
            .min(3, locale?.get?.store.product.form.validation.stringTooShort)
        ),
        updating_scope: Yup.mixed().when("$other", {
            is: () => !isNaN(+id),
            then: Yup.number().oneOf([0, 1, 2])
                .required(locale?.get?.store.product.form.validation.updatingScopeRequired)
        }),
        update_price: Yup.mixed().when("updating_scope", {
            is: (value) => !isNaN(+id) && +value === 0,
            then: Yup.number()
                .min(0, locale?.get?.store.product.form.validation.priceBelowZero)
                .max(100000, locale?.get?.store.product.form.validation.priceTooHigh)
                .required(locale?.get?.store.product.form.validation.priceRequired)
        }),
        updating_review_ru: Yup.string().max(1000, `${locale?.get?.store.product.form.validation.stringTooLong} 1000`),
        updating_review_en: Yup.string().max(1000, `${locale?.get?.store.product.form.validation.stringTooLong} 1000`),
        updating_review_cn: Yup.string().max(1000, `${locale?.get?.store.product.form.validation.stringTooLong} 1000`),
    }));

export default createValidationSchema;
import {useEffect} from "react";
import {useDrag} from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";

import dndItems from "../dndItems";


export default function useTabDragSource(tabId) {
    const [{isDragging}, drag, dragPreview] = useDrag({
        type: dndItems.tab,
        item: () => {
            if (typeof tabId === "number") {
                return {tabId};
            }
            return {tabId: -1};
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => { //hide default preview
        dragPreview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    return [{isTabDragging: isDragging}, drag];
}
import React, {useState, useCallback, useRef, useEffect} from 'react';

import {useApiClient} from "../../../../customHooks/useApiClient";
import {replaceAt} from "../../../../utils";


//original names are often inconsistent
function processRegionName(sourceObject) {
    let region = {};
    sourceObject.parents.map(par => par.contentType === "region" ? Object.assign(region, par) : null);

    let regionType = region.type;
    if (["Край", "Автономная область", "Автономный округ", "Область"].includes(regionType)) {
        regionType = regionType.toLowerCase();
    }

    if (["Саха /Якутия/"].includes(region.name)) {
        return `, ${regionType} ${region.name.split("/").join("").split(" ").join(" - ")}`;
    }
    if (["Ханты-Мансийский Автономный округ - Югра", "Кемеровская область - Кузбасс"].includes(region.name)) {
        return ", " + region.name.split("А").join("а");
    }
    if (
        ["Кабардино-Балкарская", "Карачаево-Черкесская", "Удмуртская", "Чеченская", "Чувашская"].includes(region.name)
        || ["край", "автономная область", "автономный округ", "область"].includes(regionType)
    ) {
        return `, ${region.name} ${regionType}`;
    }
    if (["Город", "Республика"].includes(regionType)) {
        return `, ${regionType} ${region.name}`;
    }

    return ", " + region.name;
}

function processDistrictName(sourceObject) {
    let districts = [];
    sourceObject.parents.map(par => ["district", "cityOwner"].includes(par.contentType) ? districts.push(par) : null);
    let districtString = "";

    districts.reverse().map(district => {
        let districtType = district.typeShort;
        if(!districtType) {
            districtString += "";
            return;
        }

        //incorrect Moscow districts
        if([108803, 108809, 108811, 108821, 108823, 108824, 108825, 108833, 142750].includes(district.zip)) {
            districtType = "г/п";
        }

        //incorrect Moscow districts
        if([142784, 143700, 143800].includes(district.zip)) {
            districtType = "г/о";
        }

        if(district.zip === 652971) {
            districtType = "с/п";
        }

        if(districtType === "у") {
            districtType += ".";
        }

        if(districtType === "г" || districtType === "г.") {
            districtType = "г/о";
        }

        if (["г/о", "г/п", "с/п", "с/с"].includes(districtType)) {
            districtString += `, ${districtType}. ${district.name}`;
            return;
        }

        if (["массив"].includes(districtType)) {
            districtString += `, ${districtType} ${district.name}`;
            return;
        }

        districtString += `, ${district.name} ${districtType}`;
    });

    return districtString;
}

function processCityName(sourceObject) {
    let cityType = sourceObject.typeShort.replace(".", "").replace("_", " ");

    if (!["заимка", "промзона", "жилрайон", "арбан", "массив"].includes(cityType)) {
        cityType += ".";
    }

    let cityName = sourceObject.name;

    cityName = cityName
        .replace(/([^а-яА-ЯёЁ]|^)[сС]овхоза?( |$)/, "$1СВХ$2")
        .replace(" с-за ", " СВХ ")
        .replace(" им. ", " имени ")
        .replace(" им ", " имени ")
        .replace(/([а-яА-ЯёЁ])\(/, "$1 (");

    if (cityName.startsWith("поселок ")) {
        cityName = cityName.replace("поселок ", "");
    }

    if (cityName.toLowerCase().startsWith("ст ") || cityName.toLowerCase().startsWith("ст. ")) {
        return `${cityType} ${"станции " + cityName.slice(3).trim()}`;
    }

    if (cityName.startsWith("сдт")) {
        return `${cityType} ${cityName.replace("сдт ", "СДТ ")}`;
    }

    if (cityName.startsWith("свх") || cityName.startsWith("свх.")) {
        return `${cityType} ${"СВХ " + cityName.slice(4)}`;
    }

    if (
        cityName.toLowerCase().startsWith('центральной усадьбы')
        || cityName.toLowerCase().startsWith('центральная усадьба')
    ) {
        return `${cityType} ${
            cityName
                .replace("Центральной усадьбы ", "ЦУ ")
                .replace("центральной усадьбы ", "ЦУ ")
                .replace("Центральная Усадьба ", "ЦУ ")
                .replace("Центральная усадьба ", "ЦУ ")
                .replace("центральная усадьба ", "ЦУ ")
            }`;
    }

    if (
        cityName.toLowerCase().startsWith('центрального отделения')
        || cityName.toLowerCase().startsWith('центральное отделение')
    ) {
        return `${cityType} ${
            cityName
                .replace("Центрального отделения ", "ЦО ")
                .replace("центрального отделения ", "ЦО ")
                .replace("Центральное Отделение ", "ЦО ")
                .replace("Центральное отделение ", "ЦО ")
                .replace("центральное отделение ", "ЦО ")
            }`;
    }

    if (cityName.startsWith('При ') || cityName.startsWith('Имени ')) {
        return `${cityType} ${cityName.slice(0, 1).toLowerCase() + cityName.slice(1)}`;
    }

    if (cityName.startsWith('опытного') || cityName.startsWith('при ') || cityName.startsWith('имени ')) {
        return `${cityType} ${cityName}`;
    }

    if (cityName.toLowerCase().startsWith('им ')) {
        return `${cityType} ${"имени" + cityName.slice(2)}`;
    }

    if (cityName.toLowerCase().startsWith('им. ')) {
        return `${cityType} ${"имени" + cityName.slice(3)}`;
    }

    return `${cityType} ${cityName.slice(0, 1).toUpperCase() + cityName.slice(1)}`;
}


export default function AnswerLocality ({disableAutofocus, content, lang, answers, setAnswers}) {
    const api = useApiClient();

    const inputRef = useRef(null);
    const answerContainerRef = useRef(null);

    const [suggestedLocalities, setSuggestedLocalities] = useState([]);
    const [hoveredSuggestedLocality, setHoveredSuggestedLocality] = useState("");
    const [selectedSuggestedLocality, setSelectedSuggestedLocality] = useState("");
    const [userInput, setUserInput] = useState("");

    useEffect(() => {
        if (inputRef?.current && !disableAutofocus) {
            inputRef.current.focus();
        }
        setUserInput(answers ? answers[content.id - 1] : "");
    }, []);

    const search = useCallback(async event => {
        const entities = [];
        let result = await api.get("/api/v2/surveys/search-locality", {params: {pattern: event.target.value}});
        if (!result) {
            return;
        }
        result = result.data.result;
        result.map(ent => (ent.id !== "Free" && ent.zip !== 618202 /*Чусовой, дубликат*/) && entities.push(ent));

        setSuggestedLocalities(entities);
    }, [setSuggestedLocalities]);

    const changeValue = useCallback(async event => {
        if (selectedSuggestedLocality || hoveredSuggestedLocality) {
            if (selectedSuggestedLocality) {
                setSelectedSuggestedLocality("");
            } else {
                setHoveredSuggestedLocality("");
            }
            return;
        }

        let value = event.target.value;
        setUserInput(value);
        setAnswers(prev => replaceAt(prev, content.id - 1, value));

        await search(event);

    }, [selectedSuggestedLocality, hoveredSuggestedLocality]);

    const setLocality = event => {
        if (event.target.className === "survey-answers-container") {
            return;
        }
        setSelectedSuggestedLocality(event.target.innerHTML);
        setHoveredSuggestedLocality("");
        setAnswers(prev => replaceAt(prev, content.id - 1, event.target.innerHTML));
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    };

    const hoverLocality = event => setHoveredSuggestedLocality(
        `${event.target.id.split("-")[event.target.id.split("-").length - 1]}|${event.target.innerHTML}`
    );
    const unhoverLocality = () => setHoveredSuggestedLocality("");

    const changeHoveredAnswer = event => {
        if (event.key === "Enter" && hoveredSuggestedLocality) {
            setSelectedSuggestedLocality(hoveredSuggestedLocality.split("|")[1]);
            setHoveredSuggestedLocality("");
            setAnswers(prev => replaceAt(prev, content.id - 1, hoveredSuggestedLocality.split("|")[1]));
            event.stopPropagation();
        }

        if (answerContainerRef?.current) {
            const answerCount = answerContainerRef.current.children.length;
            if (!answerCount) {
                return;
            }
            const currentAnswerIndex = hoveredSuggestedLocality === "" ? undefined : hoveredSuggestedLocality?.split("|")[0];

            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                let targetIndex;
                if (event.key === "ArrowDown") {
                    targetIndex = currentAnswerIndex === undefined ? 0 : (+currentAnswerIndex + 1) % answerCount;
                } else {
                    targetIndex = currentAnswerIndex === undefined ? (answerCount - 1) : (+currentAnswerIndex + answerCount - 1) % answerCount;
                }

                let target = undefined;
                for (let el of answerContainerRef.current.children) {
                    if (+el.id.split("-")[el.id.split("-").length - 1] === targetIndex) {
                        target = el;
                        setHoveredSuggestedLocality(`${targetIndex}|${el.innerHTML}`);
                    }
                }

                let scrollShift = (
                    target.getBoundingClientRect().top
                    - answerContainerRef.current.children[0].getBoundingClientRect().top
                    - 175
                );

                answerContainerRef.current.scrollTop = scrollShift >= 0 ? scrollShift : 0;
            }
        }
    }

    return <>
        {content.title && <p className="survey-p">{content.title[lang]}</p>}
        <div className={`survey-answer-text-container ${
            (suggestedLocalities.length && !selectedSuggestedLocality) ? "survey-dropdown-opened" : "survey-dropdown"
        }`}>
            <input
                type="text"
                className="survey-answer-text-input"
                ref={inputRef}
                value={hoveredSuggestedLocality?.split("|")[1] || selectedSuggestedLocality || userInput}
                onChange={changeValue}
                onFocus={search}
                onKeyDown={changeHoveredAnswer}
            />
            <div
                className="survey-answers-container"
                ref={answerContainerRef}
                onClick={setLocality}
            >
                {suggestedLocalities.map((ans, i) => <div
                    id={`survey-possible-answer-${i}`}
                    className={`survey-possible-answer${
                        (hoveredSuggestedLocality && +hoveredSuggestedLocality.split("|")[0] === i) ? "-active" : ""
                    }`}
                    onMouseEnter={hoverLocality}
                    onMouseLeave={unhoverLocality}
                >{processCityName(ans)}{processDistrictName(ans)}{processRegionName(ans)}</div>)}
            </div>
        </div>
    </>;
}

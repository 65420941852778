import {toast} from "react-toastify";
import JSZip from "jszip";
import isSvg from "is-svg";

export const compareTags = locale => ((l, r) => {
    if (!locale || !locale.get) {
        return -1;
    }
    if (l.language === r.language) {
        return l.name >= r.name ? 1 : -1;
    } else if (l.language === locale.get.abbr.toLowerCase() || (l.language === "en" && r.language !== locale.get.abbr.toLowerCase())) {
        return 1;
    } else if (l.language !== locale.get.abbr.toLowerCase() && l.language !== "en" && r.language !== locale.get.abbr.toLowerCase() && r.language !== "en") {
        return l.language >= r.language ? 1 : -1;
    }
    return -1;
});

export const cropString = (text, max_length) => text.length > max_length ? text.slice(0, max_length - 3) + "..." : text;

export const getLangAbbrByEndonym = (languageAbbrs, endonym) => {
    for (let i = 0; i < Object.keys(languageAbbrs).length - 1; i++) {
        if (languageAbbrs[Object.keys(languageAbbrs)[i]].endonym === endonym) {
            return Object.keys(languageAbbrs)[i];
        }
    }
}

export const getProductLangs = product => {
    let langs = [];
    for (let i = 0; i < Object.keys(product.name).length; i++) {
        if (product.name[Object.keys(product.name)[i]].length > 0) {
            langs.push(Object.keys(product.name)[i]);
        }
    }
    return langs;
}

export const getFieldContent = (property, userLocale) => {
    let content = property[userLocale] || property.en || "";
    if (content === "") {
        for (let i = 0; i < Object.keys(property).length; i++) {
            if (
                ![userLocale, "en"].includes(Object.keys(property)[i])
                && property[Object.keys(property)[i]].length > 0
            ) {
                // console.log(property[Object.keys(property)[i]]);
                return property[Object.keys(property)[i]];
            }
        }
    }
    return content;
}

export const extractRasterArchive = async (file, locale, showWarnings = true) => {
    const SUPPORTED_ARCHIVE_FORMATS = ['application/zip'];

    if (!SUPPORTED_ARCHIVE_FORMATS.includes(file.type)) {
        if (showWarnings) {
            toast.error(locale?.get?.store.product.form.validation.archive.unsupportedArchiveType);
        }
        return undefined;
    }

    let errorOccurred = "";
    let icons = [];
    let incorrectIcons = [];

    let zip = JSZip();

    try {
        let archive = await zip.loadAsync(file);

        let iconsInBase64 = [];
        if (!archive || !archive?.files || !Object.keys(archive.files).length) {
            errorOccurred = "archiveIsEmpty";
            return undefined;
        }

        let zipEntries = [];
        let iconsInArchive = 0;
        archive.forEach((relativePath, zipEntry) => {
            iconsInArchive += zipEntry.dir ? 0 : 1;
            zipEntries.push(zipEntry);
        });

        for (let i = 0; i < zipEntries.length; i++) {
            let fileName = zipEntries[i].name.split("/")[zipEntries[i].name.split("/").length - 1];
            let result = await archive.file(zipEntries[i].name)?.async("base64");

            try {
                iconsInBase64.push({
                    data: "data:image/png;base64," + result,
                    name: zipEntries[i].name
                });
            } catch(e) {
                incorrectIcons.push(fileName);
            }

            if (iconsInArchive === incorrectIcons.length) {
                errorOccurred = "allFilesInArchiveAreIncorrect";
            }
        }

        icons = iconsInBase64;
    } catch (error) {
        console.log(error);
        errorOccurred = "archiveIsIncorrect";
    }

    if ([
        "archiveIsIncorrect",
        "archiveIsEmpty",
        "allFilesInArchiveAreIncorrect"
    ].includes(errorOccurred)) {
        if (showWarnings) {
            toast.error(locale?.get?.store.product.form.validation.archive[errorOccurred]);
        }
        return;
    } else if (incorrectIcons.length > 0 && showWarnings) {
        toast.warn(`${locale?.get?.store.product.form.validation.archive.archiveContainsIncorrectFiles} ${incorrectIcons.join(", ")}`);
    }

    if (icons.length > 0) {
        return icons;
    }
};

export const extractVectorArchive = async (file, locale, showWarnings = true) => {
    const SUPPORTED_ARCHIVE_FORMATS = ['application/zip'];

    if (!SUPPORTED_ARCHIVE_FORMATS.includes(file.type)) {
        if (showWarnings) {
            toast.error(locale?.get?.store.product.form.validation.archive.unsupportedArchiveType);
        }
        return undefined;
    }

    let errorOccurred = "";
    let icons = [];
    let incorrectIcons = [];

    let zip = JSZip();

    try {
        let archive = await zip.loadAsync(file);
        let iconsInBase64 = [];
        let zipEntries = [];

        if (!archive || !archive?.files || !Object.keys(archive.files).length) {
            errorOccurred = "archiveIsEmpty";
        } else {
            let iconsInArchive = 0;
            archive.forEach((relativePath, zipEntry) => {
                iconsInArchive += zipEntry.dir ? 0 : 1;
                zipEntries.push(zipEntry);
            });

            for (let i = 0; i < zipEntries.length; i++) { // We cannot use forEach, because we need await
                let fileName = zipEntries[i].name.split("/")[zipEntries[i].name.split("/").length - 1];

                if (fileName === "") { //folder
                    continue;
                }

                let result = await archive.file(zipEntries[i].name)?.async("uint8array");

                if (!isSvg(new TextDecoder().decode(result))) {
                    incorrectIcons.push(fileName);
                } else {
                    try {
                        iconsInBase64.push({
                            textData: new TextDecoder().decode(result),
                            data: "data:image/svg+xml;base64," + btoa(new TextDecoder().decode(result)),
                            name: fileName
                        });
                    } catch(e) {
                        // console.log(fileName);
                        incorrectIcons.push(fileName);
                    }
                }

                if (iconsInArchive === incorrectIcons.length) {
                    errorOccurred = "allFilesInArchiveAreIncorrect";
                }
            }
            icons = iconsInBase64;
        }
    } catch (error) {
        errorOccurred = "archiveIsIncorrect";
    }

    if ([
        "archiveIsIncorrect",
        "archiveIsEmpty",
        "allFilesInArchiveAreIncorrect"
    ].includes(errorOccurred)) {
        if (showWarnings) {
            toast.error(locale?.get?.store.product.form.validation.archive[errorOccurred]);
        }
        return;
    } else if (incorrectIcons.length > 0) {
        if (showWarnings) {
            toast.error(`${locale?.get?.store.product.form.validation.archive.archiveContainsIncorrectFiles} ${incorrectIcons.join(", ")}`);
        }
        return;
    }

    if (icons.length > 0) {
        return icons;
    }
};

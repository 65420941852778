import {useState, useMemo, useCallback} from "react";

import {useLocalization} from "../../../../customHooks/useContextLocalization";

import "./confirmer.css";


export default function useConfirmer () {
    const [message, setMessage] = useState("");

    const handlers = useMemo(() => ({
        showConfirmer: msg => setMessage(msg),
        hideConfirmer: () => setMessage(""),
    }), [setMessage]);

    return [message, handlers];
}


export function Confirmer({message, onConfirm, hideConfirmer}) {
    const locale = useLocalization();

    const confirm = useCallback(() => {
        onConfirm();
        hideConfirmer();
    }, [onConfirm, hideConfirmer]);

    return message ? <div className="confirmer-shadow-box">
        <span>{message}</span>
        <div>
            <input type="button" value={locale.get.yes} onClick={confirm}/>
            <input type="button" value={locale.get.no} onClick={hideConfirmer}/>
        </div>
    </div> : null;
}
import React from "react";

import Maps from "./Maps";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useCurrentProject} from "../../customHooks/useContextCurrentProject";


export default function UsersMaps() {
    const locale = useLocalization();
    const {get: currentProject} = useCurrentProject();
    return <Maps
        mapsLink={`/api/v2/map/shared_with_user/?current_project_id=${currentProject.id}`}
        title={locale?.get?.studio.usersMaps.usersMaps}
        noMapsMessage={locale?.get?.studio.usersMaps.youHaveNoMaps}
        showSpace={false}
    />
}

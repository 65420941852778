import React, {useMemo} from "react";

import TransformationalConnectorContainer from "../../connectors/TransformationalConnectorContainer";
import SnappingConnectorContainer from "../../connectors/SnappingConnectorContainer";
import {useReduxData} from "../../../customHooks/useContextReduxData";


/* connector preview can't be split into static and transforming
* because of these types must be in one component for correct event cycle
* (we can't mouse down on one and mouse up on another)*/
export default function ({movementType, transformingConnectorParents, isMouseDownOnCanvas, handleMouseEnterOnConnector}) {
    const {figures, isEditMode, selectedConnectorUuids, selectedLeafUuids} = useReduxData();

    const figureToRenderUuids = useMemo(() => {
        return movementType === "figure"
            ? selectedConnectorUuids.filter(uuid => !selectedLeafUuids.includes(uuid))
            : [...new Set(selectedLeafUuids.concat(selectedConnectorUuids))]; // union without repeat
    }, [selectedLeafUuids, selectedConnectorUuids, movementType]);

    return <>{[
        isEditMode && figureToRenderUuids.map(uuid =>
            <TransformationalConnectorContainer
                key={`${uuid}-transformation`}
                figure={movementType === "connector" && selectedConnectorUuids.includes(uuid) && transformingConnectorParents[uuid]
                    ? transformingConnectorParents[uuid]
                    : figures.get(uuid)
                }
            />
        ),
        [...figures].map(([uuid, figure]) => //it can be rendered when arrow want to clip in edit mode
            <SnappingConnectorContainer
                key={`${uuid}-snapping`}
                figure={figure}
                isMouseDownOnCanvas={isMouseDownOnCanvas}
                handleMouseEnterOnConnector={handleMouseEnterOnConnector}
            />
        )
    ]}</>
}
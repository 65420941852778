import React, {useCallback, useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import Avatar from "../components/Avatar";
import {useModal} from "./useContextModal";
import {useApiClient} from "./useApiClient";
import {useLocalization} from "./useContextLocalization";
import useSurvey from "../studio/customHooks/useSurvey";


export const CurrentUserContext = React.createContext();


export const CurrentUserProvider = ({children}) => {
    const api = useApiClient();
    const [user, setUser] = useState();

    const fetchUser = useCallback(() => {
        api.get('/api/v1/user/me', {restricted: false})
            .then(resp => setUser(resp.data))
            .catch(() => setUser(null));
    }, [api]);

    useEffect(() => fetchUser(), []);

    return <CurrentUserContext.Provider value={{user, setUser, fetchUser}}>
        {children}
    </CurrentUserContext.Provider>;
};


export const useCurrentUser = (allow_unauthorized=false) => {
    const {user} = useContext(CurrentUserContext);
    return user;
}


export const UserInlineView = ({user, prefix, show_avatar=true, show_login=true, size=25, ...props}) => {
    const modal = useModal();
    const openEditProfilePopup = () => !prefix && modal.set("edit_profile");
    return <div className={`${prefix || "top-menu-"}user-name-container`} onClick={props.onClick || undefined}>
        <span onClick={openEditProfilePopup}>{user && show_login && user.username}</span>
        {user && show_avatar && <Avatar
            imgUrl={user.custom_avatar || user.social_avatar}
            imgClassName="react-gravatar"
            wrapperClassName="top-menu-avatar-wrapper"
            email={user.email}
            size={size}
        />}
    </div>;
}


export const UserToolbarView = (props) => {
    const history = useHistory();
    const locale = useLocalization();
    const api = useApiClient();
    const {tryToShowSurvey} = useSurvey();
    const {user, setUser} = useContext(CurrentUserContext);

    const logout = useCallback(async () => {
        await api.delete('/api/v1/token/');
        setUser(undefined);
        window.location.reload();
    }, [api]);

    const showSurvey = async () => tryToShowSurvey(true);

    const openAnalystPage = () => history.push("/project");

    return <div className={`${props.prefix || ""}dropdown`}>
        <div className={`${props.prefix || ""}dropbtn`}><UserInlineView {...props} /></div>
        <div className={`${props.prefix || ""}dropdown-content`}>
            {!props.prefix && <span onClick={showSurvey}>{locale?.get.studio.header.project.survey}</span>}
            {!props.prefix && user?.is_analyst &&
                <span onClick={openAnalystPage}>{locale?.get.studio.header.project.project}</span>
            }
            <span onClick={logout}>{locale?.get.auth.signingout}</span>
        </div>
    </div>;
}

import React from "react";

import Studio from "./Studio";
import {SnappingModeProvider} from "./customHooks/useContextSnappingMode";
import {MapFileNameProvider} from "./customHooks/useContextMapFileName";
import {MapProvider} from "./customHooks/useContextMap";
import {OnboardingProvider} from "./customHooks/useContextOnboarding";
import {CanvasBoundingClientRectProvider} from "./customHooks/useContextCanvasBoundingClientRect";
import {ReduxDataProvider} from "./customHooks/useContextReduxData";
import {UndoRedoProvider} from "./customHooks/useContextUndoRedo";
import {DeleteObjectsProvider} from "./customHooks/useContextDeleteObjects";
import {CopyPasteProvider} from "./customHooks/useContextCopyPaste";
import {GroupingObjectsProvider} from "./customHooks/useContextGroupingObjects";
import {PencilDrawingProvider} from "./customHooks/useContextPencilDrawing";
import {LongPolygonDrawingProvider} from "./customHooks/useContextLongPolygonDrawing";
import {IsMouseDownProvider} from "./customHooks/useContextIsMouseDown";
import {UserViewBoxProvider} from "./customHooks/useContextUserViewBox";
import {GridProvider} from "./customHooks/useContextGrid";
import {MapMembersProvider} from "./customHooks/useContextMapMembers";
import {ShouldHideToolbarProvider} from "./customHooks/useContextShouldHideToolbar";
import {ShouldLiftZindexUpProvider} from "./customHooks/useContextShouldLiftZindexUp";
import {SelectedFigureContainerEventsProvider} from "./components/canvas/customHooks/useSelectedFigureContainerEvents";
import {
    ShouldShowColorPickerProvider
} from "./components/header/baseBlock/toolBlockBigItems/customHooks/useContextShouldShowColorPicker";
import {ChatProvider} from "./customHooks/useContextChat";
import {useLocalization} from "../customHooks/useContextLocalization";



export default function StudioWithProviders() {
    const locale = useLocalization();

    return locale?.get ?
        <SnappingModeProvider>
        <MapFileNameProvider>
        <CanvasBoundingClientRectProvider>
        <ReduxDataProvider>
        <MapProvider>
        <OnboardingProvider>
        <UndoRedoProvider>
        <DeleteObjectsProvider>
        <CopyPasteProvider>
        <GroupingObjectsProvider>
        <PencilDrawingProvider>
        <UserViewBoxProvider>
        <LongPolygonDrawingProvider>
        <IsMouseDownProvider>
        <GridProvider>
        <ShouldHideToolbarProvider>
        <ShouldLiftZindexUpProvider>
        <ShouldShowColorPickerProvider>
        <SelectedFigureContainerEventsProvider>
        <MapMembersProvider>
        <ChatProvider>
            <Studio/>
        </ChatProvider>
        </MapMembersProvider>
        </SelectedFigureContainerEventsProvider>
        </ShouldShowColorPickerProvider>
        </ShouldLiftZindexUpProvider>
        </ShouldHideToolbarProvider>
        </GridProvider>
        </IsMouseDownProvider>
        </LongPolygonDrawingProvider>
        </UserViewBoxProvider>
        </PencilDrawingProvider>
        </GroupingObjectsProvider>
        </CopyPasteProvider>
        </DeleteObjectsProvider>
        </UndoRedoProvider>
        </OnboardingProvider>
        </MapProvider>
        </ReduxDataProvider>
        </CanvasBoundingClientRectProvider>
        </MapFileNameProvider>
        </SnappingModeProvider> : <span>Localization not found</span>;
}

import React from "react";

import Scrollbar from "../../../../components/Scrollbar";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useIconSet} from "../../../../customHooks/useContextIconSet";
import {useReduxData} from "../../../customHooks/useContextReduxData";


export default function({tabIndex, selectTab}) {
    const {isWatchMode} = useReduxData();
    const locale = useLocalization();
    const {get: {tabSet}} = useIconSet();

    return <div className="figure-bar-tab-title-scroll-container">
        <Scrollbar
            autohide={false}
            classNamePrefix="tool-tabs-"
            style={{height: tabSet.length > 0 ? "1.875rem" : "0", width: "100%"}}
        >
            <ul className="figure-bar-tab-title-container">
                {tabSet.map((tab, i) => <li
                    key={i}
                    className={`figure-bar-tab-title${tabIndex === i ? "-active" : ""}${isWatchMode ? " text-disabled" : ""}`}
                    id={`head-title-${i}`}
                    onClick={selectTab}
                >{tab.name[locale?.get.name]}</li>)}
            </ul>
        </Scrollbar>
    </div>;
}

import React, {useEffect, useState} from "react";

import Figure from "../../figures/Figure";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import {checkIfArrowDependent} from "../../figures/utils/arrow";


export default function ({movementType, isObjectsMoved, setInitialPreviewCoo}) {
    const {figures, selectedConnectorUuids, selectedFigureUuids, selectedLeafUuids} = useReduxData();

    const [figureToRenderUuids, setFigureToRenderUuids] = useState([]);

    useEffect(() => {
        setFigureToRenderUuids(movementType === "figure" && isObjectsMoved
            ? selectedConnectorUuids.filter(uuid =>
                !selectedLeafUuids.includes(uuid) && !checkIfArrowDependent(figures.get(uuid))
            ) : movementType === "connector"
                ? selectedLeafUuids.filter(uuid => !selectedConnectorUuids.includes(uuid))
                : [...new Set(selectedFigureUuids.concat(selectedConnectorUuids))] // union without repeat // TODO: check if I need to replace selectedConnectorUuids with its union with group
        );
    }, [selectedFigureUuids, selectedConnectorUuids, selectedLeafUuids, movementType, isObjectsMoved]);

    return <g onMouseDown={setInitialPreviewCoo}>
        {figureToRenderUuids.map(uuid => figures.get(uuid) &&
            <Figure key={figures.get(uuid).get("uuid")} figure={figures.get(uuid)} kind={"separate"}/>)}
    </g>;
}

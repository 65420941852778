

export default function (dict) {
    return Object.keys(dict).reduce((max, val) => +dict[val] > +dict[max] ? +val : +max, Object.keys(dict)[0]);
}

function sum(arr) {
    return arr.reduce(function(a, b) { return a + b; }, 0);
}

export function getWeightedAverage(dict) {
    const weightSum = sum(Object.keys(dict).map(key => +dict[key]));
    return sum(Object.keys(dict).map(key => +key * +dict[key])) / weightSum;
}

import React from 'react';

import Question from "./Question";


export default function SurveyPage({content, lang, answers, setAnswers}) {
    return <>
        {content?.title && <h4 className = "modal-survey-title">{content.title[lang]}</h4>}
        {content?.elements && content.elements.map(el =>
            <Question content = {el} lang = {lang} answers = {answers} setAnswers = {setAnswers}/>
        )}
    </>;
}

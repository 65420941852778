import React, {useMemo} from "react";

import HintConnector from "../connectors/HintConnector";
import SnappingConnector from "../connectors/SnappingConnector";
import useCooTransformation from "../../canvas/customHooks/useCooTransformation";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import {useSnappingMode} from "../../../customHooks/useContextSnappingMode";
import {checkIsTheOnlyArrowSelected} from "../../figures/utils/arrow";


export default function ({parentFigure, isMouseDown}) {
    const {get: isSnappingMode} = useSnappingMode();
    const {figures, isEditMode, scale, selectedFigureUuids, tool} = useReduxData();
    const {transformXForCanvas, transformYForCanvas} = useCooTransformation();

    const isTheOnlyArrowSelected = useMemo(
        () => checkIsTheOnlyArrowSelected(figures, selectedFigureUuids),
        [figures, selectedFigureUuids]
    );

    const points = useMemo(() => {
        const rectWidth = parentFigure.get("width") * scale;
        const rectHeight = parentFigure.get("height") * scale;
        return [
            [0, 0],
            [rectWidth / 2, 0],
            [rectWidth, 0],
            [rectWidth, rectHeight / 2],
            [rectWidth, rectHeight],
            [rectWidth / 2, rectHeight],
            [0, rectHeight],
            [0, rectHeight / 2]
        ];
    }, [parentFigure.get("width"), parentFigure.get("height"), scale]);

    const outline = useMemo(() => {
        return [points[0], points[2], points[4], points[6], points[0]]
            .map(point => point.map(coo => +coo + 12).join(",")).join(" ");
    }, [points]);

    return <svg x={transformXForCanvas(parentFigure.get("x")) - 12} y={transformYForCanvas(parentFigure.get("y")) - 12}>
        {!isEditMode && points.map((point, i) =>
            <HintConnector key={`${i}-hc`} x={point[0] + 12} y={point[1] + 12}/>
        )}
        {isSnappingMode && ((!isEditMode && tool.split("-")[0] === "a") || (isEditMode && isTheOnlyArrowSelected)) &&
            <>
                <polyline points={outline} stroke="#FF983D" strokeWidth="2" fill="none"/>
                {points.map((point, i) =>
                    <SnappingConnector
                        key={`${i}-sc`}
                        id={`${parentFigure.get("uuid")}|${i}|snapping`}
                        x={point[0] + 12}
                        y={point[1] + 12}
                        isMouseDown={isMouseDown}
                        handleMouseEnter={() => {
                        }}
                    />
                )}
            </>
        }
    </svg>;
}

import React, {useMemo} from "react";

import PathSnappingConnectorContainer from "./snappingConnectorContainers/PathSnappingConnectorContainer";
import RectangleSnappingConnectorContainer from "./snappingConnectorContainers/RectangleSnappingConnectorContainer";
import PointFeatureSnappingConnectorContainer
    from "./snappingConnectorContainers/PointFeatureSnappingConnectorContainer";


export default function ({figure, isMouseDownOnCanvas, handleMouseEnterOnConnector}) {
    const figureType = useMemo(() => {
        const figureToolType = figure.get("tool")?.split("-")?.[0];
        return ["p", "pl", "pr", "c"].includes(figureToolType) ? "path" : figureToolType === "r"
            ? "rectangle"
            : figureToolType === "i" ? "pointFeature" : "";
    }, [figure.get("tool")]);

    return useMemo(() => {
        return figureType === "path"
            ? <PathSnappingConnectorContainer
                parentFigure={figure}
                isMouseDown={isMouseDownOnCanvas}
                handleMouseEnter={handleMouseEnterOnConnector}
            />
            : figureType === "rectangle" ? <RectangleSnappingConnectorContainer
                parentFigure={figure}
                isMouseDown={isMouseDownOnCanvas}
            /> : figureType === "pointFeature"
                ? <PointFeatureSnappingConnectorContainer
                parentFigure={figure}
                isMouseDown={isMouseDownOnCanvas}
            /> : null;
    }, [figure, isMouseDownOnCanvas, handleMouseEnterOnConnector, figureType]);
}
import React from "react";

import LinkToNewWindow from "../../../components/LinkToNewTab";
import EditorModeSwitch from "./rightPart/EditorModeSwitch";
import LanguageSelector from "./rightPart/LanguageSelector";
import ScaleSlider from "./rightPart/ScaleSlider";
import SocialNetworkIcon from "../../../components/SocialNetworkIcon";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useCurrentProject} from "../../customHooks/useContextCurrentProject";
import {useChat} from "../../customHooks/useContextChat";
import tooltipHOC from "../../../components/tooltipHOC";

import {ReactComponent as IconHelp} from "../../images/controls/icon-help.svg";
import {ReactComponent as IconChat} from "../../images/controls/icon-chat.svg";
import {ReactComponent as IconChatBlack} from "../../images/controls/icon-chat-black.svg";
import {ReactComponent as TGIcon} from "../../../images/social/icon-telegram.svg";
import {ReactComponent as VKIcon} from "../../../images/social/icon-vk.svg";

import "./footer.scss";


const HelpButton = tooltipHOC(IconHelp, {display: "flex"}, {direction: "top", padding: "10px"});


export default function () {
    const locale = useLocalization();
    const {get: currentProject} = useCurrentProject();
    const {get: shouldShowChat, set: setChatState} = useChat();

    const goToHelp = () => window.open(`/docs/${locale.get.abbr.toLowerCase()}/start/1-map-editor/`);

    const handleChatState = () => setChatState(prev => !prev);

    return <footer className="footer">
        <div className="footer-sharing-container">
            <p className="footer-normal-text">{locale?.get.studio.footer.left.weAreIn}</p>
            <LinkToNewWindow
                link="https://t.me/studiomaps"
                content={
                    <SocialNetworkIcon
                        id="footer-social-telegram"
                        className="footer-icon-wrapper"
                        SvgIcon={TGIcon}
                        iconSize="1.5rem"
                    />
                }
            />
            <LinkToNewWindow
                link="https://vk.com/public202161779"
                content={
                    <SocialNetworkIcon
                        id="footer-social-vk"
                        className="footer-icon-wrapper"
                        SvgIcon={VKIcon}
                        iconSize="1.5rem"
                    />
                }
            />
            <p className="footer-studio-name">Creative Maps Studio</p>
            <p className="footer-company-name"> - Human Semantics</p>
        </div>
        <div className="footer-sharing-container">
            <div className="chat-icon-wrapper" onClick={handleChatState}>
                {shouldShowChat ? <IconChatBlack/> :<IconChat/>}
            </div>
            {(currentProject.config?.hiddenElements || {}).watchMode !== "all" && <EditorModeSwitch/>}
            <LanguageSelector/>
            <ScaleSlider/>
            <HelpButton
                className="studio-footer-help-button"
                onClick={goToHelp}
                tooltipContent={locale?.get.studio.header.menu.help.title}
            />
        </div>
    </footer>;
}

import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";

import SocialNetworkIcon from "../../../components/SocialNetworkIcon";
import ShareToSomeoneField from "./sharing/ShareToSomeoneField";
import AccessRights from "./sharing/AccessRights";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {useMap} from "../../customHooks/useContextMap";
import {useModal} from "../../../customHooks/useContextModal";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useMapMembers} from "../../customHooks/useContextMapMembers";
import {useApiClient} from "../../../customHooks/useApiClient";
import {copyToClipboard} from "./sharing/utils/copyToClipboard";

import {ReactComponent as TGIcon} from "../../../images/social/icon-telegram.svg";
import {ReactComponent as VKIcon} from "../../../images/social/icon-vk.svg";
import {ReactComponent as CPIcon} from "../header/baseBlock/images/first-block/copy.svg";


function CPIconTuned(props) {
    return <CPIcon viewBox="3 3 24 24" {...props}/>;
}


export default function () {
    const [sharingMode, setSharingMode] = useState("viewer");
    const {shareMap, mapId} = useMap();
    const user = useCurrentUser();
    const modal = useModal();
    const api = useApiClient();
    const locale = useLocalization();
    const {get: members} = useMapMembers();

    const openSignInIfUnauthorized = () => !user && modal.set("signin");
    const getWindowPosition = () => ({x: window.screen.height / 2 - 150, y: window.screen.width / 2 - 300});

    const options = {
        editor: locale.get.studio.sharing.editing,
        viewer: locale.get.studio.sharing.viewing,
        commentator: locale.get.studio.sharing.commenting
    };

    useEffect(() => {
        if (mapId) {
            api.get(`/api/v2/map/${mapId}/link_right/`).then(response => setSharingMode(response.data));
        }
    }, [mapId]);

    const share = async () => {
        openSignInIfUnauthorized();
        const url = await shareMap(sharingMode);
        if (!user) {
            toast.warn(locale?.get.studio.sharing.authRequired);
        }
        return url;
    }

    const handleShareToTelegram = async () => {
        const {x: popupLeft, y: popupTop} = getWindowPosition();
        let url = await share();
        if (!url) {
            return undefined;
        }
        const text = "";
        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=300,left=${popupLeft},top=${popupTop}`;

        window.open(`https://t.me/share/url?url=${url}&text=${text}`, "tg.share", params);
    };

    const handleShareToVK = async () => {
        const {x: popupLeft, y: popupTop} = getWindowPosition();
        let url = await share();
        if (!url) {
            return undefined;
        }
        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=300,left=${popupLeft},top=${popupTop}`;

        window.open(`https://vk.com/share.php?url=${url}`, "vk.share", params);
    };

    const handleCopyLink = async () => {
        let url = await share();
        if (!url) {
            return undefined;
        }
        copyToClipboard(url).then(
            () => toast.info(locale?.get.studio.sharing.linkCopiedSuccessfully),
            () => toast.warn(locale?.get.studio.sharing.copyLinkFailed)
        );
    };

    const openAccessRightsConfiguration = () => modal.set("access_rights");

    const isCurrentUserAuthor = members.filter(member => member.username === user.username && member.role === "author")
        .length > 0;

    return <div className="modal-container-overflow-friendly">
        <h4 className="modal-sharing-title">{locale?.get.studio.sharing.sharingTitle}</h4>
        <div className="modal-sharing-section-container">
            <p className="modal-sharing-p">{locale?.get.studio.sharing.accessRights}</p>
            {isCurrentUserAuthor
                ? <AccessRights setSharingMode={setSharingMode}/>
                : <p className="modal-sharing-p">{options[sharingMode]}</p>
            }
        </div>
        <div className="modal-sharing-section-container">
            <p className="modal-sharing-p">{locale?.get.studio.sharing.shareLink}</p>
            <div className="modal-share-buttons-container">
                <SocialNetworkIcon
                    id="footer-social-copy"
                    className="sharing-icon-wrapper"
                    SvgIcon={CPIconTuned}
                    iconSize="2.5rem"
                    backgroundColors={{dark: "#144066", light: "#9CC6EA"}}
                    onClick={handleCopyLink}
                />
                <SocialNetworkIcon
                    id="footer-social-telegram"
                    className="sharing-icon-wrapper"
                    SvgIcon={TGIcon}
                    iconSize="2.5rem"
                    backgroundColors={{dark: "#144066", light: "#9CC6EA"}}
                    onClick={handleShareToTelegram}
                />
                <SocialNetworkIcon
                    id="footer-social-vk"
                    className="sharing-icon-wrapper"
                    SvgIcon={VKIcon}
                    iconSize="2.5rem"
                    backgroundColors={{dark: "#144066", light: "#9CC6EA"}}
                    onClick={handleShareToVK}
                />
            </div>
        </div>
        <div className="modal-sharing-section-container">
            <p className="modal-sharing-p">{locale?.get.studio.sharing.invite}</p>
            <ShareToSomeoneField openSignInIfUnauthorized={openSignInIfUnauthorized} sharingMode={sharingMode}/>
        </div>
        <input
            type="button"
            className="modal-access-rights-button"
            value={locale?.get.studio.sharing.accessConfiguration}
            onClick={openAccessRightsConfiguration}
        />
    </div>;
}

import {useEffect} from "react";

import {useCurrentUser} from "../customHooks/useContextCurrentUser";
import {useMap} from "./customHooks/useContextMap";


export default function AutosendToServerHandler () {
    const user = useCurrentUser();
    const map = useMap();

    useEffect(() => {
        const interval = setInterval(() => user && map.saveMapToServer("auto"), 240000);
        return () => clearInterval(interval);
    }, [user, map]);

    return null;
}

import React from 'react';

import QuestionHTML from "./QuestionHTML";
import QuestionRadio from "./QuestionRadio";
import QuestionNumber from "./QuestionNumber";
import QuestionLocality from "./QuestionLocality";
import QuestionRating from "./QuestionRating";


export default function Question({content, lang, answers, setAnswers}) {
    switch (content.type) {
        case "html": {
            return <QuestionHTML html={content.html[lang]}/>;
        }
        case "radiogroup": {
            return <QuestionRadio content={content} lang={lang} answers={answers} setAnswers={setAnswers}/>;
        }
        case "rating": {
            return <QuestionRating content={content} lang={lang} answers={answers} setAnswers={setAnswers}/>;
        }
        case "text": {
            switch (content.inputType) {
                case "number": {
                    return <QuestionNumber content={content} lang={lang} answers={answers} setAnswers={setAnswers}/>;
                }
                case "locality": {
                    return <QuestionLocality content={content} lang={lang} answers={answers} setAnswers={setAnswers}/>;
                }
                default: {
                    return null;
                }
            }
        }
        default: {
            return null;
        }
    }
}

import React, {useMemo} from "react";

import HintConnector from "../connectors/HintConnector";
import SnappingConnector from "../connectors/SnappingConnector";
import useCooTransformation from "../../canvas/customHooks/useCooTransformation";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import {useSnappingMode} from "../../../customHooks/useContextSnappingMode";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {checkIsTheOnlyArrowSelected} from "../../figures/utils/arrow";


export default function ({parentFigure, isMouseDown}) {
    const {get: isSnappingMode} = useSnappingMode();
    const {figures, isEditMode, scale, selectedFigureUuids, tool} = useReduxData();
    const {transformXForCanvas, transformYForCanvas} = useCooTransformation();
    const locale = useLocalization();

    const isTheOnlyArrowSelected = useMemo(
        () => checkIsTheOnlyArrowSelected(figures, selectedFigureUuids),
        [figures, selectedFigureUuids]
    );

    const transformedFigureX = transformXForCanvas(parentFigure.get("x")),
        transformedFigureY = transformYForCanvas(parentFigure.get("y"));

    const points = useMemo(() => {
        const volume = parentFigure.get("volume") * scale;
        let x = 1.5 * volume;
        let y = volume;
        let rectHeight = volume;
        let rectWidth = volume;

        return [
            [x, y],
            [x + rectWidth / 2, y],
            [x + rectWidth, y],
            [x + rectWidth, y + rectHeight / 2],
            [x + rectWidth, y + rectHeight],
            [x + rectWidth / 2, y + rectHeight],
            [x, y + rectHeight],
            [x, y + rectHeight / 2]
        ];
    }, [parentFigure.get("volume"), scale]);

    const outline = useMemo(() => {
        return [points[0], points[2], points[4], points[6], points[0]]
            .map(point => point.map(coo => +coo + 12).join(",")).join(" ");
    }, [points]);

    return <svg x={transformedFigureX - 12} y={transformedFigureY - 12}>
        {!isEditMode && points.map((point, i) =>
            <HintConnector key={`${i}-hc`} x={point[0] + 12} y={point[1] + 12}/>
        )}
        {isSnappingMode && ((!isEditMode && tool.split("-")[0] === "a") || (isEditMode && isTheOnlyArrowSelected)) &&
            <>
                <polyline points={outline} stroke="#FF983D" strokeWidth="2" fill="#FF983D33"/*this is 20% of opacity*//>
                {points.map((point, i) =>
                    <SnappingConnector
                        key={`${i}-sc`}
                        id={`${parentFigure.get("uuid")}|${i}|snapping`}
                        x={point[0] + 12}
                        y={point[1] + 12}
                        isMouseDown={isMouseDown}
                        handleMouseEnter={() => {
                        }}
                    />
                )}
            </>
        }
    </svg>;
}

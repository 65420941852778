import React from "react";

import LinkToNewWindow from "../components/LinkToNewTab";
import {useLocalization} from "../customHooks/useContextLocalization";
import {useCurrentProject} from "../studio/customHooks/useContextCurrentProject";



export default function OAuthAgreement({oAuthProviderName}) {
    const locale = useLocalization();
    const {get: currentProject} = useCurrentProject();
    const texts = locale?.get.studio.licenseAgreement;

    const handleApplyClick = () => {
        document.cookie = "agreement=true";
    };

    const licenseAgreementLink = <LinkToNewWindow
        link="/store/licenses/licenseAgreement/"
        content={texts.licenseAgreementConditions}
    />;

    //we cannot remove links because of CORS policy (is prevents redirects via axios)
    return <div className="modal-container">
        <h4 className="modal-title">{texts.title}</h4>
        <p className="modal-p">{texts.oAuthText}{licenseAgreementLink}</p>
        <a href={`/api/v1/oauth/authorize/${oAuthProviderName}?project_id=${currentProject.id}`}>
            <input
                type="button"
                className="modal-button modal-button-apply"
                value={texts.apply}
                onClick={handleApplyClick}
            />
        </a>
    </div>;
}

import React, {useState} from "react";
import {useDispatch} from "react-redux";

import {useReduxData} from "../../../customHooks/useContextReduxData";
import {useIsMouseDown} from "../../../customHooks/useContextIsMouseDown";
import {deleteAt, replaceAt} from "../../../../utils";


function ConnectorView({type, id, x, y, opacity, fill, onMouseDown, onMouseUp, onMouseOver, onMouseOut, isIncreased}) {
    switch (type) {
        case "directive":
            return <circle
                className="transformational-connector"
                id={id}
                cx={x}
                cy={y}
                r={isIncreased ? 8 : 4}
                stroke="#FF983D"
                fill={fill}
                opacity={opacity}
                pointerEvents={opacity ? "auto" : "none"}
                // capture нужно, чтобы новый коннектор уже успел захватиться до начала рассчета превью
                onMouseDownCapture={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            />;
        default:
            return <rect
                className="transformational-connector"
                id={id}
                x={isIncreased ? x - 8 : x - 4}
                y={isIncreased ? y - 8 : y - 4}
                width={isIncreased ? 16 : 8}
                height={isIncreased ? 16 : 8}
                stroke="#FF983D"
                opacity={opacity}
                fill={fill}
                // capture нужно, чтобы новый коннектор уже успел захватиться до начала рассчета превью
                onMouseDownCapture={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            />;
    }
}


export default function TransformationalConnector({id, type, x, y, opacity}) {
    const [isConnectorShouldBeIncreased, setIsConnectorShouldBeIncreased] = useState(false);
    const [isConnectorCaptured, setIsConnectorCaptured] = useState(false);
    const [isConnectorSelectedBeforeCaptured, setIsConnectorSelectedBeforeCaptured] = useState(false);
    const [onMouseDownCoo, setOnMouseDownCoo] = useState("");

    const dispatch = useDispatch();
    const isMouseDown = useIsMouseDown();
    const {figures, selectedConnectors} = useReduxData();
    const figure = figures?.get(id.split("|")[0]);

    const handleMouseDown = event => {
        if (!selectedConnectors.includes(id)) {
            const figureType = figure.get("tool").split("-")[0];
            if (["p", "pl", "pr", "c"].includes(figureType)) {
                dispatch({type: "selectedConnectors", value: !event.ctrlKey ? [id] : selectedConnectors.concat([id])});
            } else if (["r", "a"].includes(figureType)) {
                const selectedConnectorIndex = selectedConnectors.map(conn => conn.split("|")[0])
                    .indexOf(figure.get("uuid"));
                dispatch({type: "selectedConnectors", value: !event.ctrlKey
                    ? [id]
                    : (selectedConnectorIndex !== -1
                        ? deleteAt(selectedConnectors, selectedConnectorIndex)
                        : selectedConnectors
                    ).concat([id])
                });
            }
            setIsConnectorSelectedBeforeCaptured(false);
        } else {
            setIsConnectorSelectedBeforeCaptured(true);
        }
        setOnMouseDownCoo(`${event.pageX}|${event.pageY}`);
        setIsConnectorCaptured(true);
    }
    const handleMouseUp = event => {
        const connLen = selectedConnectors.length;
        if (selectedConnectors.includes(id)) {
            const isMouseMoved = `${event.pageX}|${event.pageY}` !== onMouseDownCoo;
            if (connLen === 1 && (isMouseMoved || isConnectorSelectedBeforeCaptured)) {
                dispatch({type: "selectedConnectors", value: []});
            } else if (connLen > 1 && !event.ctrlKey && !isMouseMoved) {
                dispatch({type: "selectedConnectors", value: [id]});
            } else if (connLen >= 1 && event.ctrlKey && !isMouseMoved && isConnectorSelectedBeforeCaptured) {
                dispatch({
                    type: "selectedConnectors",
                    value: deleteAt(selectedConnectors, selectedConnectors.indexOf(id))
                });
            }
        } else {
            const figureUuid = id.split("|")[0];
            if (figure.get("tool").startsWith("r")) {
                const indexOfOldConnector = selectedConnectors.findIndex(item => item.startsWith(figureUuid));
                if (indexOfOldConnector !== -1) {
                    dispatch({
                        type: "selectedConnectors",
                        value: connLen === 1
                            ? deleteAt(selectedConnectors, indexOfOldConnector)
                            : replaceAt(selectedConnectors, indexOfOldConnector, id)
                    });
                }
            }
        }
        setOnMouseDownCoo("");
        setIsConnectorCaptured(false);
    }

    const increaseConnector = () => setIsConnectorShouldBeIncreased(true);
    const decreaseConnector = () => setIsConnectorShouldBeIncreased(false);

    const isConnectorSelected = selectedConnectors.includes(id);

    return <ConnectorView
        type={type}
        id={id}
        x={x}
        y={y}
        opacity={opacity}
        fill={figure.get("tool").split("-")[0] === "r"
            ? isConnectorShouldBeIncreased && isMouseDown
                ? "red"
                : isConnectorSelected && (!isMouseDown || !isConnectorCaptured) ? "yellow" : "#FAFEFF"
            : isConnectorCaptured ? "red" : isConnectorSelected ? "yellow" : "#FAFEFF"
        }
        isIncreased={isConnectorShouldBeIncreased}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseOver={increaseConnector}
        onMouseOut={decreaseConnector}
    />;
}

import React, {useState, useEffect, useContext, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useApiClient} from "./useApiClient";
import {useLocalization} from "./useContextLocalization";
import {useCurrentUser} from "./useContextCurrentUser";
import {extractVectorArchive} from "../store/components/utils";
import {parseIconNodeFromDataUri} from "../studio/utils/parseIconNode";
import {useCurrentProject} from "../studio/customHooks/useContextCurrentProject";


const IconSetContext = React.createContext();


const initialState = {icons: {}, iconNodes: {}, parameters: {}, tabSet: []};


export const IconSetProvider = ({children}) => {
    const [get, set] = useState(initialState);

    const color = useSelector(state => state.get("menu").get("color"));
    const fontColor = useSelector(state => state.get("menu").get("fontColor"));

    const api = useApiClient();
    const locale = useLocalization();
    const user = useCurrentUser();
    const {get: currentProject} = useCurrentProject();
    const dispatch = useDispatch();

    const loadDefaultTools = async () => {
        let defaultToolArchive = await api.get(`/api/v2/store/account/default_figures`, {responseType: 'blob'});
        let defaultTool = await extractVectorArchive(defaultToolArchive.data, locale, false);
        if (defaultTool) {
            defaultTool = defaultTool.sort((l, r) => l.name >= r.name ? 1 : -1);
        }
        return defaultTool;
    };

    const loadIconsFromProduct = async id => {
        let productIconArchive = await api.get(
            `/api/v2/store/product_version/${id}/file?current_project_id=${currentProject.id}`,
            {responseType: 'blob'}
        );
        let iconsFromProduct = await extractVectorArchive(productIconArchive.data, locale, false);
        if (iconsFromProduct) {
            return iconsFromProduct.sort((l, r) => {
                let leftName = l.name.replace(".svg", "").split(" ")[0];
                if (!isNaN(+leftName)) { //for FF, it sort like 1, 10, 11, 2, .. by default (Chrome sort like numbers)
                    leftName = +leftName;
                }
                let rightName = r.name.replace(".svg", "").split(" ")[0];
                if (!isNaN(+rightName)) {
                    rightName = +rightName;
                }
                return leftName >= rightName;
            });
        }
    }

    const loadPolygonFromProduct = async id => {
        let polygon = await api.get(`/api/v2/store/product_version/${id}/file?current_project_id=${currentProject.id}`);
        return "data:image/svg+xml;base64," + btoa(polygon.data);
    }

    const loadAllData = async () => {
        if (currentProject.id) {
            let icons = {polygons: {}, icons: {}, polylines: {}, etc: {}};
            let iconNodes = {};

            //default tool and tool node loading
            let defaultTools = await loadDefaultTools();
            if (defaultTools) {
                let defaultIcons = {}, defaultPolygons = {}, defaultContours = {}, defaultEtc = {};
                for (let i = 0; i < defaultTools.length; i++) {
                    const toolName = defaultTools[i].name.split(".")[0];
                    const toolDataUri = defaultTools[i].data;

                    let [type, , index] = toolName.split("-");
                    if (type === "i") {
                        defaultIcons[index] = toolDataUri;
                        iconNodes[toolName] = parseIconNodeFromDataUri(toolDataUri);
                    } else if (type === "p") {
                        defaultPolygons[index] = toolDataUri;
                    } else if (type === "c") {
                        defaultContours[index] = toolDataUri;
                    } else if (type !== "g") {
                        defaultEtc[index] = {icon: toolDataUri, type};
                    }
                }
                icons.icons["s"] = {
                    name: {russian: "Стандартные", english: "Standard", chinese: "Стандартные"},
                    icons: defaultIcons,
                    version: ""
                };
                icons.polygons["s"] = {name: {russian: "Стандартные", english: "Standard", chinese: "Стандартные"}, icons: defaultPolygons, version: ""};
                icons.polylines["s"] = {name: {russian: "Стандартные", english: "Standard", chinese: "Стандартные"}, icons: defaultContours, version: ""};
                icons.etc["s"] = {name: {russian: "Стандартные", english: "Standard", chinese: "Стандартные"}, icons: defaultEtc, version: ""};
            }

            //tab and tool parameter loading
            let currentIconSet = (
                await api.get(`/api/v2/store/account/tab_set?current_project_id=${currentProject.id}`)
            )?.data;

            //custom tool and tool node loading
            if (currentProject.id === -1) {
                let productVersions = [];
                try {
                    productVersions = (await api.get("/api/v2/store/product_version/me"))?.data;
                } catch (e) {}

                for (let i = 0; i < productVersions.length; i++) {
                    let productVersion = productVersions[i];
                    let id = productVersion.id,
                        name = productVersion.name,
                        category = productVersion.type,
                        version = productVersion.version;

                    if (category === 0) {
                        let iconsFromProduct = await loadIconsFromProduct(id);
                        if (iconsFromProduct) {
                            let iconDataFromProduct = {};
                            for (let i = 0; i < iconsFromProduct.length; i++) {
                                iconDataFromProduct[i] = iconsFromProduct[i].data;
                                iconNodes[`i-${id}-${i}`] = parseIconNodeFromDataUri(iconsFromProduct[i].data);
                            }
                            icons.icons[id] = {name: name, icons: iconDataFromProduct, version: version};
                        }
                    } else if (category === 2) {
                        let polygon = await loadPolygonFromProduct(id);
                        icons.polygons[id] = {name: name, icons: {"0": polygon}, version: version}
                    }
                }
            } else { //TODO: move to server assembling of icons (to elude sending icons project doesn't use)
                let iconsUsedByProject = {};
                currentIconSet.tab_set.map(tab => tab.children.map(partition => {
                    if (partition.isComplicated) {
                        partition.children.map(subpartition => {
                            subpartition.children.map(figure => {
                                let [type, productId, index] = figure.split("-");
                                if (productId !== "s") {
                                    if (!Object.keys(iconsUsedByProject).includes(type)) {
                                        iconsUsedByProject[type] = {};
                                    }
                                    if (!Object.keys(iconsUsedByProject[type]).includes(productId)) {
                                        iconsUsedByProject[type][productId] = [];
                                    }
                                    iconsUsedByProject[type][productId].push(index);
                                }
                            });
                        });
                    } else {
                        partition.children.map(figure => {
                            let [type, productId, index] = figure.split("-");
                            if (productId !== "s") {
                                if (!Object.keys(iconsUsedByProject).includes(type)) {
                                    iconsUsedByProject[type] = {};
                                }
                                if (!Object.keys(iconsUsedByProject[type]).includes(productId)) {
                                    iconsUsedByProject[type][productId] = [];
                                }
                                iconsUsedByProject[type][productId].push(index);
                            }
                        });
                    }
                }));
                //TODO: simplify structure of iconsUsedByProject (I can get category from pv entity)
                if (Object.keys(iconsUsedByProject).includes("i")) {
                    for (let i = 0; i < Object.keys(iconsUsedByProject.i).length; i++) {
                        let productId = Object.keys(iconsUsedByProject.i)[i];
                        const productVersion = (await api.get(`/api/v2/store/product_version/${productId}?current_project_id=${currentProject.id}`))?.data
                        let name = productVersion.name,
                            version = productVersion.version;
                        let iconsFromProduct = await loadIconsFromProduct(productId);
                        if (iconsFromProduct) {
                            let iconDataFromProduct = {};
                            for (let i = 0; i < iconsFromProduct.length; i++) {
                                iconDataFromProduct[i] = iconsFromProduct[i].data;
                                iconNodes[`i-${productId}-${i}`] = parseIconNodeFromDataUri(iconsFromProduct[i].data);
                            }
                            icons.icons[productId] = {name: name, icons: iconDataFromProduct, version: version};
                        }
                    }
                }
                if (Object.keys(iconsUsedByProject).includes("p")) {
                    for (let i = 0; i < Object.keys(iconsUsedByProject.p).length; i++) {
                        let productId = Object.keys(iconsUsedByProject.p)[i];
                        const productVersion = (await api.get(`/api/v2/store/product_version/${productId}?current_project_id=${currentProject.id}`))?.data
                        let name = productVersion.name,
                            version = productVersion.version;
                        let polygon = await loadPolygonFromProduct(productId);
                        icons.polygons[productId] = {name: name, icons: {"0": polygon}, version: version}
                    }
                }
            }

            set({
                icons: icons,
                iconNodes: iconNodes,
                parameters: currentIconSet.parameters,
                tabSet: currentIconSet.tab_set
            });

            //set menu tool, color and fontColor
            const defaultTool = Object.keys(currentIconSet.parameters)?.[0] || "";
            dispatch({type: "tool", value: defaultTool});

            if (defaultTool.split("-")[0] === "c" && color === "#FFFFFF") {
                dispatch({type: "color", value: currentIconSet.parameters[defaultTool].color});
            }
            else if (defaultTool.split("-")[0] === "p" && fontColor === "#FFFFFF") {
                dispatch({type: "fontColor", value: currentIconSet.parameters[defaultTool].color});
            }
        }
    };

    const resetData = useCallback(() => set(initialState), [set]);

    useEffect(() => {
        loadAllData();
    }, [user, currentProject.id]);

    return <IconSetContext.Provider value={{get, set, loadAllData, resetData}}>
        {children}
    </IconSetContext.Provider>;
};

export const useIconSet = () => useContext(IconSetContext);

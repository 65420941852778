import React, {useMemo} from "react";

import TransformationalConnector from "../connectors/TransformationalConnector.js";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import useCooTransformation from "../../canvas/customHooks/useCooTransformation";
import {getTransformationalConnectorOpacity} from "../utils/transformationalConnector";


export default function ({parentFigure}) {
    const {figures, scale, selectedFigureUuids} = useReduxData();
    const {transformXForCanvas, transformYForCanvas} = useCooTransformation();

    const opacity = useMemo(
        () => getTransformationalConnectorOpacity(figures, parentFigure.get("uuid"), selectedFigureUuids),
        [figures, parentFigure.get("uuid"), selectedFigureUuids]
    );

    const points = useMemo(() => {
        const rectWidth = parentFigure.get("width") * scale;
        const rectHeight = parentFigure.get("height") * scale;
        return [[0, 0], [rectWidth, 0], [rectWidth, rectHeight], [0, rectHeight]];
    }, [parentFigure.get("width"), parentFigure.get("height"), scale]);

    return <svg x={transformXForCanvas(parentFigure.get("x")) - 12} y={transformYForCanvas(parentFigure.get("y")) - 12}>
        {points.map((point, i) =>
            <TransformationalConnector
                key={`${i}-bc`}
                type="base"
                id={`${parentFigure.get("uuid")}|${i}`}
                x={point[0] + 12}
                y={point[1] + 12}
                opacity={opacity}
                hintModeKey={i === 0 && "baseTransformationalConnector"}
            />
        )}
    </svg>;
}

import React, {useState} from "react";
import {components} from "react-select";

import UserSelector from "../../../../components/UserSelector";
import ShareToSomeoneButton from "./ShareToSomeoneButton";


export default function ShareToSomeoneField({openSignInIfUnauthorized, sharingMode}) {
    const [users, setUsers] = useState([]);

    return <div className="footer-share-wrapper">
        <UserSelector
            users={users}
            setUsers={setUsers}
            menuPlacement="bottom"
            className="studio-modal-share-field"
            fieldName="users"
            dropdownIndicator=""
            clearIndicator=""
            valueContainer={components.ValueContainer}
        />
        <ShareToSomeoneButton
            users={users}
            setUsers={setUsers}
            sharingMode={sharingMode}
            openSignInIfUnauthorized={openSignInIfUnauthorized}
            style={users.length > 0 ? {} : {pointerEvents: "none", opacity: 0}}
        />
    </div>;
}

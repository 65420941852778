import React from "react";
import {useHistory} from "react-router-dom";

import {useLocalization} from "../../customHooks/useContextLocalization";
import {useCurrentUser} from "../../customHooks/useContextCurrentUser";

import {ReactComponent as Tick} from "../images/tick.svg";

import "./servicePage.css";


export default function ProductPurchased() {
    const history = useHistory();
    const locale = useLocalization();
    const currentUser = useCurrentUser();

    const goToAccount = () => history.push(`/account/${currentUser.id}`);

    return (
        <div className = "page-404-container">
            <Tick/>
            <h1 className = "tick-title">{locale?.get?.store.productPurchased.title}</h1>
            <p className = "tick-text">{locale?.get?.store.productPurchased.explanation}</p>
            <span className = "tick-link" onClick={goToAccount}>{locale?.get?.store.productPurchased.goToHome}</span>
            <span className = "tick-link" onClick={goToAccount}>{locale?.get?.store.productPurchased.goToMainPage}</span>
        </div>
    );
}

import {useReduxData} from "../../../customHooks/useContextReduxData";
import {CANVAS_HEIGHT} from "../Canvas";
import {CANVAS_WIDTH} from "../Canvas";


export default function useCooTransformation() {
    const {scale} = useReduxData();

    const transformXForCanvas = coo => (coo + CANVAS_WIDTH / 2) * scale;
    const transformYForCanvas = coo => (coo + CANVAS_HEIGHT / 2) * scale;
    const transformXForMap = coo => coo / scale - CANVAS_WIDTH / 2;
    const transformYForMap = coo => coo / scale - CANVAS_HEIGHT / 2;

    return {transformXForCanvas, transformYForCanvas, transformXForMap, transformYForMap};
}

import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {v4 as uuid} from "uuid";

import Line from "../Line";
import {useCanvasBoundingClientRect} from "../../../customHooks/useContextCanvasBoundingClientRect";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import useCooTransformation from "./useCooTransformation";
import getTimeElapsedSince from "../../getTimeElapsedSince";
import {isActiveSnappingConnector} from "../../../customHooks/utils/snappingConnector";
import {arrowSchema} from "../../../utils/validators";


export default function useArrowDrawing() {
    const dispatch = useDispatch();
    const {transformXForMap, transformYForMap} = useCooTransformation();
    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const {color, fontColor, fontSize, startTime, tool, transparency, dashLength, thickness} = useReduxData();

    const [arrowFromX, setArrowFromX] = useState(0);
    const [arrowFromY, setArrowFromY] = useState(0);
    const [arrowToX, setArrowToX] = useState(0);
    const [arrowToY, setArrowToY] = useState(0);
    const [isArrowDrawingActive, setIsArrowDrawingActive] = useState(false);
    const [fromSnappingFigure, setFromSnappingFigure] = useState("");

    const resetState = () => {
        setArrowFromX(0);
        setArrowFromY(0);
        setArrowToY(0);
        setArrowToX(0);
        setIsArrowDrawingActive(false);
        setFromSnappingFigure("");
    };

    const startArrowDrawing = event => {
        if (isActiveSnappingConnector(event.target)) {
            const [figureUuid, figurePoint, ] = event.target.id.split("|");
            setFromSnappingFigure(`${figureUuid}|${figurePoint}`);
        }

        const startX = event.pageX - canvasBoundingClientRect.get.left;
        const startY = event.pageY - canvasBoundingClientRect.get.top;
        setIsArrowDrawingActive(true);
        setArrowFromX(startX);
        setArrowFromY(startY);
        setArrowToX(startX);
        setArrowToY(startY);
    };

    const changeArrowPreview = (newXCoo, newYCoo) => {
        if (isArrowDrawingActive) {
            setArrowToX(newXCoo - canvasBoundingClientRect.get.left);
            setArrowToY(newYCoo - canvasBoundingClientRect.get.top);
        }
    };

    const finishArrowDrawing = event => {
        let toSnappingFigure = "";
        if (isActiveSnappingConnector(event.target)) {
            const [figureUuid, figurePoint, ] = event.target.id.split("|");
            toSnappingFigure = `${figureUuid}|${figurePoint}`;
        }

        resetState();

        if (fromSnappingFigure
            && toSnappingFigure
            && fromSnappingFigure.split("|")[0] === toSnappingFigure.split("|")[0]
        ) {
            return;
        }

        const newRectangleUuid = uuid();
        const newFigureObject = {
            uuid: newRectangleUuid,
            parentUuid: undefined,
            toX: toSnappingFigure || Math.round(transformXForMap(arrowToX)),
            toY: toSnappingFigure || Math.round(transformYForMap(arrowToY)),
            fromX: fromSnappingFigure || Math.round(transformYForMap(arrowFromX)),
            fromY: fromSnappingFigure || Math.round(transformYForMap(arrowFromY)),
            tool,
            color,
            fontColor,
            fontSize,
            transparency,
            dashLength,
            thickness,
            name: "",
            link: "",
            description: "",
            layout: 50,
            orderIndex: Math.round(new Date().getTime())
        };
        if (!arrowSchema.isValidSync(newFigureObject)) {
            return;
        }
        dispatch({...newFigureObject, type: "addArrow", time: getTimeElapsedSince(startTime)});
        dispatch({type: "selectedFigureUuids", value: [newRectangleUuid]});
    };

    return {
        arrowPreview: <Line fromX={arrowFromX} fromY={arrowFromY} toX={arrowToX} toY={arrowToY} stroke="red"/>,
        startArrowDrawing,
        changeArrowPreview,
        finishArrowDrawing
    }
}

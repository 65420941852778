import React from "react";

import {useLocalization} from "../../../../customHooks/useContextLocalization";
import useTabs from "../hooks/useContextTabs";

import {ReactComponent as Plus} from "../../../images/cross-transparent.svg";


const AddNewPartitionButton = ({tabIndex}) => {
    const locale = useLocalization();
    const {dispatchTabs} = useTabs();

    const addPartition = () => dispatchTabs({type: "addPartition", payload: {locale: locale, tIndex: tabIndex}});

    return <div className="account-icons-add-partition" onClick={addPartition}>
        <Plus height="36" width="36" fill="#144066" style={{transform: "rotate(45deg)", marginBottom: "1.25rem"}}/>
        <div>{locale.get.store.icons.addPartition}</div>
    </div>;
};

export default AddNewPartitionButton;
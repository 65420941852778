import React from "react";


export default function Rectangle({x, y, width, height, stroke, strokeDasharray}) {
    if (width < 0) {
        x += width;
        width *= -1;
    }
    if (height < 0) {
        y += height;
        height *= -1;
    }

    return <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill="none"
        stroke={stroke}
        strokeWidth="0.0625rem"
        strokeDasharray={strokeDasharray}
        style={{pointerEvents: "none"}}
    />;
}

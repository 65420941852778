import React from "react";

import styles from "./linkButton.module.css";


export default function ({text, href, isActive}) {
    return <a href={href} className={`${styles.link} ${isActive ? styles.active : ""}`}>
        {text}
    </a>;
}

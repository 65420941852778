import React from "react";

import useNodeDragSource from "./hooks/useNodeDragSource";


const NodeDragSource = ({type, children, name, inputs, outputs}) => {
    const drag = useNodeDragSource(type, name, inputs, outputs);

    return <div ref={drag}>
        {children}
    </div>;
}

export default NodeDragSource;

import {createContext, useContext, useEffect, useState} from "react";

import useAvailableConverters from "../../hooks/useAvailableConverters";
import useAvailableMetrics from "../../hooks/useAvailableMetrics";


const NodeFilterContext = createContext();

export function NodeFilterProvider({children}) {
    const availableConverters = useAvailableConverters();
    const availableMetrics = useAvailableMetrics();

    const [inputTypesToShow, setInputTypesToShow] = useState([]);
    const [outputTypesToShow, setOutputTypesToShow] = useState([]);

    const [convertersToShow, setConvertersToShow] = useState([]);
    const [metricsToShow, setMetricsToShow] = useState([]);

    useEffect(() => {
        setConvertersToShow(availableConverters.filter(el => inputTypesToShow.length === 0
            || el.inputs.map(input => input.data_type).filter(dt => inputTypesToShow.indexOf(dt) !== -1).length > 0
        ).filter(el => outputTypesToShow.length === 0
            || el.outputs.map(output => output.data_type).filter(dt => outputTypesToShow.indexOf(dt) !== -1).length > 0
        ));
    }, [availableConverters, inputTypesToShow, outputTypesToShow]);

    useEffect(() => {
        setMetricsToShow(availableMetrics.filter(el => inputTypesToShow.length === 0
            || el.inputs.map(input => input.data_type).filter(dt => inputTypesToShow.indexOf(dt) !== -1).length > 0
        ));
    }, [availableMetrics, inputTypesToShow]);

    return <NodeFilterContext.Provider value={
        {inputTypesToShow, setInputTypesToShow, outputTypesToShow, setOutputTypesToShow, convertersToShow, metricsToShow}
    }>
        {children}
    </NodeFilterContext.Provider>;
}

export function useNodeFilter() {
    return useContext(NodeFilterContext);
}

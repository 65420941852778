import {Handle, Position} from "react-flow-renderer";

import HintedHandleLabel from "./HintedHandleLabel";
import {useDataSource} from "../hooks/useContextDataSource";
import {useLocalization} from "../../../../../../customHooks/useContextLocalization";


export default function ({id, data: {label}}) {
    const {dataSources} = useDataSource();
    const locale = useLocalization();

    const clear_data_type = dataSources?.[id]?.dataType && dataSources[id].dataType.startsWith("List")
        ? dataSources[id].dataType.replace("List[", "").replace("]", "")
        : dataSources?.[id]?.dataType || "";

    return <div
        className="project-dfd-source-node"
        style={dataSources?.[id]?.dataType ? {} : {backgroundColor: "rgba(255, 0, 0, 0.3)"}}
    >
        <label className="project-dfd-source-node-title" htmlFor="text">{label}</label>
        <Handle id="1" type="source" position={Position.Right}/>

        <HintedHandleLabel
            name={dataSources?.[id]?.dataType || ""}
            style={{right: "100%"}}
            tooltipContent={locale.get.project.dfd.nodeTypeHint[clear_data_type]}
            tooltipStyle={{top: `calc(-8px + 50%)`, right: 5, position: "absolute"}}
        />
    </div>;
};
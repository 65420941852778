import React from "react";

import {useLocalization} from "../../../../../customHooks/useContextLocalization";

import {ReactComponent as Arrow} from "../../../../../images/md-editor/arrow.svg";


export default function SubpartitionDragPreview({item, tabs}) {
    const locale = useLocalization();
    const subpartitionIndex = tabs[item.tabIndex].children[item.partitionIndex].children.map(el=>el.id).indexOf(item.subpartitionId);
    return <label
        className="account-icons-subpartition-title"
        htmlFor="retractable-subsection-checkbox"
    >
        <Arrow className="retractable-subsection-checkbox-label-arrow"/>
        {tabs[item.tabIndex].children[item.partitionIndex].children[subpartitionIndex]?.name[locale.get.name] || ""}
    </label>;
};

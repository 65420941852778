import React from "react";
import {useDispatch} from "react-redux";

import Flag from "../figures/Flag";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useReduxData} from "../../customHooks/useContextReduxData";
import {useIconSet} from "../../../customHooks/useContextIconSet";
import tooltipHOC from "../../../components/tooltipHOC";
import {setFixedChildrenValues} from '../../utils/figureHierarchy';
import getTimeElapsedSince from "../getTimeElapsedSince";

import {ReactComponent as Cross} from "../../images/flags/cross.svg";


const HintedFlag = tooltipHOC( //hint on hover
    ({className, id, height, width, onMouseDown}) => <Flag
        className={className}
        id={id}
        height={height}
        width={width}
        onMouseDown={onMouseDown}
    />, {margin: "10px"}
);


const HintedCross = tooltipHOC( //hint on hover
    ({className, id, height, width, onMouseDown, style}) => <Cross
        className={className}
        id={id}
        height={height}
        width={width}
        onMouseDown={onMouseDown}
        style={style}
    />, {margin: "12px 12px 0px 12px"}
);


export default function Flags({rerenderFloatingMenuItem}) {
    const {get: {parameters}} = useIconSet();
    const locale = useLocalization();
    const {
        getFiguresUpdatedOn,
        selectedFigureUuidsWithoutArrows,
        shouldCrossesShow: [
            shouldPinkCrossShow,
            shouldRedCrossShow,
            shouldGreenCrossShow,
            shouldBlueCrossShow,
            shouldBlackCrossShow,
        ],
        startTime
    } = useReduxData();
    const figures = getFiguresUpdatedOn([
        "addPolygon",
        "addPolyline",
        "addPointFeature",
        "addGroup",
        "changeObjectParentUuid",
        "addFigureEmoji",
        "deleteFigureEmoji",
        "clearState",
        "deleteFigure",
        "disbandGroup"
    ]);

    const dispatch = useDispatch();

    const captureFlag = event => {
        let cursorColor = event.target.parentNode.id.split("-")[3];
        if (cursorColor) {
            selectedFigureUuidsWithoutArrows.map(uuid => setFixedChildrenValues(
                figures,
                uuid,
                ["flags"],
                dispatch,
                [cursorColor],
                "addFigureFlag",
                getTimeElapsedSince(startTime),
                figure => parameters?.[figure.get("tool")]?.canHoldFlag
            ));
            rerenderFloatingMenuItem();
        }
    };

    const deleteFlag = event => {
        let cursorColor = event.target.id.split("-")[3];
        selectedFigureUuidsWithoutArrows.map(uuid => setFixedChildrenValues(
            figures,
            uuid,
            ["flags"],
            dispatch,
            [cursorColor],
            "deleteFigureFlag",
            getTimeElapsedSince(startTime)
        ));
        rerenderFloatingMenuItem();
    };

    return <>
        {(shouldPinkCrossShow || shouldRedCrossShow || shouldGreenCrossShow || shouldBlueCrossShow || shouldBlackCrossShow)
            && <div className="flag-container">
                <HintedCross
                    className="point-feature-cross"
                    id="point-feature-flag-pink"
                    height={16}
                    width={16}
                    onMouseDown={deleteFlag}
                    tooltipContent={locale?.get.studio.floatingMenu.flagCrosses.pinkCrossHint}
                    style={{
                        opacity: shouldPinkCrossShow ? 1 : 0,
                        pointerEvents: shouldPinkCrossShow ? "auto" : "none"
                    }}
                />
                <HintedCross
                    className="point-feature-cross"
                    id="point-feature-flag-red"
                    height={16}
                    width={16}
                    onMouseDown={deleteFlag}
                    tooltipContent={locale?.get.studio.floatingMenu.flagCrosses.redCrossHint}
                    style={{
                        opacity: shouldRedCrossShow ? 1 : 0,
                        pointerEvents: shouldRedCrossShow ? "auto" : "none"
                    }}
                />
                <HintedCross
                    className="point-feature-cross"
                    id="point-feature-flag-green"
                    height={16}
                    width={16}
                    onMouseDown={deleteFlag}
                    tooltipContent={locale?.get.studio.floatingMenu.flagCrosses.greenCrossHint}
                    style={{
                        opacity: shouldGreenCrossShow ? 1 : 0,
                        pointerEvents: shouldGreenCrossShow ? "auto" : "none"
                    }}
                />
                <HintedCross
                    className="point-feature-cross"
                    id="point-feature-flag-blue"
                    height={16}
                    width={16}
                    onMouseDown={deleteFlag}
                    tooltipContent={locale?.get.studio.floatingMenu.flagCrosses.blueCrossHint}
                    style={{
                        opacity: shouldBlueCrossShow ? 1 : 0,
                        pointerEvents: shouldBlueCrossShow ? "auto" : "none"
                    }}
                />
                <HintedCross
                    className="point-feature-cross"
                    id="point-feature-flag-black"
                    height={16}
                    width={16}
                    onMouseDown={deleteFlag}
                    tooltipContent={locale?.get.studio.floatingMenu.flagCrosses.blackCrossHint}
                    style={{
                        opacity: shouldBlackCrossShow ? 1 : 0,
                        pointerEvents: shouldBlackCrossShow ? "auto" : "none"
                    }}
                />
            </div>
        }
        <div className="flag-container">
            <HintedFlag
                className="point-feature-flag-pink"
                id="point-feature-flag-pink"
                height={20}
                width={20}
                onMouseDown={captureFlag}
                tooltipContent={locale?.get.studio.floatingMenu.flags.pinkFlagHint}
            />
            <HintedFlag
                className="point-feature-flag-red"
                id="point-feature-flag-red"
                height={20}
                width={20}
                onMouseDown={captureFlag}
                tooltipContent={locale?.get.studio.floatingMenu.flags.redFlagHint}
            />
            <HintedFlag
                className="point-feature-flag-green"
                id="point-feature-flag-green"
                height={20}
                width={20}
                onMouseDown={captureFlag}
                tooltipContent={locale?.get.studio.floatingMenu.flags.greenFlagHint}
            />
            <HintedFlag
                className="point-feature-flag-blue"
                id="point-feature-flag-blue"
                height={20}
                width={20}
                onMouseDown={captureFlag}
                tooltipContent={locale?.get.studio.floatingMenu.flags.blueFlagHint}
            />
            <HintedFlag
                className="point-feature-flag-black"
                id="point-feature-flag-black"
                height={20}
                width={20}
                onMouseDown={captureFlag}
                tooltipContent={locale?.get.studio.floatingMenu.flags.blackFlagHint}
            />
        </div>
    </>;
}

import {useState, useEffect} from "react";

import Menu from "./Menu";
import PointObjectsRenderer from "./PointObjectsRenderer";
import BigObjectsRenderer from "./BigObjectsRenderer";
import {MenuProvider, useMenu} from "./hooks/useMenu";
import {getWeightedAverage} from "../utils/getKeyOfMaxVal";
import {processFigure} from "./utils/processFigure";
import MapGrid from "./MapGrid";

import "./generalMapVisualizer.css";


export default function ({data}) {
    return <MenuProvider>
        <GeneralMapVisualizer data={data}/>
    </MenuProvider>;
}


function GeneralMapVisualizer ({data}) {
    const [dataToRender, setDataToRender] = useState({});
    const [error, setError] = useState("");
    const [figuresAvailableToShow, setFiguresAvailableToShow] = useState({});
    const [figuresToShowTransparencies, setFiguresToShowTransparencies] = useState({});
    const [currentMaxFrequency, setCurrentMaxFrequency] = useState(0);

    const {figuresToShow, setFiguresToShow} = useMenu();

    useEffect(() => {
        if (data) {
            try {
                let jsonData = JSON.parse(data.replace(/'/g, '"'));
                if (jsonData[0]) {
                    jsonData = jsonData[0][0];
                }

                let preparedData = {};
                Object.keys(jsonData).map(category => {
                    const categoryData = jsonData[category];

                    const creationTimesOfSettlements = [...new Set(Object.keys(categoryData).map(
                        settlement => +getWeightedAverage(categoryData[settlement].params_distribution.orderIndex)
                    ))].sort((a, b) => (+a - +b));

                    preparedData[category] = {};
                    Object.keys(categoryData).map(settlement => {
                        preparedData[category][settlement] = processFigure(
                            categoryData[settlement],
                            creationTimesOfSettlements
                        );
                    });
                });

                setDataToRender(preparedData);

                const newFiguresAvailableToShow = {};
                const initialFiguresToShow = {};
                Object.keys(jsonData).forEach(category => {
                    newFiguresAvailableToShow[category] = Object.keys(jsonData[category]).sort();
                    initialFiguresToShow[category] = [];
                });
                setFiguresAvailableToShow(newFiguresAvailableToShow);
                setFiguresToShow(initialFiguresToShow);
            } catch (e) {
                console.log(e);
                setError("Ошибка при обработке данных. Скорее всего данные имеют некорректный формат. Попробуйте вычислить шаблон с помощью алгоритма PatternAssembler из аналитики.");
            }
        }
    }, [data]);

    useEffect(() => {
        // console.log("runs");
        // console.log(data);
        // console.log(figuresToShow);
        if (data && figuresToShow && Object.keys(figuresToShow).length) {
            let jsonData = JSON.parse(data.replace(/'/g, '"'));
            if (jsonData[0]) {
                jsonData = jsonData[0][0];
            }

            let maxFreq = 0;
            Object.keys(jsonData).forEach(category => {
                const categoryMax = Math.max(...Object.keys(jsonData[category]).map(
                    settlement => (figuresToShow[category]?.indexOf(settlement) !== -1 ? jsonData[category][settlement].frequency : 0)
                ));
                if (maxFreq < categoryMax) {
                    maxFreq = categoryMax;
                }
            });

            const transparencies = {};
            Object.keys(jsonData).map(category => {
                transparencies[category] = {};
                Object.keys(jsonData[category]).map(settlement => {
                    transparencies[category][settlement] = (
                        "0" + Math.round((jsonData[category][settlement].frequency / maxFreq * 0.8 + 0.2) * 255).toString(16)
                    ).slice(-2);
                });
            });
            console.log(transparencies);

            setCurrentMaxFrequency(maxFreq);
            setFiguresToShowTransparencies(transparencies);
        }
    }, [figuresToShow, data]);

    console.log(dataToRender);
    // console.log(figuresToShowTransparencies);
    return error ? error : <div className="wfpm-p-visualizer-container">
        <svg className="wfpm-p-visualizer-svg-container">
            <MapGrid/>
            {["Реки", "Озера", "Моря/океаны", "Острова/полуострова/арктика", "Регионы России", "Государства/географические области", "Болота", "Заливы/проливы/бухты", "Водохранилища", "Равнины"].map(category => dataToRender[category] && figuresToShowTransparencies[category] &&
                <BigObjectsRenderer
                    dataToRender={dataToRender[category]}
                    figuresToShowTransparencies={figuresToShowTransparencies[category]}
                    category={category}
                />
            )}
            {["Населенные пункты", "Курорты/базы отдыха/национальные деревни", "Горы и подобные горам объекты", "Древние/старые антропогенные объекты", "Заповедники/национальные парки", "Горы и подобные горам объекты", "Пещеры/ландшафтные памятники", "Прочие объекты"].map(category => dataToRender[category] && figuresToShowTransparencies[category] &&
                <PointObjectsRenderer
                    dataToRender={dataToRender[category]}
                    figuresToShowTransparencies={figuresToShowTransparencies[category]}
                    category={category}
                />
            )}
        </svg>
        <Menu
            currentMaxFrequency={currentMaxFrequency}
            figuresAvailableToShow={figuresAvailableToShow}
            dataToRender={dataToRender}
        />
    </div>;
}

import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {Provider} from "react-redux";
import * as Sentry from "@sentry/react";
import {ToastContainer} from "react-toastify";

import reportWebVitals from "./reportWebVitals";
import configureStore from "./studio/store";
import StudioWithProviders from "./studio/StudioWithProviders";
import Store from "./store/Store";
import Project from "./project/Project";
import AdminPanel from "./adminPanel/AdminPanel";
import {CurrentUserProvider} from "./customHooks/useContextCurrentUser";
import {LocalizationProvider} from "./customHooks/useContextLocalization";
import {IconSetProvider} from "./customHooks/useContextIconSet";
import {ModalProvider} from "./customHooks/useContextModal";
import {CurrentProjectProvider} from "./studio/customHooks/useContextCurrentProject";
import {HintModeProvider} from "./studio/customHooks/useContextHintMode";
import {WebSocketProvider} from "./websocket";

import "./studio/Studio.css";
import "./index.css";
import "./styles/auth.css";
import "./styles/controls.css";
import "./styles/studio/Footer.css";
import "./styles/studio/Slider.css";
import "./styles/studio/ToolHint.css";
import "./styles/studio/canvas/Canvas.css";
import "./styles/studio/canvas/ContextMenu.css";
import "./styles/studio/canvas/Description.css";
import "./styles/studio/modal/modal.css";
import "./styles/studio/modal/survey.css";
import "./styles/studio/floatingMenu/FloatingMenu.css";
import "./styles/studio/floatingMenu/FloatingMenuItem.css";
import "./styles/studio/floatingMenu/ColorPicker.css";
import "./styles/studio/floatingMenu/EmojiPicker.css";
import "./styles/studio/floatingMenu/Flags.css";
import "./styles/studio/floatingMenu/TextBox.css";
import "./styles/studio/floatingMenu/TextArea.css";
import "./styles/studio/topMenu/ToolBar.css";
import "./styles/studio/topMenu/TopMenu.css";


if (process.env.REACT_APP_SENTRY_DSN) {
    const release = [].slice.call(document.getElementsByTagName("meta"))
        .find(el => el.name === "release")
        ?.content;

    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENV,
        release: release,
        normalizeDepth: 10
    });
}

ReactDOM.render(
    <Provider store={configureStore()}>
        <ModalProvider>
        <Router>
        <LocalizationProvider>
        <CurrentProjectProvider>
        <CurrentUserProvider>
        <WebSocketProvider>
        <IconSetProvider>
        <HintModeProvider>
            <Switch>
                <Route path="/project" component = {Project}/>
                <Route path="/store" component = {Store}/>
                <Route path="/admin" component={AdminPanel}/>
                <Route path="/" component = {StudioWithProviders}/>
            </Switch>
        </HintModeProvider>
        </IconSetProvider>
        </WebSocketProvider>
        </CurrentUserProvider>
        </CurrentProjectProvider>
        </LocalizationProvider>
        </Router>
        </ModalProvider>
        <ToastContainer theme="colored"/>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

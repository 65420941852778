import React, {useState, useEffect} from "react";

import FigureBarItem from "./FigureBarItem";
import tooltipHOC from "../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import useMeasure from "../../../../customHooks/useMeasure";
import {useIconSet} from "../../../../customHooks/useContextIconSet";
import {useReduxData} from "../../../customHooks/useContextReduxData";

import {ReactComponent as SubpartitionArrow} from "../../../images/controls/subpartition-arrow.svg";


const SubpartitionHint = hintModeTooltipHOC(() => <div style={{height: "0"}}/>);


const SubpartitionPreview = tooltipHOC(({icon, hintModeKey, tooltipContent}) => {
    return <>
        <div
            className="figure-bar-subpartition"
            style={{backgroundImage: `url(${icon})`, backgroundSize: "contain"}}
        />
        <SubpartitionHint hintModeKey={hintModeKey} tooltipContent={tooltipContent}/>
        <SubpartitionArrow className="figure-bar-subpartition-arrow" width="2.1875rem" height="0.875rem"/>
    </>;
});


export default function Subpartition({
    subpartition,
    subpartitionIndex,
    isOpened,
    setOpenedSubpartitionIndex,
    positionIndex,
    partitionPosition,
    hintModeKey,
    tooltipContent
}) {
    const [xPosition, setXPosition] = useState(0);
    const [yPosition, setYPosition] = useState(0);
    const [subpartitionImage, setSubpartitionImage] = useState("");

    const {tool: currentFigureName, isWatchMode, isEditMode} = useReduxData();
    const locale = useLocalization();
    const {get: {parameters, icons}} = useIconSet();
    const [subpartitionRef, {left: subpartitionLeft, top: subpartitionTop, height: subpartitionHeight}] = useMeasure();

    useEffect(() => {
        if (subpartitionLeft && subpartitionTop && subpartitionHeight && partitionPosition) {
            setXPosition(+subpartitionLeft - +partitionPosition.left - 5);
            setYPosition(+subpartitionTop - +partitionPosition.top + subpartitionHeight + 5);
        }
    }, [subpartitionRef.current, subpartitionTop, subpartitionLeft, subpartitionHeight, partitionPosition]);

    useEffect(()=> {
        const [iconType, productId, iconIndex] = (subpartition.icon || subpartition.children[0]).split("-");
        const iconSetType = iconType === "p"
            ? "polygons"
            : iconType === "c" ? "polylines" : iconType === "i" ? "icons" : "etc";
        if (icons[iconSetType]?.[productId]) {
            setSubpartitionImage(icons[iconSetType][productId].icons[iconIndex]);
        }
    }, [subpartition, icons]);

    const handleSubpartitionClick = () => {
        setOpenedSubpartitionIndex(isOpened ? -1 : subpartitionIndex);
        let focus = subpartitionRef?.current?.focus();
    };

    const handleBlur = (event) => {
        if (!event.relatedTarget
            || !event.relatedTarget.className
            || (
                event.relatedTarget.className !== "figure-bar-subpartition-container"
                && event.relatedTarget.className !== "figure-bar-subpartition-title"
            )
        ) {
            setOpenedSubpartitionIndex(-1);
        } else {
            event.target.focus();
        }
    };

    return <>
        {isOpened ? <>
            <div
                className={`figure-bar-subpartition-container${isWatchMode ? " disabled" : ""}`}
                tabIndex="2"
                style={{marginLeft: xPosition + 3, marginTop: yPosition - 5}}
            >
                <p className="figure-bar-subpartition-title" tabIndex="1">{subpartition.name[locale?.get.name]}</p>
                {subpartition.children.map((figureName, i) =>
                    <FigureBarItem
                        key={`figure-bar-subpartition-${subpartitionIndex}-figure-${i}`}
                        figureName={figureName}
                        type="-subpartition"
                        setOpenedSubpartitionIndex={setOpenedSubpartitionIndex}
                        tooltipContent={parameters[figureName]?.title?.[locale?.get.name] || ""}
                    />
                )}
            </div>
            <div className="figure-bar-subpartition-image" style={{marginLeft: (positionIndex * 3.375) + "rem"}}>
                <div
                    className={`figure-bar-subpartition-active${isWatchMode ? " disabled" : ""}`}
                    style={{backgroundImage: `url(${subpartitionImage})`, backgroundSize: "contain"}}
                />
                <SubpartitionArrow className="figure-bar-subpartition-arrow" width="2.1875rem" height="0.875rem"/>
            </div>
        </> : undefined}
        <div
            className={`figure-bar-subpartition-preview-container${isWatchMode ? " disabled" : ""}`}
            ref={subpartitionRef}
            tabIndex="0"
            onClick={handleSubpartitionClick}
            onBlur={handleBlur}
            style={{backgroundColor: subpartition.children.includes(currentFigureName) && !isEditMode ? "#FF983D" : ""}}
        >
            <SubpartitionPreview icon={subpartitionImage} hintModeKey={hintModeKey} tooltipContent={tooltipContent}/>
        </div>
    </>;
}

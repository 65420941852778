import {Record} from 'immutable';

class Arrow extends Record({
    uuid: undefined,
    parentUuid: undefined,
    toX: undefined,
    toY: undefined,
    fromX: undefined,
    fromY: undefined,
    tool: "empty",
    color: null,
    fontColor: undefined,
    fontSize: undefined,
    transparency: undefined,
    thickness: undefined,
    dashLength: undefined,
    name: "",
    link: "",
    description: "",
    layout: undefined,
    orderIndex: undefined,
    users: []
}) {
}

export default Arrow;

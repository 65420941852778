import {SVGPathUtils} from 'svg-path-utils';
import {flattenDeep} from "lodash";


export default class Path {
    /* library path format:
    * [
    *   {
    *       mCoo: {x: 0, y: 0},
    *       curves: [
    *           {
    *               command: "L",
    *               points: [
    *                   {x: 0, y: 0},
    *                   ...
    *               ]
    *           },
    *           ...
    *       ]
    *   },
    *   ...
    * ]
    * */

    /**
     * Convert path from string to library format
     * @typedef {{x: number, y: number}}                             Point
     * @typedef {Array<{command:('L'|'Q'|'C'),points:Array<Point>}>} Offcut
     * @typedef {Array<{mCoo:Point,curves:Offcut}>}                  Path
     * @param  {string} path The string path
     * @return {Path}        The string path.
     * @example
     * _parse("M 12,15 L 2,3");
     * // returns: [{mCoo: {x: 12, y: 15}, curves: [{command: "L", points: [{x: 2, y: 3}]}]}]
     */
    static _parse(path) {
        const SEGMENT_COMMANDS = ["L", "Q", "C"];

        if (typeof(path) !== "string") {
            // console.log("Wrong format of path");
        }

        let parsed = [];
        path.split(/M/).map(simplePath => {
            if (simplePath.trim() !== "") {
                let parsedPath = {};
                let curves = simplePath.split(/ [LQC] /);
                parsedPath.mCoo = {x: +curves[0].split(",")[0], y: +curves[0].split(",")[1]};
                parsedPath.curves = [];
                curves.splice(0, 1);
                curves.map(curve => {
                    let parsedCurve = {};
                    parsedCurve.command = SEGMENT_COMMANDS[curve.trim().split(" ").length - 1];
                    parsedCurve.points = [];

                    let points = curve.trim().split(" ");
                    points.map(point => parsedCurve.points.push({x: +point.split(",")[0], y: +point.split(",")[1]}));
                    parsedPath.curves.push(parsedCurve);
                    return undefined;
                });
                parsed.push(parsedPath);
            }
            return undefined;
        });
        return parsed;
    }

    /**
     * Convert path from library format to string
     * @typedef {{x: number, y: number}}                             Point
     * @typedef {Array<{command:('L'|'Q'|'C'),points:Array<Point>}>} Offcut
     * @typedef {Array<{mCoo:Point,curves:Offcut}>}                  Path
     * @param  {(Path|Offcut|Point)}       parsed The path in library format
     * @param  {('path'|'offcut'|'point')} type   The type of path in library format: path, offcut, point
     * @return {string}        The string path.
     * @example
     * _toString([{mCoo: {x: 12, y: 15}, curves: [{command: "L", points: [{x: 2, y: 3}]}]}], "path");
     * // returns: "M 12,15 L 2,3"
     */
    static _toString(parsed, type) {
        let path = "";
        if (type === "path") {
            parsed.map(simplePath => {
                path += `M ${simplePath.mCoo.x},${simplePath.mCoo.y} `;
                simplePath.curves.map(curve => {
                    path += `${curve.command} `;
                    curve.points.map(point => path += `${point.x},${point.y} `);
                    return undefined;
                });
                return undefined;
            });
        } else if (type === "offcut") {
            parsed.map(curve => {
                path += `${curve.command} `;
                curve.points.map(point => path += `${point.x},${point.y} `);
                return undefined;
            });
        } else if (type === "point") {
            path += `${parsed.x},${parsed.y}`;
        }
        return path.trim();
    }

    /**
     * Check if path is valid or not
     * @param  {string}  path  The path to check; like this: "M 2,3 Q 34,56 45,64"
     * @return {boolean}       The new unclosed polyline path.
     * @example
     * createFromPoints(["12,3", "10,5", "11,6"]);
     * // returns: "M 12,3 L 10,5 L 11,6"
     */
    static checkIfPathValid(path) {
        if (path === "") {
            return false;
        }
        try {
            let parsed = Path._parse(path);

            if (!parsed || parsed.length === 0) {
                return false;
            }

            let checks = parsed.map(simplePath => {
                if (isNaN(simplePath.mCoo.x) || isNaN(simplePath.mCoo.y)) {
                    return false;
                }
                let curveChecks = simplePath.curves.map(curve => curve.points.map(point => {
                    if (isNaN(point.x) || isNaN(point.y)) {
                        return false;
                    }
                }));
                if (flattenDeep(curveChecks).includes(false)) {
                    return false;
                }
            });
            if (checks.includes(false)) {
                return false;
            }
        } catch (e) {
            return false;
        }
        return true;
    }

    /**
     * Returns reduced path from points
     * @param  {string}   firstPath   The first path
     * @param  {string}   secondPath  The second path
     * @return {string}               The combined path
     */
    static concatTwoPaths(firstPath, secondPath) {
        return secondPath ? (firstPath ? firstPath + " L" + secondPath.slice(1) : secondPath) : firstPath;
    }

    /**
     * Create simple unclosed polyline path from point array
     * @param  {Array<string>}  points The source array of points (like this: ["12,3", "10,5", "11,6"])
     * @return {string}                The new unclosed polyline path.
     * @example
     * createFromPoints(["12,3", "10,5", "11,6"]);
     * // returns: "M 12,3 L 10,5 L 11,6"
     */
    static createFromPoints(points) {
        return "M " + points.join(" L ");
    }

    /**
     * Returns reduced path from points
     * @param  {Array<string>}   points  The point array, like this: ["1,5", "4,6", "10,32"]
     * @param  {number}  deviation     The maximum deviation (%) of a second point from straight between first and third points, allows to remove second point
     * @param  {number}  limitDistance The minimal distance between points, which is sufficient to save them
     * @return {string}                  The reduced path
     */
    static createReducedPath(points, deviation=2.5, limitDistance=16) {
        let reducedPoints = Path.excludeDuplicatePoints(points);
        if (reducedPoints.length >= 3) {
            let path = Path.createFromPoints(reducedPoints);
            path = Path.reduceSimplePath(path, 0, deviation, limitDistance);
            return path;
        } else {
            return "";
        }
    }

    /**
     * Remove points, which is equal to the next one
     * @param  {Array}  points The source array of points (like this: ["12,3", "10,5", "11,6"])
     * @return {Array}         The updated array without duplicates
     * @example
     * excludeDuplicatePoints(["1,4", "4,7", "3,5", "3,5", "3,5", "4,7"]);
     * // returns: ["1,4", "4,7" "3,5", "4,7"]
     */
    static excludeDuplicatePoints(points) {
        let reducedPoints = [];
        points.map((point, i) => (i === 0 || point !== points[i - 1]) && reducedPoints.push(point));
        return reducedPoints;
    }

    /**
     * Get all segments
     * @param  {string}  path          The source path
     * @param  {number}  simpleIndex   The index of path in whole path (like "M ... " in "M .. M .. M ..")
     * @return {string}                return all segments in path (offcut)
     * @example
     * getAllSegments("M 1,1 L 1,6 Q 34,2 1,1", 0);
     * // returns: "L 1,6 Q 34,2 1,1"
     */
    static getAllSegments(path, simpleIndex) {
        return Path._toString(Path._parse(path)[simpleIndex].curves, "offcut")
    };

    /**
     * Get base point of index-th segment in simpleIndex-th elementary path
     * @typedef {{x: number, y: number}} Point
     * @param  {string}  path          The source path
     * @param  {number}  simpleIndex   The index of path in whole path (like "M ... " in "M .. M .. M ..")
     * @param  {number}  index         The index of segment path (like "L .." in "M .. L .. L ..")
     * @return {Point}                 return base point (last in segment)
     * @example
     * getBasePoint("M 1,1 L 1,6 Q 34,2 1,1", 0, 0);
     * // returns: {x: 1, y: 6}
     */
    static getBasePoint(path, simpleIndex, index) {
        if (index >= 0) {
            let points = Path._parse(path)[simpleIndex].curves[index].points;
            return points[points.length - 1];
        }
        return Path._parse(path)[simpleIndex].mCoo;
    }

    /**
     * Get center of path using only base points (Barycenter used)
     * @param  {string|Array}  path                        The source path
     * @return {{centerX: number, centerY: number}}  returns minimal x- and y-coordinates contains into path
     * @example
     * getCenter("M 1,1 L 1,6 Q 34,2 1,1 L 3,6", 0);
     * // returns: {centerX: 1, centerY: 2}
     */
    static getCenter(path) {
        let parsed = path;
        if (typeof path === "string") {
            parsed = Path._parse(path);
        }
        let x1, x2, y1, y2, S = 0, centerX = 0, centerY = 0;
        let localBarycenters = [];
        parsed.map(simplePath => {
            let curves = simplePath.curves;
            S = 0;
            centerX = 0;
            centerY = 0;

            let xCoordinates = [simplePath.mCoo.x].concat(curves.map(c => c.points[c.points.length - 1].x));
            let yCoordinates = [simplePath.mCoo.y].concat(curves.map(c => c.points[c.points.length - 1].y));
            for (let i = 0; i < xCoordinates.length - 1; i++) {
                x1 = xCoordinates[i];
                y1 = yCoordinates[i];
                x2 = xCoordinates[i + 1];
                y2 = yCoordinates[i + 1];
                S += x1 * y2 - x2 * y1;
                centerX += (x1 + x2) * (x1 * y2 - x2 * y1);
                centerY += (y1 + y2) * (x1 * y2 - x2 * y1);
            }
            centerX = Math.round(centerX / 3 / S);
            centerY = Math.round(centerY / 3 / S);
            localBarycenters.push({x: centerX, y: centerY});
            return undefined;
        });

        S = 0;
        centerX = 0;
        centerY = 0;
        for (let i = 1; i < localBarycenters.length; i++) {
            x1 = localBarycenters[i - 1].x;
            y1 = localBarycenters[i - 1].y;
            x2 = localBarycenters[i].x;
            y2 = localBarycenters[i].y;
            S += x1 * y2 - x2 * y1;
            centerX += (x1 + x2) * (x1 * y2 - x2 * y1);
            centerY += (y1 + y2) * (x1 * y2 - x2 * y1);
        }
        centerX = Math.round(centerX / 3 / S);
        centerY = Math.round(centerY / 3 / S);

        if (localBarycenters.length === 1) {
            if (!isNaN(localBarycenters[0].x) && !isNaN(localBarycenters[0].y)) {
                return {centerX: localBarycenters[0].x, centerY: localBarycenters[0].y};
            } else {
                return {centerX: parsed[0].mCoo.x, centerY: parsed[0].mCoo.y};
            }
        }
        return {centerX, centerY};
    }

    /**
     * Compute Euclidean distance between two points
     * @param  {number}  x0 The x-coordinate of the first point
     * @param  {number}  y0 The y-coordinate of the first point
     * @param  {number}  x1 The x-coordinate of the second point
     * @param  {number}  y1 The y-coordinate of the second point
     * @return {number}     The Euclidean distance.
     * @example
     * getDistanceBetweenTwoPoints(1, 4, 5, 1);
     * // returns: 5
     */
    static getDistanceBetweenTwoPoints(x0, y0, x1, y1) {
        return Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2));
    }

    /**
     * Get mCoo point simpleIndex-th elementary path
     * @param  {string}  path          The source path
     * @param  {number}  simpleIndex   The index of path in whole path (like "M ... " in "M .. M .. M ..")
     * @return {string}                return first point (mCoo)
     * @example
     * getFirstPoint("M 1,1 L 1,6 Q 34,2 1,1", 0);
     * // returns: "1,1"
     */
    static getFirstPoint(path, simpleIndex) {
        return Path._toString(Path._parse(path)[simpleIndex].mCoo, "point");
    }

    /**
     * Get minimal x- and y-coordinates contains into path
     * @param  {string}  path      The source path
     * @return {[number, number]}  returns minimal x- and y-coordinates contains into path
     * @example
     * getMinimumCoo("M 1,1 L 1,6 Q 34,2 1,1", 0);
     * // returns: [1, 1]
     */
    static getMinimumCoo(path) {
        if (path === undefined || path === "") {
            return [NaN, NaN];
        }

        let parsed = Path._parse(path);
        let minX = parsed[0].mCoo.x,
            minY = parsed[0].mCoo.y;
        parsed.map(simplePath => {
            if (simplePath.mCoo.x < minX){
                minX = simplePath.mCoo.x;
            }
            if (simplePath.mCoo.y < minY){
                minY = simplePath.mCoo.y;
            }
            simplePath.curves.map(curve =>
                curve.points.map(point => {
                    if (point.x < minX) {
                        minX = point.x;
                    }
                    if (point.y < minY) {
                        minY = point.y;
                    }
                    return undefined;
                })
            );
            return undefined;
        });
        return [minX, minY];
    }

    /**
     * Get segment count
     * @param  {string}  path          The source path
     * @param  {number}  simpleIndex   The index of path in whole path (like "M ... " in "M .. M .. M ..")
     * @return {number}                return count of segments in path
     * @example
     * getNumberOfSegments("M 1,1 L 1,6 Q 34,2 1,1", 0);
     * // returns: 2
     */
    static getNumberOfSegments(path, simpleIndex) {
        return Path._parse(path)[simpleIndex].curves.length
    };

    /**
     * Get path bounding box (only base points used)
     * @param  {string}  path                                                 The source path
     * @return {{startX: number, startY: number, endX: number, endY: number}} return bounding box of path
     * @example
     * getPathBoundingBox("M 1,1 L 1,6 Q 34,2 1,1");
     * // returns: {startX: 1, startY: 1, endX: 1, endY: 6}
     */
    static getPathBoundingBox(path) {
        if (path === undefined) {
            return {startX: NaN, startY: NaN, endX: NaN, endY: NaN};
        }

        let parsed = Path._parse(path);
        let maxX = parsed[0].mCoo.x,
            maxY = parsed[0].mCoo.y,
            minX = parsed[0].mCoo.x,
            minY = parsed[0].mCoo.y;
        parsed.map(simplePath => {
            if (simplePath.mCoo.x < minX){
                minX = simplePath.mCoo.x;
            }
            if (simplePath.mCoo.y < minY){
                minY = simplePath.mCoo.y;
            }
            simplePath.curves.map(curve => {
                if (curve.points[curve.points.length - 1].x < minX) {
                    minX = curve.points[curve.points.length - 1].x;
                }
                if (curve.points[curve.points.length - 1].y < minY) {
                    minY = curve.points[curve.points.length - 1].y;
                }
                return undefined;
            });

            if (simplePath.mCoo.x > maxX){
                maxX = simplePath.mCoo.x;
            }
            if (simplePath.mCoo.y > maxY){
                maxY = simplePath.mCoo.y;
            }
            simplePath.curves.map(curve => {
                if (curve.points[curve.points.length - 1].x > maxX) {
                    maxX = curve.points[curve.points.length - 1].x;
                }
                if (curve.points[curve.points.length - 1].y > maxY) {
                    maxY = curve.points[curve.points.length - 1].y;
                }
                return undefined;
            });
            return undefined;
        });
        return {startX: minX, startY: minY, endX: maxX, endY: maxY};
    }

    /**
     * Get connectorIndex-th point of curveIndex-th segment in simpleIndex-th elementary path.
     * @typedef {{x: number, y: number}} Point
     * @param  {string}  path           The source path
     * @param  {number}  simpleIndex    The index of path in whole path (like "M ... " in "M .. M .. M ..")
     * @param  {number}  curveIndex     The index of segment in path (like "L .." in "M .. L .. L ..")
     * @param  {number}  connectorIndex The index of point in segment
     * @return {Point}                  return certain point. If curveIndex === -1, mCoo will be returned
     * @example
     * getPoint("M 1,1 L 1,6 Q 34,2 1,1", 0, 1, 0);
     * // returns: {x: 34, y: 2}
     */
    static getPoint(path, simpleIndex, curveIndex, connectorIndex) {
        let parsed = Path._parse(path);
        if (curveIndex === -1) {
            return parsed[simpleIndex].mCoo;
        }
        if (connectorIndex === "last") {
            connectorIndex = parsed[simpleIndex].curves[curveIndex].points.length - 1;
        }
        return parsed[simpleIndex].curves[curveIndex].points[connectorIndex];
    }

    /**
     * Returns slice of path from first curve after point at startIndex to stop index (including)
     * @param  {string}     path         The source path
     * @param  {number}     simpleIndex  The index of path in whole path (like "M ... " in "M .. M .. M ..")
     * @param  {number}     startIndex   The index of segment to slice from it to stopIndex
     * @param  {number}     stopIndex    The index of segment to slice from startIndex to it
     * @param  {('forward'|'backward')}  side         The direction of slice (contour may be enclosed)
     * @return {string}                  return slice of path (offcut)
     * @example
     * getSlice("M 1,1 L 1,6 Q 34,2 1,1", 0, 0, 2, 'forward');
     * // returns: "L 1,6 Q 34,2 1,1"
     */
    static getSlice(path, simpleIndex, startIndex, stopIndex, side) {
        if (startIndex === stopIndex || isNaN(startIndex) || startIndex < 0 || isNaN(stopIndex) || stopIndex < 0 || path === undefined) {
            return "";
        }

        let parsed = Path._parse(path)
        let curves = parsed[simpleIndex].curves;

        if (startIndex > curves.length || stopIndex > curves.length) { // TODO: add M point
            return "";
        }

        //check, if path enclosed
        let startCoo = parsed[simpleIndex].mCoo;
        let finishCoo = curves[curves.length - 1].points[curves[curves.length - 1].points.length - 1];
        if (startCoo.x === finishCoo.x && startCoo.y === finishCoo.y) {
            startIndex = (startIndex - 1 + curves.length) % curves.length;
            stopIndex = (stopIndex - 1 + curves.length) % curves.length;
        }

        let newCurves = [];
        if (side === "forward") {
            if (startIndex < stopIndex) {
                if (startCoo.x === finishCoo.x && startCoo.y === finishCoo.y) {
                    newCurves = curves.slice(startIndex + 1, stopIndex + 1);
                } else {
                    newCurves = curves.slice(startIndex, stopIndex);
                }
            } else {
                newCurves = curves.slice(startIndex + 1);
                newCurves = newCurves.concat(curves.slice(0, stopIndex + 1));
            }
        } else if (side === "backward") {
            if (startIndex > stopIndex) {
                if (startCoo.x === finishCoo.x && startCoo.y === finishCoo.y) {
                    newCurves = curves.slice(stopIndex, startIndex + 1);
                } else {
                    curves = [{command: "L", points: [{x: startCoo.x, y: startCoo.y}]}].concat(curves);
                    newCurves = curves.slice(stopIndex, startIndex + 1);
                }
            } else {
                newCurves = curves.slice(stopIndex);
                newCurves = newCurves.concat(curves.slice(0, startIndex + 1));  //startIndex + 1
            }

            const utils = new SVGPathUtils();
            let d = "M 0,0" + Path._toString(newCurves, "offcut");
            let inverse_d = utils.inversePath(d); //.replace(/[LQCM](?! )/, "$& ");
            inverse_d = inverse_d.slice(inverse_d.search(/[LQC]/));
            while (inverse_d !== inverse_d.replace(/[LQCM](?! )/, "$& ")) {
                inverse_d = inverse_d.replace(/[LQCM](?! )/, "$& ");
            }
            inverse_d = Path
                .removeSegments("M 0,0 " + inverse_d, 0, Path.getNumberOfSegments("M 0,0 " + inverse_d, 0) - 1, 1);
            inverse_d = inverse_d.slice(inverse_d.search(/[LQC]/));
            return inverse_d;
        }
        return Path._toString(newCurves, "offcut");
    }

    /**
     * Check if path enclosed or not (path enclosed when its first point is equal to the last one
     * @param  {string}  path          The source path
     * @param  {number}  simpleIndex   The index of path in whole path (like "M ... Z" in "M .. Z M .. Z M .. Z")
     * @return {boolean}               return if path enclosed or not
     * @example
     * isEnclosed("M 1,1 L 1,6 Q 34,2 1,1", 0);
     * // returns: true
     */
    static isEnclosed(path, simpleIndex) {
        let parsed = Path._parse(path)[simpleIndex];
        if (!parsed) {
            return undefined;
        }
        let startCoo = parsed.mCoo;
        let finishCoo = parsed.curves[parsed.curves.length - 1]
            .points[parsed.curves[parsed.curves.length - 1].points.length - 1];
        return startCoo.x === finishCoo.x && startCoo.y === finishCoo.y;
    };

    /**
     * Approximate and reduce simple path
     * @param  {string}  path          The source long path
     * @param  {number}  simpleIndex   The index of path in whole path (like "M ... Z" in "M .. Z M .. Z M .. Z")
     * @param  {number}  deviation     The maximum deviation (%) of a second point from straight between first and third points, allows to remove second point
     * @param  {number}  limitDistance The minimal distance between points, which is sufficient to save them
     * @return {string}                The new reduced path
     * @example
     * reduceSimplePath("M 12,3 L 10,5 L 11,6", 0, 5, 10);
     * // returns: "M 12,3 L 11,6"
     */
    static reduceSimplePath(path, simpleIndex, deviation, limitDistance) {
        let parsed = Path._parse(path)[simpleIndex]; //parsed has the only curve always

        //first point need for computing
        parsed.curves = [{command: "L", points: [{x: parsed.mCoo.x, y: parsed.mCoo.y}]}].concat(parsed.curves);

        let usefulIteration = true;
        while (usefulIteration) {
            usefulIteration = false;
            let tempReducedCurves = [parsed.curves[0]];
            for (let i = 0; i < parsed.curves.length; i+=2) {
                if (i + 2 < parsed.curves.length) {
                    let x1 = parsed.curves[i].points[0].x, //L always has the only point
                        y1 = parsed.curves[i].points[0].y,
                        x2 = parsed.curves[i + 1].points[0].x,
                        y2 = parsed.curves[i + 1].points[0].y,
                        x3 = parsed.curves[i + 2].points[0].x,
                        y3 = parsed.curves[i + 2].points[0].y;

                    let yv = (x2 - x3) * (y1 - y3) / (x1 - x3) + y3;
                    let xh = (y2 - y3) * (x1 - x3) / (y1 - y3) + x3;

                    let hvLength = Math.sqrt(Math.pow(xh - x2, 2) + Math.pow(yv - y2, 2));
                    let hbLength = Math.abs(xh - x2);
                    let hpLength = hbLength * hbLength / hvLength;
                    let bpLength = Math.sqrt(Math.pow(hbLength, 2) - Math.pow(hpLength, 2));//three-points curvature
                    let distance = Math.sqrt((x3 - x1) * (x3 - x1) + (y3 - y1) * (y3 - y1));

                    if ((bpLength / distance) * 100 < deviation || distance < limitDistance) {//несущественное отклонение или короткий отрезок
                        tempReducedCurves.push(parsed.curves[i + 2]);
                        usefulIteration = true;
                    } else {
                        tempReducedCurves.push(parsed.curves[i + 1]);
                        tempReducedCurves.push(parsed.curves[i + 2]);
                    }
                } else if (i + 1 < parsed.curves.length) {
                    tempReducedCurves.push(parsed.curves[i + 1]);
                }
            }
            parsed.curves = tempReducedCurves;
        }

        let quadraticCurvedCurves = [];
        for (let i = 0; i < parsed.curves.length; i+=2) {
            if (i + 2 < parsed.curves.length) {
                let [middleX, middleY] = [0, 0];
                let fx = +parsed.curves[i + 1].points[0].x;
                let fy = +parsed.curves[i + 1].points[0].y;
                let x0 = +parsed.curves[i].points[0].x;
                let y0 = +parsed.curves[i].points[0].y;
                let x2 = +parsed.curves[i + 2].points[0].x;
                let y2 = +parsed.curves[i + 2].points[0].y;
                let t = Path.getDistanceBetweenTwoPoints(x0, y0, fx, fy) / (
                    Path.getDistanceBetweenTwoPoints(x0, y0, fx, fy) + Path.getDistanceBetweenTwoPoints(fx, fy, x2, y2)
                );

                middleX = (fx - (1 - t) * (1 - t) * x0 - t * t * x2) / 2 / t / (1 - t);
                middleY = (fy - (1 - t) * (1 - t) * y0 - t * t * y2) / 2 / t / (1 - t);

                quadraticCurvedCurves.push({
                    command: "Q",
                    points: [
                        {x: middleX, y: middleY},
                        parsed.curves[i + 2].points[0]
                    ]
                });
            } else if (i + 1 < parsed.curves.length) {
                quadraticCurvedCurves.push({
                    command: "L",
                    points: [
                        parsed.curves[i + 1].points[0]
                    ]
                });
            }
        }
        parsed.curves = quadraticCurvedCurves;
        return Path._toString([parsed], "path");
    }

    /**
     * Remove count curves from startIndex to startIndex+count
     * @param  {string}  path        The string path
     * @param  {number}  simpleIndex The index of path in whole path (like "M ... Z" in "M .. Z M .. Z M .. Z")
     * @param  {number}  startIndex  The index of segment after which points will be remove
     * @param  {number}  count       The point to remove count
     * @return {string}              The path with removed points
     */
    static removeSegments(path, simpleIndex, startIndex, count) {
        if (isNaN(startIndex) || startIndex < 0 || isNaN(count) || count < 0 || path === undefined) {
            return "";
        }

        let parsed = Path._parse(path);
        if (startIndex + count > parsed[simpleIndex].curves.length || startIndex >= parsed[simpleIndex].curves.length) {
            return "";
        }
        parsed[simpleIndex].curves.splice(startIndex, count);
        return Path._toString(parsed, "path");
    }

    /**
     * Replace some points in path
     * @typedef {{spInd: number, curveInd: (number|'last'), conInd: number, x: number, y: number}} PointWithPlace
     * @param  {string}  path                      The string path
     * @param  {Array<PointWithPlace>}  newPoints  The point array with their places (indices in path)
     * @return {string}                            The path with replaced points
     */
    static replacePoints(path, newPoints) {
        let parsed = Path._parse(path);

        newPoints.map(newPoint => {
            if (newPoint.curveInd === "last") {
                newPoint.curveInd = parsed[newPoint.spInd].curves.length - 1;
            }
            if (newPoint.conInd === "last" && newPoint.curveInd !== -1) {
                newPoint.conInd = parsed[newPoint.spInd].curves[newPoint.curveInd].points.length - 1;
            }
            if (newPoint.curveInd === -1) {
                parsed[newPoint.spInd].mCoo = {x: newPoint.x, y: newPoint.y};
            } else {
                parsed[newPoint.spInd].curves[newPoint.curveInd].points[newPoint.conInd] = {
                    x: newPoint.x,
                    y: newPoint.y
                };
            }
            return undefined;
        });
        return Path._toString(parsed, "path")
    }

    /**
     * Round number to hundredths
     * @param  {number}  num   The number to round
     * @return {number}        The rounded number
     */
    static roundCooToHundredths(num) {
        return Math.round(num * 100) / 100;
    }

    /**
     * Round point to hundredths
     * @typedef {{x: number, y: number}} Point
     * @param  {Point}  point   The point to round
     * @return {Point}          The rounded point
     */
    static roundPointToHundredths(point) {
        return {x: Path.roundCooToHundredths(point.x), y: Path.roundCooToHundredths(point.y)};
    }

    /**
     * Round all coordinates in path to hundredths
     * @param  {string} path The string path to round
     * @return {string}      The rounded string path.
     * @example
     * round("M 12,1556 L 2,311");
     * // returns: "M 12,16 L 2,31"
     */
    static roundToHundredths(path) {
        if (path === "") {
            return "";
        }

        let parsed = Path._parse(path);
        parsed.map((simplePath, i) => {
            parsed[i].mCoo = Path.roundPointToHundredths(parsed[i].mCoo);
            simplePath.curves.map((curve, j) =>
                curve.points.map((point, k) =>
                    parsed[i].curves[j].points[k] = Path.roundPointToHundredths(point))
            );
            return undefined;
        });
        return Path._toString(parsed, "path");
    };

    /**
     * Transform path via shifting and scaling it along x and y axis
     * @param  {string}  path    The string path
     * @param  {number}  xShift  x-axis shift of coordinates (not scaled)
     * @param  {number}  yShift  y-axis shift of coordinates (not scaled)
     * @param  {number}  xScale  x-axis scale of coordinates
     * @param  {number}  yScale  y-axis scale of coordinates
     * @return {string}          The transformed path
     */
    static transform(path, xShift, yShift, xScale, yScale) {
        if (path === "") {
            return "";
        }
        let parsed = Path._parse(path);
        parsed.map((simplePath, i) => {
            parsed[i].mCoo = {x: (parsed[i].mCoo.x + xShift) * xScale, y: (parsed[i].mCoo.y + yShift) * yScale};
            simplePath.curves.map((curve, j) =>
                curve.points.map((point, k) =>
                    parsed[i].curves[j].points[k] = {x: (point.x + xShift) * xScale, y: (point.y + yShift) * yScale})
            );
            return undefined;
        });

        return Path._toString(parsed, "path");
    }
}

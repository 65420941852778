import {useDataSource} from "../../hooks/useContextDataSource";
import {useLocalization} from "../../../../../../../customHooks/useContextLocalization";


export default function ({selectedNodeId}) {
    const {dataSources, dispatch} = useDataSource();
    const locale = useLocalization();

    const updateCustomServerMapListOfNode = event => dispatch({
        type: "Add or update data source",
        id: selectedNodeId,
        data: {serverCustomList: event.target.value}
    });
    const setServerMapSourceOfNode = event => dispatch({
        type: "Add or update data source",
        id: selectedNodeId,
        data: {serverMapSource: event.target.value}
    });

    return <div className="project-analytics-source-node-configurator-server-map-selector">
        <div>
            <input
                type="radio"
                id="use-all-maps-from-server"
                name="map-server-source"
                value="table"
                checked={dataSources[selectedNodeId]?.serverMapSource === "table"}
                onChange={setServerMapSourceOfNode}
            />
            <label htmlFor="use-all-maps-from-server">
                {locale?.get?.project.analytics.mainPage.dfd.configurator.useAllMapsFromTable}
            </label>
        </div>
        <div>
            <input
                type="radio"
                id="use-following-maps"
                name="map-server-source"
                value="custom"
                checked={dataSources[selectedNodeId]?.serverMapSource === "custom"}
                onChange={setServerMapSourceOfNode}
            />
            <label htmlFor="use-following-maps">
                {locale?.get?.project.analytics.mainPage.dfd.configurator.useFollowingMaps}
                <input
                    type="text"
                    className="project-analytics-source-node-configurator-custom-map-list"
                    placeholder="map_id-map_ver, map_id-map_ver"
                    value={dataSources[selectedNodeId]?.serverCustomList || ""}
                    onChange={updateCustomServerMapListOfNode}
                />
            </label>
        </div>
    </div>;
}
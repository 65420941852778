import React, {useState} from "react";

import Rectangle from "../Rectangle";
import {useCanvasBoundingClientRect} from "../../../customHooks/useContextCanvasBoundingClientRect";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import useCooTransformation from "./useCooTransformation";
import {getUuidHierarchy} from "../../../utils/figureHierarchy";
import Path from "../../../utils/path";
import {replaceAt} from "../../../../utils";
import {getRealTarget} from "./utils/target";
import {isPointIntoRectangle} from "./utils/selection";
import {isUUID} from "../../../utils/validators";
import {checkIfArrowDependent, getPhysicalArrowCoos} from "../../figures/utils/arrow";


export default function useObjectSelection() {
    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const {figures, scale, selectedFigureUuids} = useReduxData();
    const {transformYForCanvas, transformXForCanvas} = useCooTransformation();

    const [rectangleX, setRectangleX] = useState(0);
    const [rectangleY, setRectangleY] = useState(0);
    const [rectangleWidth, setRectangleWidth] = useState(0);
    const [rectangleHeight, setRectangleHeight] = useState(0);
    const [isRectangleSelectionActive, setIsRectangleSelectionActive] = useState(false);

    const selectObject = event => {
        let target = getRealTarget(event.target);
        if (target) {
            let targetId = target.id.split("|")[0];
            let targetUuidHierarchy = getUuidHierarchy(figures, targetId);
            let shouldSelect = selectedFigureUuids.length === 0 || event.ctrlKey;
            if (!shouldSelect) {
                let isTargetSelected = false;
                for (let i = 0; i < targetUuidHierarchy.length; i++) {
                    if (selectedFigureUuids.includes(targetUuidHierarchy[i])) {
                         isTargetSelected = true;
                         break;
                    }
                }
                if (!isTargetSelected) {
                    shouldSelect = true;
                }
            }
            if (shouldSelect) {
                return {type: "simpleSelection", payload: targetUuidHierarchy.slice(targetUuidHierarchy.length - 1)};
            }
        } else if (event.target.className.baseVal !== "transformational-connector") {
            return {type: "resetSelection"};
        }
        return {type: "canMove"};
    };

    const selectSubgroup = event => {
        let target = getRealTarget(event.target);
        if (target) {
            const targetId = target.id.split("|")[0];
            const targetUuidHierarchy = getUuidHierarchy(figures, targetId);
            const selectedGroupUuid = selectedFigureUuids.filter(uuid => targetUuidHierarchy.includes(uuid))[0];
            const selectedGroupIndex = selectedFigureUuids.indexOf(selectedGroupUuid);
            const subgroupToSelectUuid = targetUuidHierarchy[targetUuidHierarchy.indexOf(selectedGroupUuid) - 1];
            return subgroupToSelectUuid
                ? replaceAt(selectedFigureUuids, selectedGroupIndex, subgroupToSelectUuid)
                : selectedFigureUuids;
        }
        return [];
    };

    const startRectangleSelection = event => {
        const [figureId, segmentIndex] = event.target.id.split("|");
        if (!isUUID(figureId) || isNaN(parseFloat(segmentIndex)) || figures.get(figureId).get("tool").split("-")[0] !== "a") {
            setIsRectangleSelectionActive(true);
            setRectangleX(event.pageX - canvasBoundingClientRect.get.left);
            setRectangleY(event.pageY - canvasBoundingClientRect.get.top);
        }
    };

    const changeSelectionRectangle = (newXCoo, newYCoo) => {
        if (isRectangleSelectionActive) {
            setRectangleWidth(newXCoo - rectangleX - canvasBoundingClientRect.get.left);
            setRectangleHeight(newYCoo - rectangleY - canvasBoundingClientRect.get.top);
        }
    };

    const finishRectangleSelection = () => {
        let figuresData = [];
        figures.forEach(figure => {
            let startPoint = {}, endPoint = {};
            const figureType = figure.get("tool").split("-")[0];
            const transformedX = transformXForCanvas(figure.get("x"));
            const transformedY = transformYForCanvas(figure.get("y"));
            if (figureType === "i") {
                startPoint.x = transformedX + figure.get("volume") * 1.5 * scale;
                startPoint.y = transformedY + figure.get("volume") * scale;
                endPoint.x = startPoint.x + figure.get("volume") * scale;
                endPoint.y = startPoint.y + figure.get("volume") * scale;
            } else if (figureType === "t") {
                startPoint.x = transformedX;
                startPoint.y = transformedY;
                endPoint.x = startPoint.x + figure.get("name").length * figure.get("fontSize") / 2;
                endPoint.y = startPoint.y + 20;
            } else if (["p", "c", "pl", "pr"].includes(figureType)
                && typeof figure.get("x") === "number" && typeof figure.get("y") === "number"
            ) {
                startPoint.x = transformedX;
                startPoint.y = transformedY;
                endPoint.x = transformedX + Path.getPathBoundingBox(figure.get("points")).endX * scale;
                endPoint.y = transformedY + Path.getPathBoundingBox(figure.get("points")).endY * scale;
            } else if (figureType === "r") {
                startPoint.x = transformedX;
                startPoint.y = transformedY;
                endPoint.x = startPoint.x + figure.get("width") * scale;
                endPoint.y = startPoint.y + figure.get("height") * scale;
            } else if (figureType === "a") {

                let fromX = figure.get("fromX"),
                    fromY = figure.get("fromY"),
                    toX = figure.get("toX"),
                    toY = figure.get("toY");
                if (checkIfArrowDependent(figure)) {
                    const physicalCoos = getPhysicalArrowCoos(figure, figures);
                    if (typeof figure.get("fromX") === "string") {
                        fromX = physicalCoos.from.x;
                        fromY = physicalCoos.from.y;
                    }
                    if (typeof figure.get("toX") === "string") {
                        toX = physicalCoos.to.x;
                        toY = physicalCoos.to.y;
                    }
                }
                let [minX, maxX] = fromX < toX ? [fromX, toX] : [toX, fromX];
                let [minY, maxY] = fromY < toY ? [fromY, toY] : [toY, fromY];
                startPoint.x = transformXForCanvas(minX);
                startPoint.y = transformYForCanvas(minY);
                endPoint.x = transformXForCanvas(maxX);
                endPoint.y = transformYForCanvas(maxY);
            }

            if (typeof startPoint.x === "number"
                && typeof startPoint.y === "number"
                && typeof endPoint.x === "number"
                && typeof endPoint.y === "number"
                && !isNaN(startPoint.x)
                && !isNaN(startPoint.y)
                && !isNaN(endPoint.x)
                && !isNaN(endPoint.y)
            ) {
                figuresData.push({uuid: figure.get("uuid"), startPoint: startPoint, endPoint: endPoint});
            }
        });

        let newSelectedFigureUuids = [],
            selectionRectangle = {
                startX: rectangleX,
                startY: rectangleY,
                endX: rectangleX + rectangleWidth,
                endY: rectangleY + rectangleHeight
            };

        if (rectangleWidth < 0) {
            selectionRectangle.startX += rectangleWidth;
            selectionRectangle.endX -= rectangleWidth;
        }
        if (rectangleHeight < 0) {
            selectionRectangle.startY += rectangleHeight;
            selectionRectangle.endY -= rectangleHeight;
        }

        figuresData.forEach(figureCoos => isPointIntoRectangle(figureCoos.startPoint, selectionRectangle)
            && isPointIntoRectangle(figureCoos.endPoint, selectionRectangle)
            && newSelectedFigureUuids.push(figureCoos.uuid)
        );

        setIsRectangleSelectionActive(false);
        setRectangleX(0);
        setRectangleY(0);
        setRectangleHeight(0);
        setRectangleWidth(0);
        return newSelectedFigureUuids;
    };

    return {
        rectangleSelection: <Rectangle
            x={rectangleX}
            y={rectangleY}
            width={rectangleWidth}
            height={rectangleHeight}
            stroke="black"
            strokeDasharray={"0.25rem"}
        />,
        selectObject,
        selectSubgroup,
        startRectangleSelection,
        changeSelectionRectangle,
        finishRectangleSelection,
        isRectangleSelectionActive: (rectangleWidth === 0 && rectangleHeight === 0) ? false : isRectangleSelectionActive
    }
}

import React from "react";
import {Formik, Field, Form, ErrorMessage, getIn} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";

import {ErrorView, RecaptchaField, getStyles} from "./form_fields";
import {useLocalization} from "../customHooks/useContextLocalization";
import {useApiClient} from "../customHooks/useApiClient";
import {useCurrentUser} from "../customHooks/useContextCurrentUser";
import {useModal} from "../customHooks/useContextModal";


export const ForgotPasswordForm = () => {
    const locale = useLocalization();
    const api = useApiClient();
    const user = useCurrentUser();
    const modal = useModal();

    return user
        ? <span>{locale?.get.auth.alreadyLoggedIn}</span>
        : <Formik
            initialValues={{email: '', recaptcha: ''}}
            validationSchema={Yup.object({
                email: Yup.string()
                    .email(locale?.get.auth.errorView.invalidEmailAddress)
                    .required(locale?.get.auth.errorView.required),
                recaptcha: Yup.string()
                    .required(locale?.get.auth.errorView.arentYouRobot),
            })}
            onSubmit={async (values, {setSubmitting, setErrors}) => {
                try {
                    values.locale = locale?.get?.abbr.toLowerCase();
                    await api.post('/api/v1/user/forgot/', values);
                    modal.set("");
                    toast.success(locale?.get.auth.resetPassMsgSentOnEmail);
                } catch (err) {
                    values.recaptcha = "";
                    if ([404, 409].includes(err.response.status) && err.response.data.detail) {
                        setErrors({email: locale?.get.auth.errorView[err.response.data.detail.user]});
                    }
                }
                setSubmitting(false);
            }}
            render={formProps => {
                return <Form className='row gtr-uniform gtr-50'>
                    <h4 className="auth-title">{locale?.get.auth.restorePass}</h4>
                    <div className="field-wrapper">
                        <Field
                            className="auth-text-field"
                            name='email'
                            type='email'
                            placeholder='Email'
                            style={getIn(formProps.touched, 'email') && getStyles(formProps.errors, 'email')}
                        />
                        <ErrorMessage component={ErrorView} name='email'/>
                    </div>
                    <div className="field-wrapper">
                        <Field
                            name='recaptcha'
                            component={RecaptchaField}
                            style={getIn(formProps.touched, 'recaptcha') && getStyles(formProps.errors, 'recaptcha')}
                        />
                        <ErrorMessage component={ErrorView} name='recaptcha'/>
                    </div>
                    <div className='col-12'>
                        <button type='submit' className='button alt'>{locale?.get.auth.restorePass}</button>
                    </div>
                </Form>;
            }}
        />;
};

import React, {useRef, useState, useContext} from "react";

import useObjectMoving from "./useObjectMoving";
import useObjectTransformation from "./useObjectTransformation";
import useTransformationPreview from "./useTransformationPreview";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import {getRealTarget} from "./utils/target";


const SelectedFigureContainerEventsContext = React.createContext();


export function SelectedFigureContainerEventsProvider ({children}) {
    const {isEditMode} = useReduxData();
    const {startObjectMoving, finishObjectMoving} = useObjectMoving();
    const {startObjectTransformation, transformObjects} = useObjectTransformation();
    const {startPreviewTransformation, transformPreview, clearTransformationPreview, transformationPreviews}
        = useTransformationPreview();

    const [isMouseDown, setIsMouseDown] = useState(false);
    const [movementType, setMovingType] = useState("");
    const [position, setPosition] = useState({x: 0, y: 0, coords: {}});

    const handleMouseDownOnSelectedFiguresContainer = event => {
        if ((event.target.x || event.target.x1 || event.target.cx || event.target.parentNode.parentNode.x || event.target.parentNode.x)
            && isEditMode && event.button === 0
        ) {
            const targetFigureNode = getRealTarget(event.target);
            if (targetFigureNode) {
                if (["figure", "figure-neighborhood", "group", "text"].includes(targetFigureNode.className.baseVal)) {
                    let movementType = startObjectMoving(targetFigureNode, event.pageX, event.pageY);
                    if (movementType === "success") {
                        setIsMouseDown(true);
                        setMovingType("figure");
                    }
                } else if (targetFigureNode.className.baseVal === "transformational-connector") {
                    startObjectTransformation(event.pageX, event.pageY);
                    startPreviewTransformation(event.pageX, event.pageY);
                    setIsMouseDown(true);
                    setMovingType("connector");
                }
            }
        }
    };

    const updateConnectorTransformationPreview = event => {
        if (isMouseDown && isEditMode && movementType === "connector") {
            transformPreview(event.pageX, event.pageY);
        }
    };

    const handleMouseUpOnSelectedFiguresContainer = event => {
        if (isMouseDown && isEditMode) {
            if (movementType === "figure") {
                finishObjectMoving(event.pageX, event.pageY);
            } else if (movementType === "connector") {
                transformObjects(event);
                clearTransformationPreview();
            }
            setMovingType("");
        }

        setIsMouseDown(false)

        //finish moving figure preview
        window.removeEventListener('mousemove', updateFigureMovingPreviewCoo.current);
        setPosition({x: 0, y: 0, coords: {}});
    };

    const updateFigureMovingPreviewCoo = useRef(event => setPosition(position => {
        const xDiff = position.coords.x - event.pageX;
        const yDiff = position.coords.y - event.pageY;
        return {x: position.x - xDiff, y: position.y - yDiff, coords: {x: event.pageX, y: event.pageY}};
    }));

    const setInitialFigureMovingPreviewCoo = event => {
        if (event.button === 0 && isEditMode) {
            setPosition(position => Object.assign({}, position, {coords: {x: event.pageX, y: event.pageY}}));
            window.addEventListener('mousemove', updateFigureMovingPreviewCoo.current);
        }
    };

    return <SelectedFigureContainerEventsContext.Provider value={{
        handleMouseDownOnSelectedFiguresContainer,
        handleMouseUpOnSelectedFiguresContainer,
        setInitialFigureMovingPreviewCoo,
        updateConnectorTransformationPreview,
        movementType,
        position,
        transformationPreviews
    }}>
        {children}
    </SelectedFigureContainerEventsContext.Provider>;
}


export const useSelectedFigureContainerEvents = () => useContext(SelectedFigureContainerEventsContext);

import {useEffect, useRef, useMemo} from "react";
import QRCode from "qrcode";

import {useApiClient} from "../../../../customHooks/useApiClient";
import {useMap} from "../../../customHooks/useContextMap";
import {useCurrentProject} from "../../../customHooks/useContextCurrentProject";
import ClassificationContainer from "./ClassificationContainer";


export default function ({isAlbumFormat, width, height, background, userText, userColor, gradientAngle}) {
    const qrcodeCanvasRef = useRef();

    const map = useMap();
    const api = useApiClient();
    const {get: currentProject} = useCurrentProject();

    const classificationAlgorithmUsed = useMemo(
        () => currentProject?.reward?.classificationAlgorithm || "none",
        [currentProject]
    );

    useEffect(async () => {
        if (map.mapId) {
            const resp = await api.get(`/api/v2/map/${map.mapId}/share?mode=viewer`);
            const urlToShare = `${location.href.replace("/#", "")}/share?share_token=${encodeURIComponent(resp.data)}`;
            if (urlToShare) {
                QRCode.toCanvas(qrcodeCanvasRef.current, urlToShare, {
                    width: "5rem",
                    scale: 1.5,
                    margin: 2,
                    color: {light: "#FFFFFF00", dark: userColor === "white" ? "#FAFEFF" : "000000"}
                });
            }
        }
    }, [map.mapId, userColor]);

    return <div
        className="postcard-map-statistics"
        style={{
            width: `calc(${width} - 2rem)`,
            height: `calc(${height} - 2rem)`,
            background: background.replace("linear-gradient(", `linear-gradient(${gradientAngle}deg, `),
            color: userColor,
            padding: isAlbumFormat ? "1rem" : "1.25rem",
            flexDirection: isAlbumFormat ? "row" : "column",
            alignItems: isAlbumFormat ? "center" : "flex-start"
        }}
    >
        {["region", "concept"].includes(classificationAlgorithmUsed) && <ClassificationContainer
            classificationAlgorithmUsed={classificationAlgorithmUsed}
            userColor={userColor}
            isAlbumFormat={isAlbumFormat}
        />}
        <div
            className={`postcard-user-text-container-${isAlbumFormat ? "horizontal" : "vertical"}`}
            style={classificationAlgorithmUsed === "none"
                ? (isAlbumFormat ? {width: "calc(50% + 14.25rem)"} : {height: "20.325rem"})
                : {}}
        >
            <span style={{color: userColor}}>{userText}</span>
        </div>
        <div className="postcard-qr-container">
            <canvas ref={qrcodeCanvasRef} width="82"/>
        </div>
    </div>;
}



/**
 * Returns if target active snapping connector or not
 * @param  {Node}   target   The target DOM node
 * @return {boolean}         true if target is an active snapping connector, false otherwise
 */
export function isActiveSnappingConnector(target) {
    return target.className.baseVal === "snapping-connector"
        && target.id.split("|")[0] !== "closing-snapping-connector"
        && target.getAttribute("opacity") === "1"
}

/**
 * Returns if target active closing connector or not
 * @param  {Node}   target   The target DOM node
 * @return {boolean}         true if target is an active closing connector, false otherwise
 */
export function isActiveClosingConnector(target) {
    return target.className.baseVal === "snapping-connector"
        && target.id.split("|")[0] === "closing-snapping-connector"
        && target.getAttribute("opacity") === "1"
}

/**
 * Get connector center coordinates
 * @param  {Node}   target          The target DOM node
 * @return {{x: number, y: number}} true if target is an active snapping connector, false otherwise
 */
export function getSnappingConnectorCenterCoo(target) {
    if (isActiveSnappingConnector(target) || isActiveClosingConnector(target)) {
        const connectorXCoo = target.x.baseVal.value + 8;
        const connectorYCoo = target.y.baseVal.value + 8;
        const figureXCoo = target.parentNode.x.baseVal.value;
        const figureYCoo = target.parentNode.y.baseVal.value;
        const pointX = (figureXCoo + connectorXCoo);
        const pointY = (figureYCoo + connectorYCoo);
        return {x: pointX, y: pointY};
    } else {
        return {x: NaN,  y: NaN};
    }
}
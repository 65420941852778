import React, {useContext} from "react";
import {Formik, Field, Form, ErrorMessage, getIn} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";

import {CurrentUserContext} from "../customHooks/useContextCurrentUser";
import {ErrorView, getStyles} from "./form_fields";
import {useLocalization} from "../customHooks/useContextLocalization";
import {useApiClient} from "../customHooks/useApiClient";
import {useModal} from "../customHooks/useContextModal";


export const DeleteProfileForm = () => {
    const locale = useLocalization();
    const api = useApiClient();
    const {user, fetchUser} = useContext(CurrentUserContext);
    const modal = useModal();

    return user
        ? <Formik
            initialValues={{username: ""}}
            validationSchema={Yup.object({username: Yup.string()
                .test("username-test", locale?.get.deleteProfile.usernameIsIncorrect, value => value === user.username)
                .required(locale?.get.auth.errorView.required),
            })}
            onSubmit={async (values, {setSubmitting, setErrors}) => {
                try {
                    await api.delete('/api/v1/user/me', values);
                    modal.set("");
                    toast.success(locale?.get.deleteProfile.profileDeletedSuccessfully);
                    await fetchUser();
                } catch (err) {
                    if (err.response.status === 400 && err.response.data.detail) {
                        setErrors(err.response.data.detail);
                    }
                }
                setSubmitting(false);
            }}
            render={formProps => {
                return <Form className="buy-error-container delete-profile-container">
                    <h4 className="auth-title edit-profile-title">{locale?.get.deleteProfile.profileDeleting}</h4>
                    <p>{locale?.get.deleteProfile.explanation}</p>
                    <div className="field-wrapper edit-profile-username-container">
                        <span className="edit-profile-form-field-name">
                            {locale.get.editProfile.usernamePlaceholder}
                        </span>
                        <Field
                            className="edit-profile-text-field-short"
                            name='username'
                            type='username'
                            style={getIn(formProps.touched, 'username') && getStyles(formProps.errors, 'username')}
                        />
                        <ErrorMessage component={ErrorView} name='username'/>
                    </div>
                    <div className="edit-profile-apply-container">
                        <button type='submit' className='button alt'>{locale?.get.deleteProfile.delete}</button>
                    </div>
                </Form>;
            }}
        />
        : <span>{locale?.get.deleteProfile.signInBefore}</span>;
};

import React from "react";
import {DateTime} from "luxon";

import styles from "./datetime.module.css";


export default function ({date, langCode}) {
    const dates = DateTime.fromISO(date, {zone: "utc"})
        .setZone("local")
        .setLocale(langCode)
        .toLocaleString(DateTime.DATETIME_SHORT);
    return <div className={styles.date}>{dates}</div>;
}

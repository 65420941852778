

const defaultActionList = [ //all actions affecting figures changing
    "createMap",
    "addArrow",
    "addRectangle",
    "addPolygon",
    "addPolyline",
    "addPointFeature",
    "addGroup",
    "changeObjectName",
    "changeObjectDescription",
    "changeObjectParentUuid",
    "changeFigureColor",
    "changeFigurePoints",
    "changeFigureDashLength",
    "addFigureEmoji",
    "deleteFigureEmoji",
    "changeFigureFontColor",
    "changeFigureFontSize",
    "changeFigureTransparency",
    "changeFigureLayout",
    "changeFigureVolume",
    "changeFigureThickness",
    "loadStateByTime",
    "clearState",
    "deleteFigure",
    "disbandGroup",
    "moveFigure"
];


export function shouldFiguresUpdate (lastAction, actionList = undefined) {
    return (actionList || defaultActionList).includes(lastAction);
}
import ReactSelect from "react-select";

import {createOption} from "../../../../utils";
import {ClearIndicator, DropdownIndicator, IndicatorSeparator, ValueContainer} from "../../CustomIcons";


const ProductSingleSelect = props => {
    const changeValue = option => {
        props.setFieldValue(props.fieldName, option?.value || "");
        if (props.executeExtraEffect) {
            props.executeExtraEffect();
        }
    };

    return <div className="product-form-input-wrapper">
        <ReactSelect
            isClearable
            isPlanar
            className="store-select"
            options={props.items.map(createOption)}
            name={props.fieldName}
            placeholder={props.items[0]}
            components={{ClearIndicator, DropdownIndicator, IndicatorSeparator, ValueContainer}}
            defaultValue={props.formValues[props.fieldName] ? createOption(props.formValues[props.fieldName]) : undefined}
            onChange={changeValue}
        />
    </div>;
}

export default ProductSingleSelect;
import AsyncCreatable from "react-select/async-creatable";

import {createOption} from "../../../../utils";
import {ClearIndicator, DropdownIndicator, IndicatorSeparator, ValueContainer} from "../../CustomIcons";
import {useApiClient} from "../../../../customHooks/useApiClient";
import {useLocalization} from "../../../../customHooks/useContextLocalization";


const ProductTags = props => {
    const api = useApiClient();
    const locale = useLocalization();

    const searchPossibleTags = inputValue => api.get(
        `/api/v2/store/tag-list/`,
        {params: {user_input: inputValue, lang: props.lang}}
    ).then(result => result.data.map(createOption));

    const getNoOptionMessage = () => locale?.get?.store.product.form.noOptions;
    const getLoadingMessage = () => locale?.get?.store.product.form.loading;
    const setFieldValue = options => props.setFieldValue(`tags_${props.lang}`, options.map(option => option.value));

    return <div className="product-form-input-wrapper">
        <AsyncCreatable
            isClearable
            isMulti
            closeMenuOnSelect
            cacheOptions
            defaultOptions
            className="store-select"
            loadOptions={searchPossibleTags}
            name={`tags_${props.lang}`}
            placeholder={locale?.get?.store.product.form.tagsPlaceholder}
            components={{ClearIndicator, DropdownIndicator, IndicatorSeparator, ValueContainer}}
            noOptionsMessage={getNoOptionMessage}
            loadingMessage={getLoadingMessage}
            onChange={setFieldValue}
            value={props.formValues[`tags_${props.lang}`] ? props.formValues[`tags_${props.lang}`].map(createOption) : []}
        />
    </div>
}

export default ProductTags;
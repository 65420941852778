import DataTypeLabel from "./DataTypeLabel";
import DataSourceSelector from "./DataSourceSelector";
import LocalDataLoader from "./LocalDataLoader";
import ServerDataSourceSelector from "./ServerDataSourceSelector";
import ServerMapSourceSelector from "./ServerMapSourceSelector";
import ServerTableDumpSelector from "./ServerTableDumpSelector";
import {useDataSource} from "../../hooks/useContextDataSource";
import {useLocalization} from "../../../../../../../customHooks/useContextLocalization";


export default function ({handleServerMapTableShowing, selectedNodeId}) {
    const {dataSources} = useDataSource();
    const locale = useLocalization();

    const nodeDataSource = dataSources[selectedNodeId]?.source;
    const nodeDataServerSource = dataSources[selectedNodeId]?.serverSource;

    return <div className="project-analytics-source-node-configurator-container">
        <h3>{locale?.get?.project.analytics.mainPage.dfd.configurator.title}</h3>
        <div className="project-analytics-source-node-configurator-grid">
            <DataSourceSelector
                selectedNodeId={selectedNodeId}
                handleServerMapTableShowing={handleServerMapTableShowing}
            />
            {nodeDataSource === "local"
                ? <LocalDataLoader selectedNodeId={selectedNodeId}/>
                : nodeDataSource === "server" ? <ServerDataSourceSelector selectedNodeId={selectedNodeId}/> : null
            }
            {nodeDataSource === "server"
                ? nodeDataServerSource === "maps"
                    ? <ServerMapSourceSelector selectedNodeId={selectedNodeId}/>
                    : nodeDataServerSource === "dump" ? <ServerTableDumpSelector selectedNodeId={selectedNodeId}/> : null
                : null
            }
        </div>
        <DataTypeLabel id={selectedNodeId}/>
    </div>
}

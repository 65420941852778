import React, {useContext, useEffect, useState} from "react";

import "./tabs.css";


const TabContext = React.createContext();


export const TabProvider = ({tabs, activeTab=0, children}) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(activeTab);

    useEffect(() => setCurrentTabIndex(activeTab), [activeTab]);

    return (
        <TabContext.Provider value={{tabs, currentTab: tabs[currentTabIndex], currentTabIndex, setCurrentTabIndex}}>
            {children}
        </TabContext.Provider>
    );
};


export const useTabs = () => {
    const {tabs, currentTab, currentTabIndex, setCurrentTabIndex} = useContext(TabContext);
    return {tabs, currentTab, currentTabIndex, setCurrentTabIndex};
};


export function TabBar({}) {
    const {tabs, currentTabIndex, setCurrentTabIndex} = useTabs();

    return <div className="tabs">
        {tabs.map((tab, i) => <div
            key={i}
            className={`tab${currentTabIndex === i ? " active" : ""}`}
            onClick={() => setCurrentTabIndex(i)}
        >
            <label>{tab.title}</label>
        </div>)}
    </div>;
}

export function TabContent({}) {
    const {currentTab} = useTabs();
    return <div>{currentTab.content}</div>;
}

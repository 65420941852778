import React, {useRef} from "react";

import NameField from "./NameField";
import {useTabs as useSemuiTabs} from "../../../../customHooks/useTabs";
import useTabs from "../hooks/useContextTabs";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import useTabDragSource from "./dnd/hooks/useTabDragSource";
import useTabDropTarget from "./dnd/hooks/useTabDropTarget";

import {ReactComponent as Cross} from "../../../images/cross-transparent.svg";


export default function TabTitle({name, tabIndex}) {
    const tabRef = useRef();

    const {tabs, dispatchTabs} = useTabs();
    const {setCurrentTabIndex, currentTabIndex} = useSemuiTabs();
    const locale = useLocalization();

    const [{isTabDragging}, dragTab] = useTabDragSource(tabs[tabIndex].id);
    const [{handlerId, sourceTabId}, dropTab] =
        useTabDropTarget(tabRef, tabIndex, dispatchTabs, tabs, setCurrentTabIndex, currentTabIndex);

    const tryToRemoveTab = tabIndex => event => {
        event.stopPropagation();
        const answer = confirm(locale.get.store.icons.tabDeleteWarn);
        if (answer) {
            dispatchTabs({type: "deleteTab", payload: {tIndex: tabIndex}})
            if (tabIndex === tabs.length - 1) {
                setCurrentTabIndex(Math.max(tabs.length - 2, 0));
            } else if (currentTabIndex > tabIndex) {
                setCurrentTabIndex(currentTabIndex - 1);
            }
        }
    };

    dragTab(dropTab(tabRef));
    let opacity = (sourceTabId === tabs[tabIndex].id) ? 0 : 1;

    return <div className="account-icons-tab-title-container" ref={tabRef} style={{opacity}}>
        <div style={{width: `${0.60 * name.length}rem`}}>
            <NameField defaultValue={name} tIndex={tabIndex}/>
        </div>
        {tabs.length > 1 ? <Cross
            className="account-icons-partition-button"
            id={`account-icons-partition-button-${tabIndex}`}
            height="16"
            width="16"
            viewBox="-4 -4 24 24"
            fill="#144066"
            onClick={tryToRemoveTab(tabIndex)}
            style={{marginLeft: "0.25rem"}}
        /> : null}
    </div>;
}

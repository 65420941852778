import React from 'react';

import styles from './headerPattern.module.css';


export default function (props) {
    return <header className={styles.header}>
        <div className={styles.title} onClick={props.onClick}>
            {props.renderIconLogo({className: styles.logo})}
            {props.renderIconName({className: styles.name})}
        </div>
        <div className={styles.icons}>
            {props.icons.map(item => <div key={item.key} className={styles.icon}>{item}</div>)}
        </div>
    </header>;
}

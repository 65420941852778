import React from "react";

import {useReduxData} from "../../../customHooks/useContextReduxData";
import {CANVAS_HEIGHT, CANVAS_WIDTH} from "../Canvas";


export default function ({shouldShow}) {
    const {scale} = useReduxData();

    return shouldShow ? <rect
        x="0"
        y="0"
        width={scale * CANVAS_WIDTH + "px"}
        height={scale * CANVAS_HEIGHT + "px"}
        fill="black"
        opacity="0"
    /> : null; // фон для перемещения коннектора или фигуры, без него mouse up не ловится, (SelectedFigureContainer бесконечно малый делается)
}
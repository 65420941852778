import {useDataSource} from "../../hooks/useContextDataSource";
import {useLocalization} from "../../../../../../../customHooks/useContextLocalization";


export default function ({selectedNodeId}) {
    const {dataSources, dispatch} = useDataSource();
    const locale = useLocalization();

    const setServerTableDumpOfNode = event => dispatch({
        type: "Add or update data source",
        id: selectedNodeId,
        data: {serverDumpTable: event.target.value}
    });

    return <select onChange={setServerTableDumpOfNode} value={dataSources[selectedNodeId]?.serverDumpTable}>
        <option>--{locale?.get?.project.analytics.mainPage.dfd.configurator.chooseTable}--</option>
        <option>Surveys</option>
        <option>Users</option>
    </select>;
}
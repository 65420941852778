import {useState, useEffect} from "react";

import MapPreview from "./MapPreview";
import PostcardInfoBar from "./PostcardInfoBar";
import Slider from "../../common/Slider";
import useLinearGradient from "./customHooks/useLinearGradient";
import useUserText from "./customHooks/useUserText";
import {useLocalization} from "../../../../customHooks/useContextLocalization";

import {ReactComponent as Logo} from "../../../../images/logo.svg";


export default function ({tabIndex}) {
    const [shouldShowImage, setShouldShowImage] = useState(false);
    const locale = useLocalization();
    const {
        predefinedGradients,
        chosenGradient,
        chooseGradient,
        gradientHexColors,
        gradientAngle,
        chooseGradientAngle
    } = useLinearGradient();
    const {userText, changeUserText, userColor, changeUserColor, colors} = useUserText();

    useEffect(() => {  // otherwise previous map version loads
        setTimeout(() => setShouldShowImage(true), 1000);
    }, []);

    return <div className="postcard-tab-container" style={{borderTopLeftRadius: !tabIndex ? "0" : "0.625rem"}}>
        <div id="postcard-container" className="postcard-container" style={{flexDirection: tabIndex ? "row": "column"}}>
            <span className="postcard-link-to-studio">creativemaps.studio</span>
            <Logo
                className="postcard-logo"
                height="2.25rem"
                width="2.25rem"
                style={tabIndex ? {left: "0.625rem", bottom: "0.625rem"} : {right: "0.625rem", top: "0.625rem"}}
             />
            {shouldShowImage && <MapPreview width={tabIndex ? "38.9375rem" : "100%"} height={tabIndex ? "100%" : "22.5rem"}/>}
            <PostcardInfoBar
                isAlbumFormat={!tabIndex}
                width={tabIndex ? "13.5625rem" : "100%"}
                height={tabIndex ? "100%" : "7.5rem"}
                background={chosenGradient}
                gradientAngle={gradientAngle}
                userText={userText}
                userColor={userColor}
            />
        </div>
        <div className="postcard-configs-container">
            <div className="postcard-config-color-container">
                <span className="postcard-part-title">{locale.get.studio.postcard.predefinedColors}</span>
                <div className="postcard-config-colors-container">
                    {predefinedGradients.map(gradient => {
                        const isActive = chosenGradient === gradient;
                        return <div
                            key={`postcard-gradient-${gradient}`}
                            className={`postcard-config-color${isActive ? "-active" : ""}`}
                            style={{background: isActive ? "#FAFEFF" : gradient}}
                            onClick={chooseGradient}
                        >
                            {isActive && <div className="postcard-config-color-small" style={{background: gradient}}/>}
                        </div>
                    })}
                </div>
                <div className="postcard-config-color-params-container">
                    <div className="postcard-config-color-chosen-container">
                        <div className="postcard-config-color-chosen" style={{backgroundColor: gradientHexColors.first}}/>
                        <span className="postcard-config-color-chosen-name">{gradientHexColors.first}</span>
                    </div>
                    <div className="postcard-config-color-chosen-container">
                        <div className="postcard-config-color-chosen" style={{backgroundColor: gradientHexColors.second}}/>
                        <span className="postcard-config-color-chosen-name">{gradientHexColors.second}</span>
                    </div>
                    <div className="postcard-config-gradient-angle-container">
                        <span className="postcard-config-gradient-angle-title">
                            {locale.get.studio.postcard.slope}
                        </span>
                        <Slider
                            id="scale-slider"
                            value={gradientAngle}
                            min="-90"
                            max="90"
                            step="10"
                            style={{width: "100%"}}
                            isLabelHidden={true}
                            onChange={chooseGradientAngle}
                        />
                    </div>
                </div>
            </div>
            <div className="postcard-config-text-container">
                <span className="postcard-part-title">{locale.get.studio.postcard.yourText}</span>
                <span className="postcard-part-title">{locale.get.studio.postcard.textColor}</span>
                <textarea
                    className="postcard-config-user-text"
                    placeholder={locale.get.studio.postcard.enterText}
                    value={userText}
                    onChange={changeUserText}
                />
                <select className="postcard-config-text-color" value={userColor} onChange={changeUserColor}>
                    {colors.map(color => <option
                        key={`postcard-text-color-${color}`}
                        value={color}
                    >{locale.get.studio.postcard[color]}</option>)}
                </select>
            </div>
        </div>
    </div>;
}
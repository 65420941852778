import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {v4 as uuid} from "uuid";

import {useCanvasBoundingClientRect} from "./useContextCanvasBoundingClientRect";
import {useReduxData} from "./useContextReduxData";
import {getRootUuid} from "../utils/figureHierarchy";
import {useIconSet} from "../../customHooks/useContextIconSet";
import useCooTransformation from "../components/canvas/customHooks/useCooTransformation";
import {
    getSnappingConnectorCenterCoo,
    isActiveClosingConnector,
    isActiveSnappingConnector
} from "./utils/snappingConnector";
import Path from '../utils/path.js';
import {groupSchema, polygonSchema, polylineSchema} from "../utils/validators";
import getTimeElapsedSince from "../components/getTimeElapsedSince";


const PencilDrawingContext = React.createContext();


export function PencilDrawingProvider({children}) {
    const {get: {left: canvasX, top: canvasY}} = useCanvasBoundingClientRect();
    const {
        color,
        dashLength,
        figures,
        fontColor,
        fontSize,
        isEditMode,
        isWatchMode,
        startTime,
        thickness,
        tool,
        transparency
    } = useReduxData();
    const {get: {parameters}} = useIconSet();
    const {transformYForCanvas, transformXForCanvas, transformXForMap, transformYForMap} = useCooTransformation();
    const dispatch = useDispatch();

    const figureType = tool.split("-")[0];

    const [lastSnappedPointId, setLastSnappedPointId] = useState("");

    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);

    const [snappingGroup, setSnappingGroup] = useState([]);

    const [snappingPoints, setSnappingPoints] = useState([]); //temporary points (between snapping points)
    const [pencilPoints, setPencilPoints] = useState([]); //temporary points, not reduced
    const [pencilPath, setPencilPath] = useState(""); //reduced path fragment, almost (not closed) ready to use

    //drawing always starts with start point setting
    useEffect(() => {
        if ((isEditMode || isWatchMode) && startX !== -100) {
            resetHooks();
        }
    }, [isEditMode, isWatchMode]);

    const resetHooks = () => {
        setLastSnappedPointId("");
        setStartX(-100);
        setStartY(-100);
        setSnappingGroup([]);
        setPencilPoints([]);
        setSnappingPoints([]);
        setPencilPath("");
    };

    const addSegmentToPathOnMouseDown = event => {
        if (!event.ctrlKey) {
            let x = transformXForMap(event.clientX - canvasX);
            let y = transformYForMap(event.clientY - canvasY);
            if (pencilPath === "") {
                setStartX(x);
                setStartY(y);
            }

            if (isActiveSnappingConnector(event.target)) {
                let connectorCoo = getSnappingConnectorCenterCoo(event.target);
                x = transformXForMap(connectorCoo.x);
                y = transformYForMap(connectorCoo.y);
                setPencilPath(`M ${x},${y}`);
                setPencilPoints([`${x},${y}`]);
                setSnappingPoints([`${x},${y}`]);
                setLastSnappedPointId(event.target.id);

                setSnappingGroup(snappingGroup.concat([event.target.id.split("|")[0]]));

            } else {
                setPencilPoints([`${x},${y}`]);
            }
        } else {
            let x, y;
            if (isActiveSnappingConnector(event.target) || isActiveClosingConnector(event.target)) {
                if (isActiveSnappingConnector(event.target)) {
                    setSnappingGroup(snappingGroup.concat([event.target.id.split("|")[0]]));
                }
                let connectorCoo = getSnappingConnectorCenterCoo(event.target);
                x = connectorCoo.x;
                y = connectorCoo.y;
            } else {
                x = event.clientX - canvasX;
                y = event.clientY - canvasY;
            }
            x = transformXForMap(x);
            y = transformYForMap(y);

            if (startX === -100 && startY === -100) {
                setPencilPath(`M ${x},${y}`);
                setStartX(x);
                setStartY(y);
            } else {
                let reducedPath = Path.createReducedPath(pencilPoints);
                let reducedSnappingPath = Path.createReducedPath(snappingPoints);
                let path = Path.concatTwoPaths(pencilPath, reducedPath);
                path = Path.concatTwoPaths(path, reducedSnappingPath);
                if (path) {
                    setPencilPath(`${path} L ${x},${y}`);
                } else {
                    setPencilPath(`M ${x},${y}`);
                }
                setPencilPoints([]);
                setSnappingPoints([]);
            }
        }
    };

    const addSegmentToPathOnMouseMove = event => {
        if (!event.ctrlKey) {
            const newPoint = [
                `${transformXForMap(event.clientX - canvasX)},${transformYForMap(event.clientY - canvasY)}`
            ];
            if (lastSnappedPointId !== "") {
                setSnappingPoints(snappingPoints.concat(newPoint));
            } else {
                setPencilPoints(pencilPoints.concat(newPoint));
            }
        }
    };

    const createFigurePath = (event, type, caller) => {
        let result = [];
        if (!event.ctrlKey || caller === "enter") {
            let reducedPath = Path.createReducedPath(pencilPoints);
            let reducedSnappingPath = Path.createReducedPath(snappingPoints);
            let path = Path.concatTwoPaths(pencilPath, reducedPath);
            path = Path.concatTwoPaths(path, reducedSnappingPath);
            if (reducedPath || reducedSnappingPath || pencilPath) {
                let isEnclosed = false;
                if (isActiveClosingConnector(event.target) || (caller === "enter" && Path.isEnclosed(path, 0))) {
                    //add line to connector center
                    path += ` L ${startX},${startY}`;
                    isEnclosed = true;
                }

                let [minX, minY] = Path.getMinimumCoo(path);
                path += type === "p" ? ` L ${Path.getFirstPoint(path, 0)}` : "";
                path = Path.transform(path, -minX, -minY, 1, 1);
                result = [path, transformXForCanvas(minX), transformYForCanvas(minY), isEnclosed];
            }
        }
        resetHooks();
        return result;
    };

    const createFigureFromPencil = (event, caller) => {
        let [path, minX, minY, isEnclosed] = createFigurePath(event, figureType, caller);
        if (Path.checkIfPathValid(path)) {
            const newFigureUuid = uuid();
            let newFigureObject = { //TODO: create create-figure function
                uuid: newFigureUuid,
                parentUuid: undefined,
                name: "",
                link: "",
                description: "",
                emoji: [],
                x: transformXForMap(minX),
                y: transformYForMap(minY),
                points: path,
                tool,
                color,
                transparency,
                layout: +parameters[tool]?.layout || 50,
                orderIndex: Math.round(new Date().getTime())
            };
            let newFigureAction;
            if (figureType === "p") {
                newFigureObject = {...newFigureObject, fontColor, fontSize};
                if (!polygonSchema.isValidSync(newFigureObject)) {
                    return;
                }
                newFigureAction = {...newFigureObject, type: "addPolygon"};
            } else {
                newFigureObject = {...newFigureObject, thickness, dashLength, enclosed: isEnclosed};
                if (!polylineSchema.isValidSync(newFigureObject)) {
                    return;
                }
                newFigureAction = {...newFigureObject, type: "addPolyline"};
            }
            dispatch({...newFigureAction, time: getTimeElapsedSince(startTime)});

            const newGroupChildrenUuids = getUuidsOfSnappingParticipants(newFigureUuid);
            if (newGroupChildrenUuids.length > 1) {
                const newGroupUuid = uuid();
                const newGroupObject = { //TODO: create create-group function
                    uuid: newGroupUuid,
                    parentUuid: undefined,
                    tool: "g-s-72",
                    children: newGroupChildrenUuids,
                    name: "",
                    description: "",
                    layout: 50,
                    orderIndex: Math.round(new Date().getTime()),
                };

                if (!groupSchema.isValidSync(newGroupObject)) {
                    dispatch({type: "selectedFigureUuids", value: [newFigureUuid]});
                    return;
                }

                dispatch({...newGroupObject, type: "addGroup", time: getTimeElapsedSince(startTime)});
                dispatch({type: "selectedFigureUuids", value: [newGroupUuid]});
            } else {
                dispatch({type: "selectedFigureUuids", value: [newFigureUuid]});
            }
        }
    };

    const handleMouseEnterOnConnector = event => {
        if (!event.ctrlKey && isActiveSnappingConnector(event.target) && ["p", "c"].includes(tool.split("-")[0])) {
            let [lastUuid, lastIndex, ] = lastSnappedPointId.split("|");
            let [currentUuid, currentIndex, ] = event.target.id.split("|");
            lastIndex = +lastIndex;
            currentIndex = +currentIndex;

            setSnappingGroup(snappingGroup.concat([currentUuid]));

            const connectors = [...event.target.parentNode.childNodes]
                .filter(child => child.className.baseVal === "snapping-connector")
                .map(child => +child.id.split("|")[1]);
            const numberOfConnectors = connectors.filter(child => child !== 0).length + 1;
            const isEnclosed = connectors[0] === connectors[connectors.length - 1];
            let arePointsNeighborhoods = lastUuid === currentUuid && (Math.abs(lastIndex - currentIndex) === 1 || (
                [0, numberOfConnectors - 1].includes(lastIndex)
                && [0, numberOfConnectors - 1].includes(currentIndex)
                && isEnclosed
            ));

            let {x: pointX, y: pointY} = getSnappingConnectorCenterCoo(event.target);
            pointX = transformXForMap(pointX);
            pointY = transformYForMap(pointY);

            if (!lastSnappedPointId) {
                setPencilPoints(pencilPoints.concat([`${pointX},${pointY}`]));
                setSnappingPoints(snappingPoints.concat([`${pointX},${pointY}`]));
            } else {
                if (lastUuid === currentUuid && lastIndex === currentIndex) {
                    setSnappingPoints([`${pointX},${pointY}`]);
                } else if (arePointsNeighborhoods) {
                    let reducedPath = Path.createReducedPath(pencilPoints);
                    let path = Path.concatTwoPaths(pencilPath, reducedPath);

                    setSnappingPoints([`${pointX},${pointY}`]);
                    setPencilPoints([]);

                    let figurePath = figures.get(lastUuid).get("points");
                    let snapPath = ""; //here indices including M-command as first curve
                    if (
                        [0, numberOfConnectors - 1].includes(lastIndex)
                        && [0, numberOfConnectors - 1].includes(currentIndex)
                    ) {
                        if (lastIndex < currentIndex) {
                            snapPath = Path.getSlice(figurePath, 0, lastIndex, currentIndex, "backward");
                        } else {
                            snapPath = Path.getSlice(figurePath, 0, lastIndex, currentIndex, "forward");
                        }
                    } else {
                        if (lastIndex < currentIndex) {
                            snapPath = Path.getSlice(figurePath, 0, lastIndex, currentIndex, "forward");
                        } else {
                            snapPath = Path.getSlice(figurePath, 0, lastIndex, currentIndex, "backward");
                        }
                    }
                    let shiftX = figures.get(lastUuid).get("x");
                    let shiftY = figures.get(lastUuid).get("y");

                    if (snapPath !== "") {
                        snapPath = "M 0,0 " + snapPath;
                        snapPath = Path.transform(snapPath, shiftX, shiftY, 1, 1);
                        snapPath = Path.getAllSegments(snapPath, 0);
                        setPencilPath(`${path} ${snapPath}`);
                    } else {
                        setPencilPath(path);
                    }
                } else {
                    let reducedPoints = Path.excludeDuplicatePoints(pencilPoints);
                    if (reducedPoints.length >= 3) {
                        let path = "";
                        path = Path.createFromPoints(reducedPoints);
                        path = Path.reduceSimplePath(path, 0, 2.5, 16);
                        path = pencilPath !== "" ? pencilPath + " L" + path.slice(1) : path;
                        setPencilPath(path);
                    }

                    setPencilPoints(snappingPoints.concat([`${pointX},${pointY}`]));
                    setSnappingPoints([`${pointX},${pointY}`]);
                }
            }
            setLastSnappedPointId(event.target.id);
        }
    };

    const getUuidsOfSnappingParticipants = mainUuid => {  //call it on pencil drawing ends
        let children = [];
        snappingGroup.map(item => figures.get(item)
            && children.indexOf(getRootUuid(figures, item)) === -1
            && children.push(getRootUuid(figures, item))
        );
        children.push(mainUuid);
        setSnappingGroup([]);
        return children;
    };

    const removeLastSegment = () => {
        // if (pencilPath !== "") {
        //     setSnappingGroup(snappingGroup.slice(0, snappingGroup.length - 1)); //TODO: check, should I remove it or not
        //     if (Path.getNumberOfSegments(pencilPath, 0) > 1) {
        //         setPencilPath(Path.removeSegments(pencilPath, 0, Path.getNumberOfSegments(pencilPath, 0) - 1, 1));
        //     } else {
        //         resetHooks();
        //     }
        // }
    };

    const simplePencilPath = pencilPath +
        (pencilPoints.length !== 0 ? (pencilPath !== "" ? " L " : "M ") + pencilPoints.join(" L ") : "");
    const pencilPathWithSnapping = simplePencilPath +
        (snappingPoints.length !== 0 ? (simplePencilPath !== "" ? " L " : "M ") + snappingPoints.join(" L ") : "");

    return <PencilDrawingContext.Provider value={{
        startX,
        startY,
        pencilPath: pencilPathWithSnapping,
        handleMouseEnterOnConnector,
        addSegmentToPathOnMouseDown,
        addSegmentToPathOnMouseMove,
        createFigureFromPencil
    }}>
        {children}
    </PencilDrawingContext.Provider>;
}

export const usePencilDrawing = () => useContext(PencilDrawingContext);

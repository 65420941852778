import React from "react";

import {useUndoRedo} from "../../../../customHooks/useContextUndoRedo";
import {useReduxData} from "../../../../customHooks/useContextReduxData";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";

import {ReactComponent as Undo} from "../images/first-block/undo.svg";


const IconUndo = tooltipHOC(
    hintModeTooltipHOC(Undo, "firstToolBlockUndo", {marginBottom: "-2px", marginTop: "-2px"}),
    {display: "flex"},
    {direction: "right", padding: "10px"}
)


export default function () {
    const locale = useLocalization();
    const {undo} = useUndoRedo();
    const {isTherePast, isWatchMode} = useReduxData();

    return <div className="tool-block-item" onClick={undo}>
        <IconUndo
            className={!isWatchMode && isTherePast ? "" : "disabled"}
            tooltipContent={locale.get.studio.header.firstToolBlock.undo}
        />
    </div>;
}

import {useState, useEffect, useRef} from "react";

import {useReduxData} from "../../../customHooks/useContextReduxData";
import {getUserFriendlyTime} from "./utils/timeConverter";
import PlotPointer from "./PlotPointer";
import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";


const PLOT_DIVISION_COUNT = 50;


export default hintModeTooltipHOC(function() {
    const [points, setPoints] = useState({});
    const [maxFrequency, setMaxFrequency] = useState(0);
    const [maxFrequencyCoo, setMaxFrequencyCoo] = useState(0);
    const [maxTime, setMaxTime] = useState(0);
    const [plotTop, setPlotTop] = useState(0);
    const [plotLeft, setPlotLeft] = useState(0);
    const [plotHeight, setPlotHeight] = useState(0);
    const [plotWidth, setPlotWidth] = useState(0);
    const [currentTime, setCurrentTime] = useState(1);
    const [valueOfDivision, setValueOfDivision] = useState(1);
    const plotRef = useRef();

    const {actionHistory, isWatchMode} = useReduxData();

    useEffect(() => {
        if (plotRef?.current) {
            const {width, height, top, left} = plotRef.current.getBoundingClientRect();
            if (height && height !== plotHeight) {
                setPlotHeight(height);
            }
            if (width && width !== plotWidth) {
                setPlotWidth(width);
            }
            if (top && top !== plotTop) {
                setPlotTop(top);
            }
            if (left && left !== plotLeft) {
                setPlotLeft(left);
            }
        }
    }, [plotRef, maxFrequency]);

    useEffect(() => {
        if (isWatchMode && plotLeft && plotTop && plotWidth && plotHeight) {
            const xValueOfDivision = Math.round((plotWidth - 5) / PLOT_DIVISION_COUNT);
            const rawLocalPoints = {};
            let maxPointX = 0;
            actionHistory.map(action => {
                const time = action.time;
                if (time) {
                    rawLocalPoints[time] = (rawLocalPoints[time] || 0) + 1;
                    if (maxPointX < time) {
                        maxPointX = time;
                    }
                }
            });
            const localPoints = {};
            let maxPointY = 0;
            Object.keys(rawLocalPoints).map(xCoo => {
                const newX = Math.round(
                    Math.floor((xCoo / maxPointX * (plotWidth - 5)) / xValueOfDivision) * xValueOfDivision
                );
                localPoints[newX] = (localPoints[newX] || 0) + rawLocalPoints[xCoo];
                if (maxPointY < localPoints[newX]) {
                    maxPointY = localPoints[newX];
                }
            });
            let maxFreqCoo = Number.POSITIVE_INFINITY;
            Object.keys(localPoints).map(xCoo => {
                localPoints[xCoo] = Math.round(-localPoints[xCoo] / maxPointY * plotHeight / 1.1 + plotHeight)
                if (localPoints[xCoo] < maxFreqCoo) {
                    maxFreqCoo = localPoints[xCoo];
                }
            });
            setPoints(localPoints);
            setMaxTime(Math.round(maxPointX / 1000));
            setMaxFrequency(maxPointY);
            setMaxFrequencyCoo(maxFreqCoo);
            setValueOfDivision(xValueOfDivision);
        }
    }, [actionHistory, isWatchMode, plotLeft, plotTop, plotWidth, plotHeight]);

    return <div className="figure-bar-action-history-container">
        <div className="figure-bar-action-history-plot-container">
            <span className="figure-bar-action-history-max-frequency">{maxFrequency}</span>
            <svg className="figure-bar-action-history-plot" ref={plotRef} width="97%">
                <line
                    x1="0"
                    x2="100000"
                    y1={maxFrequencyCoo}
                    y2={maxFrequencyCoo}
                    stroke="black"
                    strokeDasharray="5"
                    style={{pointerEvents: "none"}}
                />
                <PlotPointer
                    plotHeight={plotHeight}
                    plotLeft={plotLeft}
                    plotWidth={plotWidth}
                    setCurrentTime={setCurrentTime}
                />
                {Object.keys(points).map(xCoo =>
                    <line
                        x1={+xCoo}
                        x2={+xCoo + valueOfDivision}
                        y1={points[xCoo]}
                        y2={points[xCoo]}
                        stroke="black"
                        strokeWidth="0.25rem"
                        style={{pointerEvents: "none"}}
                    />
                )}
            </svg>
        </div>
        <span className="figure-bar-action-history-time-info">
            {getUserFriendlyTime(currentTime) + " / " + getUserFriendlyTime(maxTime)}
        </span>
    </div>;
}, "timeSlider", {height: "100%", width: "100%"});

import React, {useState, useLayoutEffect, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {useBeforeunload} from "react-beforeunload";
import queryString from "query-string";
import {ReactQueryDevtools} from 'react-query/devtools';

import TopMenuContainer from "./components/header/TopBlock";
import FigureBar from "./components/header/FigureBar";
import Canvas from "./components/canvas/Canvas";
import FloatingMenu from "./components/floatingMenu/FloatingMenu";
import Description from "./components/canvas/popups/Description";
import ContextMenu from "./components/canvas/popups/contextMenu/ContextMenu";
import Footer from "./components/footer/Footer";
import AgreementShowingHandler from "./AgreementShowingHandler";
import AutosendToServerHandler from "./AutosendToServerHandler";
import HotKeyHandler from "./HotKeyHandler";
import UnsupportedDevicePage from "./UnsupportedDevicePage";
import OnboardingHandler from "./OnboardingHandler";
import HintModeOverlay from "./onboarding/HintModeOverlay";
import Chat from "./components/chat/Chat";
import {QueryClientProvider} from "react-query";
import useAppPreparationOnLoad from "./customHooks/useAppPreparationOnLoad";
import {useModal} from "../customHooks/useContextModal";
import {useHintMode} from "./customHooks/useContextHintMode";
import {useSnappingMode} from "./customHooks/useContextSnappingMode";
import {useReduxData} from "./customHooks/useContextReduxData";
import {useCurrentProject} from "./customHooks/useContextCurrentProject";
import {useShouldHideToolbar} from "./customHooks/useContextShouldHideToolbar";
import {useShouldShowColorPicker} from "./components/header/baseBlock/toolBlockBigItems/customHooks/useContextShouldShowColorPicker";
import {useApiClient} from "../customHooks/useApiClient";
import {useChat} from "./customHooks/useContextChat";
import {isMapChanged} from "./customHooks/utils/map";

import "react-toastify/dist/ReactToastify.css";


export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function Studio() {
    const hintMode = useHintMode();
    const snappingMode = useSnappingMode();
    const {get: shouldShowChat} = useChat();
    const modal = useModal();
    const location = useLocation();
    const {presentState} = useReduxData();
    const {get: currentProject} = useCurrentProject();
    const {get: shouldHideToolbar} = useShouldHideToolbar();
    const {set: setShouldShowSketchPicker} = useShouldShowColorPicker();
    const dispatch = useDispatch();
    const api = useApiClient();

    useAppPreparationOnLoad();

    useBeforeunload(event => { //show warning on tab closed/updated
        if (isMapChanged(presentState) && !localStorage.getItem("is map cleared")) {
            return event.preventDefault();
        }
        localStorage.removeItem("is map cleared");
    });

    useEffect(() => { //blocking display the survey when it's called not when sending map
        if (location.pathname.startsWith("/survey") && !currentProject.survey) {
            modal.set("");
        }
    }, [location?.pathname, currentProject.survey]);

    useEffect(() => {
        if (queryString.parse(location.search)["token"]) {
            modal.set("reset_password");
        }
    }, []);

    const switchModesOff = () => {
        if (hintMode.get) {
            hintMode.set(false);
        }
        if (snappingMode.get) {
            snappingMode.set(false);
        }
    };

    const handleMouseDown = event => {
        let isTargetFloatingMenuItemBodyChild = false;
        let currentNode = event.target;
        while (currentNode && currentNode.className !== "app") {
            if (currentNode.className === "floating-menu-item-body") {
                isTargetFloatingMenuItemBodyChild = true;
                break;
            }
            currentNode = currentNode.parentNode;
        }

        let targetClass = event.target.className;
        if (!isTargetFloatingMenuItemBodyChild && (typeof(targetClass) !== "string"
            || (targetClass !== "floating-menu-item" && targetClass.split(" ")[0] !== "floating-menu-item-wrapper")
        )) {
            dispatch({type: "openedMenuItemNumber", value: "-1"});
        }

        let isTargetColorPickerItemBodyChild = false;
        currentNode = event.target;
        while (currentNode && currentNode.className !== "app") {
            if (typeof currentNode.className === "string" && currentNode.className.trim() === "sketch-picker") {
                isTargetColorPickerItemBodyChild = true;
                break;
            }
            currentNode = currentNode.parentNode;
        }
        if (!isTargetColorPickerItemBodyChild) {
            setShouldShowSketchPicker(false);
        }
    }

    return screen.availHeight < 450 || screen.availWidth < 800
        ? <UnsupportedDevicePage/>
        : <div
            className="app"
            id="app"
            onMouseDown={handleMouseDown}
            onMouseLeave={switchModesOff}
            onBlur={switchModesOff}
        >
            <QueryClientProvider client={api.queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            {modal?.getModal()}
            <div>
                <Description/>
                <ContextMenu/>
            </div>

            <div className={`main-container${shouldHideToolbar ? "-no-toolbar" : ""}`}>
                <TopMenuContainer/>
                <FigureBar/>
                <Canvas/>
                <Footer/>
                {shouldShowChat && <Chat/>}
            </div>

            {(currentProject.config?.hiddenElements || {}).floatingMenu !== "all" && <FloatingMenu/>}
            <HintModeOverlay/>
            <OnboardingHandler/>
            <AgreementShowingHandler/>
            <AutosendToServerHandler/>
            <HotKeyHandler/>
            </QueryClientProvider>
        </div>;
}

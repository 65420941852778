import React, {useState, useContext} from "react";


const SnappingModeContext = React.createContext();


export const SnappingModeProvider = ({children}) => {
    const [get, set] = useState(false);

    return (
        <SnappingModeContext.Provider value={{get, set}}>
            {children}
        </SnappingModeContext.Provider>
    );
};


export const useSnappingMode = () => useContext(SnappingModeContext);

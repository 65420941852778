import React, {useState} from "react";
import {toast} from "react-toastify";

import {useLocalization} from "../customHooks/useContextLocalization";
import {useApiClient} from "../customHooks/useApiClient";


export default function ProductConfiguring() {
    const [config, setConfig] = useState("");

    const locale = useLocalization();
    const api = useApiClient();
    const texts = locale?.get.studio.configureProduct;

    const updateLocalConfig = event => setConfig(event.target.value);
    const sendConfig = async () => {
        try {
            await api.put(
                `/api/v2/store/product_version/${
                    location.pathname.split("/")[location.pathname.split("/").length - 1]
                }/config/`,
                {config: config}
            );
            toast.success("Success");
        } catch (e) {
            toast.error("Error");
        }
    };

    //we cannot remove links because of CORS policy (is prevents redirects via axios)
    return <div className="modal-container">
        <h4 className="modal-title">{texts.title}</h4>
        <p className="modal-p">{texts.text}</p>
        <textarea
            placeholder="{parameters: {...}, tabs: [...]}"
            cols="100"
            rows="20"
            value={config}
            onChange={updateLocalConfig}
        />
        <input
            type="button"
            className="modal-button modal-button-apply"
            value={texts.apply}
            onClick={sendConfig}
        />
    </div>;
}

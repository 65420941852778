

export function getUserFriendlyTime(timeInSeconds) {
    timeInSeconds = Math.floor(timeInSeconds);
    const seconds = timeInSeconds % 60;
    timeInSeconds = Math.floor(timeInSeconds / 60);
    const minutes = timeInSeconds % 60;
    timeInSeconds = Math.floor(timeInSeconds / 60);
    const hours = timeInSeconds % 24;
    timeInSeconds = Math.floor(timeInSeconds / 24);
    const days = timeInSeconds;

    return (days ? `${days} д. ` : "") + (hours ? `${hours} ч. ` : "") + (minutes ? `${minutes} мин. ` : "") + (seconds ? `${seconds} сек.` : "")
}
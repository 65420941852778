import React from "react";
import WorldMap from "react-svg-worldmap";

import {CANVAS_HEIGHT, CANVAS_WIDTH} from "./Canvas";
import {useCurrentProject} from "../../customHooks/useContextCurrentProject";


export default function CanvasWorldMap({scale}) {
    const {get: currentProject} = useCurrentProject();

    const worldMapConfig = currentProject.config?.extraElements?.worldMap;
    const mapSize = worldMapConfig?.size || 2000;
    const shouldHighlightOnHover = worldMapConfig?.highlightOnHover || false;

    console.log(shouldHighlightOnHover);
    if (shouldHighlightOnHover) {
        console.log('hover');
        const nodes = document.querySelectorAll('.worldmap__figure-container path');
        console.log(nodes);
        for (let node in nodes) {
            console.log(node);
            console.log(node.style);
            console.log(node.style.hasOwnProperty("strokeOpacity"));
            console.log(node.style.hasOwnProperty("strokeWidth"));

            node.style.strokeOpacity = "0.2 !important";
            node.style.strokeWidth = "1 !important";
        }
    }

    const getLocalizedText = ({countryCode, countryValue, prefix, suffix}) => {
        const mapping = {"KZ": "Казахстан"};
        return `${mapping[countryCode] || "Неизвестно"}: ${prefix}${countryValue}${suffix}`;
    }

    return <foreignObject
        x={(CANVAS_WIDTH / 2 - mapSize / 2) * scale}
        y={(CANVAS_HEIGHT / 2 - mapSize * 0.75 / 2) * scale}
        width={mapSize * scale}
        height={0.75 * mapSize * scale}
    >
        <WorldMap
            color="white"
            backgroundColor='#abcdef'
            size={mapSize * scale}
            tooltipTextFunction={getLocalizedText}
            data={[{country: "kz", value: 'Население: ~20 млн.'}]}
        />
    </foreignObject>;
}
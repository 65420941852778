import React, {useState, useMemo, useEffect, useRef} from "react";

import Scrollbar from "../../../../components/Scrollbar";
import Subpartition from "./Subpartition";
import NameField from "./NameField";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import useIconDropTarget from "./dnd/hooks/useIconDropTarget";
import useIconDummyDropTarget from "./dnd/hooks/useIconDummyDropTarget";
import useConfirmer, {Confirmer} from "../hooks/useConfirmer";
import useTabs from "../hooks/useContextTabs";
import useRenderIconsById from "../hooks/useRenderIconsById";
import usePartitionDragSource from "./dnd/hooks/usePartitionDragSource";
import usePartitionDropTarget from "./dnd/hooks/usePartitionDropTarget";

import {ReactComponent as Cross} from "../../../images/cross-transparent.svg";
import {ReactComponent as TypeSwitcher} from "../../../../images/md-editor/unorderedList.svg";


const Partition = ({icons, tabIndex, partitionIndex}) => {
    const partitionRef = useRef();

    const [subpartitionIndex, setSubpartitionIndex] = useState(-1);
    const [selectedIcons, setSelectedIcons] = useState([]);

    const [confirmerState, {showConfirmer, hideConfirmer}] = useConfirmer();
    const {tabs, dispatchTabs} = useTabs();
    const [drop, {isOver, sourceId}] = useIconDropTarget(tabs, dispatchTabs, tabIndex, partitionIndex);
    const [dummyDrop, {dummyIsOver}] = useIconDummyDropTarget();
    const [{isPartitionDragging}, dragPartition] = usePartitionDragSource(
        tabIndex,
        tabs[tabIndex].children[partitionIndex].id
    );
    const [
        {handlerId, sourcePartitionId},
        dropPartition
    ] = usePartitionDropTarget(partitionRef, tabIndex, partitionIndex, dispatchTabs, tabs);
    const locale = useLocalization();

    const partition = useMemo(() => tabs[tabIndex].children[partitionIndex], [partitionIndex, tabs]);
    const renderIcons = useRenderIconsById();

    useEffect(() => { //filter selected icons on children changed
        if (partition?.children) {
            if (partition.isComplicated) {
                let allIconsInPartition = [];
                partition.children.map(subpartition => allIconsInPartition.push(
                    subpartition.children.filter(el => !allIconsInPartition.includes(el))
                ));
                setSelectedIcons(selectedIcons.filter(el => allIconsInPartition.includes(el.id)));
            } else {
                setSelectedIcons(selectedIcons.filter(el => partition.children.includes(el)));
            }
        }
    }, [tabIndex, partition?.children]);

    const changePartitionComplexity = () => dispatchTabs({
        type: "setPartitionComplexity",
        payload: {tIndex: tabIndex, pIndex: partitionIndex, isComplicated: !partition.isComplicated, locale: locale}
    });
    const deletePartition = () => dispatchTabs(
        {type: "deletePartition", payload: {tIndex: tabIndex, pIndex: partitionIndex}}
    );
    const addSubpartition = () => dispatchTabs({
        type: "addSubpartition",
        payload: {tIndex: tabIndex, pIndex: partitionIndex, locale: locale}
    });
    const deleteSubpartition = () => {
        dispatchTabs({
            type: "deleteSubpartition",
            payload: {tIndex: tabIndex, pIndex: partitionIndex, sIndex: subpartitionIndex}
        });
        setSubpartitionIndex(-1);
    };

    const tryToChangePartitionComplexity = () => showConfirmer(
        partition.isComplicated ? "partitionSimplifyWarn" : "partitionComplicateWarn"
    );
    const tryToRemovePartition = () => showConfirmer("partitionDeleteWarn");
    const tryToRemoveSubpartition = event => {
        showConfirmer("subpartitionDeleteWarn");
        setSubpartitionIndex(+event.target.id.split("-")[event.target.id.split("-").length - 1]);
    };

    const checkIcon = event => {
        let id = event.target.id.split("-");
        if (partition.isComplicated) {
            let subpartitionId = id[4], iconId = `${id[5]}-${id[6]}-${id[7]}`;
            let whereIsThisIcon = selectedIcons.map(el => `${el.id}-${el.source}`).indexOf(`${iconId}-${subpartitionId}`);
            if (whereIsThisIcon !== -1) {
                setSelectedIcons(selectedIcons.slice(0, whereIsThisIcon).concat(selectedIcons.slice(whereIsThisIcon + 1)));
            } else {
                setSelectedIcons(selectedIcons.concat([{id: iconId, source: subpartitionId}]));
            }
        } else {
            let iconId = `${id[4]}-${id[5]}-${id[6]}`;
            let whereIsThisIcon = selectedIcons.indexOf(iconId);
            if (whereIsThisIcon !== -1) {
                setSelectedIcons(selectedIcons.slice(0, whereIsThisIcon).concat(selectedIcons.slice(whereIsThisIcon + 1)));
            } else {
                setSelectedIcons(selectedIcons.concat([iconId]));
            }
        }
    };

    dragPartition(dropPartition(partitionRef));
    let opacity = (sourcePartitionId === tabs[tabIndex].children[partitionIndex].id) ? 0 : 1;

    return <div className="account-icons-partition-wrapper" ref={partitionRef} style={{opacity}}>
        <Confirmer
            message={locale.get.store.icons[confirmerState]}
            onConfirm={["partitionSimplifyWarn", "partitionComplicateWarn"].includes(confirmerState)
                ? changePartitionComplexity
                : (confirmerState === "partitionDeleteWarn" ? deletePartition : deleteSubpartition)}
            hideConfirmer={hideConfirmer}
        />
        <div className="account-icons-partition" style={confirmerState ? {filter: "blur(0.125rem)"} : {}}>
            <div className="account-icons-partition-title-container">
                <span className="account-icons-partition-title">
                    <NameField defaultValue={partition.name[locale?.get.name]} tIndex={tabIndex} pIndex={partitionIndex}/>
                </span>
                <TypeSwitcher
                    className={`account-icons-partition-button${partition.isComplicated ? "-active" : ""}`}
                    height="24"
                    width="24"
                    style={{marginRight: "0.625rem"}}
                    onClick={tryToChangePartitionComplexity}
                />
                <Cross
                    className="account-icons-partition-button"
                    height="24"
                    width="24"
                    viewBox="-4 -4 24 24"
                    fill="#144066"
                    onClick={tryToRemovePartition}
                />
            </div>
            <div ref={dummyDrop} className="account-icons-subpartition-container">
                 <Scrollbar autohide={true} classNamePrefix="account-icons-" style={{height: "100%", width: "100%"}}>
                     {partition.isComplicated
                         ? partition.children.map((subpartition, i) =>
                             <Subpartition
                                 key={`account-icons-subpartition-${tabIndex}-${partitionIndex}-${i}`}
                                 subpartition={subpartition}
                                 icons={icons}
                                 tabIndex={tabIndex}
                                 partitionIndex={partitionIndex}
                                 subpartitionIndex={i}
                                 tryToRemoveSubpartition={tryToRemoveSubpartition}
                                 checkIcon={checkIcon}
                                 selectedIcons={selectedIcons}
                                 setSelectedIcons={setSelectedIcons}
                             />
                         ).concat([<input
                             key="account-icons-add-subpartition"
                             type="button"
                             className="account-icons-add-subpartition-button"
                             value={`+ ${locale.get.store.icons.addSubpartition}`}
                             onClick={addSubpartition}
                         />])
                         : <div ref={drop} className="account-icons-partition-icons-container">
                             {icons && renderIcons(partition?.children, icons, checkIcon, selectedIcons, tabIndex, partitionIndex)}
                         </div>
                     }
                 </Scrollbar>
            </div>
            <span className="account-icons-partition-hint">
                {locale.get.store.icons[(isOver || dummyIsOver)
                    ? (partitionIndex === sourceId
                        ? "partitionIconDeleteHint"
                        : (partition.isComplicated ? "subpartitionDropHint" : "partitionDropHint")
                    )
                    : "partitionHint"
                ]}
            </span>
        </div>
    </div>;
};

export default Partition;
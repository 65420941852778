import Scrollbar from "../../../../../../../components/Scrollbar";
import {useLocalization} from "../../../../../../../customHooks/useContextLocalization";
import {useMenu} from "./hooks/useMenu";


export default function ({currentMaxFrequency, figuresAvailableToShow, dataToRender}) {
    const locale = useLocalization();
    const {
        shouldShowCooRanges,
        shouldMakeRareObjectsTranslucent,
        switchShouldShowCooRanges,
        switchRareObjectsTranslucency,
        figuresToShow,
        switchFigureVisibility,
        switchShouldShowFrequenciesAbove,
        shouldShowFrequenciesAbove,
        frequencyThreshold,
        setNewFrequencyThreshold,
        applyFrequencyFilter
    } = useMenu();

    const switchVisibilityInstance = switchFigureVisibility(figuresAvailableToShow);
    const setFrequencyThresholdUsingAvailableFigure = applyFrequencyFilter(dataToRender);

    return <div className="wfpm-p-visualizer-menu-container">
        <p>{`${
            locale?.get?.project.analytics.mainPage.dfd.wfpmVisualizer.maxFrequency
        } ${Math.round(currentMaxFrequency * 100)}%`}</p>
        <div className="wfpm-p-visualizer-menu-dispersion-container">
            <input type="checkbox" checked={shouldShowCooRanges} onChange={switchShouldShowCooRanges}/>
            <p>{locale?.get?.project.analytics.mainPage.dfd.wfpmVisualizer.showDispersion}</p>
        </div>
        <div className="wfpm-p-visualizer-menu-dispersion-container">
            <input type="checkbox" checked={shouldMakeRareObjectsTranslucent} onChange={switchRareObjectsTranslucency}/>
            <p>{locale?.get?.project.analytics.mainPage.dfd.wfpmVisualizer.translucentRare}</p>
        </div>
        <div className="wfpm-p-visualizer-menu-dispersion-container">
            <p>
                {locale?.get?.project.analytics.mainPage.dfd.wfpmVisualizer.showCertainObjects}
                <span onClick={switchShouldShowFrequenciesAbove}> {shouldShowFrequenciesAbove
                    ? locale?.get?.project.analytics.mainPage.dfd.wfpmVisualizer.above
                    : locale?.get?.project.analytics.mainPage.dfd.wfpmVisualizer.below
                }</span>
            </p>
            <input
                type="text"
                placeholder="%"
                value={frequencyThreshold}
                onChange={setNewFrequencyThreshold}
                style={{width: "3rem"}}
            />
            <input
                type="button"
                value={locale?.get?.project.analytics.mainPage.dfd.wfpmVisualizer.apply}
                onClick={setFrequencyThresholdUsingAvailableFigure}
            />
        </div>
        <Scrollbar
            autohide={false}
            classNamePrefix="my-maps-"
            style={{height: "31rem"}}
        >
            <table><tbody>
                {Object.keys(dataToRender).map(category => <>
                    <tr>
                        <td><input
                            type="checkbox"
                            id={`wfpm-p-visualizer-filter-${category}-all`}
                            checked={figuresToShow[category].length === figuresAvailableToShow[category].length}
                            onChange={switchVisibilityInstance}
                        /></td>
                        <td style={{fontWeight: 900}}>{category}</td>
                    </tr>
                    {figuresAvailableToShow[category].map(settlement => <tr>
                        <td>
                            <input
                                type="checkbox"
                                id={`wfpm-p-visualizer-filter-${category}-${settlement}`}
                                checked={figuresToShow[category].indexOf(settlement) !== -1}
                                onChange={switchVisibilityInstance}
                            />
                        </td>
                        <td>{settlement} ({dataToRender[category][settlement].orderIndex})</td>
                    </tr>)}
                </>)}
            </tbody></table>
        </Scrollbar>
    </div>;
}
import {v4 as uuid} from "uuid";


const toolDictionary = { // this is dictionary to translate old tool names to new ones
    "bioFish": "i-s-55",
    "bioFur": "i-s-54",
    "bioPlant": "i-s-56",
    "brdSolid": "c-s-0",
    "custom": "p-s-12",
    "extBuildingMaterials": "i-s-43",
    "extBuildingStones": "i-s-44",
    "extChemicalMaterials": "i-s-45",
    "extCoal": "i-s-46",
    "extGas": "i-s-47",
    "extJewel": "i-s-48",
    "extMine": "i-s-49",
    "extOilPump": "i-s-50",
    "extOilShale": "i-s-51",
    "extOre": "i-s-52",
    "extPeat": "i-s-53",
    "group": "g-s-72",
    "hisAnotherAncient": "i-s-28",
    "hisArcheology": "i-s-19",
    "hisBuddhist": "i-s-23",
    "hisCemetery": "i-s-27",
    "hisFort": "i-s-24",
    "hisMonument": "i-s-25",
    "hisMosque": "i-s-22",
    "hisObelisk": "i-s-20",
    "hisOrthodox": "i-s-21",
    "hisRiderMonument": "i-s-26",
    "humCity": "i-s-14",
    "humMegacity": "i-s-13",
    "humMilitary": "i-s-42", //this line from update to 1.42.0
    "humTown": "i-s-15",
    "humTownship": "i-s-16",
    "humVillage": "i-s-18",
    "humVillageWithChurch": "i-s-17",
    "lanArcticDesert": "p-s-9",
    "lanBeach": "i-s-70",
    "lanCave": "i-s-69",
    "lanDesert": "p-s-8",
    "lanGlacier": "i-s-68",
    "lanGrassyIsland": "p-s-11",
    "lanHill": "i-s-64",
    "lanIsland": "p-s-10",
    "lanMountain": "i-s-66",
    "lanMountainWithSnow": "i-s-67",
    "lanPlateau": "i-s-65",
    "lanSteppe": "p-s-6",
    "lanSwamp": "p-s-5",
    "lanTundra": "p-s-7",
    "lanVolcano": "i-s-71",
    "outAirport": "i-s-30",
    "outCosmodrome": "i-s-38",
    "outFactory": "i-s-31",
    "outGeothermal": "i-s-32",
    "outLighthouse": "i-s-29",
    "outObservatory": "i-s-35",
    "outMilitary": "i-s-42",
    "outNationalPark": "i-s-39",
    "outNuclearPowerPlant": "i-s-33",
    "outPowerPlant": "i-s-34",
    "outRadar": "i-s-36",
    "outReserve": "i-s-40",
    "outReservoir": "i-s-37",
    "outTouristBase": "i-s-41",
    "vegBush": "i-s-57",
    "vegDeciduous": "i-s-62",
    "vegEvergreen": "i-s-58",
    "vegFruit": "i-s-63",
    "vegGrass": "i-s-60",
    "vegPalm": "i-s-59",
    "vegWheat": "i-s-61",
    "watReservoirs": "p-s-4",
    "watRiver": "c-s-3",
    "wayAuto": "c-s-1",
    "wayRail": "c-s-2"
};

export default function updateMap(map) {
    /*
    * too old map (map without history, before 07/17/2020)
    * */

    if (!map.actionHistory) {
        return "unsupported";
    }

    /*
    * too old map (actionHistory is incompatible, before 07/29/2020)
    * */

    if (map.actionHistory.map(action => action.type).includes("device")) {
        return "unsupported";
    }

    /*
    * too old map (map without orderIndices, before 08/03/2020)
    * */

    if (Object.keys(map.main.figures).map(uuid => map.main.figures[uuid].orderIndex).includes(undefined)) {
        return "unsupported";
    }

    /*
    * map version is lower than 1.16.0
    * */

    if (!map.main.mapId) {
        map.main.mapId = uuid();
    }

    /*
    * map version is lower than 1.16.2
    * */

    if ("lang" in map.menu) {
        delete map.menu.lang;
    }

    /*
    * map version is lower than 1.17.6
    * */

    if ("currentDescription" in map.menu && "currentName" in map.menu && "isDescriptionShow" in map.menu) {
        delete map.menu.currentDescription;
        delete map.menu.currentName;
        delete map.menu.isDescriptionShow;
        map.menu.descriptionPopupTitle = "";
        map.menu.descriptionPopupText = "";
        map.menu.figureUuidDescriptionPopupShownFor = "";
    }

    /*
    * map version is lower than 1.34.0
    * */

    if ("isContextMenuColorPickerShow" in map.menu && "isContextMenuEmojiPickerShow" in map.menu) {
        delete map.menu.isContextMenuColorPickerShow;
        delete map.menu.isContextMenuEmojiPickerShow;
        map.menu.openContextMenuTool = "no";
    }

    /*
    * map version is lower than 1.38.9
    * */

    if (map.menu.isSquareMode || map.menu.isSquareMode === false) {
        map.menu.isRectangleSelectionMode = map.menu.isSquareMode;
    }

    /*
    * map version is lower than 1.41.0
    * */

    Object.keys(map.main.figures).map(uuid => {
        if (map.main.figures[uuid].tool.split("-")[0] === "i" && !map.main.figures[uuid].flags) {
            map.main.figures[uuid].flags = [];
        }
    });
    map.actionHistory.map((action, i) => {
        if (action.type === "addPointFeature" && typeof map.actionHistory[i].flags === "undefined") {
            map.actionHistory[i].flags = [];
        }
    });
    if (map.main.lastAction.type === "addPointFeature" && typeof map.main.lastAction.flags === "undefined") {
        map.main.lastAction.flags = [];
    }

    /*
    * map version is lower than 1.45.0
    * */

    if (!("isUserCanChangeCanvasSize" in map.menu) && !("backgroundImage" in map.menu)) {
        map.menu.isUserCanChangeCanvasSize = true;
        map.menu.backgroundImage = "";
    }

    /*
    * map version is lower than 1.47.0
    * */

    if (!map.menu.tool.includes("-") && map.menu.tool) {
        map.menu.tool = toolDictionary[map.menu.tool] || map.menu.tool;
        Object.keys(map.main.figures).map(uuid => {
            map.main.figures[uuid].tool = toolDictionary[map.main.figures[uuid].tool] || map.main.figures[uuid].tool;
        });
        map.main.lastAction.tool = toolDictionary[map.main.lastAction.tool] || map.main.lastAction.tool;
        map.actionHistory.map((action, i) => {
            if (map.actionHistory[i].tool) {
                map.actionHistory[i].tool = toolDictionary[map.actionHistory[i].tool] || map.actionHistory[i].tool;
            }
        })
    }

    /*
    * map version is lower than 1.65.0
    * */

    if ("isRectangleSelectionMode" in map.menu && "isPencilMode" in map.menu) {
        delete map.menu.isRectangleSelectionMode;
        delete map.menu.isPencilMode;
    }

    /*
    * map version is lower than 1.67.0
    * */

    map.actionHistory.map((action, i) => {
        if (typeof action.layout === "string") {
            map.actionHistory[i].layout = +action.layout;
        }
    });
    if (typeof map.main.lastAction.layout === "string") {
        map.main.lastAction.layout = +map.main.lastAction.layout;
    }
    Object.keys(map.main.figures).map(uuid => {
        if (typeof map.main.figures[uuid].layout === "string") {
            map.main.figures[uuid].layout = +map.main.figures[uuid].layout;
        }
    });

    /*
    * map version is lower than 1.69.0
    * */

    map.actionHistory.map((action, i) => {
        if (action.type === "addGroup" && (
                map.actionHistory[i].tool !== "g-s-72"
            || map.actionHistory[i].description !== ""
            || map.actionHistory[i].name !== ""
        )) {
            map.actionHistory[i].name = "";
            map.actionHistory[i].description = "";
            map.actionHistory[i].tool = "g-s-72";
        }
    });
    if (map.main.lastAction.type === "addGroup" && (
            map.main.lastAction.tool !== "g-s-72"
        || map.main.lastAction.description !== ""
        || map.main.lastAction.name !== ""
    )) {
        map.main.lastAction.name = "";
        map.main.lastAction.description = "";
        map.main.lastAction.tool = "g-s-72";
    }

    /*
    * map version is lower than 1.70.0
    * */

    if ("isUserCanChangeCanvasSize" in map.menu && "canvasWidth" in map.menu && "canvasHeight" in map.menu) {
        const shiftX = map.menu.canvasWidth / 2, shiftY = map.menu.canvasHeight / 2;
        Object.keys(map.main.figures).map(uuid => {
            if (typeof map.main.figures[uuid].x === "number" && typeof map.main.figures[uuid].y === "number") {
                map.main.figures[uuid].x -= shiftX;
                map.main.figures[uuid].y -= shiftY;
            }
        });
        delete map.menu.isUserCanChangeCanvasSize;
        delete map.menu.canvasWidth;
        delete map.menu.canvasHeight;
        map.menu.backgroundWidth = 0;
        map.menu.backgroundHeight = 0;
    }
    if (!("canvasScrollX" in map.menu) && !("canvasScrollY" in map.menu)) {
        map.menu.canvasScrollX = 0;
        map.menu.canvasScrollY = 0;
    }
    if (!("selectedConnectors" in map.menu)) {
        map.menu.selectedConnectors = [];
    }
    if ("prevScale" in map.menu) {
        delete map.menu.prevScale;
    }

    /*
    * map version is lower than 1.78.0 (when dev has been merged to master)
    * */

    if ("mouseXAtTheClipboardTime" in map.menu) {
        delete map.menu.mouseXAtTheClipboardTime;
        delete map.menu.mouseYAtTheClipboardTime;
        map.menu.mouseXAtCopyTime = 0;
        map.menu.mouseYAtCopyTime = 0;
        map.menu.canvasScrollXAtCopyTime = 0;
        map.menu.canvasScrollYAtCopyTime = 0;
    }

    /*
    * map version is lower than 2.5.0
    * */

    if ("backgroundImage" in map.menu && "backgroundHeight" in map.menu && "backgroundWidth" in map.menu) {
        delete map.menu.backgroundImage;
        delete map.menu.backgroundHeight;
        delete map.menu.backgroundWidth;
    }

    /*
    * map version is lower than 2.19.0
    * */

    if (!("backgroundColor" in map.menu)) {
        map.menu.backgroundColor = "#FAFEFF";
    }

    /*
    * map version is lower than 2.24.0
    * */

    map.actionHistory.forEach((action, i) => {
        if (["addArrow", "addRectangle", "addPolygon", "addPolyline", "addPointFeature", "addText"]
                .includes(action.type) && map.actionHistory[i].users === undefined
        ) {
            map.actionHistory[i].users = [];
        }
    });


    return map;
}

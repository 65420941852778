import React from "react";
import {useDispatch} from "react-redux";
import {flattenDeep} from "lodash";

import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useReduxData} from "../../customHooks/useContextReduxData";
import {getChildrenValues, setFixedChildrenValues} from "../../utils/figureHierarchy";
import {areAllItemsEqual} from "../../../utils";
import getTimeElapsedSince from "../getTimeElapsedSince";


export default function Slider({title, purpose, action, menuAction, type, min, max, step, value}) {
    const locale = useLocalization();
    const {getFiguresUpdatedOn, isEditMode, selectedFigureUuids, tool, startTime} = useReduxData();
    const figures = getFiguresUpdatedOn([
        "addPolygon",
        "addPolyline",
        "addPointFeature",
        "addGroup",
        "changeObjectParentUuid",
        "changeFigureDashLength",
        "changeFigureFontSize",
        "changeFigureTransparency",
        "changeFigureLayout",
        "changeFigureVolume",
        "changeFigureThickness",
        "clearState",
        "deleteFigure",
        "disbandGroup"
    ]);

    const dispatch = useDispatch();

    const handleSliderChanged = event => isEditMode
        ? selectedFigureUuids.map(uuid => {
            setFixedChildrenValues(
                figures,
                uuid,
                [purpose],
                dispatch,
                [event.target.value],
                action,
                getTimeElapsedSince(startTime)
            );
            if (action === "changeFigureVolume") {
                let oldSize = +figures.get(uuid).get("volume");
                setFixedChildrenValues(
                    figures,
                    uuid,
                    ["x", "y"],
                    dispatch,
                    [
                        +figures.get(uuid).get("x") - (+event.target.value - oldSize) * 2,
                        +figures.get(uuid).get("y") - (+event.target.value - oldSize) * 2
                    ],
                    "moveFigure",
                    getTimeElapsedSince(startTime)
                );
            }
            return undefined;
        })
        : dispatch({type: menuAction, value: event.target.value});

    let currentValue = "NaN";
    let isSliderVisible = false;

    if (!isEditMode && (type === "any" || type.includes(tool.split("-")[0])) && value !== undefined) {
        isSliderVisible = true;
        currentValue = value;
        if (+currentValue === -1) {
            currentValue = "standard";
        }
    } else if (isEditMode && selectedFigureUuids.length !== 0) {
        let parameterArray = [];
        selectedFigureUuids.map(u => {
            let tempArr = flattenDeep([getChildrenValues(figures, u, purpose)]);
            if (typeof tempArr === "object") {
                tempArr.map(el => parameterArray.push(el));
            } else {
                parameterArray.push(tempArr);
            }
            return undefined;
        });

        if (areAllItemsEqual(parameterArray) && parameterArray[0] !== undefined) {
            currentValue = parameterArray[0];
        }

        isSliderVisible = true;
        parameterArray.map(param => (param === undefined ? isSliderVisible = false : null));
    }

    return isSliderVisible && <div className="floating-menu-slider-container">
        <label htmlFor="size" className="floating-menu-slider-title">
            {title + (currentValue === "standard"
                ? locale?.get.studio.floatingMenu.sliderStandard
                : ((currentValue === undefined || isNaN(+currentValue))
                    ? locale?.get.studio.floatingMenu.undefined
                    : currentValue
                )
            )}
        </label>
        <input
            className="floating-menu-slider"
            type="range"
            name="size"
            value={isNaN(+currentValue) ? -1 : currentValue}
            min={min}
            max={max}
            step={step}
            onChange={handleSliderChanged}
        />
    </div>;
}

import {russian} from "./russian.js";
import {english} from "./english.js";
import {chinese} from "./chinese.js";

export const languages = {
    russian: russian,
    english: english,
    chinese: chinese
};

export const languageAbbrs = {
    ru: russian,
    en: english,
    cn: chinese
};

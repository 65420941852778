import React from "react";

import {useReduxData} from "../../../../customHooks/useContextReduxData";
import {useCopyPaste} from "../../../../customHooks/useContextCopyPaste";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";

import {ReactComponent as Copy} from "../images/first-block/copy.svg";


const IconCopy = tooltipHOC(
    hintModeTooltipHOC(Copy, "firstToolBlockCopy", {marginBottom: "-2px", marginTop: "-2px"}),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () {
    const locale = useLocalization();
    const {copyObjects} = useCopyPaste();
    const {isWatchMode, selectedFigureUuids} = useReduxData();

    const copy = () => copyObjects({x: 0, y: 0});

    return <div className="tool-block-item" onClick={copy}>
        <IconCopy
            className={!isWatchMode && selectedFigureUuids.length !== 0 ? "" : "disabled"}
            tooltipContent={locale.get.studio.header.firstToolBlock.copy}
        />
    </div>;
}

import {useState, useLayoutEffect} from "react";
import {useDispatch} from 'react-redux';

import {useReduxData} from "./customHooks/useContextReduxData";
import {usePencilDrawing} from "./customHooks/useContextPencilDrawing";
import {useLongPolygonDrawing} from "./customHooks/useContextLongPolygonDrawing";
import {useUndoRedo} from "./customHooks/useContextUndoRedo";
import {useDeleteObjects} from "./customHooks/useContextDeleteObjects";
import {useCopyPaste} from "./customHooks/useContextCopyPaste";
import {useGroupingObjects} from "./customHooks/useContextGroupingObjects";
import {useHintMode} from "./customHooks/useContextHintMode";
import {useSnappingMode} from "./customHooks/useContextSnappingMode";


export default function () {
    const dispatch = useDispatch();
    const hintMode = useHintMode();
    const snappingMode = useSnappingMode();
    const {isEditMode, tool} = useReduxData();
    const {undo, redo} = useUndoRedo();
    const {deleteObjects} = useDeleteObjects();
    const {copyObjects, pasteObjects} = useCopyPaste();
    const {groupObjects, ungroupObjects} = useGroupingObjects();
    const {createFigureFromPencil} = usePencilDrawing();
    const {showConfigurationMenu} = useLongPolygonDrawing();

    const [mousePosition, setMousePosition] = useState({x: 0, y: 0});

    useLayoutEffect(() => { // to catch key down everywhere
        window.addEventListener("keydown", handleKeyDownOnCanvas);
        return () => window.removeEventListener("keydown", handleKeyDownOnCanvas);
    });

    useLayoutEffect(() => { // to catch key up everywhere
        window.addEventListener("keyup", switchModesOff);
        return () => window.removeEventListener("keyup", switchModesOff);
    });

    useLayoutEffect(() => { // to catch mouse position everywhere
        window.addEventListener("mousemove", setMouseCoo);
        return () => window.removeEventListener("mousemove", setMouseCoo);
    });

    const setMouseCoo = event => setMousePosition({x: event.clientX, y: event.clientY});

    const handleKeyDownOnCanvas = event => {
        if (!event.key) {
            return;
        }
        if (
            (document.activeElement.type === "text" && document.activeElement.tagName === "INPUT") ||
            (document.activeElement.type === "textarea" && document.activeElement.tagName === "TEXTAREA")
        ) {
            return;
        }

        if (event.shiftKey && document.activeElement.id !== "map-title-input") {
            snappingMode.set(true);
            if (["h", "р"].includes(event.key.toLowerCase())) {
                hintMode.set(true);
            }
        }

        if (event.key === "Escape") {
            // removeLastSegment();
        } else if (event.key === "Delete" && isEditMode) {
            deleteObjects();
        } else if (event.key === "Enter" && !isEditMode) {
            const toolType = tool.split("-")[0];
            if (toolType === "pl") {
                showConfigurationMenu();
            } else if (["p", "c"].includes(toolType)) {
                createFigureFromPencil(event, "enter");
            }
            dispatch({type: "isEditMode", value: true});
        }
        if (isEditMode) {
            let key = event.key.toLowerCase();
            if (["g", "п"].includes(key) && event.shiftKey) {
                groupObjects();
            } else if (["u", "г"].includes(key) && event.shiftKey) {
                ungroupObjects();
            } else if (["z", "я"].includes(key) && event.ctrlKey && event.shiftKey) {
                redo();
            } else if (["z", "я"].includes(key) && event.ctrlKey) {
                undo();
            } else if (["c", "с"].includes(key) && event.ctrlKey) {
                copyObjects(mousePosition);
            } else if (["v", "м"].includes(key) && event.ctrlKey) {
                pasteObjects(mousePosition);
            }
        }
    };

    const switchModesOff = event => {
        if (event.key === "Shift") {
            snappingMode.set(false);
            hintMode.set(false);
        }
    };

    return null;
}
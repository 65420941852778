import React from "react";

import {useLocalization} from "../../../customHooks/useContextLocalization";


export const UpdateButton = props => {
    const locale = useLocalization();
    return <p className="account-card-button" onClick={props.onClick}>{locale?.get?.store.update}</p>
}

export const PublishButton = props => {
    const locale = useLocalization();
    return <p className="account-card-button" onClick={props.onClick}>{locale?.get?.store.publish}</p>
}

export const UnpublishButton = props => {
    const locale = useLocalization();
    return <p className="account-card-button" onClick={props.onClick}>{locale?.get?.store.unpublish}</p>
}
import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {v4 as uuid} from "uuid";

import {useCanvasBoundingClientRect} from "./useContextCanvasBoundingClientRect";
import {useReduxData} from "./useContextReduxData";
import {useIconSet} from "../../customHooks/useContextIconSet";
import useCooTransformation from "../components/canvas/customHooks/useCooTransformation";
import {useLocalization} from "../../customHooks/useContextLocalization";
import Path from '../utils/path.js';
import {polygonSchema} from "../utils/validators";
import getTimeElapsedSince from "../components/getTimeElapsedSince";
import {
    enrichPointsWithDirectiveOnes,
    getLeftwardVector,
    getRightwardVector,
    isVectorsIntersect,
    reduceIntersectedVectors
} from "./utils/longPolygonCreation";
import {replaceAt} from "../../utils";
import {useUserViewBox} from "./useContextUserViewBox";


const LongPolygonDrawingContext = React.createContext();


function ConfigurationMenu({path, defaultThickness=50, createLongPolygonFromPath}) {
    const MENU_WIDTH = 150;
    const MENU_HEIGHT = 200;

    const {transformYForCanvas, transformXForCanvas} = useCooTransformation();
    const locale = useLocalization();
    const {get: userViewBox} = useUserViewBox();
    const [viewBoxX, viewBoxY, viewBoxWidth, viewBoxHeight] = userViewBox.split(" ").map(item => +item);

    const [adjustedX, setAdjustedX] = useState(0);
    const [adjustedY, setAdjustedY] = useState(0);
    const [thickness, setThickness] = useState(defaultThickness);

    useEffect(() => {
        let {centerX: x, centerY: y} = Path.getCenter(path);
        x = transformXForCanvas(x);
        y = transformYForCanvas(y);

        setAdjustedX(x + MENU_WIDTH > viewBoxX + viewBoxWidth ? viewBoxX + viewBoxWidth - MENU_WIDTH : x);
        setAdjustedY(y + MENU_HEIGHT > viewBoxY + viewBoxHeight ? viewBoxY + viewBoxHeight - MENU_HEIGHT : y);
    }, [path]);

    const changeThicknessOnValueChange = event => (event.target.value.match(/^\d*$/)?.[0] || event.target.value === "")
        && setThickness(+event.target.value);

    const createLongPolygon = () => createLongPolygonFromPath(thickness);

    return <foreignObject x={adjustedX} y={adjustedY} width={MENU_WIDTH} height={MENU_HEIGHT}>
        <div className="long-polygon-configurator-container">
            <p>{locale?.get?.studio.plConfigurator.thickness}</p>
            <input type="number" value={thickness} onChange={changeThicknessOnValueChange}/>
            <input type="button" value={locale?.get?.studio.plConfigurator.set} onClick={createLongPolygon}/>
        </div>
    </foreignObject>;
}


export function LongPolygonDrawingProvider({children}) {
    const {get: {left: canvasX, top: canvasY}} = useCanvasBoundingClientRect();
    const {
        color,
        fontColor,
        fontSize,
        startTime,
        tool,
        transparency
    } = useReduxData();
    const {get: {parameters}} = useIconSet();
    const {transformYForCanvas, transformXForCanvas, transformXForMap, transformYForMap} = useCooTransformation();
    const dispatch = useDispatch();

    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [currentThickness, setCurrentThickness] = useState(50);
    const [mode, setMode] = useState("drawing");

    const [pencilPath, setPencilPath] = useState([]); //reduced path fragment, almost (not closed) ready to use

    const resetHooks = () => {
        setStartX(-100);
        setStartY(-100);
        setPencilPath([]);
        setMode("drawing");
    };

    const addSegmentToLongPolygonOnMouseDown = event => {
        let x = transformXForMap(event.clientX - canvasX),
            y = transformYForMap(event.clientY - canvasY);
        if (startX === -100 && startY === -100) {
            setPencilPath([[x, y]]);
            setStartX(x);
            setStartY(y);
        } else {
            if (pencilPath) {
                setPencilPath(pencilPath.concat([[x, y]]));
            } else {
                setPencilPath([[x, y]]);
            }
        }
    };

    const createFigurePath = thickness => {
        const CURVATURE = 2 / 3; // should be > 1/2

        let newPoints = [];
        let secondSidePoints = [];
        if (pencilPath.length > 1) {
            for (let i = 1; i < pencilPath.length; i++) {
                const startPoint = {x: pencilPath[i - 1][0], y: pencilPath[i - 1][1]};
                const finishPoint = {x: pencilPath[i][0], y: pencilPath[i][1]};

                const leftwardVector = getLeftwardVector({start: startPoint, finish: finishPoint}, thickness);
                const rightwardVector = getRightwardVector({start: startPoint, finish: finishPoint}, thickness);

                newPoints.push([leftwardVector.start.x, leftwardVector.start.y]);
                newPoints.push([leftwardVector.finish.x, leftwardVector.finish.y]);

                secondSidePoints.push([rightwardVector.start.x, rightwardVector.start.y]);
                secondSidePoints.push([rightwardVector.finish.x, rightwardVector.finish.y]);
            }

            for (let i = 2; i < newPoints.length - 1; i += 2) {
                const prevVector = {
                    start: {x: newPoints[i - 2][0], y: newPoints[i - 2][1]},
                    finish: {x: newPoints[i - 1][0], y: newPoints[i - 1][1]}
                };
                const currentVector = {
                    start: {x: newPoints[i][0], y: newPoints[i][1]},
                    finish: {x: newPoints[i + 1][0], y: newPoints[i + 1][1]}
                };
                if (isVectorsIntersect(prevVector, currentVector)) {
                    const {v1, v2} = reduceIntersectedVectors(prevVector, currentVector);
                    newPoints = replaceAt(newPoints, i - 1, [v1.finish.x, v1.finish.y]);
                    newPoints = replaceAt(newPoints, i, [v2.start.x, v2.start.y]);
                }
            }

            secondSidePoints.reverse();
            for (let i = 2; i < secondSidePoints.length - 1; i += 2) {
                const prevVector = {
                    start: {x: secondSidePoints[i - 2][0], y: secondSidePoints[i - 2][1]},
                    finish: {x: secondSidePoints[i - 1][0], y: secondSidePoints[i - 1][1]}
                };
                const currentVector = {
                    start: {x: secondSidePoints[i][0], y: secondSidePoints[i][1]},
                    finish: {x: secondSidePoints[i + 1][0], y: secondSidePoints[i + 1][1]}
                };
                if (isVectorsIntersect(prevVector, currentVector)) {
                    const {v1, v2} = reduceIntersectedVectors(prevVector, currentVector);
                    secondSidePoints = replaceAt(secondSidePoints, i - 1, [v1.finish.x, v1.finish.y]);
                    secondSidePoints = replaceAt(secondSidePoints, i, [v2.start.x, v2.start.y]);
                }
            }
        }

        newPoints = enrichPointsWithDirectiveOnes(newPoints, thickness, CURVATURE);
        secondSidePoints = enrichPointsWithDirectiveOnes(secondSidePoints, thickness, CURVATURE);

        let path = "";
        newPoints.map((p, i) => {
            if (i === 0) {
                path += "M " + p;
            } else if (i % 3 === 1) {
                path += " L " + p;
            } else if (i % 3 === 2) {
                path += " C " + p;
            } else if (i % 3 === 0) {
                path += " " + p;
            }
        });
        secondSidePoints.map((p, i) => {
            if (i === 0) {
                path += " L " + p;
            } else if (i % 3 === 1) {
                path += " L " + p;
            } else if (i % 3 === 2) {
                path += " C " + p;
            } else if (i % 3 === 0) {
                path += " " + p;
            }
        });

        path += " L " + newPoints[0];

        let result = [];
        if (path) {
            let [minX, minY] = Path.getMinimumCoo(path);
            path = Path.transform(path, -minX, -minY, 1, 1);
            result = [path, transformXForCanvas(minX), transformYForCanvas(minY)];
        }
        resetHooks();
        return result;
    };

    const createLongPolygonFromPath = thickness => {
        setCurrentThickness(thickness);

        let [path, minX, minY] = createFigurePath(thickness);

        if (Path.checkIfPathValid(path)) {
            const newFigureUuid = uuid();
            let newFigureObject = {
                uuid: newFigureUuid,
                parentUuid: undefined,
                name: "",
                link: "",
                description: "",
                emoji: [],
                x: transformXForMap(minX),
                y: transformYForMap(minY),
                points: path,
                tool,
                color,
                fontColor,
                fontSize,
                transparency,
                layout: +parameters[tool]?.layout || 50,
                orderIndex: Math.round(new Date().getTime())
            };
            if (!polygonSchema.isValidSync(newFigureObject)) {
                return;
            }
            dispatch({...newFigureObject, type: "addPolygon", time: getTimeElapsedSince(startTime)});
            dispatch({type: "selectedFigureUuids", value: [newFigureUuid]});
        }
    };

    const showConfigurationMenu = () => setMode("configuration");

    return <LongPolygonDrawingContext.Provider value={{
        longPolygonPath: "M " + pencilPath.join(" L "),
        addSegmentToLongPolygonOnMouseDown,
        showConfigurationMenu,
        configurationMenu: mode === "configuration"
            ? <ConfigurationMenu
                path={"M " + pencilPath.join(" L ")}
                defaultThickness={currentThickness}
                createLongPolygonFromPath={createLongPolygonFromPath}
            />
            : null
    }}>
        {children}
    </LongPolygonDrawingContext.Provider>;
}

export const useLongPolygonDrawing = () => useContext(LongPolygonDrawingContext);

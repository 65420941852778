import {useMenu} from "./hooks/useMenu";
import {getPreferredFontColorByBackgroundColor} from "../../../../../../../studio/utils/color";


export default function ({dataToRender, figuresToShowTransparencies, category}) {
    const {shouldShowCooRanges, figuresToShow, shouldMakeRareObjectsTranslucent} = useMenu();
    // console.log(dataToRender);
    // console.log(figuresToShowTransparencies);
    return Object.keys(dataToRender).map(figure => {
        const figureObject = dataToRender[figure];
        const transparency = shouldMakeRareObjectsTranslucent ? figuresToShowTransparencies[figure] : "";

        return figuresToShow[category].indexOf(figure) !== -1 ? <g>
            {shouldShowCooRanges && <rect
                x={figureObject.minX}
                y={figureObject.minY}
                width={figureObject.maxX - figureObject.minX}
                height={figureObject.maxY - figureObject.minY}
                fill={`${figureObject.color}10`}
                stroke="none"
            />}
            {!figureObject.path && <rect
                width={figureObject.width}
                height={figureObject.height}
                x={figureObject.x}
                y={figureObject.y}
                fill={`${figureObject.color}${transparency}`}
                stroke={`#000000${transparency}`}
            />}
            {figureObject.path && <defs><path
                id={`path-${category}-${figure}`}
                d={figureObject.path}
                fill={`${figureObject.color}${transparency}`}
                stroke={`#000000${transparency}`}
            /></defs>}
            {figureObject.path && <use href={`#path-${category}-${figure}`} x={figureObject.x} y={figureObject.y}/>}
            <defs>
                <filter x="0" y="0" width="1" height="1" id={`filter-${category}-${figure}`}>
                    <feFlood
                        floodColor={getPreferredFontColorByBackgroundColor(figureObject.fontColor || "#000000")}
                        result="bg"/>
                    <feMerge>
                        <feMergeNode in="bg"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
            </defs>
            <text
                filter={`url(#filter-${category}-${figure})`}
                fill={`${figureObject.fontColor || "#000000"}`}
                x={figureObject.x + 10}
                y={figureObject.y + figureObject.height / 2}
                fontSize={(figureObject.fontSize || 10) * 2}
            >{`${figure} (${figureObject.orderIndex})`}</text>
            <text
                fill={getPreferredFontColorByBackgroundColor(figureObject.color)}
                x={figureObject.x + 10}
                y={figureObject.y + figureObject.height / 2 + 20}
                fontSize={(figureObject.fontSize || 10) * 2}
            >{Math.round(figureObject.frequency * 100) + "%"}</text>
        </g> : null
    });
}
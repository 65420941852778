import React from "react";

import MyMaps from "./toolBlockItems/MyMaps";
import Export from "./toolBlockItems/Export";
import Share from "./toolBlockItems/Share";
import Save from "./toolBlockItems/Save";
import Copy from "./toolBlockItems/Copy";
import Paste from "./toolBlockItems/Paste";
import Redo from "./toolBlockItems/Redo";
import Undo from "./toolBlockItems/Undo";
import Outline from "./toolBlockBigItems/Outline";
import Layers from "./toolBlockBigItems/Layers";
import CopyStyle from "./toolBlockBigItems/CopyStyle";
import PasteStyle from "./toolBlockBigItems/PasteStyle";
import Background from "./toolBlockBigItems/Background";
import Grid from "./toolBlockBigItems/Grid";
import Center from "./toolBlockBigItems/Center";


export default function() {
    return <div className="tool-blocks-container" id="tool-block-container">
        <div className="first-tool-block">
            <MyMaps/>
            <Export/>
            <Share/>
            <Save/>
            <Copy/>
            <Paste/>
            <Undo/>
            <Redo/>
        </div>
        <div className="second-tool-block">
            <Outline/>
            <Layers/>
            <CopyStyle/>
            <PasteStyle/>
            <Background/>
            <Grid/>
            <Center/>
        </div>
    </div>;
}

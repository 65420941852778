import {useState, useEffect} from "react";


export default function () {
    const [chosenGradient, setChosenGradient] = useState("linear-gradient(rgb(96, 137, 241), rgb(95, 73, 232))");
    const [gradientHexColors, setGradientHexColors] = useState({first: "#6089F1", second: "#5F49E8"});
    const [gradientAngle, setGradientAngle] = useState("0");

    useEffect(() => {
        const rgbColors = chosenGradient.replace("linear-gradient(", "").replace("))", "").split("), ");
        const hexColors = rgbColors.map(rgbColor => {
            const channels = rgbColor.replace("rgb(", "").split(", ");
            return "#" + channels.map(channel => {
                const hex = (+channel).toString(16);
                return hex.length === 1 ? "0" + hex : hex;
            }).join("");
        });
        setGradientHexColors({first: hexColors[0], second: hexColors[1]});
    }, [chosenGradient]);

    const predefinedGradients = [
        "linear-gradient(rgb(238, 214, 129), rgb(228, 121, 43))",
        "linear-gradient(rgb(228, 183, 255), rgb(238, 129, 227))",
        "linear-gradient(rgb(222, 30, 134), rgb(222, 129, 79))",
        "linear-gradient(rgb(89, 235, 255), rgb(25, 206, 206))",
        "linear-gradient(rgb(133, 225, 34), rgb(23, 216, 89))",
        "linear-gradient(rgb(255, 247, 46), rgb(255, 134, 199))",
        "linear-gradient(rgb(231, 255, 206), rgb(197, 255, 124))",
        "linear-gradient(rgb(96, 137, 241), rgb(95, 73, 232))",
        "linear-gradient(rgb(255, 195, 78), rgb(253, 114, 114))",
        "linear-gradient(rgb(89, 235, 255), rgb(95, 73, 232))",
    ];

    const chooseGradient = event => setChosenGradient(event.target.style.backgroundImage);
    const chooseGradientAngle = event => setGradientAngle(event.target.value);

    return {predefinedGradients, chosenGradient, chooseGradient, gradientHexColors, gradientAngle, chooseGradientAngle};
}
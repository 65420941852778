import React, {useState} from "react";
import {useDispatch} from "react-redux";

import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useMap} from "../../customHooks/useContextMap";
import useRectangleDrawing from "../canvas/customHooks/useRectangleDrawing";


function DimensionField({placeholder, value, setValue}) {
    const handleTextChanged = event => {
        let processedValue = "";
        for (let i = 0; i < event.target.value.length; i++) {
            if (event.target.value[i] >= "0" && event.target.value[i] <= "9") {
                processedValue = processedValue.concat(event.target.value[i]);
            }
        }
        if (processedValue.length > 4) {
            processedValue = "9999";
        }
        setValue(processedValue);
    };

    return <input
        className="modal-background-textbox"
        type="number"
        placeholder={placeholder}
        value={value}
        onChange={handleTextChanged}
    />;
}


export default function BackgroundLoading(props) { //TODO: rewrite it to loading of dynamic image
    const locale = useLocalization();
    const map = useMap();

    const [fitType, setFitType] = useState("original");
    const [backgroundWidth, setBackgroundWidth] = useState(undefined);
    const [backgroundHeight, setBackgroundHeight] = useState(undefined);

    const checkRadio = event => setFitType(event.target.id.split("-")[event.target.id.split("-").length - 1]);

    const loadBackground = async () => {
        await map.loadMapBackgroundFromFS(fitType, backgroundWidth, backgroundHeight);
        props.close();
    };

    return <div className="modal-background-container">
        <h4 className="modal-title modal-background-title">{locale?.get.studio.backgroundLoading.title}</h4>
        <div className="modal-background-radio-group">
            <div className="survey-answer-radio-wrapper">
                <input
                    id="background-fit-original"
                    className="survey-answer-radio-hidden-std"
                    type="radio"
                    radioGroup="background-fit"
                    name="background-fit-original"
                    checked={fitType === "original"}
                    onChange={checkRadio}
                />
                <label className="survey-answer-radio-label" htmlFor="background-fit-original">
                    {locale?.get.studio.backgroundLoading.original}
                </label>
            </div>
            <div className="survey-answer-radio-wrapper">
                <input
                    id="background-fit-custom"
                    className="survey-answer-radio-hidden-std"
                    type="radio"
                    radioGroup="background-fit"
                    name="background-fit-custom"
                    checked={fitType === "custom"}
                    onChange={checkRadio}
                />
                <label className="survey-answer-radio-label" htmlFor="background-fit-custom">
                    {locale?.get.studio.backgroundLoading.custom}
                    <DimensionField
                        placeholder={locale?.get.studio.backgroundLoading.width}
                        value={backgroundWidth}
                        setValue={setBackgroundWidth}
                    />
                    {", "}
                    <DimensionField
                        placeholder={locale?.get.studio.backgroundLoading.height}
                        value={backgroundHeight}
                        setValue={setBackgroundHeight}
                    />
                </label>
            </div>
        </div>
        <div className="modal-background-apply">
            <input
                type="button"
                className="modal-button modal-button-apply"
                value={locale?.get.studio.backgroundLoading.apply}
                onClick={loadBackground}
            />
        </div>
    </div>;
}

import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {useIconSet} from "../../../customHooks/useContextIconSet";
import {useReduxData} from "../../customHooks/useContextReduxData";
import useCooTransformation from "../canvas/customHooks/useCooTransformation";
import Path from "../../utils/path.js";


export default function Polyline({figure, kind, isMouseDown}) {
    const [isIncreased, setIsIncreased] = useState(false);

    const {get: {parameters}} = useIconSet();
    const {transformXForCanvas, transformYForCanvas} = useCooTransformation();
    const {isValidationAnimationShow, scale} = useReduxData();
    const path = figure ? Path.transform(figure.get("points"), 0, 0, scale, scale) : "";

    const transformedFigureX = transformXForCanvas(figure.get("x")),
        transformedFigureY = transformYForCanvas(figure.get("y"));

    const dispatch = useDispatch();

    useEffect(() => {
        function func() {
            if (isValidationAnimationShow)
                dispatch({type: "isValidationAnimationShow", value: false});
        }
        setTimeout(func, 1000);
    });

    //show and hide description window
    const handleMouseEnterOnPolyline = () => {
        if (!isMouseDown) {
            dispatch({type: "figureUuidDescriptionPopupShownFor", value: figure.get("uuid")});
        }
        setIsIncreased(true);
    }

    const handleMouseLeaveOnPolyline = () => {
        if (!isMouseDown) {
            dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});
        }
        setIsIncreased(false);
    }

    //hide description on figure move
    const handleMouseDownOnPolyline = event => event.button === 0
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});

    //show description on moving ended up
    const handleMouseUpOnPolyline = event => event.button === 0
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: figure.get("uuid")});

    return <g
        className="figure"
        onMouseEnter={handleMouseEnterOnPolyline}
        onMouseLeave={handleMouseLeaveOnPolyline}
        onMouseDown={handleMouseDownOnPolyline}
        onMouseUp={handleMouseUpOnPolyline}
    >
        <symbol id={`${figure.get("uuid")}-symbol-figure`}>
            <path
                fill="none"
                stroke={figure.get("color")}
                strokeWidth={isIncreased ? figure.get("thickness") * scale * 5 : figure.get("thickness") * scale}
                strokeDasharray={figure.get("dashLength")}
                strokeLinecap="round"
                style={{
                    animationName: (isValidationAnimationShow && parameters[figure.get("tool")]?.isNameRequired
                        && (figure.get("name") === "" || figure.get("description") === "")
                    ) ? "highlight" : "",
                    animationDuration: "0.5s",
                    animationFillMode: "backwards",
                    strokeMiterlimit: "10"
                }}
                opacity={figure.get("transparency")}
                d={path}
            />
        </symbol>
        <use
            id={figure.get("uuid")}
            className={kind === "separate" ? "figure" : "group"}
            href={`#${figure.get("uuid")}-symbol-figure`}
            x={transformedFigureX - 2}
            y={transformedFigureY - 2}
        />
    </g>;
}

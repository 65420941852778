

export const getPointFeaturePoint = (figure, index) => {
    let x = figure.get("x") + 1.5 * figure.get("volume");
    let y = figure.get("y") + figure.get("volume");
    let rectHeight = figure.get("volume");
    let rectWidth = figure.get("volume");

    let point = {x, y};
    if ([1, 5].includes(index)) {
        point.x += rectWidth / 2;
    } else if ([2, 3, 4].includes(index)) {
        point.x += rectWidth;
    }
    if ([3, 7].includes(index)) {
        point.y += rectHeight / 2;
    } else if ([4, 5, 6].includes(index)) {
        point.y += rectHeight;
    }
    return point;
}

import React, {useState} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {ReactFlowProvider} from "react-flow-renderer";

import TableWithMaps from "./TableWithMaps";
import DFDCanvas from "./processingAndAnanysis/dfd/DFDCanvas";
import {DataSourceProvider} from "./processingAndAnanysis/dfd/hooks/useContextDataSource";
import {useLocalization} from "../../../customHooks/useContextLocalization";


export default function () {
    const locale = useLocalization();

    const [serverMapInfo, setServerMapInfo] = useState([]);
    const [shouldShowServerMapTable, setShouldShowServerMapTable] = useState(true);

    const switchTableShowing = () => setShouldShowServerMapTable(!shouldShowServerMapTable);

    return <div className="main-project-analytics-container" id="main-project-analytics-container">
        {shouldShowServerMapTable && <TableWithMaps serverMapInfo={serverMapInfo} setServerMapInfo={setServerMapInfo}/>}
        <input
            type="button"
            className="project-analytics-table-showing-handler"
            value={shouldShowServerMapTable ? "<" : ">"}
            onClick={switchTableShowing}
        />
        <div className="project-analytics-dashed-container">
            <h3>{locale?.get?.project.analytics.mainPage.convertersProcessorsAndMetrics}</h3>
            <div
                className="project-dfd-canvas-container"
                style={!shouldShowServerMapTable ? {width: "calc(100vw - 8rem)"} : {}}
            >
                <ReactFlowProvider>
                    <DndProvider backend={HTML5Backend}>
                        <DataSourceProvider>
                            <DFDCanvas
                                handleServerMapTableShowing={setShouldShowServerMapTable}
                                serverMapInfo={serverMapInfo}
                            />
                        </DataSourceProvider>
                    </DndProvider>
                </ReactFlowProvider>
            </div>
        </div>
    </div>;
}

import React, {useState, useContext} from "react";


const OnboardingContext = React.createContext();


export const OnboardingProvider = ({children}) => {
    const [shouldShowOnboarding, setShouldShowOnboarding] = useState(false);
    return <OnboardingContext.Provider value={{shouldShowOnboarding, setShouldShowOnboarding}}>
        {children}
    </OnboardingContext.Provider>;
};


export const useOnboarding = () => useContext(OnboardingContext);

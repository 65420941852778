import React from "react";
import {toast} from "react-toastify";

import {useCurrentUser} from "../../../../../customHooks/useContextCurrentUser";
import {useMapFileName} from "../../../../customHooks/useContextMapFileName";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import {useReduxData} from "../../../../customHooks/useContextReduxData";
import {getBoundingBoxOfMap, takePngSnapshotOfTheCanvas} from "../../../../customHooks/utils/map";
import {getAgeOfUser} from "../../../../utils/user";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";
import {CANVAS_HEIGHT, CANVAS_WIDTH} from "../../../canvas/Canvas";

import {ReactComponent as Export} from "../images/first-block/export.svg";


const IconExport = tooltipHOC(
    hintModeTooltipHOC(Export, "firstToolBlockExport", {marginBottom: "-2px", marginTop: "-2px"}),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () {
    const user = useCurrentUser();
    const mapFileName = useMapFileName();
    const locale = useLocalization();
    const {figures, scale} = useReduxData();

    const saveAsPNG = async () => {
        let png;
        try {
            const mapBoundingBox = getBoundingBoxOfMap(figures);
            png = await takePngSnapshotOfTheCanvas(
                (CANVAS_WIDTH / 2 + mapBoundingBox.x) * scale,
                (CANVAS_HEIGHT / 2 + mapBoundingBox.y) * scale,
                mapBoundingBox.width * scale,
                mapBoundingBox.height * scale
            );
        } catch (e) {
            toast.warn(e);
            return undefined;
        }

        const now = new Date();
        const gender = user?.gender || locale.get.studio.mapFileName.gender;
        const age = getAgeOfUser(user, now) || locale.get.studio.mapFileName.age;
        const formattedDate = [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()]
            .join(".");
        const mapNamePattern = mapFileName.get || `${gender}_${age}_${formattedDate}`;

        const filename = prompt(locale?.get.studio.header.menu.loadMapTitle, mapNamePattern);
        filename && saveAs(png, filename + ".png");
    };

    return <div className="tool-block-item" onClick={saveAsPNG}>
        <IconExport tooltipContent={locale.get.studio.header.firstToolBlock.export}/>
    </div>;
}

import React, {useEffect, useState, useRef} from "react";
import {useDispatch} from "react-redux";

import {useReduxData} from "../../customHooks/useContextReduxData";
import getTimeElapsedSince from "../getTimeElapsedSince";
import ColorPicker from "./ColorPicker";
import Slider from "./Slider";
import {useLocalization} from "../../../customHooks/useContextLocalization";


export default function TextBox({property, hiddenItems={}}) {
    const [textParameterOpened, setTextParameterOpened] = useState("");
    const inputRef = useRef();

    const {
        isEditMode,
        fontSize,
        selectedObject: {isTheOnlyObjectSelected, figureUuid, figureName, figureLink},
        startTime
    } = useReduxData();
    const locale = useLocalization();
    const dispatch = useDispatch();

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleTextChanged = event => {
        let actionData = {
            type: property === "name" ? "changeObjectName" : "changeObjectLink",
            uuid: figureUuid,
            time: getTimeElapsedSince(startTime)
        };
        actionData[property] = event.target.value;
        dispatch(actionData);
    };

    const closeMenu = event => event.key === "Enter" && dispatch({type: "openedMenuItemNumber", value: "-1"});

    const setNewTextParameterOpened = event => {
        const itemType = event.target.id.split("-")[3];
        setTextParameterOpened(prev => itemType === prev ? "" : itemType);
    }

    return <>
        {!("name" in hiddenItems) && <input
            ref={inputRef}
            className="floating-menu-text-box"
            type="textbox"
            id={`tool-menu-${property}`}
            disabled={!isEditMode || !isTheOnlyObjectSelected}
            value={isEditMode && isTheOnlyObjectSelected ? (property === "name" ? figureName : figureLink) : ""}
            onChange={handleTextChanged}
            onKeyDown={closeMenu}
        />}
        {property === "name" && <>
            <div className="floating-menu-param-row">
                {!("color" in hiddenItems) && <div
                    id="tool-menu-name-color"
                    className="context-menu-button"
                    style={{
                        backgroundImage: "url(../../../../images/toolMenu/icon-text-color-black.svg)",
                        backgroundRepeat: "no-repeat",
                        borderBottom: textParameterOpened === "color" ? "0.0625rem solid #144066" : "none",
                        height: "1.25rem",
                        width: "1.25rem",
                    }}
                    onClick={setNewTextParameterOpened}
                />}
                {!("size" in hiddenItems) && <div
                    id="tool-menu-name-size"
                    className="context-menu-button"
                    style={{
                        backgroundImage: "url(../../../../images/toolMenu/icon-font-size.svg)",
                        backgroundRepeat: "no-repeat",
                        borderBottom: textParameterOpened === "size" ? "0.0625rem solid #144066" : "none",
                        height: "1.25rem",
                        width: "1.25rem",
                    }}
                    onClick={setNewTextParameterOpened}
                />}
            </div>
            {textParameterOpened === "color" && <ColorPicker context="fontColor"/>}
            {textParameterOpened === "size" && <Slider
                title={locale?.get.studio.floatingMenu.fontSize}
                action={"changeFigureFontSize"}
                purpose="fontSize"
                value={fontSize}
                menuAction="fontSize"
                type="any"
                min="-1"
                max="20"
                step="1"
            />}
        </>}
    </>;
}

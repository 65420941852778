import {useEffect, useRef, useState} from "react";


export default function useMeasure(extraDependency) {
    const ref = useRef(null);
    const [measure, setMeasure] = useState({});

    useEffect(() => {
        setMeasure(ref.current.getBoundingClientRect());
    }, [ref.current, extraDependency]);

    return [ref, measure];
}
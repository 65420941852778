import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useHistory} from "react-router";

import AddAnalystsField from "./AddAnalystsField";
import ForbiddenPage from "../ForbiddenPage";
import {useLocalization} from "../../customHooks/useContextLocalization";
import {useApiClient} from "../../customHooks/useApiClient";
import {useCurrentUser} from "../../customHooks/useContextCurrentUser";


export default function () {
    const currentUser = useCurrentUser();
    const locale = useLocalization();
    const api = useApiClient();
    const history = useHistory();

    const {id} = useParams();

    const [project, setProject] = useState(undefined);

    useEffect(() => {
        const asyncFunction = async () => {
            const data = (await api.get(`/api/v2/projects/${id}`)).data;
            setProject(data);
        };
        asyncFunction();
    }, [api]);

    const deleteProject = async () => {
        if (confirm("Вы точно хотите удалить проект? Удалится ВСЯ информацию о проекте, включая все карты нарисованные в рамках проекта и все опросы, заполненные в рамках проекта! Это будет БЕЗВОЗВРАТНО!")) {
            const answer = (await api.delete(`/api/v2/projects/${id}`)).data;
            if (answer === "deleted") {
                alert(`Проект ${id} удален безвозвратно!`);
                history.push("/project/");
            } else {
                alert(answer);
            }
        }
    };

    const analystsCount = project ? project.analysts.length: 0;
    const someAnalysts = project?.analysts?.length ? (
        "(" + project.analysts.slice(0, 3).join(", ") + (analystsCount > 3 ? "...)" : ")")
    ) : "";

    const participantsCount = project ? project.participants.length : 0;
    const someParticipants = project?.participants?.length ? (
        "(" + project.participants.slice(0, 3).join(", ") + (participantsCount > 3 ? "...)" : ")")
    ) : "";

    return ![1, 2].includes(currentUser?.role) && !currentUser?.is_analyst ? <ForbiddenPage/> : (
        project
            ? <div className="project-container">
                <div className="project-title-container">
                    <h1 className="project-page-title">
                        {locale?.get?.project.certainProject.certainProject} {project.name}
                    </h1>
                    <Link className="project-link" to="/project/">
                        {locale?.get?.project.certainProject.returnToList}
                    </Link>
                </div>
                <p className="project-link-p">
                    <a className="project-link" href={`${location.origin}/${project.link || ""}`}>
                        {`${location.origin}/${project.link || ""}`}
                    </a>
                </p>
                <div className="project-certain-content-container">
                    <p className="project-p">
                        {locale?.get?.project.certainProject.analysts} {analystsCount} {someAnalysts}
                    </p>
                    <p className="project-p">
                        {locale?.get?.project.certainProject.participants} {participantsCount} {someParticipants}
                    </p>
                    <p className="project-p">
                        {locale?.get?.project.certainProject.maps} {project.maps}
                    </p>
                    <p className="project-p">
                        {locale?.get?.project.certainProject.surveys} {project.surveys}
                    </p>
                </div>
                <AddAnalystsField/>
                <div className="project-certain-content-container">
                    <Link className="project-link" to={`/project/${id}/editing`}>
                        {locale?.get?.project.certainProject.edit}
                    </Link>
                    <Link className="project-link" to={`/project/${id}/analytics`}>
                        {locale?.get?.project.certainProject.goToAnalytics}
                    </Link>
                </div>
                <input
                    type="button"
                    className="project-delete-btn"
                    value={locale?.get?.project.certainProject.delete}
                    onClick={deleteProject}
                />
            </div>
            : "Не существует"
    );
}

import React, {useState, useEffect, useRef, useCallback} from "react";

import SurveyPage from "./SurveyPage";
import SurveyNavigator from "./SurveyNavigator";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useApiClient} from "../../../../customHooks/useApiClient";
import {useCurrentProject} from "../../../customHooks/useContextCurrentProject";
import useReward from "../../../customHooks/useReward";


export default function Survey({closeButton}) {
    const [page, setPage] = useState(0);
    const [lang, setLang] = useState("default");
    const [answers, setAnswers] = useState([]);
    const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);

    const api = useApiClient();
    const locale = useLocalization();
    const {get: currentProject} = useCurrentProject();
    const {tryToShowReward} = useReward();

    const surveyRef = useRef(null);

    useEffect(() => {
        if (isSurveyCompleted) {
            async function closeSurvey () {
                tryToShowReward();
            }
            setTimeout(closeSurvey, 2000);
        }
    }, [isSurveyCompleted]);

    useEffect(() => {
        if (currentProject.survey) {
            let answersArr = [];
            currentProject.survey.pages.map(page => page.elements.map(el => el.id && answersArr.push(null)));
            setAnswers(answersArr);
        }
    }, [currentProject.survey]);

    useEffect(() => surveyRef?.current && surveyRef.current.focus(), [page]);

    useEffect(
        () => locale?.get && currentProject.survey
            && Object.keys(currentProject.survey.pages[page].title).indexOf(locale.get.abbr.toLowerCase()) !== -1
            && setLang(locale.get.abbr.toLowerCase()),
        [locale?.get, currentProject.survey]
    );

    const closeSurvey = data => setIsSurveyCompleted(data);
    const turnPage = () => setPage(page + 1);
    const turnPageBack = () => setPage(page - 1);
    const turnPageOnEnter = event => (event.key === "Enter" && (page === currentProject.survey.pages.length - 1 ? sendSurvey() : turnPage()));

    const sendSurvey =  useCallback(async () => {
        setIsSurveyCompleted("onSurveySendText");
        try {
            await api.post(
                "/api/v2/surveys/",
                {answers: JSON.stringify(answers), current_project_id: currentProject.id}
            );
        } catch (err) {
            if (err.response.status === 409) {
                // no need to show survey
            }
        }
    }, [answers]);

    return !isSurveyCompleted
        ? <>
            {closeButton || null}
            <div
                className="modal-container"
                ref={surveyRef}
                tabIndex="0"
                onKeyDownCapture={turnPageOnEnter}
            >
                {currentProject.survey && <>
                    <SurveyPage
                        content={currentProject.survey.pages[page]}
                        lang={lang}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <SurveyNavigator
                        closeSurvey={closeSurvey}
                        turnPage={turnPage}
                        turnPageBack={turnPageBack}
                        sendSurvey={sendSurvey}
                        currentPage={page}
                        pagesCount={currentProject.survey.pages.length}
                        content={currentProject.survey}
                        lang={lang}
                    />
                </>}
            </div>
        </>
        : currentProject.survey && <div className="modal-container">
            <h4 className="modal-title survey-finish-title">{currentProject.survey[isSurveyCompleted][lang]}</h4>
        </div>;
}

import {useState, useEffect} from "react";
import {useDrop} from "react-dnd";

import dndItems from "../dndItems";


export default function useIconDropTarget (tabs, dispatchTabs, tabIndex, partitionIndex, subpartitionIndex=-1) {
    const [isDraggedElementHere, setIsDraggedElementHere] = useState(false);

    const [{canDrop, isOver, result, didDrop}, drop] = useDrop(() => ({
        accept: [dndItems.icon],
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            result: monitor.getItem(),
            didDrop: monitor.didDrop()
        })
    }));

    //it is needed to avoid adding item to all targets simultaneously
    useEffect(() => setIsDraggedElementHere(isOver), [isOver, setIsDraggedElementHere]);

    useEffect(() => {
        // console.log(result);
        if (result && didDrop && isDraggedElementHere) {
            if (result.source === "base") { //copy icons from base icon source
                if (tabs[tabIndex].children[partitionIndex].isComplicated) {
                    dispatchTabs({type: "addIconsToSubpartition", payload: {
                        tIndex: tabIndex,
                        pIndex: partitionIndex,
                        sIndex: subpartitionIndex,
                        icons: result.iconIds
                    }});
                } else {
                    dispatchTabs({
                        type: "addIconsToPartition",
                        payload: {tIndex: tabIndex, pIndex: partitionIndex, icons: result.iconIds}
                    });
                }
            } else { //move icons from partition to other one
                if (partitionIndex === result.source) { //break if source is the same object, that the target
                    return undefined;
                }
                if (tabs[tabIndex].children[partitionIndex].isComplicated) {
                    dispatchTabs({type: "moveIconsToSubpartition", payload: {
                        tIndex: tabIndex,
                        pIndex: partitionIndex,
                        sIndex: subpartitionIndex,
                        icons: result.iconIds,
                        sourcePIndex: result.source,
                    }});
                } else {
                    dispatchTabs({type: "moveIconsToPartition", payload: {
                        tIndex: tabIndex,
                        pIndex: partitionIndex,
                        icons: result.iconIds,
                        sourcePIndex: result.source,
                    }});
                }
            }
        }
    }, [result, didDrop]);

    return [drop, {isOver, sourceId: result?.source}];
}
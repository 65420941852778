import {useState, useCallback, useRef, useEffect} from "react";
import ReactFlow, {
    MiniMap,
    Controls,
    applyEdgeChanges,
    applyNodeChanges,
    addEdge
} from 'react-flow-renderer';

import NodeMenu from "./nodeMenu/NodeMenu";
import NodeConfigurator from "./nodeConfigurator/NodeConfigurator";
import ResultRenderer from "./resultRenderer/ResultRenderer";
import {CustomDragLayer} from "./dnd/CustomDragLayer";
import {NodeFilterProvider} from "./nodeMenu/hooks/useNodeFilter";
import useNodeDropTarget from "./dnd/hooks/useNodeDropTarget";
import {useApiClient} from "../../../../../customHooks/useApiClient";
import {useDataSource} from "./hooks/useContextDataSource";
import {getFileNameFromContentDisposition} from "../../../../../store/components/productForm/utils";
import {compressFiles} from "../../../../utils/compressFiles";
import {CUSTOM_NODE_TYPES} from "./customNodeTypes";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import getAverageContour from "./resultRenderer/generalMapVisualizerV2/utils";


export default function ({serverMapInfo, handleServerMapTableShowing}) {
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [selectedNode, setSelectedNode] = useState(undefined);
    const [newNodeId, setNewNodeId] = useState(1);
    const [isRenderMode, setIsRenderMode] = useState(false);
    const [renderDataType, setRenderDataType] = useState("");
    const [dataToRender, setDataToRender] = useState("");
    const [shouldRunPipeline, setShouldRunPipeline] = useState(true);
    const [loadingStatus, setLoadingStatus] = useState("");

    const reactFlowContainerRef = useRef(null);

    const api = useApiClient();
    const locale = useLocalization();
    const [drop] = useNodeDropTarget(newNodeId, setNewNodeId, reactFlowContainerRef);
    const {dataSources} = useDataSource();

    useEffect(() => {
        setShouldRunPipeline(true);
    }, [dataSources, serverMapInfo]);

    const onNodesChange = useCallback(
        changes => setNodes(nds => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        changes => {
            setEdges(eds => applyEdgeChanges(changes, eds));
            setShouldRunPipeline(true);
        },
        [setEdges, setShouldRunPipeline]
    );
    const onConnect = useCallback(
        connection => {
            setEdges(eds => addEdge(connection, eds));
            setShouldRunPipeline(true);
        },
        [setEdges, setShouldRunPipeline]
    );

    const onSelectionChange = useCallback(
        selectedElements => {
            if (selectedElements.nodes.length === 1) {
                setSelectedNode(selectedElements.nodes[0]);
            } else {
                setSelectedNode(undefined);
            }
        },
        []
    )

    const runPipeline = async () => {
        if (shouldRunPipeline) {
            let formData = new FormData();
            const formDataSources = [];
            for (let i = 0; i < Object.keys(dataSources).length; i++) {
                const sourceNodeId = Object.keys(dataSources)[i];
                const dataSource = dataSources[sourceNodeId].source;
                formDataSources.push({"id": sourceNodeId, "source": dataSource});

                let dataFile = undefined;
                if (dataSource === "server") {
                    const dataServerSource = dataSources[sourceNodeId].serverSource;
                    if (dataServerSource === "maps") {
                        let mapsToConvert = "";
                        if (dataSources[sourceNodeId].serverMapSource === "table") {
                            mapsToConvert = serverMapInfo.map(tableRow => tableRow.mv_id).join(", ");
                        } else if (dataSources[sourceNodeId].serverMapSource === "custom") {
                            mapsToConvert = dataSources[sourceNodeId].serverCustomList;
                        }
                        dataFile = new Blob([mapsToConvert], {type: "text/txt"});
                    } else if (dataServerSource === "dump") {
                        dataFile = new Blob([dataSources[sourceNodeId].serverDumpTable], {type: "text/txt"});
                    }
                } else if (dataSource === "local") {
                    dataFile = await compressFiles(dataSources[sourceNodeId].localFiles);
                }
                formData.append("data_files", dataFile, sourceNodeId);
            }

            formData.append("data_sources", JSON.stringify(formDataSources));

            // console.log(nodes);
            // console.log(edges);
            const processed_edges = edges.map(edge => ({
                source_id: edge.source,
                source_handle_id: edge.sourceHandle,
                target_id: edge.target,
                target_handle_id: edge.targetHandle
            }));
            const processed_nodes = {};
            nodes.map(node => {
                processed_nodes[node.id] = {name: node.data.label, type: node.type};
                if (node.type === "source") {
                    processed_nodes[node.id]["data_type"] = dataSources[node.id].dataType;
                }
            });
            formData.append("node_tree", JSON.stringify({nodes: processed_nodes, edges: processed_edges}));

            try {
                const {data: pipelineResults, ...other} = (await api.put(
                    `/api/v2/projects/analytics/pipeline/run`,
                    formData,
                    {
                        headers: {'Content-Type': 'multipart/form-data'},
                        responseType: "blob",
                        onUploadProgress: progressEvent => {
                            const loadingPercent = Math.round(progressEvent.loaded / progressEvent.total * 100);
                            setLoadingStatus(
                                `${
                                    locale?.get?.project.analytics.mainPage.dfd.uploading
                                } ${loadingPercent}%${loadingPercent === 100
                                    ? ", " + locale?.get?.project.analytics.mainPage.dfd.sending
                                    : ""
                                }`
                            );
                        }
                    }
                ));
                setLoadingStatus("");
                if (pipelineResults) {
                    if (pipelineResults.type.startsWith("studio")) {
                        setIsRenderMode(true);
                        setRenderDataType(pipelineResults.type);
                        setDataToRender(await pipelineResults.text());
                        setShouldRunPipeline(false);
                    } else {
                        const filename = prompt(
                            locale?.get?.project.analytics.mainPage.dfd.doYouWantToSaveTheResults,
                            getFileNameFromContentDisposition(other.headers["content-disposition"]) || "default_filename"
                        );
                        const blob = new Blob([pipelineResults], {type: pipelineResults.type || "application/json"});
                        filename && saveAs(blob, filename);
                    }
                }
            } catch (e) {
                console.log(e);
                setLoadingStatus(locale?.get?.project.analytics.mainPage.dfd.errorOccurred);
            }
        } else {
            setIsRenderMode(true);
        }
    }

    const returnToPipeline = () => setIsRenderMode(false);
    try {
        console.log(getAverageContour([
            // "M 80.97,56.62 Q 73.37,47.24 75.03,35.83 Q 73.6,27.47 74.04,19 Q 80.14,3.73 60.18,5.14 Q 51.81,0 42.35,3.16 Q 31.32,13.4 38.39,27.91 Q 35.92,43.76 26.51,56.62 Q 0,56.1 10.67,85.34 Q 11.85,94.14 18.59,100.19 Q 24.93,114.23 38.39,111.08 Q 49.86,112.64 60.18,107.12 Q 68.82,101.57 73.05,92.27 Q 83.73,75.87 82.95,57.61 L 80.97,56.62 L 80.97,56.62",
            // "M 52.14,2.02 Q 41.93,0 31.55,0.55 Q 18.85,0.59 8.76,8.64 Q 2.06,15.8 0.67,25.55 Q 0.67,34.74 0.67,43.93 Q 0,53.23 2.14,62.31 Q 4.9,71.71 10.23,79.96 Q 14.15,89.77 21.26,97.61 Q 31.3,109.79 46.99,110.84 Q 62.29,114.42 69.79,100.55 Q 76.65,87.75 77.88,73.34 Q 77.88,63.05 77.88,52.75 Q 79.04,44.24 74.94,36.58 Q 72.56,20.57 61.7,9.37 L 49.94,2.75 L 52.14,2.02 L 52.14,2.02",
            // "M 61.82,8.7 Q 47.66,22.26 49.4,39.75 L 49.4,39.75 L 58.09,49.7 Q 52.96,61.68 50.64,74.54 L 50.64,74.54 L 49.4,78.27 L 49.4,78.27 L 44.43,93.17 L 44.43,93.17 Q 32.71,98.5 36.36,110.56 L 43.19,115.54 L 43.19,115.54 Q 40.44,128.01 32.63,137.89 Q 25.3,143.87 16.48,142.86 Q 9.79,131.35 9.03,118.01 Q 9.03,109.94 9.03,101.86 Q 16.03,63.07 6.54,26.09 Q 0,6.97 20.21,3.73 Q 33.01,4.93 45.05,0 Q 51.05,8.28 61.82,8.7 L 61.82,8.7 L 57.47,7.45 L 58.72,13.66 L 61.82,8.7",
            "M 25.86,12.26 Q 19.64,0 1.15,17.43 Q 13.92,42.92 0,60.53 Q 21.72,62.15 24.71,69.73 Q 32.12,76.61 41.95,74.33 Q 50.29,51.88 71.26,45.01 Q 77.47,35.55 79.31,24.33 Q 82.26,0.04 43.68,4.21 L 26.44,9.38 L 25.86,12.26 L 25.86,12.26"
            // "M 0,70.55 Q 8.93,70.55 17.86,70.55 Q 29.03,70.55 40.19,70.55 Q 67.47,65.03 92.88,75.02 Q 106.47,80.42 120.57,77.7 Q 134.65,73.52 140.22,59.83 Q 144.56,47.96 137.54,38.4 Q 125.29,34.86 112.53,34.83 Q 102.71,29.91 92.88,25 Q 93.5,12.61 72.35,0 Q 59.72,21.72 44.66,18.75 Q 39.19,36.51 10.72,37.5 L 3.57,66.98 L 0,70.55 L 0,70.55"
        ]));
    } catch (e) {
        console.log(e);
    }
    return isRenderMode
        ? <div className="project-dfd-visualization-container">
            <input
                type="button"
                className="project-dfd-return-to-pipeline"
                value={locale?.get?.project.analytics.mainPage.dfd.back}
                onClick={returnToPipeline}
            />
            <ResultRenderer dataType={renderDataType} dataToRender={dataToRender}/>
        </div>
        : <>
            <div
                className="project-dfd-canvas-grid"
                style={selectedNode?.type === "source" ? {maxHeight: "calc(100% - 10rem)"} : {}}
            >
                <CustomDragLayer/>
                <NodeFilterProvider>
                    <NodeMenu runPipeline={runPipeline} loadingStatus={loadingStatus}/>
                </NodeFilterProvider>
                <div ref={reactFlowContainerRef}>
                    <ReactFlow
                        ref={drop}
                        nodes={nodes}
                        edges={edges}
                        nodeTypes={CUSTOM_NODE_TYPES}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        deleteKeyCode={"Delete"}
                        onSelectionChange={onSelectionChange}
                    >
                        {/*<MiniMap/>*/}
                        <Controls/>
                    </ReactFlow>
                </div>
            </div>
            <NodeConfigurator selectedNode={selectedNode} handleServerMapTableShowing={handleServerMapTableShowing}/>
        </>;
}
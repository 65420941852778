import {useDataSource} from "../../hooks/useContextDataSource";
import {useLocalization} from "../../../../../../../customHooks/useContextLocalization";


export default function ({id}) {
    const {dataSources} = useDataSource();
    const locale = useLocalization();

    return <div className="project-analytics-data-type-container">
        <p>{
            locale?.get?.project.analytics.mainPage.dfd.configurator.loadedDataType
        } {dataSources?.[id]?.dataType || ""}</p>
    </div>;
}

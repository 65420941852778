import React from "react";

import LinkToNewWindow from "../../../components/LinkToNewTab";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useApiClient} from "../../../customHooks/useApiClient";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";


export default function LicenseAgreement(props) {
    const locale = useLocalization();
    const user = useCurrentUser();
    const api = useApiClient();
    const texts = locale?.get.studio.licenseAgreement;

    const handleApplyClick = () => {
        document.cookie = "agreement=true";
        if (user) {
            api.post("/api/v1/agreement/", {accepted: true});
        }
        props.close();
    };

    const licenseAgreementLink = <LinkToNewWindow link="/docs/ru/license-agreement/" content={texts.licenseAgreement}/>;
    // const privacyPolicyLink = <LinkToNewWindow link="/docs/ru/privacy-policy/" content={texts.privacyPolicy}/>;

    return <div className="modal-container">
        <h4 className="modal-title">{texts.title}</h4>
        <p className="modal-p">
            {/*{texts.licenses}{licenseAgreementLink} {texts.and} {privacyPolicyLink}*/}
            {texts.licenses}{licenseAgreementLink}
        </p>
        <p className="modal-p">{texts.warning}</p>
        <p className="modal-p">{texts.note}</p>
        <input
            type="button"
            className="modal-button modal-button-apply"
            value={texts.apply}
            onClick={handleApplyClick}
        />
    </div>;
}

import React, {useState, useContext} from "react";


const GridContext = React.createContext();


export const GridProvider = ({children}) => {
    const [get, set] = useState(true);

    return <GridContext.Provider value={{get, set}}>
        {children}
    </GridContext.Provider>;
};


export const useGrid = () => useContext(GridContext);

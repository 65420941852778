import {useDragLayer} from 'react-dnd';

import SourceDataNode from "../nodes/SourceDataNode";
import IntermediateNode from "../nodes/IntermediateNode";
import FinalNode from "../nodes/FinalNode";
import dndItems from './dndItems';


function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }
    let { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
        display: "flex"
    };
}

export const CustomDragLayer = () => {
    const {itemType, item, isDragging, initialOffset, currentOffset} = useDragLayer(monitor => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    function renderItem() {
        switch (itemType) {
            case dndItems.sourceNode:
                return <SourceDataNode data={{label: item.name}}/>;
            case dndItems.intermediateNode:
                return <IntermediateNode data={{label: item.name}}/>;
            case dndItems.finalNode:
                return <FinalNode data={{label: item.name}}/>;
            default:
                return null;
        }
    }


    return (isDragging ? <div className="custom-drag-layer-container">
        <div style={getItemStyles(initialOffset, currentOffset)}>
            {renderItem()}
        </div>
    </div> : null);
};

/********************* ARRAY FUNCTIONS ************************/

/**
 * Insert item to array
 * @param  {Array}  arr   The source array
 * @param  {Number} index The insertion index
 * @param  {any}    item  The new item
 * @return {Array}        The updated array
 */
export const insertAt = (arr, index, item) => [...arr.slice(0, index), item, ...arr.slice(index)];

/**
 * Replace item in the array
 * @param  {Array}  arr   The source array
 * @param  {Number} index The index of item to be replaced
 * @param  {any}    item  The new item
 * @return {Array}        The updated array
 */
export const replaceAt = (arr, index, item) => [...arr.slice(0, +index), item, ...arr.slice(+index + 1)];

/**
 * Remove item from the array
 * @param  {Array}  arr   The source array
 * @param  {Number} index The index of item to be deleted
 * @return {Array}        The updated array
 */
export const deleteAt = (arr, index) => [...arr.slice(0, index), ...arr.slice(index + 1)];

/**
 * Return the last item from the array
 * @param  {Array}  arr The source array
 * @return {any}        The last item
 */
export const last = arr => arr[arr.length - 1];

/**
 * Move the item from the sourceIndex to target with shifting other items
 * @param  {Array}  arr         The source array
 * @param  {Number} sourceIndex The index of item to be moved
 * @param  {Number} targetIndex The index item will be moved
 * @return {Array}              The updated array
 * @example
 * moveItem(["one", "two", "three", "four"], 0, 2);
 * // returns: ["two", "three", "one", "four"]
 * @example
 * moveItem(["one", "two", "three", "four"], 3, 1);
 * // returns: ["one", "four", "two", "three"]
 * @example
 * moveItem(["one", "two", "three", "four"], 2, 2);
 * // returns: ["one", "two", "three", "four"]
 */
export const moveItem = (arr, sourceIndex, targetIndex) => {
    if (sourceIndex < targetIndex) {
        return arr.slice(0, sourceIndex)
            .concat(arr.slice(sourceIndex + 1, targetIndex + 1))
            .concat([arr[sourceIndex]])
            .concat(arr.slice(targetIndex + 1));
    } else {
        return arr.slice(0, targetIndex)
            .concat([arr[sourceIndex]])
            .concat(arr.slice(targetIndex, sourceIndex))
            .concat(arr.slice(sourceIndex + 1));
    }
}

/**
 * Return the reversed array (new array created, not inplace)
 * @param  {Array}  arr The source array
 * @return {Array}      The reversed array
 */
export const reverse = arr => {
    let newArr = [];
    for(let i = arr.length - 1; i >= 0; i--) {
        newArr.push(arr[i]);
    }
    return newArr;
}

/**
 * Return true if all items in array are equal to each other, otherwise false
 * @param  {Array}   arr  The source array
 * @return {Boolean}      The answer whether items equal or not
 */
export function areAllItemsEqual (arr) {
    return (new Set(arr)).size === 1;
}

/**
 * Check if item in array or not.
 * @param  {string} item   An item, which may be in the array
 * @param  {array}  array  The array of string items
 * @return {boolean}       True if item in array, false otherwise
 */
export function isItemInArray (item, array) {
    return array.includes(item);
}

/**
 * Return new array, every number-like item of which was scaled and shifted (sub-arrays allowed)
 * @typedef {Array<(Number|ComplicatedArray)>}     ComplicatedArray
 * @param  {ComplicatedArray}   arr    The source array
 * @param  {Number}             scale  Every item of array will be multiplied on this number
 * @param  {Number}             shift  This number will be added to each multiplied item of the array
 * @return {ComplicatedArray}          The transformed array (all sub-arrays preserved)
 */
export function transformArrayValues (arr, scale, shift) {
    let result = [];
    if (typeof arr !== "object") {
        return +arr * scale + shift;
    }
    arr.forEach(el => result.push(transformArrayValues(el, scale, shift)));
    return result;
}

/********************* OBJECT FUNCTIONS ************************/

/**
 * Update object
 * @param  {Object} object   The source object
 * @param  {Object} changes  The object, contains changes to be applied to source object
 * @return {Object}          The updated object
 */
export const updateObject = (object, changes) => {
    return {...object, ...changes};
}


export const createOption = value => ({label: value, value});

import React from "react";


export default function SvgEmojis({id, className, text, fontSize, opacity, scaleCoefficient, x, y, height, width}) {
    //slice() for string with emojis works incorrect
    return <>
        <symbol id={`${id}-symbol-emojis`}>
            {text.length <= 5
                ? <text
                    className="symbol-emojis"
                    fontSize={fontSize * scaleCoefficient}
                    opacity={opacity}
                    x="0"
                    y={43.2 * scaleCoefficient}
                >
                    {text.join("")}
                </text>
                : <>
                    <text
                        className="symbol-emojis"
                        fontSize={fontSize * scaleCoefficient}
                        opacity={opacity}
                        x="0"
                        y={16 * (scaleCoefficient + 0.2)}
                    >
                        {text.slice(0, 5).join("")}
                    </text>
                    <text
                        className="symbol-emojis"
                        fontSize={fontSize * scaleCoefficient}
                        opacity={opacity}
                        x="0"
                        y={43.2 * scaleCoefficient}
                        style={{fontWeight: "600", strokeWidth: "0.2mm", fontFamily: "AnonymousPro"}}
                    >
                        {text.slice(5).join("")}
                    </text>
                </>
            }
        </symbol>
        <use
            id={id}
            className={className}
            href={`#${id}-symbol-emojis`}
            x={x}
            y={y - 10}
            style={{pointerEvents: "none", width: width, height: height}}
        />
    </>;
}

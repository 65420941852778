import {useState} from "react";


export default function () {
    const [userText, setUserText] = useState("");
    const [userColor, setUserColor] = useState("white");

    const colors = ["white", "black"];

    const changeUserText = event => setUserText(event.target.value);
    const changeUserColor = event => setUserColor(event.target.value);

    return {userText, changeUserText, userColor, changeUserColor, colors}
}
import React, {useState} from "react";


export default function SocialNetworkIcon({
                                              id,
                                              className,
                                              SvgIcon,
                                              iconSize,
                                              onClick,
                                              iconColors={dark: "#144066", light: "#C4DEF5"},
                                              backgroundColors={dark: "#144066", light: "#C4DEF5"},
                                              style
                                          }) {
    const [isFired, setIsFired] = useState(false);

    const onFire = () => setIsFired(true);
    const onUnfire = () => setIsFired(false);

    return <div
        id={id}
        className={className}
        onMouseDown={onFire}
        onMouseUp={onUnfire}
        onClick={onClick}
        style={{...style, backgroundColor: isFired ? backgroundColors.dark : backgroundColors.light}}
    >
        <SvgIcon
            id={`${id}-icon`}
            fill={isFired ? iconColors.light : iconColors.dark}
            stroke={isFired ? iconColors.light : iconColors.dark}
            height={iconSize}
            width={iconSize}
        />
    </div>;
}

import React, {useCallback} from "react";

import styles from "./input.module.css";


export default function ({
    onChange,
    value,
    id,
    name,
    placeholder,
    type,
    className,
    maxLen,
    maxVal,
    minVal,
    size,
    renderIcon,
    viewBox,
    ...props
}) {
    const changeHandler = useCallback((e) => onChange(type === "file" ? e.target.files : e.target.value), [onChange]);

    return <div className={styles.wrap}>
        <input
            id={id}
            name={name}
            placeholder={placeholder}
            type={type}
            className={`${styles.input} ${className}`}
            value={value}
            onChange={changeHandler}
            maxLength={maxLen}
            max={maxVal}
            min={minVal}
            size={size}
            {...props}
        />
        {renderIcon && renderIcon({viewBox: viewBox, className: styles.icon})}
    </div>;
}

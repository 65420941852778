import React, {useState} from "react";

import Icon from "./Icon";

import styles from "./radioGroup.module.css";

import {ReactComponent as RadioCheckedIcon} from "../images/radio_checked.svg";
import {ReactComponent as RadioIcon} from "../images/radio.svg";


function Radio ({groupName, id, onChange, checked}) {
    return <div className={styles.radio}>
        <input
            id={id}
            type="radio"
            radioGroup={groupName}
            onChange={onChange}
            checked={checked}
            className={styles.input}
        />
        <label htmlFor={id}><Icon icon={checked ? RadioCheckedIcon : RadioIcon} viewBox="0 0 16 16"/></label>
    </div>;
}


export default function ({children, defaultValue, groupName, onChange}) {
    const [selectedIndex, setSelectedIndex] = useState(defaultValue);

    return <div className={styles["radio-group"]}>
        {children.map((child, i) => <React.Fragment key={i}>
            <Radio
                groupName={groupName}
                id={`${groupName}-${i}`}
                checked={selectedIndex === i}
                onChange={() => {
                    setSelectedIndex(i);
                    onChange(i);
                }}
            />
            {child}
        </React.Fragment>)}
    </div>;
}

import {useReducer, createContext, useContext, useEffect} from "react";
import {toast} from "react-toastify";

import {useIconSet} from "../../../../customHooks/useContextIconSet";
import {useApiClient} from "../../../../customHooks/useApiClient";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {deleteAt, moveItem, replaceAt, updateObject} from "../../../../utils";


const TabContext = createContext();

export function TabProvider({children}) {

    const {get: iconSetFromServer} = useIconSet();
    const api = useApiClient();
    const locale = useLocalization();

    const [state, dispatch] = useReducer(
        tabReducer,
        [{id: 1, name: {russian: "Tab 1", english: "Tab 1", chinese: "Tab 1"}, children: []}]
    );

    useEffect(() => {
        if (iconSetFromServer.tabSet.length > 0) {
            dispatch({type: "setWholeState", payload: {state: iconSetFromServer.tabSet}});
        }
    }, [iconSetFromServer]);

    const saveTabs = async () => { //uncomment it (and session.commit() on the server) to switch saving on
        await api.post(`/api/v2/store/account/tab_set/`, {tab_set: JSON.stringify(state)});
        toast.success(locale.get.store.icons.iconSetSaved);
    };

    const resetTabs = async () => {
        let defaultTabs = (await api.get("/api/v2/store/account/tab_set?current_project_id=-1"))?.data?.tab_set;
        dispatch({type: "setWholeState", payload: {state: defaultTabs}});
    };

    return <TabContext.Provider value={{tabs: state, dispatchTabs: dispatch, resetTabs, saveTabs}}>
        {children}
    </TabContext.Provider>;
}

export default function useTabs() {
    return useContext(TabContext);
}



function tabReducer(state, action) {
    const payload = action.payload;
    const locale = payload.locale;

    switch (action.type) {
        case "setWholeState":
            return action.payload.state;
        case "addTab": {
            const name = {};
            name[locale.get.name] = `${locale.get.store.icons.newTab} ${state.length + 1}`;
            const newTab = {
                id: Math.max(...(state.map(tab => tab.id)), 0) + 1,
                name: name,
                children: [],
            };
            return state.concat([newTab]);
        }
        case "deleteTab": {
            return deleteAt(state, payload.tIndex);
        }
        case "setTabName": {
            const updatedTab = updateObject(state[payload.tIndex], {name: payload.name});
            return replaceAt(state, payload.tIndex, updatedTab);
        }
        case "moveTab": {
            return moveItem(state, payload.dragIndex, payload.hoverIndex);
        }
        case "addPartition": {
            const name = {};
            name[locale.get.name] = `${locale.get.store.icons.newPartition} ${state[payload.tIndex].children.length + 1}`;
            const newPartition = {
                id: Math.max(...(state[payload.tIndex].children.map(partition => partition.id)), 0) + 1,
                name: name,
                defaultWidth: 100,
                isComplicated: false,
                children: []
            };
            const updatedTab = updateObject(
                state[payload.tIndex],
                {children: state[payload.tIndex].children.concat([newPartition])}
            );
            return replaceAt(state, payload.tIndex, updatedTab);
        }
        case "deletePartition": {
            const updatedTab = updateObject(
                state[payload.tIndex],
                {children: deleteAt(state[payload.tIndex].children, payload.pIndex)}
            );
            return replaceAt(state, payload.tIndex, updatedTab);
        }
        case "setPartitionComplexity": {
            let children;
            if (payload.isComplicated) { //simple partition -> complicated partition
                const name = {};
                name[locale.get.name] = locale.get.store.icons.newSubpartition
                children = [{
                    id: 1,
                    name: name,
                    children: state[payload.tIndex].children[payload.pIndex].children
                }];
            } else { //complicated partition -> simple partition
                children = [];
                state[payload.tIndex].children[payload.pIndex].children.map(subpartition => {
                    children.push(...subpartition.children.filter(el => !children.includes(el)));
                })
            }
            const updatedPartition = updateObject(
                state[payload.tIndex].children[payload.pIndex],
                {isComplicated: payload.isComplicated, children: children}
            );
            return replacePartition(state, payload, updatedPartition);
        }
        case "addSubpartition":
            const name = {};
            name[locale.get.name] = `${locale.get.store.icons.newSubpartition} ${
                state[payload.tIndex].children[payload.pIndex].children.length + 1
            }`;
            const newSubpartition = {
                id: Math.max(...(state[payload.tIndex].children[payload.pIndex].children.map(subpartition => subpartition.id)), 0) + 1,
                name: name,
                children: []
            };
            const updatedPartition = updateObject(state[payload.tIndex].children[payload.pIndex], {
                children: state[payload.tIndex].children[payload.pIndex].children.concat([newSubpartition])
            });
            return replacePartition(state, payload, updatedPartition);
        case "deleteSubpartition": {
            const updatedPartition = updateObject(state[payload.tIndex].children[payload.pIndex], {
                children: deleteAt(state[payload.tIndex].children[payload.pIndex].children, payload.sIndex)
            });
            return replacePartition(state, payload, updatedPartition);
        }
        case "addIconsToPartition":
            return addIconsToPartition(payload, state);
        case "addIconsToSubpartition":
            return addIconsToSubpartition(payload, state);
        case "moveIconsToPartition": {
            if (payload.icons && typeof payload.icons[0] === "object") { //from complicated partition
                const iconIds = payload.icons.map(icon => icon.id);
                const newState = addIconsToPartition({...payload, icons: iconIds}, state);
                return removeIconsFromComplicatedPartition({...payload, pIndex: payload.sourcePIndex}, newState);
            } else { //from simple partition
                const newState = addIconsToPartition(payload, state);
                return removeIconsFromSimplePartition({...payload, pIndex: payload.sourcePIndex}, newState);
            }
        }
        case "moveIconsToSubpartition": {
            if (payload.icons && typeof payload.icons[0] === "object") { //from complicated partition
                let iconsFromSubpartitions = [];
                payload.icons.map(icon => {
                    if (iconsFromSubpartitions.indexOf(icon.id) === -1) {
                        iconsFromSubpartitions.push(icon.id);
                    }
                });

                const newState = addIconsToSubpartition({...payload, icons: iconsFromSubpartitions}, state);
                return removeIconsFromComplicatedPartition({...payload, pIndex: payload.sourcePIndex}, newState);
            } else { //from simple partition
                const newState = addIconsToSubpartition(payload, state);
                return removeIconsFromSimplePartition({...payload, pIndex: payload.sourcePIndex}, newState);
            }
        }
        case "deleteIconsFromPartition": {
            if (payload.icons && typeof payload.icons[0] === "object") {
                return removeIconsFromComplicatedPartition(payload, state);
            } else {
                return removeIconsFromSimplePartition(payload, state);
            }
        }
        case "moveSubpartition": {
            const updatedPartition = updateObject(
                state[payload.tIndex].children[payload.pIndex],
                {children: moveItem(state[payload.tIndex].children[payload.pIndex].children, payload.dragIndex, payload.hoverIndex)}
            );
            return replacePartition(state, payload, updatedPartition);
        }
        case "movePartition": {
            const updatedTab = updateObject(
                state[payload.tIndex],
                {children: moveItem(state[payload.tIndex].children, payload.dragIndex, payload.hoverIndex)}
            );
            return replaceAt(state, payload.tIndex, updatedTab);
        }
        case "setPartitionName": {
            const updatedPartition = updateObject(
                state[payload.tIndex].children[payload.pIndex],
                {name: payload.name}
            );
            return replacePartition(state, payload, updatedPartition);
        }
        case "setSubpartitionName": {
            const updatedSubpartition = updateObject(
                state[payload.tIndex].children[payload.pIndex].children[payload.sIndex],
                {name: payload.name}
            );
            const updatedPartition = updateObject(
                state[payload.tIndex].children[payload.pIndex],
                {children:
                    replaceAt(state[payload.tIndex].children[payload.pIndex].children, payload.sIndex, updatedSubpartition)
                }
            );
            return replacePartition(state, payload, updatedPartition);
        }
        default:
            return state;
    }
}

function removeIconsFromComplicatedPartition(payload, state) {
    console.log(state);
    let newState = state;
    let iconsBySubpartitions = {};
    payload.icons.map(icon => {
        if (iconsBySubpartitions[icon.source]) {
            iconsBySubpartitions[icon.source].push(icon.id);
        } else {
            iconsBySubpartitions[icon.source] = [icon.id];
        }
    })

    Object.keys(iconsBySubpartitions).map(id => {
        const updatedSubpartition = updateObject(
            newState[payload.tIndex].children[payload.pIndex].children[id],
            {
                children: newState[payload.tIndex].children[payload.pIndex].children[id].children
                    .filter(el => !iconsBySubpartitions[id].includes(el))
            }
        );

        console.log(newState[payload.tIndex].children[payload.pIndex]);
        console.log(newState[payload.tIndex].children[payload.pIndex].children);
        console.log(id);

        const updatedPartition = updateObject(
            newState[payload.tIndex].children[payload.pIndex],
            {children: replaceAt(newState[payload.tIndex].children[payload.pIndex].children, id, updatedSubpartition)}
        );
        console.log(updatedPartition);
        newState = replacePartition(newState, payload, updatedPartition);
        return undefined;
    });
    return newState;
}

function removeIconsFromSimplePartition(payload, state) {
    const updatedPartition = updateObject(
        state[payload.tIndex].children[payload.pIndex],
        {children:
            state[payload.tIndex].children[payload.pIndex].children.filter(el => !payload.icons.includes(el))
        }
    );
    return replacePartition(state, payload, updatedPartition);
}

function addIconsToPartition(payload, state) {
    const updatedPartition = updateObject(
        state[payload.tIndex].children[payload.pIndex],
        {children: state[payload.tIndex].children[payload.pIndex].children.filter(el => !payload.icons.includes(el)).concat(payload.icons)}
    );
    return replacePartition(state, payload, updatedPartition);
}

function addIconsToSubpartition(payload, state) {
    const updatedSubpartition = updateObject(
        state[payload.tIndex].children[payload.pIndex].children[payload.sIndex],
        {children: state[payload.tIndex].children[payload.pIndex].children[payload.sIndex].children
            .filter(el => !payload.icons.includes(el)).concat(payload.icons)
        }
    );
    const updatedPartition = updateObject(
        state[payload.tIndex].children[payload.pIndex],
        {children: replaceAt(state[payload.tIndex].children[payload.pIndex].children, payload.sIndex, updatedSubpartition)}
    );
    return replacePartition(state, payload, updatedPartition);
}

function replacePartition(state, payload, newPartition) {
    const updatedTab = updateObject(
        state[payload.tIndex],
        {children: replaceAt(state[payload.tIndex].children, payload.pIndex, newPartition)}
    );
    return replaceAt(state, payload.tIndex, updatedTab);
}

import React from "react";

import MapAndLinkDownloader from "./MapAndLinkDownloader";
import LinkParser from "./LinkParser";


export default function () {
    return <div className="main-project-analytics-container" id="main-project-analytics-other-container">
        <div>
            <MapAndLinkDownloader/>
            <LinkParser/>
        </div>
    </div>;
}
import React, {useEffect, useState} from "react";
import Tooltip from "react-tooltip-lite";

import {useHintMode} from "../studio/customHooks/useContextHintMode";
import {useLocalization} from "../customHooks/useContextLocalization";


export function Hint ({content, className, direction, padding, tipContentHover, styles, children}) {
    return <Tooltip
        content={<span className="tooltip-content">{content}</span>}
        className={className || ""}
        arrow={false}
        direction={direction}
        tipContentHover={tipContentHover}
        padding={padding}
        styles={styles}
    >
        {children}
    </Tooltip>;
}


export default function tooltipHOC (ChildComponent, customWrapperStyles, params) {
    return ({tooltipContent, tooltipStyle, ...props}) => {
        const hintMode = useHintMode();
        const locale = useLocalization();

        const [shouldShowHint, setShouldShowHint] = useState(true);

        //This is needed to re-render the tooltip when changing the localization
        //See more: https://github.com/bsidelinger912/react-tooltip-lite/issues/31
        useEffect(() => {
            const showHint = () => {
                setShouldShowHint(true);
            };

            setShouldShowHint(false);
            setTimeout(showHint, 200);
        }, [locale?.get]);

        return (!hintMode?.get && tooltipContent && shouldShowHint)
            ? (<Hint
                content={tooltipContent}
                className="no-pointer-events-tooltip"
                direction={params?.direction || "bottom-start"}
                padding={params?.padding || "0"}
                tipContentHover={false}
                styles={customWrapperStyles || tooltipStyle || {}}
            >
                <ChildComponent {...props}/>
            </Hint>)
            : (<ChildComponent {...props}/>);
    }
}

import React from "react";
import {useDispatch} from "react-redux";

import {useReduxData} from "../../../../customHooks/useContextReduxData";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";

import {ReactComponent as CopyObjectStyle} from "../images/second-block/copy.svg";


const IconCopyStyle = tooltipHOC(
    hintModeTooltipHOC(CopyObjectStyle, "secondToolBlockCopyStyle"),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () {
    const locale = useLocalization();
    const dispatch = useDispatch();
    const {figures, isEditMode, isWatchMode, selectedFigureUuids} = useReduxData();

    const copyFigureParameters = () => {
        const figure = figures.get(selectedFigureUuids[0]).toJS();
        dispatch({type: "color", value: figure["color"]});
        dispatch({type: "transparency", value: figure["transparency"]});
        if (figure["fontColor"]) {
            dispatch({type: "fontColor", value: figure["fontColor"]});
            dispatch({type: "fontSize", value: figure["fontSize"]});
        }
        if (figure["thickness"]) {
            dispatch({type: "thickness", value: figure["thickness"]});
            dispatch({type: "dashLength", value: figure["dashLength"]});
        }
        if (figure["volume"]) {
            dispatch({type: "volume", value: figure["volume"]});
        }
    };

    return <div
        className={`tool-block-big-item${
            !isWatchMode && isEditMode && selectedFigureUuids.length === 1 ? "" : " disabled"
        }`}
        id="header-copy-style-button"
        onClick={copyFigureParameters}
    >
        <IconCopyStyle tooltipContent={locale?.get.studio.header.secondToolBlock.copyStyle}/>
    </div>;
}

import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import SvgEmojis from "./SvgEmojis";
import SvgText from "./SvgText";
import SvgFlags from "./SvgFlags";
import Link from "./Link";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useIconSet} from "../../../customHooks/useContextIconSet";
import {useReduxData} from "../../customHooks/useContextReduxData";
import useCooTransformation from "../canvas/customHooks/useCooTransformation";
import {parseIconNode} from "../../utils/parseIconNode";
import {getPreferredFontColorByBackgroundColor} from "../../utils/color";


export default function PointFeature({figure, kind, isMouseDown}) {
    const locale = useLocalization();
    const {get: {parameters, iconNodes}} = useIconSet();
    const {checkIfPointFeatureSelected, isEditMode, isValidationAnimationShow, scale} = useReduxData();
    const isPointFeatureSelected = checkIfPointFeatureSelected(figure);
    const {transformXForCanvas, transformYForCanvas} = useCooTransformation();

    const dispatch = useDispatch();

    const [viewBox, setViewBox] = useState("0 0 60 60");
    const [fillRule, setFillRule] = useState("nonzero");
    const [clipRule, setClipRule] = useState("nonzero");
    const [figureNode, setFigureNode] = useState(<g/>);

    useEffect(() => {
        if (figure.get("tool").split("-")[0] === "i") {
            if (iconNodes[figure.get("tool")]) {
                let iconNode = iconNodes[figure.get("tool")];
                const {figure: figureNode_, parameters} = parseIconNode(iconNode);
                const viewBox = parameters.viewBox;
                if (parameters.fillRule) {
                    setFillRule(parameters.fillRule);
                }
                if (parameters.clipRule) {
                    setClipRule(parameters.clipRule);
                }
                setViewBox(`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`);
                setFigureNode(figureNode_);
            } else {
                setFigureNode(<g className="figure">
                    <path d="M 10,20 L 50,20 L 50,60 L 10,60 L 10,20"/>
                </g>);
            }
        }
    }, [iconNodes]);

    useEffect(() => {
        function func() {
            if (isValidationAnimationShow) {
                dispatch({type: "isValidationAnimationShow", value: false});
            }
        }
        setTimeout(func, 1000);
    }, [isValidationAnimationShow]);

    //show and hide description window
    const handleMouseLeaveOnPointFeature = () => !isMouseDown
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});

    const handleMouseEnterOnPointFeature = () => !isMouseDown
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: figure.get("uuid")});

    //hide description on figure move
    const handleMouseDownOnPointFeature = event => event.button === 0
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});

    //show description on moving ended up
    const handleMouseUpOnPointFeature = event => {
        if (event.button === 0) {
            dispatch({type: "figureUuidDescriptionPopupShownFor", value: figure.get("uuid")})
        }
    };

    const transformedFigureX = transformXForCanvas(figure.get("x")),
        transformedFigureY = transformYForCanvas(figure.get("y"));
    const volume = figure.get("volume") * scale;

    const figureCenterX = transformedFigureX + 2 * volume;

    const fontSize = figure.get("fontSize") === "standard"
        ? volume / 2
        : (figure.get("fontSize") !== "" ? figure.get("fontSize") * 2 : 16);
    const langCoefficient = locale?.get.name === "chinese" ? 40/23 : 1;
    const physicalCharSize = fontSize / 1.7 * langCoefficient;
    const textLength = figure.get("name").length * physicalCharSize;

    return (figure && parameters) ? <g
        onMouseEnter={handleMouseEnterOnPointFeature}
        onMouseLeave={handleMouseLeaveOnPointFeature}
        onMouseDown={handleMouseDownOnPointFeature}
        onMouseUp={handleMouseUpOnPointFeature}
    >
        <svg
            id={figure.get("uuid")}
            className={kind === "separate" ? "figure" : "group"}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox}
            clipRule={clipRule}
            x={transformedFigureX + 1.5 * volume}
            y={transformedFigureY + volume}
            height={volume}
            width={volume}
            style={{
                fill: figure.get("color") == null ? "white" : figure.get("color"),
                fillRule: fillRule,
                stroke: figure.get("name") === "" && parameters[figure.get("tool")]?.isNameRequired ? "red" : "black",
                animationName: (isValidationAnimationShow && parameters[figure.get("tool")]?.isNameRequired
                    && (figure.get("name") === "" ||
                        (figure.get("description") === "" && figure.get("emoji").length === 0)
                    )
                ) ? "highlight" : "",
                animationDuration: "1s",
                animationFillMode: "backwards",
                opacity: figure.get("transparency")
            }}
        >{figureNode}</svg>
        <SvgText
            id={figure.get("uuid")}
            className={kind === "separate" ? "text" : "group"}
            fillColor={figure.get("fontColor")}
            strokeColor={getPreferredFontColorByBackgroundColor(figure.get("fontColor"))}
            fontSize={fontSize + "px"}
            text={figure.get("name")}
            x={figureCenterX - textLength / 2}
            y={transformedFigureY + 2 * volume - 15 + physicalCharSize}
            textLength={textLength}
            opacity={figure.get("transparency")}
        />
        <SvgEmojis
            id={figure.get("uuid")}
            className={kind === "separate" ? "figure" : "group"}
            x={figureCenterX - (
                figure.get("emoji")?.length <= 5 ? figure.get("emoji").length * 10 : 50
            ) * (volume / scale / 40)}
            y={transformedFigureY + volume - 40 - (volume / scale - 40)}
            opacity={figure.get("transparency")}
            text={figure.get("emoji")?.map(em => em.emoji.native) || []}
            width={`${10 * volume / scale / 2.5}`}
            height={volume}
            fontSize={16}
            scaleCoefficient={volume / 40 / scale}
        />
        <SvgFlags
            id={figure.get("uuid")}
            className={kind === "separate" ? "figure" : "group"}
            x={transformedFigureX + 2.6 * volume}
            y={transformedFigureY + 1.1 * volume}
            height={volume / 4}
            width={volume / 4}
            flagInterval={volume / 3.2}
            flagsToRender={figure.get("flags")}
        />
        {isEditMode && isPointFeatureSelected && <rect
            id={figure.get("uuid")}
            className="figure-neighborhood"
            x={transformedFigureX + Math.min(1.5 * volume, 2 * volume - textLength / 2) - 8}
            y={transformedFigureY + volume - 8}
            rx="0.625rem"
            ry="0.625rem"
            fill="#FF983D33" // this is 20% of opacity
            stroke="#FF983D"
            strokeWidth="1"
            // 31 = 15 + 16, where 15 is a distance between icon and text, 16 is a paddings (8 per side)
            height={volume + 31 + physicalCharSize}
            width={Math.max(volume, textLength) + 16}
        />}
        {figure.get("link") && <Link
            id={figure.get("uuid")}
            x={transformedFigureX + 2.2 * volume}
            y={transformedFigureY + volume}
            height={volume / 4}
            width={volume / 4}
            linkAddress={figure.get("link")}
        />}
    </g> : null;
}

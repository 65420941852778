import {useEffect} from "react";
import {useDrag} from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";


export default function useNodeDragSource(type, name, inputs, outputs) {
    const [{}, drag, dragPreview] = useDrag(() => ({
        type: type,
        item: {name: name, inputs: inputs, outputs: outputs},
        collect: () => ({})
    }), [type, name, inputs, outputs]);

    useEffect(() => { //hide default preview
        dragPreview(getEmptyImage(), {captureDraggingState: true});
    }, []);

    return drag;
}

import {Map} from 'immutable';

const initialState = Map({
    backgroundColor: "#FAFEFF",
    canvasScrollX: 0, // here is scroll value without viewBox shifting
    canvasScrollY: 0, // here is scroll value without viewBox shifting
    clipboard: [],
    color: '#FFFFFF',
    dashLength: 0,
    figureCenter: {x: 0, y: 0},
    figureUuidDescriptionPopupShownFor: "",
    fontColor: '#607d8b',
    fontSize: 10,
    isContextMenuShow: "no",
    isEditMode: true,
    isGridShow: false,
    isValidationAnimationShow: false,
    isWatchMode: false,
    mouseXAtTheCopyTime: 0,
    mouseYAtTheCopyTime: 0,
    canvasScrollXAtCopyTime: 0,
    canvasScrollYAtCopyTime: 0,
    openContextMenuTool: "",
    openedMenuItemNumber: "-1",
    scale: window.innerWidth / 1220,
    selectedConnectors : [],
    selectedFigureUuids : [],
    startTime: new Date().getTime(),
    thickness: 2,
    tool: "",
    transparency: 1,
    volume: 40
});

export default (state=initialState, action) => {
    if (action && action.type && reducers()[action.type]) {
        return reducers()[action.type](action);
    } else {
        return state;
    }

    function reducers() {
        return {
            loadMenuState: (action) => Map(action.data),
            backgroundColor: action => state.set("backgroundColor", action.value),
            clearMenuState: (action) => Map({...initialState.toJS(), ...action.menuParamsToSet}),
            isGridShow: (action) => state.set("isGridShow", action.value),
            isWatchMode: (action) => state.set("isWatchMode", action.value),
            isEditMode: (action) => state.set("isEditMode", action.value),
            isContextMenuShow: (action) => (["group", "figure", "figure-neighborhood", "text", "no"].includes(action.value)
                ? state.set("isContextMenuShow", action.value)
                : state
            ),
            isValidationAnimationShow: (action) => state.set("isValidationAnimationShow", action.value),
            openContextMenuTool: (action) => state.set("openContextMenuTool", action.value),
            scale: (action) => state.set("scale", action.value),
            canvasScrollX: (action) => state.set("canvasScrollX", action.value),
            canvasScrollY: (action) => state.set("canvasScrollY", action.value),
            clipboard: (action) => state.set("clipboard", action.value),
            selectedFigureUuids: (action) => state.set("selectedFigureUuids", action.value),
            selectedConnectors: (action) => state.set("selectedConnectors", action.value),
            tool: (action) => state.set("tool", action.value),
            color: (action) => state.set("color", action.value),
            fontColor: (action) => state.set("fontColor", action.value),
            openedMenuItemNumber: (action) => state.set("openedMenuItemNumber", action.value),
            figureUuidDescriptionPopupShownFor: (action) => state.set("figureUuidDescriptionPopupShownFor", action.value),
            figureCenter: (action) => state.set("figureCenter", action.value),
            mouseXAtTheCopyTime: (action) => state.set("mouseXAtTheCopyTime", action.value),
            mouseYAtTheCopyTime: (action) => state.set("mouseYAtTheCopyTime", action.value),
            canvasScrollXAtCopyTime: (action) => state.set("canvasScrollXAtCopyTime", action.value),
            canvasScrollYAtCopyTime: (action) => state.set("canvasScrollYAtCopyTime", action.value),
            startTime: (action) => state.set("startTime", action.value),
            fontSize: (action) => state.set("fontSize", action.value),
            volume: (action) => state.set("volume", +action.value),
            thickness: (action) => state.set("thickness", action.value),
            dashLength: (action) => state.set("dashLength", action.value),
            transparency: (action) => state.set("transparency", action.value),
        }
    }
}

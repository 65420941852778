import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ActionCreators} from 'redux-undo';

import {useReduxData} from "./useContextReduxData";


const UndoRedoContext = React.createContext();


export const UndoRedoProvider = ({children}) => {
    const [shouldSwitchState, setShouldSwitchState] = useState();
    const {
        figureUuidDescriptionPopupShownFor,
        nextFiguresUuids,
        previousFiguresUuids,
        selectedConnectors,
        selectedFigureUuids
    } = useReduxData();
    const dispatch = useDispatch();

    //if jumpers will be inside undo/redo, then jump will be possible to perform before than menu was prepared
    useEffect(() => {
        if (shouldSwitchState === "undo") {
            dispatch(ActionCreators.jump(-1));
            setShouldSwitchState("");
        } else if (shouldSwitchState === "redo") {
            dispatch(ActionCreators.jump(1));
            setShouldSwitchState("");
        }
    }, [shouldSwitchState]);

    const undo = () => {
        let filteredFiguresUuids = selectedFigureUuids.filter(uuid => previousFiguresUuids.includes(uuid));
        dispatch({type: "selectedFigureUuids", value: filteredFiguresUuids});
        if (!previousFiguresUuids.includes(figureUuidDescriptionPopupShownFor)) {
            dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});
        }
        let filteredConnectors = selectedConnectors.filter(id => previousFiguresUuids.includes(id.split("|")[0]));
        dispatch({type: "selectedConnectors", value: filteredConnectors});

        setShouldSwitchState("undo");
    };

    const redo = () => {
        let filteredFiguresUuids = selectedFigureUuids.filter(uuid => nextFiguresUuids.includes(uuid));
        dispatch({type: "selectedFigureUuids", value: filteredFiguresUuids});
        if (!nextFiguresUuids.includes(figureUuidDescriptionPopupShownFor)) {
            dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});
        }
        let filteredConnectors = selectedConnectors.filter(id => nextFiguresUuids.includes(id.split("|")[0]));
        dispatch({type: "selectedConnectors", value: filteredConnectors});

        dispatch(ActionCreators.jump(1));
    };

    return <UndoRedoContext.Provider value={{undo, redo}}>{children}</UndoRedoContext.Provider>;
};


export const useUndoRedo = () => useContext(UndoRedoContext);

import React from "react";
import ReactSelect, {components} from "react-select";

import {ReactComponent as IconDropdown} from "./images/icon-dropdown.svg";


const ClearIndicator = () => "";
const DropdownIndicator = () => <IconDropdown
    className="product-from-icon-search"
    width="0.75rem"
    height="0.5rem"
/>;
const Option = ({children, ...props}) => {
    return <span className={props.isSelected
        ? "modal-sharing-selected-option"
        : props.data.value === "nothing" ? "modal-sharing-remove-option" : "modal-sharing-option"
    }>
        <components.Option {...props}>{children}</components.Option>
    </span>;
};


export default function ({setSharingMode, options, defaultOption}) {
    const changeValue = option => setSharingMode(option?.value || "");

    return <ReactSelect
        isPlanar
        isSearchable={false}
        className="sharing-single-select"
        options={options}
        components={{ClearIndicator, DropdownIndicator, Option}}
        defaultValue={defaultOption}
        onChange={changeValue}
    />;
}

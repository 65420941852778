import React from "react";
import {useHistory} from "react-router-dom";
import {useMutation, useQueryClient} from "react-query";

import Rating from "../Rating";
import Icon from "../Icon";
import {PublishButton, UnpublishButton, UpdateButton} from "./ProductCardButtons";
import ProductCardPhoto from "./ProductCardPhoto";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useApiClient} from "../../../customHooks/useApiClient";
import {compareTags, cropString, getFieldContent} from "../utils";

import styles from "./card.module.css";


function Card ({tags, product, hasUser, onEdit, onDelete, localeAbbr}) {
    const isUpdateAvailable = product.has_user_old_version && !product.has_user_this_version;

    return <div className={styles.wrapper}>
        {hasUser && <div className={styles.managePanel}>
            <span>{product.can_user_update && <Icon icon={UpdateButton} onClick={onEdit}/>}</span>
            <span><Icon icon={product.published ? UnpublishButton : PublishButton} onClick={onDelete}/></span>
        </div>}
        <a href={`/store/product/${product.id}`}>
            <div className="store-product-preview">
                <ProductCardPhoto
                    id={product.id}
                    version={product.version_name}
                    type={product.type}
                    isUpdate={isUpdateAvailable}
                />
            </div>
        </a>
        <a href={`/store/product/${product.id}`} className={styles.card}>
            <span className={styles.name}>
                {cropString(getFieldContent(product.name, localeAbbr.toLowerCase()), 40)}
            </span>
            <span className={styles.text}>{tags.join(', ')}</span>
            <div className={styles.rating}>
                <Rating n={product.rating} shouldRenderNormalStars={false}/>
            </div>
            <span className={styles.price}>
                {localeAbbr === "EN"
                    ? "₽" + (isUpdateAvailable ? product.update_price : product.price)
                    : (isUpdateAvailable ? product.update_price : product.price) + " ₽"
                }
            </span>
        </a>
    </div>;
}


export default function ProductCard({product}) {
    const locale = useLocalization();
    const user = useCurrentUser();
    const history = useHistory();
    const queryClient = useQueryClient();
    const api = useApiClient();

    const updateProduct = product_id => () => history.push(`/product/update/${product_id}`);

    const unpublishMutation = useMutation(
        (product_id) => api.put(`/api/v2/store/product_version/${product_id}/unpublish/`),
        {onSuccess: () => queryClient.refetchQueries(["publicationDependent"], {active: true})}
    );
    const unpublishProduct = product_id => (() => unpublishMutation.mutate(product_id));

    const publishMutation = useMutation(
        (product_id) => api.put(`/api/v2/store/product_version/${product_id}/publish/`),
        {onSuccess: () => queryClient.refetchQueries(["publicationDependent"], {active: true})}
    );
    const publishProduct = product_id => (() => publishMutation.mutate(product_id));

    return locale?.get && product && <Card
        key={product.id}
        product={product}
        localeAbbr={locale.get.abbr}
        tags={[cropString(product.tags.map(tag => tag.name).sort(compareTags(locale)).join(", "), 44)]}
        hasUser={product.author_id === user?.id}
        onEdit={updateProduct(product.id)}
        onDelete={product.published ? unpublishProduct(product.id) : publishProduct(product.id)}
    />;
}

import Path from "../../utils/path";


export const isMapChanged = (state) => {
    return !['saveToServer', 'createMap', 'saveToComputer'].includes(state.get("lastAction").type);
}

export const convertStateToSave = (state) => {
    let actionHistory = [];
    for (let i = 0; i < state.get("main").past.length; i++) {
        actionHistory.push(state.get("main").past[i].get("lastAction"));
    }
    return {
        menu: state.get("menu").toJS(),
        main: state.get("main").present.toJS(), //data.main is only needed to view the map by the user
        actionHistory: actionHistory
    };
};


export const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};


export const takePngSnapshotOfTheCanvas = async (x, y, width, height) => {
    const el = document.getElementById("svg-canvas");
    const svgCropped = el.cloneNode(true);
    svgCropped.setAttribute("viewBox", `${x} ${y} ${width} ${height}`);
    svgCropped.setAttribute("style", "");
    svgCropped.setAttribute("width", width);
    svgCropped.setAttribute("height", height);

    const svg = new XMLSerializer().serializeToString(svgCropped);
    const blob = new Blob([svg],{type:'image/svg+xml;charset=utf-8'});
    let blobURL = URL.createObjectURL(blob);

    // console.log(blobURL);
    const readyCanvas = await new Promise(resolve => {
        let image = new Image();
        image.onload = () => {
            let canvas = document.createElement('canvas');
            canvas.width = width * 2; //x2 factor to increase resolution
            canvas.height = height * 2;
            let context = canvas.getContext('2d');
            context.drawImage(image, 0, 0, width * 2, height * 2);
            resolve(canvas);
        };
        image.onerror = err => console.log(err);
        image.src = blobURL;
    });
    return await new Promise(resolve => readyCanvas.toBlob(resolve));
}

export const getBoundingBoxOfMap = figures => {  // coordinates in map format, not canvas
    let minX, minY, maxX, maxY;
    const jsFigures = figures.toJS();
    Object.keys(jsFigures).map(uuid => {
        const figure = jsFigures[uuid];
        const figureType = figure.tool.split("-")[0];
        if (["p", "c", "i", "r", "t"].includes(figureType)) {
            if (figureType === "i") {
                if (minX === undefined || figure.x + figure.volume * 1.5 < minX) {
                    minX = figure.x + figure.volume * 1.5;
                }
                if (minY === undefined || figure.y + figure.volume < minY) {
                    minY = figure.y + figure.volume;
                }
                const endX = figure.x + figure.volume * 2.5;
                if (maxX === undefined ||  endX > maxX) {
                    maxX = endX;
                }
                const endY = figure.y + figure.volume * 2;
                if (maxY === undefined || endY > maxY) {
                    maxY = endY;
                }
            } else if (figureType === "t") {
                const fromBorderToCenterDistanseOfText = figure.fontSize * figure.name.length / 2;
                if (minX === undefined || figure.x - fromBorderToCenterDistanseOfText < minX) {
                    minX = figure.x - fromBorderToCenterDistanseOfText;
                }
                if (maxX === undefined || figure.x + fromBorderToCenterDistanseOfText > maxX) {
                    maxX = figure.x + fromBorderToCenterDistanseOfText;
                }
                if (minY === undefined || figure.y < minY) {
                    minY = figure.y;
                }

                if (maxY === undefined || figure.y + figure.fontSize > maxY) {
                    maxY = figure.y + figure.fontSize;
                }
            } else {
                if (minX === undefined || figure.x < minX) {
                    minX = figure.x;
                }
                if (minY === undefined || figure.y < minY) {
                    minY = figure.y;
                }
                if (figureType === "r") {
                    const endX = figure.x + figure.width;
                    if (maxX === undefined || endX > maxX) {
                        maxX = endX;
                    }
                    const endY = figure.y + figure.height;
                    if (maxY === undefined || endY > maxY) {
                        maxY = endY;
                    }
                } else {
                    const figureBoundingBox = Path.getPathBoundingBox(figure.points);
                    const endX = figure.x + figureBoundingBox.endX;
                    if (maxX === undefined || endX > maxX) {
                        maxX = endX;
                    }
                    const endY = figure.y + figureBoundingBox.endY;
                    if (maxY === undefined || endY > maxY) {
                        maxY = endY;
                    }
                }
            }
        } else if (figureType === "a") {
            const fromX = typeof figure.fromX === "number" ? figure.fromX : 0;
            const toX = typeof figure.toX === "number" ? figure.toX : 0;
            const fromY = typeof figure.fromY === "number" ? figure.fromY : 0;
            const toY = typeof figure.toY === "number" ? figure.toY : 0;
            const [startX, endX] = fromX < toX ? [fromX, toX] : [toX, fromX];
            const [startY, endY] = fromY < toY ? [fromY, toY] : [toY, fromY];
            if (minX === undefined || startX < minX) {
                minX = startX;
            }
            if (maxX === undefined || endX > maxX) {
                maxX = endX;
            }
            if (minY === undefined || startY < minY) {
                minY = startY;
            }
            if (maxY === undefined || endY > maxY) {
                maxY = endY;
            }
        }
    });
    if (minX === undefined && maxX === undefined && minY === undefined && maxY === undefined) {
        return {x: -50, y: -50, width: 100, height: 100};
    }
    const fieldWidth = 20;
    return {x: minX - fieldWidth, y: minY - fieldWidth, width: maxX - minX + 2 * fieldWidth, height: maxY - minY + 2 * fieldWidth};
}
import {useCurrentProject} from "./useContextCurrentProject";
import {useModal} from "../../customHooks/useContextModal";


export default function () {  //TODO: remove it, because it not so useful now
    const {get: currentProject} = useCurrentProject();
    const modal = useModal();

    const tryToShowReward = () => modal.set(currentProject.reward?.modal || "");

    return {tryToShowReward};
}

import {useEffect, useState, useMemo} from "react";

import {useMap} from "../../../customHooks/useContextMap";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useApiClient} from "../../../../customHooks/useApiClient";
import {useCurrentProject} from "../../../customHooks/useContextCurrentProject";


export default function ({classificationAlgorithmUsed, userColor, isAlbumFormat}) {
    const [classificationResult, setClassificationResult] = useState([["", 0], ["", 0], ["", 0]]);

    const map = useMap();
    const locale = useLocalization();
    const api = useApiClient();
    const {get: currentProject} = useCurrentProject();

    const classificationNote = useMemo(
        () => currentProject?.reward?.classificationNote?.[locale.get.abbr.toLowerCase()] || "",
        [currentProject]
    );

    useEffect(async () => {
        if (["region", "concept"].includes(classificationAlgorithmUsed)) {
            if (map.mapId && map.mapVersionNum) {
                setClassificationResult((await api.get(
                    `/api/v2/reward/postcard/${classificationAlgorithmUsed}/?map_id=${map.mapId}&mv_num=${
                        map.mapVersionNum
                    }`
                )).data.map(el => [el[0], el[1] + "%"]));
            } else {
                setClassificationResult([["", ""], ["", locale.get.studio.postcard.loading], ["", ""]]);
            }
        }
    }, [map.mapId, map.mapVersionNum, locale, classificationAlgorithmUsed]);


    return <div
        className={`postcard-statistics-table-${isAlbumFormat ? "horizontal" : "vertical"}`}
        style={{
            color: userColor,
            borderColor: userColor
        }}
    >
        <span className="postcard-percent-text first-percent" style={{color: userColor}}>
            {classificationResult[0][1]}
        </span>
        <span className="postcard-percent-text second-percent" style={{color: userColor}}>
            {classificationResult[1][1]}
        </span>
        <span className="postcard-percent-text third-percent" style={{color: userColor}}>
            {classificationResult[2][1]}
        </span>
        <span className="postcard-explanation-text first-explanation" style={{color: userColor}}>
            {classificationResult[0][0]}
        </span>
        <span className="postcard-explanation-text second-explanation" style={{color: userColor}}>
            {classificationResult[1][0]}
        </span>
        <span className="postcard-explanation-text third-explanation" style={{color: userColor}}>
            {classificationResult[2][0]}
        </span>
        <span className="postcard-footnote" style={{color: userColor}}>
            {classificationNote}
        </span>
    </div>;
};
import {useState} from "react";
import {useParams} from "react-router-dom";

import UserSelector from "../../components/UserSelector";
import {useLocalization} from "../../customHooks/useContextLocalization";
import {useApiClient} from "../../customHooks/useApiClient";


export default function () {
    const [users, setUsers] = useState([]);

    const locale = useLocalization();
    const api = useApiClient();
    const {id} = useParams();

    const addAnalytics = async () => {
        const answer = (await api.put(`/api/v2/projects/${id}/add_analysts`, {users})).data;
        alert(answer);
    }

    return <div className="project-add-analysts">
        <UserSelector
            users={users}
            setUsers={setUsers}
            className="studio-footer-share-field"
            fieldName="users"
        />
        <input
            type="button"
            className="my-projects-create-project-btn"
            value={locale?.get?.project.certainProject.add}
            onClick={addAnalytics}
        />
    </div>;
}

import {getUuidHierarchy} from "../../../utils/figureHierarchy";
import Path from "../../../utils/path";


export function getTransformationalConnectorOpacity(figures, parentFigureUuid, selectedFigureUuids) {
    let opacity_ = 0;
    if (figures) {
        getUuidHierarchy(figures, parentFigureUuid).map(uuid => {
            if (selectedFigureUuids.indexOf(uuid) !== -1) {
                opacity_ = 1;
            }
        });
    }
    return opacity_;
}

export function getModifiedCurvesForTransformationalConnectors(path, scale) {
    const parsed = Path._parse(Path.transform(path, 20, 20, scale, scale));
    return [{command: "M", points: [{x: parsed[0].mCoo.x, y: parsed[0].mCoo.y}]}].concat(parsed[0].curves);
}
import {Record} from 'immutable';

class Group extends Record({
    uuid: undefined,
    parentUuid: undefined,
	tool: "g-s-72",
	children: [],
	name: undefined,
	description: undefined,
    layout: undefined,
    orderIndex: undefined
}) {
}

export default Group;

import {toast} from "react-toastify";

import {useApiClient} from "../../customHooks/useApiClient";
import {useCurrentProject} from "./useContextCurrentProject";
import {useLocalization} from "../../customHooks/useContextLocalization";
import {useModal} from "../../customHooks/useContextModal";


export default function () {
    const api = useApiClient();
    const {get: currentProject} = useCurrentProject();
    const locale = useLocalization();
    const modal = useModal();

    const tryToShowSurvey = async (shouldWarnIfConflict = false) => {
        try {
            await api.get(`/api/v2/surveys/?current_project_id=${currentProject.id}`);
        } catch (e) {
            if (e.response.status === 404) {
                try {
                    modal.set("survey");
                } catch(err) {
                    if (err.response.status === 409 && shouldWarnIfConflict) {
                        toast.warn(locale?.get.studio.header.project.surveyWarn);
                    } else if (err.response.status === 404) {
                        // survey scheme not found
                    }
                }
            }
        }
    };

    return {tryToShowSurvey};
}

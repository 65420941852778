import React from "react";
import {v4 as uuid} from "uuid";
import {useDispatch} from "react-redux";

import {useReduxData} from "../../../customHooks/useContextReduxData";
import {useCanvasBoundingClientRect} from "../../../customHooks/useContextCanvasBoundingClientRect";
import {useIconSet} from "../../../../customHooks/useContextIconSet";
import useCooTransformation from "./useCooTransformation";
import getTimeElapsedSince from "../../getTimeElapsedSince";
import {pointFeatureSchema} from "../../../utils/validators";


export default function usePointFeatureDrawing() {
    const dispatch = useDispatch();
    const {color, fontColor, fontSize, startTime, tool, transparency, volume} = useReduxData();
    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const {get: {parameters}} = useIconSet();
    const {transformYForMap, transformXForMap} = useCooTransformation();

    const createPointFeature = event => {
        let canvasX = canvasBoundingClientRect.get.left;
        let canvasY = canvasBoundingClientRect.get.top;
        if (typeof canvasX !== "number" || typeof canvasY !== "number") {
            return;
        }
        let newFigureUuid = uuid();
        let newFigureObject = {
            uuid: newFigureUuid,
            name: "",
            link: "",
            description: "",
            emoji: [],
            flags: [],
            x: transformXForMap(event.clientX - canvasX) - 2 * volume,
            y: transformYForMap(event.clientY - canvasY) - 1.5 * volume,
            volume,
            tool,
            color,
            fontColor,
            fontSize,
            transparency,
            layout: +parameters[tool]?.layout || 50,
            orderIndex: Math.round(new Date().getTime())
        };

        if (!pointFeatureSchema.isValidSync(newFigureObject)) {
            return;
        }

        dispatch({...newFigureObject, type: "addPointFeature", time: getTimeElapsedSince(startTime)});
        dispatch({type: "selectedFigureUuids", value: [newFigureUuid]});
    }

    return {createPointFeature};
}

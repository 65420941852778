import React from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {Formik, Form, getIn, Field, ErrorMessage} from "formik";
import {toast} from "react-toastify";
import * as Yup from "yup";

import LinkButton from "../LinkButton";
import LoadingPage from "../LoadingPage";
import Page404 from "../Page404";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {useApiClient} from "../../../customHooks/useApiClient";
import {getStyles, ErrorView} from "../../../forms/form_fields";
import {getFieldContent} from "../utils";

import "./payment.css";


export default function Payment() {
    const {id} = useParams();

    const api = useApiClient();
    const user = useCurrentUser();
    const locale = useLocalization();

    const orderQuery = useQuery(
        ["store/order/get", id, user],
        () => api.get(`/api/v2/store/order/${id}`)
    );
    const order = orderQuery.data?.data;

    const paymentScheme = Yup.object({
        card_number: Yup.string(locale.get.store.payment.validation.shouldString)
            .required(locale.get.store.payment.validation.required),
        card_holder_name: Yup.string()
            .required(locale.get.store.payment.validation.required),
        validity: Yup.string()
            .matches(/\d\d\/\d\d/, locale.get.store.payment.validation.validity)
            .required(locale.get.store.payment.validation.required),
        cvc: Yup.number(locale.get.store.payment.validation.shouldNumber)
            .required(locale.get.store.payment.validation.required),
    });

    const sendPaymentForm = async (values, {setSubmitting, setErrors}) => {
        try {
            toast.success("Оплата прошла успешно"); // TODO: remove this line after payment will be implemented
        } catch (err) {
            if (err.response.status === 400 && err.response.data.detail) {
                setErrors({email: locale?.get.auth[err.response.data.detail.email]});
            }
        }
        setSubmitting(false);
    };

    return locale?.get && ([orderQuery.status].includes("loading")
        ? <LoadingPage/>
        : (([orderQuery.status].includes("error") || !order.name)
            ? <Page404/>
            : <div className="payment-form">
                <div className="payment-wrapper">
                    <div className="payment-brief-container">
                        <p>{locale.get.store.payment.order} №{id}</p>
                        <label>{locale.get.store.payment.name}</label>
                        <span>
                            {`${getFieldContent(order.name, locale.get.abbr.toLowerCase())} ${order.version_name}`}
                        </span>
                        <label>{locale.get.store.payment.cost}</label>
                        <label>{order.cost}</label>
                    </div>

                    <Formik
                        initialValues={{
                            card_number: "",
                            card_holder_name: "",
                            validity: "",
                            cvc: "",
                        }}
                        validationSchema={paymentScheme}
                        onSubmit={sendPaymentForm}
                        render={formProps => {
                            return <Form>
                                <div className="payment-card-data-container">
                                    <label className="long-cell">{locale.get.store.payment.cardNumber}</label>
                                    <div className="long-cell">
                                        <Field
                                            className="payment-form-field"
                                            name="card_number"
                                            type="text"
                                            placeholder="0000 0000 0000 0000"
                                            value={formProps.values.card_number}
                                            onChange={event => formProps.setFieldValue(
                                                "card_number",
                                                event.target.value.replace(/[^\d]/g, "").replace(/(.{4})/g,"$1 ")
                                            )}
                                            style={getIn(formProps.touched, "card_number")
                                                && getStyles(formProps.errors, "card_number")
                                            }
                                        />
                                        <ErrorMessage component={ErrorView} name="card_number"/>
                                    </div>
                                    <label className="long-cell">{locale.get.store.payment.cardHolderName}</label>
                                    <div className="long-cell">
                                        <Field
                                            className="payment-form-field"
                                            name="card_holder_name"
                                            type="text"
                                            placeholder="CARD HOLDER"
                                            value={formProps.values.card_holder_name}
                                            onChange={event => formProps.setFieldValue(
                                                "card_holder_name", event.target.value.toUpperCase()
                                            )}
                                            style={getIn(formProps.touched, "card_holder_name")
                                                && getStyles(formProps.errors, "card_holder_name")
                                            }
                                        />
                                        <ErrorMessage component={ErrorView} name="card_holder_name"/>
                                    </div>
                                        <label>{locale.get.store.payment.validity}</label>
                                        <label>CVC/CVV</label>
                                        <div className="payment-validity-wrapper">
                                            <Field
                                                className="payment-form-field"
                                                name="validity"
                                                type="text"
                                                placeholder="00/00"
                                                value={formProps.values.validity}
                                                onChange={event => formProps.setFieldValue(
                                                    "validity",
                                                    event.target.value
                                                        .replace(/[^\d]/g, "")
                                                        .replace(/(.{2})/g,"$1/")
                                                        .slice(0, 5)
                                                )}
                                                style={getIn(formProps.touched, "validity")
                                                    && getStyles(formProps.errors, "validity")
                                                }
                                            />
                                            <ErrorMessage component={ErrorView} name="validity"/>
                                        </div>
                                        <div className="payment-cvc-wrapper">
                                            <Field
                                                className="payment-form-field"
                                                name="cvc"
                                                type="number"
                                                placeholder="000"
                                                value={formProps.values.cvc}
                                                onChange={event => formProps.setFieldValue(
                                                    "cvc",
                                                    event.target.value.slice(0, 3)
                                                )}
                                                style={getIn(formProps.touched, "cvc")
                                                    && getStyles(formProps.errors, "cvc")
                                                }
                                            />
                                            <ErrorMessage component={ErrorView} name="cvc"/>
                                        </div>
                                </div>
                                <div className="pay-or-back-container">
                                    <button type="submit" className="store-button">
                                        {locale.get.store.payment.pay}
                                    </button>
                                    <LinkButton
                                        href={`/store/product/${order.product_version_id}`}
                                        text={locale.get.store.payment.goBack}
                                    />
                                </div>
                            </Form>;
                        }}
                    />
                </div>
            </div>
        )
    );
}

import React, {useState, useContext, useEffect} from "react";

import {useApiClient} from "../../customHooks/useApiClient";
import {useMap} from "./useContextMap";


const MapMembersContext = React.createContext();


export const MapMembersProvider = ({children}) => {
    const api = useApiClient();
    const map = useMap();
    const [get, set] = useState([]);

    useEffect(() => {
        refreshMembers();
    }, [map.mapId]);

    const refreshMembers = () => {
        if (map.mapId) {
            api.get(`/api/v2/map/members/?map_id=${map.mapId}`).then(response => set(response.data));
        }
    };

    return (
        <MapMembersContext.Provider value={{get, refreshMembers}}>
            {children}
        </MapMembersContext.Provider>
    );
};


export const useMapMembers = () => useContext(MapMembersContext);

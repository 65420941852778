import React from "react";


import {useLocalization} from "../customHooks/useContextLocalization";

import {ReactComponent as LogoSad} from "../store/images/logo-sad.svg";


export default function () {
    const locale = useLocalization();

    return <div className="page-unsupported-device">
        <LogoSad height="min(15vmin, 110px)" width="min(15vmin, 110px)"/>
        <h1 className="page-404-title">{locale?.get?.studio.unsupportedDevicePage.title}</h1>
        <p className="page-unsupported-device-text">{locale?.get?.studio.unsupportedDevicePage.explanation}</p>
    </div>;
}

import React from "react";

import {ReactComponent as Cross} from "../../../../images/cross-transparent.svg";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";


export default function TabDragPreview({item, tabs}) {
    const locale = useLocalization();
    const tabIndex = tabs.map(el=>el.id).indexOf(item.tabId);
    return <label
        className="account-icons-subpartition-title"
        htmlFor="retractable-subsection-checkbox"
    >
        {tabs[tabIndex]?.name[locale.get.name] || ""}
        {tabs.length > 1 ? <Cross
            className="account-icons-partition-button"
            id={`account-icons-partition-button--1`}
            height="16"
            width="16"
            viewBox="-4 -4 24 24"
            fill="#144066"
            style={{marginLeft: "0.25rem"}}
        /> : null}
    </label>;
};

import React, {useState, useContext} from "react";


const UserViewBoxContext = React.createContext();


export const UserViewBoxProvider = ({children}) => {
    const [get, set] = useState("0 0 0 0");

    return (
        <UserViewBoxContext.Provider value={{get, set}}>
            {children}
        </UserViewBoxContext.Provider>
    );
};


export const useUserViewBox = () => useContext(UserViewBoxContext);

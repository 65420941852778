import React, {useState} from "react";


export default function SnappingConnector({x, y, id, isMouseDown, handleMouseEnter}) {
    const [closingConnectorOpacity, setClosingConnectorOpacity] = useState(0);

    const _handleMouseEnter = event => {
        if (id === "closing-snapping-connector") {
            setClosingConnectorOpacity(1);
            handleMouseEnter(event);
        }
        return event.shiftKey && isMouseDown && handleMouseEnter(event);
    };

    const _handleMouseLeave = () => id === "closing-snapping-connector" && setClosingConnectorOpacity(0);

    return <rect
        className="snapping-connector"
        id={id + "|"}
        x={x - 8}
        y={y - 8}
        rx="0.25rem"
        ry="0.25rem"
        width="16"
        height="16"
        fill="#FF983D"
        stroke="#FAFEFF"
        opacity={id === "closing-snapping-connector" ? closingConnectorOpacity : 1}
        onMouseEnter={_handleMouseEnter}
        onMouseLeave={_handleMouseLeave}
    />;
}

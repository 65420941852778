import React from "react";

import {useLocalization} from "../../../../../customHooks/useContextLocalization";


export default function PartitionDragPreview({item, tabs}) {
    const locale = useLocalization();
    const partitionIndex = tabs[item.tabIndex].children.map(el=>el.id).indexOf(item.partitionId);
    return <div className="account-icons-partition">
        <div className="account-icons-partition-title-container">
            <span className="account-icons-partition-title">
                {tabs[item.tabIndex].children[partitionIndex]?.name[locale.get.name] || ""}
            </span>
        </div>
    </div>;
};

import React, {useEffect, useState} from "react";
import Joyride from "react-joyride";

import useOnboardingParams from "./onboarding/useOnboardingParams";
import {useOnboarding} from "./customHooks/useContextOnboarding";


export default function() {
    const onboarding = useOnboarding();
    const onboardingParams = useOnboardingParams();

    const [onboardingState, setOnboardingState] = useState({
        key: new Date(), // This field makes the tour to re-render when we restart the tour
        run: onboarding.shouldShowOnboarding,
        stepIndex: 0, // Make the component controlled
    });

    useEffect(() => {
        if (onboarding.shouldShowOnboarding !== onboardingState.run) {
            setOnboardingState({ ...onboardingState, run: onboarding.shouldShowOnboarding});
        }
    }, [onboarding.shouldShowOnboarding]);

    const handleJoyrideCallback = data => {
        switch (data.action) {
            case "start":
                onboarding.setShouldShowOnboarding(true);
                break;
            case "reset":
                setOnboardingState({...onboardingState, stepIndex: 0});
                break;
            case "close":
                setOnboardingState({...onboardingState, stepIndex: 0});
                onboarding.setShouldShowOnboarding(false);
                break;
            case "skip":
                onboarding.setShouldShowOnboarding(false);
                break;
            case "stop":
                onboarding.setShouldShowOnboarding(false);
                break;
            case "next":
                if (data.lifecycle === "complete") {
                    setOnboardingState({...onboardingState, stepIndex: data.index + 1});
                }
                break;
            case "prev":
            if (data.lifecycle === "complete") {
                setOnboardingState({...onboardingState, stepIndex: data.index - 1});
            }
                break;
            case "restart":
                setOnboardingState({...onboardingState, stepIndex: 0});
                break;
            default:
                break;
        }

        if (data.status === "finished") {
            onboarding.setShouldShowOnboarding(false);
            setOnboardingState({...onboardingState, stepIndex: 0});
        }
    };

    return <Joyride
        {...onboardingState}
        callback={handleJoyrideCallback}
        steps={onboardingParams?.steps || []}
        continuous={true}
        locale={onboardingParams?.locale}
        showProgress={onboardingParams?.showProgress}
        scrollToFirstStep={true}
        spotlightPadding={5}
        showSkipButton={true}
        disableScrolling={true}
        disableOverlayClose={true}
        styles={{options: {
            arrowColor: '#C4DEF5',
            backgroundColor: '#C4DEF5',
            overlayColor: 'rgba(9, 32, 51, 0.3)', //подобрал, чтобы было близко к rgba(20,64,102, 0.3) после hard-light
            primaryColor: '#003F64',
            textColor: '#144066',
            zIndex: 1000
        }}}
    />;
}
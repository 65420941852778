import React from "react";
import ReactSelect, {components} from "react-select";

import {useLocalization} from "../../../../customHooks/useContextLocalization";

import {ReactComponent as IconDropdown} from "./images/icon-dropdown.svg";


const ClearIndicator = () => "";
const DropdownIndicator = () => <IconDropdown
    className="product-from-icon-search"
    width="0.75rem"
    height="0.5rem"
/>;
const Option = ({children, ...props}) => {
    return <span className={props.isSelected ? "modal-sharing-selected-option" : "modal-sharing-option"}><components.Option {...props}>{children}</components.Option></span>;
};


export default function ({setSharingMode}) {
    const locale = useLocalization();

    const changeValue = option => setSharingMode(option?.value || "");

    const options = [
        {label: locale.get.studio.sharing.viewing, value: "viewer"},
        {label: locale.get.studio.sharing.commenting, value: "commentator"},
        {label: locale.get.studio.sharing.editing, value: "editor"}
    ];

    return <ReactSelect
        isPlanar
        isSearchable={false}
        className="sharing-single-select"
        options={options}
        components={{ClearIndicator, DropdownIndicator, Option}}
        defaultValue={options[0]}
        onChange={changeValue}
    />;
}

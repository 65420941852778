import {useState, createContext, useContext} from "react";

import {deleteAt} from "../../../../../../../../utils";


const MenuContext = createContext();


export function MenuProvider ({children}) {
    const [shouldShowCooRanges, setShouldShowCooRanges] = useState(false);
    const [shouldMakeRareObjectsTranslucent, setShouldMakeRareObjectsTranslucent] = useState(true);
    const [figuresToShow, setFiguresToShow] = useState({});
    const [frequencyThreshold, setFrequencyThreshold] = useState(0);
    const [shouldShowFrequenciesAbove, setShouldShowFrequenciesAbove] = useState(true);

    const switchShouldShowCooRanges = () => setShouldShowCooRanges(!shouldShowCooRanges);
    const switchRareObjectsTranslucency = () => setShouldMakeRareObjectsTranslucent(!shouldMakeRareObjectsTranslucent);
    const switchShouldShowFrequenciesAbove = () => setShouldShowFrequenciesAbove(!shouldShowFrequenciesAbove);
    const setNewFrequencyThreshold = event => setFrequencyThreshold(event.target.value);

    const applyFrequencyFilter = dataToRender => () => {
        // console.log(dataToRender);
        const newFiguresToShow = {};
        Object.keys(dataToRender).forEach(category => {
            newFiguresToShow[category] = [];
            Object.keys(dataToRender[category]).forEach(figureName => {
                const frequency = Math.round(dataToRender[category][figureName].frequency * 100);
                if (
                    (shouldShowFrequenciesAbove && frequency > frequencyThreshold)
                    || (!shouldShowFrequenciesAbove && frequency < frequencyThreshold)
                ) {
                    newFiguresToShow[category].push(figureName);
                }
            })
        });
        setFiguresToShow(newFiguresToShow);
    };

    const switchFigureVisibility = figuresAvailableToShow => e => {
        const switcherName = e.target.id.split("-").slice(5).join("-");
        const category = e.target.id.split("-")[4];
        if (switcherName === "all") {
            if (figuresToShow[category].length === figuresAvailableToShow[category].length) {
                const newFiguresToShow = {...figuresToShow};
                newFiguresToShow[category] = [];
                setFiguresToShow(newFiguresToShow);
            } else {
                const newFiguresToShow = {...figuresToShow};
                newFiguresToShow[category] = figuresAvailableToShow[category];
                setFiguresToShow(newFiguresToShow);
            }
        } else {
            const switcherIndex = figuresToShow[category].indexOf(switcherName);
            if (switcherIndex === -1) {
                const newFiguresToShow = {...figuresToShow};
                newFiguresToShow[category].push(switcherName);
                setFiguresToShow(newFiguresToShow);
            } else {
                const newFiguresToShow = {...figuresToShow};
                newFiguresToShow[category] = deleteAt(figuresToShow[category], switcherIndex);
                setFiguresToShow(newFiguresToShow);
            }
        }
    };

    // console.log(figuresToShow);

    return <MenuContext.Provider value={{
        figuresToShow,
        setFiguresToShow,
        shouldShowCooRanges,
        switchShouldShowCooRanges,
        shouldMakeRareObjectsTranslucent,
        switchFigureVisibility,
        switchRareObjectsTranslucency,
        switchShouldShowFrequenciesAbove,
        shouldShowFrequenciesAbove,
        frequencyThreshold,
        setNewFrequencyThreshold,
        applyFrequencyFilter
    }}>
        {children}
    </MenuContext.Provider>;
}


export function useMenu() {
    return useContext(MenuContext);
}



/**
 * Get first tool from tabSet
 * @typedef {id: number, icon: string, name: Object, children: Array<string>} Subpartition
 * @typedef {id: number, defaultWidth: number, isComplicated: bool, name: Object, children: Array<(string|Subpartition)>} Partition
 * @typedef {id: number, name: Object, children: Array<(Partition)>} Tab
 * @param  {Array<Tab>} tabSet  The tabSet object.
 * Note, if there is no tool, function will return "" (empty string)
 * @return {string}       The name of the first tool
 */
export function getFirstToolFromTabSet(tabSet) {
    for (let i = 0; i < tabSet.length; i++) {
        for (let j = 0; j < tabSet[i].children.length; j++) {
            if (!tabSet[i].children[j].isComplicated) {
                const firstTool = tabSet[i].children[j].children[0];
                if (firstTool) {
                    return firstTool;
                }
            } else {
                for (let k = 0; k < tabSet[i].children[j].children.length; k++) {
                    const firstTool = tabSet[i].children[j].children[k].children[0];
                    if (firstTool) {
                        return firstTool;
                    }
                }
            }
        }
    }
    return "";
}

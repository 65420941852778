import React from "react";
import {useDispatch} from "react-redux";

import SvgText from "./SvgText";
import Link from "./Link";
import {useReduxData} from "../../customHooks/useContextReduxData";
import useCooTransformation from "../canvas/customHooks/useCooTransformation";
import {getPreferredFontColorByBackgroundColor} from "../../utils/color";
import {getUuidHierarchy} from "../../utils/figureHierarchy";


export default function({figure, kind, isMouseDown}) {
    const {figures, scale, selectedFigureUuidsWithoutArrows} = useReduxData();
    const {transformXForCanvas, transformYForCanvas} = useCooTransformation();

    const dispatch = useDispatch();

    //show and hide description window
    const handleMouseLeaveOnPointFeature = () => !isMouseDown
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});

    const handleMouseEnterOnPointFeature = () => !isMouseDown
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: figure.get("uuid")});

    //hide description on figure move
    const handleMouseDownOnPointFeature = event => event.button === 0
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});

    //show description on moving ended up
    const handleMouseUpOnPointFeature = event => {
        if (event.button === 0) {
            dispatch({type: "figureUuidDescriptionPopupShownFor", value: figure.get("uuid")});
        }
    };

    const fontSize = ["standard", ""].includes(figure.get("fontSize")) ? 5 * scale : figure.get("fontSize") / 2 * scale;
    const transformedFigureX = transformXForCanvas(figure.get("x")),
        transformedFigureY = transformYForCanvas(figure.get("y"));

    const textUuidHierarchy = getUuidHierarchy(figures, figure.get("uuid"));
    const isTextSelected = selectedFigureUuidsWithoutArrows.filter(uuid => textUuidHierarchy.includes(uuid)).length > 0;

    const textStartsX = transformedFigureX - figure.get("name").length * fontSize;
    const textStartsY = transformedFigureY + 0.07 * fontSize * 40;

    return figure ? <g
        onMouseEnter={handleMouseEnterOnPointFeature}
        onMouseLeave={handleMouseLeaveOnPointFeature}
        onMouseDown={handleMouseDownOnPointFeature}
        onMouseUp={handleMouseUpOnPointFeature}
    >
        {!figure.get("name") && <line
            id={figure.get("uuid")}
            className={kind === "separate" ? "text" : "group"}
            x1={transformedFigureX}
            y1={transformedFigureY}
            x2={transformedFigureX}
            y2={transformedFigureY + 15}
            stroke={isTextSelected ? "green" : "red"}
            strokeWidth="4"
        />}
        <SvgText
            id={figure.get("uuid")}
            className={kind === "separate" ? "text" : "group"}
            fillColor={figure.get("fontColor")}
            strokeColor={isTextSelected ? "green" : getPreferredFontColorByBackgroundColor(figure.get("fontColor"))}
            fontSize={fontSize + "mm"}
            textDecoration={isTextSelected ? "underline" : "normal"}
            text={figure.get("name")}
            x={textStartsX}
            y={textStartsY - 18}
            opacity={figure.get("transparency")}
        />
        {figure.get("link") && <Link
            id={figure.get("uuid")}
            x={textStartsX}
            y={textStartsY + 10}
            height={10}
            width={10}
            linkAddress={figure.get("link")}
        />}
    </g> : null;
}

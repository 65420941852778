import {useEffect, useState} from "react";
import JSZip from "jszip";
import {toast} from 'react-toastify';
import {useParams} from "react-router-dom";

import Scrollbar from "../../../components/Scrollbar";
import {useApiClient} from "../../../customHooks/useApiClient";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {useLocalization} from "../../../customHooks/useContextLocalization";


function sortTable(mapInfo, sortRule) {
    const [columnName, order] = sortRule.split("-");
    if (columnName && columnName !== "mv_id") {
        mapInfo.sort((l, r) => l[columnName] > r[columnName] ? +(order === "asc") || -1 : +(order === "desc") || -1);
    } else if (columnName) {
        mapInfo.sort((l, r) =>
            +l.mv_id.split("-")[0] > +r.mv_id.split("-")[0]
            || (+l.mv_id.split("-")[0] === +r.mv_id.split("-")[0] && +l.mv_id.split("-")[1] > +r.mv_id.split("-")[1])
                ? +(order === "asc") || -1
                : +(order === "desc") || -1
        );
    }
    return mapInfo;
}


export default function ({serverMapInfo, setServerMapInfo}) {
    const api = useApiClient();
    const user = useCurrentUser();
    const locale = useLocalization();
    const {id} = useParams();

    const [shouldShowOnlyLastUserMap, setShouldShowOnlyLastUserMap] = useState(false);
    const [shouldShowOnlyLastMapVersion, setShouldShowOnlyLastMapVersion] = useState(false);
    const [shouldUseAllUsers, setShouldUseAllUsers] = useState(true);
    const [shouldExcludeUsers, setShouldExcludeUsers] = useState(true);
    const [usersToConfine, setUsersToConfine] = useState("");
    const [loadingStatus, setLoadingStatus] = useState("");
    const [sortBy, setSortBy] = useState("");

    const loadTable = async ({
        lastUserMapOnly = shouldShowOnlyLastUserMap,
        lastMapVersionOnly = shouldShowOnlyLastMapVersion,
        useAllUsers = shouldUseAllUsers,
        excludeUsers = shouldExcludeUsers
    }) => {
        setLoadingStatus("computing...");
        const archiveFile = (await api.get(
            "/api/v2/projects/analytics/maps-info/",
            {
                params: {
                    last_user_map_only: lastUserMapOnly,
                    last_map_version_only: lastMapVersionOnly,
                    confine_users_mode: useAllUsers ? "" : excludeUsers ? "ex" : "in",
                    users_to_confine: usersToConfine,
                    current_project_id: id,
                },
                responseType: "blob",
                onDownloadProgress: e => setLoadingStatus(`loading: ${Math.round((e.loaded * 100) / e.total)}%`)
            }
        ))?.data;
        setLoadingStatus("");

        if (!archiveFile) {
            return [];
        } else {
            let zip = JSZip();
            try {
                let archive = await zip.loadAsync(archiveFile);
                if (!archive || !archive?.files || !Object.keys(archive.files).length) {
                    return [];
                }

                let zipEntries = [];
                archive.forEach((relativePath, zipEntry) => {
                    zipEntries.push(zipEntry);
                });
                let jsonData = await archive.file(zipEntries[0].name)?.async("string");
                return JSON.parse(jsonData);
            } catch (error) {
                console.log(error);
                return [];
            }
        }
    }

    useEffect(() => {
        const asyncFunction = async () => {
            setServerMapInfo(await loadTable({}));
        };
        asyncFunction();
    }, [user]);

    const switchShouldShowOnlyLastUserMapCheckbox = async () => {
        setShouldShowOnlyLastUserMap(!shouldShowOnlyLastUserMap);
        setServerMapInfo(await loadTable({lastUserMapOnly: !shouldShowOnlyLastUserMap}));
    }

    const switchShouldShowOnlyLastMapVersionCheckbox = async () => {
        setShouldShowOnlyLastMapVersion(!shouldShowOnlyLastMapVersion);
        setServerMapInfo(await loadTable({lastMapVersionOnly: !shouldShowOnlyLastMapVersion}));
    }

    const switchShouldUseAllUsersCheckbox = async () => {
        setShouldUseAllUsers(!shouldUseAllUsers);
        setServerMapInfo(await loadTable({useAllUsers: !shouldUseAllUsers}));
    }

    const switchShouldExcludeUsers = async () => {
        setShouldExcludeUsers(!shouldExcludeUsers);
        setServerMapInfo(await loadTable({excludeUsers: !shouldExcludeUsers}));
    }

    const updateExcludedUsers = event => setUsersToConfine(event.target.value);

    const refreshTable = async () => setServerMapInfo(await loadTable({}));

    const copyLinkForMap = async event => {
        const mapId = event.target.id.split("-")[3], mapVersionNum = event.target.id.split("-")[4];
        try{
            const {token: shareToken, project: projectLink} = (await api.put(`/api/v2/projects/analytics/link/${mapId}/${mapVersionNum}/`)
                .catch(() => toast.error(locale?.get?.studio.errors.obsoleteMapLoadingFailed))
            )?.data;
            if (shareToken) {
                window.open(`${location.origin}/${projectLink}share?share_token=${encodeURIComponent(shareToken)}`, '_blank').focus();
            }
        } catch (e) {
            console.log("old");
        }
    };

    const setSorting = event => {
        const columnName = event.target.id.split("-")[4];
        const [lastSortColumn, lastSortOrder] = sortBy.split("-");
        setSortBy(`${columnName}-${lastSortColumn === columnName  && lastSortOrder === "asc" ? "desc" : "asc"}`);
    }

    return <div className="project-analytics-table-menu-container">
        <h3>{locale?.get?.project.analytics.mainPage.projectMaps}</h3>
        <h5>{locale?.get?.project.analytics.mainPage.mvExplanation}</h5>
        <div>
            <div>
                <input type="checkbox" id="show-last-user-map" onClick={switchShouldShowOnlyLastUserMapCheckbox}/>
                <label htmlFor="show-last-user-map">
                    {locale?.get?.project.analytics.mainPage.showLastUserMapOnly}
                </label>
            </div>
            <div>
                <input type="checkbox" id="show-last-map-version" onClick={switchShouldShowOnlyLastMapVersionCheckbox}/>
                <label htmlFor="show-last-map-version">
                    {locale?.get?.project.analytics.mainPage.showLastMapVersionOnly}
                </label>
            </div>
            <div>
                <input type="checkbox" id="exclude-some-users" onClick={switchShouldUseAllUsersCheckbox}/>
                <label htmlFor="exclude-some-users">
                    {<label
                        className={shouldExcludeUsers
                            ? "project-analytics-confine-mode-active"
                            : "project-analytics-confine-mode"
                        }
                        onClick={switchShouldExcludeUsers}
                    >{locale?.get?.project.analytics.mainPage.ex}</label>}
                    /
                    {<label
                        className={!shouldExcludeUsers
                            ? "project-analytics-confine-mode-active"
                            : "project-analytics-confine-mode"
                        }
                        onClick={switchShouldExcludeUsers}
                    >{locale?.get?.project.analytics.mainPage.in}</label>}
                    {locale?.get?.project.analytics.mainPage.cludeFollowingUsers}
                    <input
                        type="text"
                        className="project-analytics-exclude-user-textbox"
                        placeholder="user_id_1, user_id_2"
                        onChange={updateExcludedUsers}
                    />
                </label>
            </div>
        </div>
        <div className="project-analytics-table-update-container">
            <input
                type="button"
                className="project-analytics-table-update-button"
                value={locale?.get?.project.analytics.mainPage.update}
                onClick={refreshTable}
            />
            {`${locale?.get?.project.analytics.mainPage.mapCount} ${serverMapInfo.length}${loadingStatus ? ", " + loadingStatus : ""}`}
        </div>
        <Scrollbar
            autohide={false}
            classNamePrefix="my-maps-"
            style={{height: "calc(100% - 12.5rem)"}}
        >
            <table>
                <thead>
                    <tr>
                        <td id="project-analytics-table-cell-user_id" onClick={setSorting}>
                            User id{sortBy.split("-")[0] === "user_id" ? sortBy.split("-")[1] === "asc" ? "↑" : "↓" : ""}
                        </td>
                        <td
                            className="project-analytics-table-cell-blue"
                            id="project-analytics-table-cell-user"
                            onClick={setSorting}
                        >
                            User{sortBy.split("-")[0] === "user" ? sortBy.split("-")[1] === "asc" ? "↑" : "↓" : ""}
                        </td>
                        <td
                            className="project-analytics-table-cell-date"
                            id="project-analytics-table-cell-mv_created"
                            onClick={setSorting}
                        >
                            MV Created{sortBy.split("-")[0] === "mv_created" ? sortBy.split("-")[1] === "asc" ? "↑" : "↓" : ""}
                        </td>
                        <td
                            className="project-analytics-table-cell-blue"
                            id="project-analytics-table-cell-mv_id"
                            onClick={setSorting}
                        >
                            MV id{sortBy.split("-")[0] === "mv_id" ? sortBy.split("-")[1] === "asc" ? "↑" : "↓" : ""}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {sortTable(serverMapInfo, sortBy).map((row, i) => <tr key={`project-table-row-${i}`}>
                        <td>{row.user_id}</td>
                        <td className="project-analytics-table-cell-blue">{row.user}</td>
                        <td>{row.mv_created}</td>
                        <td className="project-analytics-table-cell-blue">
                            <input
                                type="button"
                                className="project-analytics-table-create-link"
                                id={`project-table-id-${row.mv_id}`}
                                value={row.mv_id}
                                onClick={copyLinkForMap}
                            />
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </Scrollbar>
    </div>;
}

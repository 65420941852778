import React, {useState, useContext} from "react";


const ChatContext = React.createContext();


export const ChatProvider = ({children}) => {
    const [get, set] = useState(false);

    return <ChatContext.Provider value={{get, set}}>
        {children}
    </ChatContext.Provider>;
};


export const useChat = () => useContext(ChatContext);

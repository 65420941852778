import React, {useState, useEffect} from "react";

import AccessRightsExtended from "./sharing/AccessRightsExtended";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useMapMembers} from "../../customHooks/useContextMapMembers";
import {useMap} from "../../customHooks/useContextMap";
import {useApiClient} from "../../../customHooks/useApiClient";


export default function () {
    const [accessByLinkMode, setAccessByLinkMode] = useState();
    const user = useCurrentUser();
    const api = useApiClient();
    const map = useMap();
    const locale = useLocalization();
    const {get: members, refreshMembers} = useMapMembers();

    const options = [
        {label: locale.get.studio.sharing.editing, value: "editor"},
        {label: locale.get.studio.sharing.viewing, value: "viewer"},
        {label: locale.get.studio.sharing.commenting, value: "commentator"},
        {label: locale.get.studio.accessConfiguration.remove, value: "nothing"}
    ];

    const findOptionByRole = role => options.filter(option => option.value === role)[0];

    useEffect(() => {
        if (map.mapId) {
            api.get(`/api/v2/map/${map.mapId}/link_right/`).then(
                response => setAccessByLinkMode(findOptionByRole(response.data))
            );
        }
    }, [map.mapId]);

    const updateMembers = username => newVal => {
        if (username) {
            api.put(`/api/v2/map/${map.mapId}/share_to_someone/?role=${newVal}`, {users: [username]})
                .then(() => refreshMembers());
        } else {
            api.put(`/api/v2/map/${map.mapId}/share?mode=${newVal}`).then(() => setAccessByLinkMode(newVal));
        }
    };

    const isCurrentUserAuthor = members.filter(member => member.username === user.username && member.role === "author")
        .length > 0;

    let sortedMembers = [...members];
    sortedMembers.sort((l, r) => l.username >= r.username ? 1 : -1);

    return <div className="modal-container-overflow-friendly">
        <h4 className="modal-sharing-title">{locale?.get.studio.accessConfiguration.title}</h4>
        <div className="modal-sharing-section-container">
            <p className="modal-sharing-p">{locale?.get.studio.accessConfiguration.accessViaLink}</p>
            {accessByLinkMode && (isCurrentUserAuthor ? <AccessRightsExtended
                options={options}
                defaultOption={accessByLinkMode}
                setSharingMode={updateMembers()}
            /> : <p className="modal-sharing-p">{accessByLinkMode.label}</p>)}
        </div>
        {sortedMembers.map(member => <div className="modal-sharing-section-container">
            <p className="modal-sharing-p">
                {member.username} {member.username === user.username ? locale?.get.studio.accessConfiguration.you : ""}
            </p>
            {member.role === "author"
                ? <p className="modal-sharing-p">{locale?.get.studio.accessConfiguration.owner}</p>
                : isCurrentUserAuthor ? <AccessRightsExtended
                    setSharingMode={updateMembers(member.username)}
                    defaultOption={findOptionByRole(member.role)}
                    options={options}
                /> : <p className="modal-sharing-p">{findOptionByRole(member.role).label}</p>
            }
        </div>)}
    </div>;
}

import {Map} from 'immutable';
import {v4 as uuid} from "uuid";
import {flattenDeep} from "lodash";

import Arrow from '../schemas/arrow';
import Group from '../schemas/group';
import PointFeature from '../schemas/pointFeature';
import Text from '../schemas/text';
import Polyline from '../schemas/polyline';
import Polygon from '../schemas/polygon';
import Rectangle from '../schemas/rectangle';
import Path from "../utils/path";
import {getChildrenValues} from '../utils/figureHierarchy';
import {deleteAt, replaceAt, isItemInArray} from "../../utils";
import {getRectanglePoint} from "../components/figures/utils/rectangle";


const initialState = Map({
    figures: Map([]),
    watch: Map({
        figures: Map([])
    }),
    mapName: "Новая карта",
    mapId: uuid(),
    lastAction: {type: "createMap"},
    version: (
        [].slice.call(document.getElementsByTagName("meta"))
            .find(el => el.name === "release")?.content?.split("@")[1]
        || `dev (${new Date().toDateString()})`
    )
});

export default (state=initialState, action) => {
    if (action && action.type && reducers()[action.type]) {
        if (action.x !== undefined && action.y !== undefined) {
            if (typeof(action.x) === "object") {
                if (action.x) { //null is an object too
                    action.x = action.x.map(xCoo => Path.roundCooToHundredths(xCoo));
                    action.y = action.y.map(yCoo => Path.roundCooToHundredths(yCoo));
                }
            } else {
                // console.log(action.x);
                action.x = Path.roundCooToHundredths(action.x);
                action.y = Path.roundCooToHundredths(action.y);
            }
        }
        if (action.points !== undefined) {
            action.points = Path.roundToHundredths(action.points);
        }
        if (!["addPolygon", "addPolyline", "addPointFeature", "addGroup", "addArrow", "addRectangle", "addText"].includes(action.type)
            && action.uuid && !state.get("figures").get(action.uuid)
        ) {
            return state;
        }

        const s = reducers()[action.type](state, action);
        if (action.type === "loadStateByTime" || action.type === "setMapId") {
            return s;
        }
        // console.log(`setting last action... ${action.type}`);

        // console.log(s.get("lastAction"));
        return action.type !== "clearState" ? s.set("lastAction", action) : s.set("lastAction", {type: "createMap"});
    } else {
        return state;
    }

    //как функция, чтобы можно было редьюсеры в конце файла разместить
    function reducers() {
        return {
            addArrow: (state, payload, mode = "") => {
                let newFigure = new Arrow({ //TODO: set others default values to remove them from dispatch
                    uuid: payload.uuid,
                    parentUuid: payload.parentUuid,
                    toX: payload.toX,
                    toY: payload.toY,
                    fromX: payload.fromX,
                    fromY: payload.fromY,
                    tool: payload.tool,
                    color: payload.color,
                    fontColor: payload.fontColor,
                    fontSize: payload.fontSize,
                    transparency: payload.transparency,
                    dashLength: payload.dashLength,
                    thickness: payload.thickness,
                    name: payload.name,
                    link: payload.link,
                    description: payload.description,
                    layout: payload.layout,
                    orderIndex: payload.orderIndex,
                    users: payload.users || []
                });
                return mode === "watch" ?  //TODO: move it out to the separate function
                    state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]))) :
                    state.set("figures", state.get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]));
            },
            addRectangle: (state, payload, mode = "") => {
                let newFigure = new Rectangle({
                    uuid: payload.uuid,
                    parentUuid: payload.parentUuid,
                    height: payload.height,
                    width: payload.width,
                    x: payload.x,
                    y: payload.y,
                    tool: payload.tool,
                    color: payload.color,
                    image: payload.image,
                    fontColor: payload.fontColor,
                    fontSize: payload.fontSize,
                    transparency: payload.transparency,
                    name: payload.name,
                    link: payload.link,
                    description: payload.description,
                    layout: payload.layout,
                    orderIndex: payload.orderIndex,
                    users: payload.users || []
                });
                return mode === "watch" ?
                    state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]))) :
                    state.set("figures", state.get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]));
            },
            addPolygon: (state, payload, mode = "") => {
                let newFigure = new Polygon({
                    uuid: payload.uuid,
                    parentUuid: payload.parentUuid,
                    points: payload.points,
                    x: payload.x,
                    y: payload.y,
                    tool: payload.tool,
                    color: payload.color,
                    emoji: payload.emoji,
                    fontColor: payload.fontColor,
                    fontSize: payload.fontSize,
                    transparency: payload.transparency,
                    name: payload.name,
                    link: payload.link,
                    description: payload.description,
                    layout: payload.layout,
                    orderIndex: payload.orderIndex,
                    users: payload.users || []
                });
                return mode === "watch" ?
                    state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]))) :
                    state.set("figures", state.get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]));

            },
            addPolyline: (state, payload, mode = "") => {
                let newFigure = new Polyline({
                    uuid: payload.uuid,
                    parentUuid: payload.parentUuid,
                    points: payload.points,
                    dashLength: payload.dashLength,
                    x: payload.x,
                    y: payload.y,
                    thickness: payload.thickness,
                    tool: payload.tool,
                    color: payload.color,
                    transparency: payload.transparency,
                    name: payload.name,
                    link: payload.link,
                    description: payload.description,
                    enclosed: payload.enclosed,
                    layout: payload.layout,
                    orderIndex: payload.orderIndex,
                    users: payload.users || []
                });
                return mode === "watch" ?
                    state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]))) :
                    state.set("figures", state.get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]));

            },
            addPointFeature: (state, payload, mode = "") => {
                let newFigure = new PointFeature({
                    uuid: payload.uuid,
                    parentUuid: payload.parentUuid,
                    x: payload.x,
                    y: payload.y,
                    volume: payload.volume,
                    tool: payload.tool,
                    color: payload.color,
                    emoji: payload.emoji,
                    flags: payload.flags,
                    fontColor: payload.fontColor,
                    fontSize: payload.fontSize,
                    transparency: payload.transparency,
                    name: payload.name,
                    link: payload.link,
                    description: payload.description,
                    layout: payload.layout,
                    orderIndex: payload.orderIndex,
                    users: payload.users || []
                });
                return mode === "watch" ?
                    state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]))) :
                    state.set("figures", state.get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]));
            },
            addText: (state, payload, mode = "") => {
                let newFigure = new Text({
                    uuid: payload.uuid,
                    parentUuid: payload.parentUuid,
                    x: payload.x,
                    y: payload.y,
                    tool: payload.tool,
                    fontColor: payload.fontColor,
                    fontSize: payload.fontSize,
                    transparency: payload.transparency,
                    name: payload.name,
                    link: payload.link,
                    description: payload.description,
                    layout: payload.layout,
                    orderIndex: payload.orderIndex,
                    users: payload.users || []
                });
                return mode === "watch" ?
                    state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]))) :
                    state.set("figures", state.get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]));
            },
            addGroup: (state, payload, mode = "") => {
                const newGroup = new Group({
                    uuid: payload.uuid,
                    parentUuid: payload.parentUuid,
                    tool: payload.tool || "g-s-72", //TODO: for backward compatibility / move it to updater
                    children: payload.children,
                    name: payload.name || "", //TODO: for backward compatibility / move it to updater
                    description: payload.description || "", //TODO: for backward compatibility / move it to updater
                    layout: payload.layout,
                    orderIndex: payload.orderIndex
                });
                if (mode === "watch") {
                    payload.children.map((u, i) => {
                        state = setState(state, ["watch", "figures", u], {parentUuid: payload.uuid}); // + "layout": payload.layout если нужно стереть старый слой ребёнка
                    });
                    return state.set("watch", state.get("watch").set(
                        "figures",
                        state.get("watch").get("figures").concat([[payload.uuid, newGroup]])
                    ));
                } else {
                    payload.children.map(u => {
                        state = setState(state, ["figures", u], {parentUuid: payload.uuid}); // + "layout": payload.layout если нужно стереть старый слой ребёнка
                    });
                    return state.set("figures", state.get("figures").concat([[payload.uuid, newGroup]]));
                }
            },
            changeObjectName: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {name: payload.name}
            ),
            changeUsersRelatedWithObject: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {users: payload.users}
            ),
            changeObjectDescription: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {description: payload.description}
            ),
            changeObjectLink: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {link: payload.link}
            ),
            changeObjectParentUuid: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {parentUuid: payload.parentUuid}
            ),
            changeFigureColor: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {color: payload.color}
            ),
            changeFigurePoints: (state, payload, mode = "") => {
                const figure = state.get("figures").get(payload.uuid);
                const figureType = figure.get("tool").split("-")[0];
                if (["p", "pr", "pl", "c"].includes(figureType)) {
                    let paramsToSet = {points: payload.points};
                    if (typeof payload.x === "number" && typeof payload.y === "number") {
                        paramsToSet.x = payload.x;
                        paramsToSet.y = payload.y;
                    }
                    return setState(
                        state,
                        mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                        paramsToSet
                    );
                }
                return state;
            },
            changeRectangleSize: (state, payload, mode = "") => {
                const figure = state.get("figures").get(payload.uuid);
                const figureType = figure.get("tool").split("-")[0];
                if (figureType === "r") {
                    let paramsToSet = {width: payload.width, height: payload.height};
                    if (typeof payload.x === "number") {
                        paramsToSet.x = payload.x;
                    }
                    if (typeof payload.y === "number") {
                        paramsToSet.y = payload.y;
                    }
                    return setState(
                        state,
                        mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                        paramsToSet
                    );
                }
            },
            changeArrowGeometry: (state, payload, mode = "") => {
                const coosToSet = {};
                if (payload.toX) {
                    coosToSet.toX = payload.toX;
                }
                if (payload.toY) {
                    coosToSet.toY = payload.toY;
                }
                if (payload.fromX) {
                    coosToSet.fromX = payload.fromX;
                }
                if (payload.fromY) {
                    coosToSet.fromY = payload.fromY;
                }

                return setState(
                    state,
                    mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                    coosToSet
                )
            },
            changeFigureDashLength: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {dashLength: payload.dashLength}
            ),
            addFigureEmoji: (state, payload, mode = "") => {
                if (mode === "watch") {
                    if (state.get("watch").get("figures").get(payload.uuid).get("emoji").length >= 10) {
                        return state;
                    }
                    return setState(
                        state,
                        ["watch", "figures", payload.uuid],
                        {emoji: state.get("watch").get("figures").get(payload.uuid).get("emoji").concat(payload.emoji)}
                    );
                } else {
                    if (state.get("figures").get(payload.uuid).get("emoji").length >= 10) {
                        return state;
                    }
                    return setState(
                        state,
                        ["figures", payload.uuid],
                        {emoji: state.get("figures").get(payload.uuid).get("emoji").concat(payload.emoji)}
                    );
                }
            },
            deleteFigureEmoji: (state, payload, mode = "") => {
                if (mode === "watch") {
                    let emoji = state.get("watch").get("figures").get(payload.uuid).get("emoji");
                    let index = -1;
                    for (let i = 0; i<emoji.length; i++) {
                        if (JSON.stringify(emoji[i]) === JSON.stringify(payload.emoji)) {
                            index = i;
                        }
                    }
                    if (index !== -1) {
                        emoji.splice(index, 1);
                    }

                    return setState(state, ["watch", "figures", payload.uuid], {emoji: emoji});
                } else {
                    let emoji = state.get("figures").get(payload.uuid).get("emoji");
                    let index = -1;
                    for (let i = 0; i<emoji.length; i++) {
                        if (JSON.stringify(emoji[i]) === JSON.stringify(payload.emoji)) {
                            index = i;
                        }
                    }
                    if (index !== -1) {
                        emoji.splice(index, 1);
                    }
                    return setState(state, ["figures", payload.uuid], {emoji: emoji});
                }
            },
            addFigureFlag: (state, payload, mode = "") => {
                if (mode === "watch") {
                    if (state.get("watch").get("figures").get(payload.uuid).get("flags").length >= 3) {
                        return state;
                    }
                    return setState(
                        state,
                        ["watch", "figures", payload.uuid],
                        {flags: state.get("watch").get("figures").get(payload.uuid).get("flags").concat(payload.flags)}
                    );
                } else {
                    if (state.get("figures").get(payload.uuid).get("flags").length >= 3) {
                        return state;
                    }
                    return setState(
                        state,
                        ["figures", payload.uuid],
                        {flags: state.get("figures").get(payload.uuid).get("flags").concat(payload.flags)}
                    );
                }
            },
            deleteFigureFlag: (state, payload, mode = "") => {
                if (mode === "watch") {
                    let flags = state.get("watch").get("figures").get(payload.uuid).get("flags");
                    let index = -1;
                    for (let i = 0; i < flags.length; i++)
                        if (flags[i] === payload.flags) {
                            index = i;
                        }
                    if (index !== -1)
                        flags.splice(index, 1);

                    return setState(state, ["watch", "figures", payload.uuid], {flags: flags});
                } else {
                    let flags = state.get("figures").get(payload.uuid).get("flags");
                    let index = -1;
                    for (let i = 0; i < flags.length; i++)
                        if (flags[i] === payload.flags) {
                            index = i;
                        }
                    if (index !== -1)
                        flags.splice(index, 1);
                    return setState(state, ["figures", payload.uuid], {flags: flags});
                }
            },
            changeFigureFontColor: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {fontColor: payload.fontColor}
            ),
            changeFigureFontSize: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {fontSize: +payload.fontSize >= 0 ? payload.fontSize : "standard"}
            ),
            changeFigureTransparency: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {transparency: payload.transparency}
            ),
            changeFigureLayout: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {layout: payload.layout}
            ),
            changeFigureVolume: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {volume: +payload.volume}
            ),
            changeFigureThickness: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {thickness: payload.thickness}
            ),
            loadStateByTime: (state, payload) => {
                let newState = state;
                newState = newState.set("watch", initialState.set("mapId", uuid()));

                let actionHistory = [];
                for (let i = 0; i < payload.past.length; i++) {
                    actionHistory.push(payload.past[i].get("lastAction"));
                }
                actionHistory.push(state.get("lastAction"));

                for (let i = 0; i < actionHistory.length; i++) {
                    if (!actionHistory[i].time || actionHistory[i].time <= +payload.currentTime) {
                        newState = reducers()[actionHistory[i].type](newState, actionHistory[i], "watch");
                    } else {
                        break;
                    }
                }
                return newState;
            },
            clearState: (state, payload, mode = "") => (
                mode === "watch"
                    ? state.set("watch", (payload.customName
                        ? initialState.set("mapName", payload.customName)
                        : initialState
                    ).set("mapId", uuid()))
                    : (payload.customName ? initialState.set("mapName", payload.customName) : initialState).set("mapId", uuid())
            ),
            deleteFigure: (state, payload, mode = "") => {
                const figures = (mode === "watch" ? state.get("watch") : state).get("figures");
                const pathToFigures = mode === "watch" ? ["watch", "figures"] : ["figures"];

                //delete figure from group if parent group exist
                const parentGroup = figures.find(f =>
                    f.get("tool").split("-")[0] === "g" && isItemInArray(payload.uuid, f.get("children"))
                );
                if (parentGroup) {
                    const parentGroupChildren = parentGroup.get("children");
                    const figureIndex = parentGroupChildren.indexOf(payload.uuid);
                    const newChildren = deleteAt(parentGroupChildren, figureIndex);
                    const parentGroupUuid = parentGroup.get("uuid");
                    state = setState(state, pathToFigures.concat([parentGroupUuid]), {children: newChildren});

                    if (newChildren.length === 1) { //If group has the only child, then disband group
                        state = setState(state, pathToFigures.concat([newChildren[0]]), {parentUuid: undefined});
                        if (parentGroup.get("parentUuid")) { //if disbanded group into group
                            const parentOfParentGroup = figures.get(parentGroup.get("parentUuid"));
                            const childrenOfParentOfParentGroup = parentOfParentGroup.get("children");
                            //replacing group uuid to group child uuid
                            const indexOfParentGroupAsChild = childrenOfParentOfParentGroup.indexOf(parentGroupUuid);
                            const newChildrenOfParentOfParentGroup = replaceAt(
                                childrenOfParentOfParentGroup,
                                indexOfParentGroupAsChild,
                                newChildren[0]
                            );
                            //TODO: newChildrenOfParentOfParentGroup may contain the only child (implement recursion)
                            state = setState(
                                state,
                                pathToFigures.concat([parentGroup.get("parentUuid")]),
                                {children: newChildrenOfParentOfParentGroup}
                            );

                            //replace parent uuid for child
                            state = setState(
                                state,
                                pathToFigures.concat([newChildren[0]]),
                                {parentUuid: parentGroup.get("parentUuid")}
                            );
                        }
                        //delete disbanded group
                        if (mode === "watch") {
                            state = state.set("watch",
                                state.get("watch").set("figures",
                                    state.get("watch").get("figures").delete(parentGroupUuid)
                                )
                            );
                        } else {
                            state = state.set("figures", state.get("figures").delete(parentGroupUuid));
                        }
                    }
                }

                const uuids = flattenDeep([getChildrenValues(figures, payload.uuid, "uuid")]); //payload.uuid also here
                uuids.map(u => {
                    const currentFigure = figures.get(u);
                    const currentFigureType = currentFigure.get("tool").split("-")[0];
                    if (["r", "p", "pr", "pl", "c"].includes(currentFigureType)) {
                        const dependentArrows = figures.filter(f =>
                            f.get("tool").split("-")[0] === "a"
                            && (
                                (typeof f.get("fromX") === "string" && f.get("fromX").includes(u))
                                || (typeof f.get("toX") === "string" && f.get("toX").includes(u))
                            )
                        );
                        dependentArrows.map(dependentArrow => {
                            ["from", "to"].map(cooKey => {
                                const xKey = `${cooKey}X`;
                                const yKey = `${cooKey}Y`;
                                if (typeof dependentArrow.get(xKey) === "string" && dependentArrow.get(xKey).includes(u)) {
                                    const [, segmentIndex] = dependentArrow.get(xKey).split("|");
                                    let realCoo;
                                    if (currentFigureType === "r") {
                                        realCoo = getRectanglePoint(currentFigure, +segmentIndex);
                                    } else {
                                        realCoo = Path.getPoint(currentFigure.get("points"), 0, +segmentIndex - 1, "last");
                                        realCoo.x += currentFigure.get("x");
                                        realCoo.y += currentFigure.get("y");
                                    }
                                    const newCooParams = {};
                                    newCooParams[xKey] = realCoo.x;
                                    newCooParams[yKey] = realCoo.y;
                                    state = setState(
                                        state,
                                        pathToFigures.concat([dependentArrow.get("uuid")]),
                                        newCooParams
                                    );
                                }
                            })
                        })
                    }
                });
                if (mode === "watch") { //TODO: use deleteAll() methods when immutable-js will be updated to ^4.0.0
                    uuids.map(u => {
                        state = state.set("watch",
                            state.get("watch").set("figures", state.get("watch").get("figures").delete(u))
                        );
                    });
                } else {
                    uuids.map(u => {
                        state = state.set("figures", state.get("figures").delete(u));
                    });
                }

                return state;
            },
            disbandGroup: (state, payload, mode = "") => {
                if (mode === "watch") {
                    payload.children.map(u => {
                        state = setState(state, ["watch", "figures", u], {parentUuid: undefined});
                    });

                    return state.set("watch", state.get("watch").set("figures",
                        state.get("watch").get("figures").delete(payload.uuid)
                    ));
                } else {
                    payload.children.map(u => {
                        state = setState(state, ["figures", u], {parentUuid: undefined});
                    });

                    return state.set("figures", state.get("figures").delete(payload.uuid));
                }
            },
            moveFigure: (state, payload, mode = "") => setState(
                state,
                mode === "watch" ? ["watch", "figures", payload.uuid] : ["figures", payload.uuid],
                {x: payload.x, y: payload.y}
            ),
            createMap: state => state,
            saveToServer: state => state,
            saveToComputer: state => state,
            setMapName: (state, payload) => state.set("mapName", payload.name),
            setMapId: (state, payload) => state.set("mapId", payload.id)
        };
    }
};

/**
     * Set some value in state
     * @param  {{}}                        state      The source state
     * @param  {[]}                        path       Path to value within state like: ["figures", "some_uuid"]
     * @param  {{key: any, value: any}}    values     Values to set with keys like: {x: 12, y: 34, points: "M ..."}
     * @return {{}}                                   return new state
     */
function setState(state, path, values) {
    let newState = state;
    for (let i = 0; i <= path.length; i++) {
        let tempObject = state;
        path.slice(0, path.length - i).map(pathItem => {
            tempObject = tempObject.get(pathItem);
        });
        if (i === 0) {
            newState = tempObject;
            Object.keys(values).map(valueKey => {
                newState = newState.set(valueKey, values[valueKey]);
            });
        } else {
            newState = tempObject.set(path[path.length - i], newState);
        }
    }
    return newState;
}
import React from "react";
import {useHistory} from "react-router-dom";

import {useLocalization} from "../../customHooks/useContextLocalization";

import {ReactComponent as LogoSad} from "../images/logo-sad.svg";

import "./servicePage.css";


export default function Page404() {
    const history = useHistory();
    const locale = useLocalization();

    const goToHome = () => history.push("/");

    return (
        <div className = "page-404-container">
            <LogoSad height = "110" width = "110"/>
            <h1 className = "page-404-title">{locale?.get?.store.page404.title}</h1>
            <p className = "page-404-text">{locale?.get?.store.page404.explanation}</p>
            <span className = "page-404-link" onClick={goToHome}>{locale?.get?.store.page404.goToHome}</span>
        </div>
    );
}

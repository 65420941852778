import Async from "react-select/async";
import {components} from "react-select";

import {
    ClearIndicator,
    DropdownIndicator,
    IndicatorSeparator,
    ValueContainer
} from "../store/components/CustomIcons";
import {useApiClient} from "../customHooks/useApiClient";
import {useLocalization} from "../customHooks/useContextLocalization";
import {useCurrentUser} from "../customHooks/useContextCurrentUser";
import {createOption} from "../utils";


export default function ({
                             users,
                             setUsers,
                             menuPlacement,
                             className,
                             fieldName,
                             shouldAddCurrentUser=false,
                             customSearchUserFunction,
                             dropdownIndicator=DropdownIndicator,
                             clearIndicator=ClearIndicator,
                             valueContainer=ValueContainer,
                             multiValueContainer=components.MultiValueContainer,
                             ...props
}) {
    const api = useApiClient();
    const locale = useLocalization();
    const currentUser = useCurrentUser();

    const searchUsers = async inputValue => inputValue.length > 2 && api.get(
        `/api/v2/store/user-list/`,
        {params: {user_input: inputValue}}
    ).then(result => {
        const users = shouldAddCurrentUser && currentUser ? result.data.concat([currentUser.username]) : result.data;
        return users.map(createOption);
    });

    const getNoOptionMessage = () => locale?.get?.store.product.form.noOptions;
    const getLoadingMessage = () => locale?.get?.store.product.form.loading;
    const setUsers_ = options => setUsers(options.map(option => option.value));

    return <Async
        isClearable
        isMulti
        isPlanar
        closeMenuOnSelect
        cacheOptions
        defaultOptions
        className={className}
        loadOptions={customSearchUserFunction || searchUsers}
        name={fieldName}
        placeholder={locale?.get?.store.product.form.usersPlaceholder}
        components={{
            ClearIndicator: clearIndicator,
            DropdownIndicator: dropdownIndicator,
            IndicatorSeparator,
            ValueContainer: valueContainer,
            MultiValueContainer: multiValueContainer
        }}
        noOptionsMessage={getNoOptionMessage}
        loadingMessage={getLoadingMessage}
        onChange={setUsers_}
        value={users ? users.map(createOption) : []}
        menuPlacement={menuPlacement}
        {...props}
    />;
}

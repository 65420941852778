import React, {useMemo} from "react";

import TransformationalConnector from "../connectors/TransformationalConnector.js";
import ConnectorLine from "../connectors/ConnectorLine.js";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import useCooTransformation from "../../canvas/customHooks/useCooTransformation";
import {getModifiedCurvesForTransformationalConnectors, getTransformationalConnectorOpacity} from "../utils/transformationalConnector";
import {checkIsFigureEnclosed} from "../../figures/utils/figure";


export default function ({parentFigure}) {
    const {figures, scale, selectedFigureUuids} = useReduxData();
    const {transformXForCanvas, transformYForCanvas} = useCooTransformation();

    const curves = useMemo(
        () => getModifiedCurvesForTransformationalConnectors(parentFigure.get("points"), scale),
        [parentFigure.get("points"), scale]
    );

    const isEnclosed = checkIsFigureEnclosed(parentFigure.get("tool"), parentFigure.get("enclosed"));

    const opacity = useMemo(
        () => getTransformationalConnectorOpacity(figures, parentFigure.get("uuid"), selectedFigureUuids),
        [figures, parentFigure.get("uuid"), selectedFigureUuids]
    );

    const connectorLines = useMemo(() => {
        return curves.map((curve, i) => {
            let lines = [];
            if (curve.points.length > 1) {
                lines = [<ConnectorLine
                    key={`${i}-cl-1`}
                    x1={curves[i - 1].points[curves[i - 1].points.length - 1].x}
                    y1={curves[i - 1].points[curves[i - 1].points.length - 1].y}
                    x2={curve.points[0].x}
                    y2={curve.points[0].y}
                    opacity={opacity}
                />];
                if (curve.points.length === 2) {
                    lines.push(<ConnectorLine
                        key={`${i}-cl-2`}
                        x1={curve.points[0].x}
                        y1={curve.points[0].y}
                        x2={curve.points[1].x}
                        y2={curve.points[1].y}
                        opacity={opacity}
                    />);
                } else if (curve.points.length === 3) {
                    lines.push(<ConnectorLine
                        key={`${i}-cl-2`}
                        x1={curve.points[1].x}
                        y1={curve.points[1].y}
                        x2={curve.points[2].x}
                        y2={curve.points[2].y}
                        opacity={opacity}
                    />);
                }
            }
            return lines;
        })
    }, [curves, opacity]);

    const transformationalConnectors = useMemo(() => {
        return curves.map((curve, i) => {
            let connectors = [
                <TransformationalConnector
                    key={`${i}-bc`}
                    type="base"
                    id={`${parentFigure.get("uuid")}|${(isEnclosed && i === curves.length - 1) ? 0 : i}`}
                    x={curve.points[curve.points.length - 1].x}
                    y={curve.points[curve.points.length - 1].y}
                    opacity={opacity}
                    hintModeKey={i === 0 && "baseTransformationalConnector"}
                />
            ];

            if (curve.points.length === 2) {
                connectors.push(<TransformationalConnector
                    key={`${i}-dc-1`}
                    type="directive"
                    id={`${parentFigure.get("uuid")}|${i}|0`}
                    x={curve.points[0].x}
                    y={curve.points[0].y}
                    opacity={opacity}
                    hintModeKey={i === 0 && "directiveTransformationalConnector"}
                />);
            } else if (curve.points.length === 3) {
                connectors = connectors.concat([
                    <TransformationalConnector
                        key={`${i}-dc-1`}
                        type="directive"
                        id={`${parentFigure.get("uuid")}|${i}|0`}
                        x={curve.points[0].x}
                        y={curve.points[0].y}
                        opacity={opacity}
                        hintModeKey={i === 0 && "directiveTransformationalConnector"}
                    />,
                    <TransformationalConnector
                        key={`${i}-dc-2`}
                        type="directive"
                        id={`${parentFigure.get("uuid")}|${i}|1`}
                        x={curve.points[1].x}
                        y={curve.points[1].y}
                        opacity={opacity}
                    />
                ]);
            }
            return connectors;
        })
    }, [curves, parentFigure.get("uuid"), opacity, isEnclosed]);


    return <svg x={transformXForCanvas(parentFigure.get("x") - 20)} y={transformYForCanvas(parentFigure.get("y") - 20)}>
        {connectorLines}
        {transformationalConnectors}
    </svg>;
}

import {useState} from "react";

import TabTitles from "./TabTitles";
import TabContent from "./TabContent";


export default function () {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const selectTab = event => setCurrentTabIndex(+event.target.id.split("-")[2]);

    return <div className="postcard-tabs-container">
        <TabTitles tabIndex={currentTabIndex} selectTab={selectTab}/>
        <TabContent tabIndex={currentTabIndex}/>
    </div>;
}

import React, {useMemo} from "react";

import TransformationalConnector from "../connectors/TransformationalConnector.js";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import useCooTransformation from "../../canvas/customHooks/useCooTransformation";
import {getPhysicalArrowCoos} from "../../figures/utils/arrow";
import {getTransformationalConnectorOpacity} from "../utils/transformationalConnector";


export default function ({parentFigure}) {
    const {figures, scale, selectedFigureUuids} = useReduxData();
    const {transformXForCanvas, transformYForCanvas} = useCooTransformation();

    const opacity = useMemo(
        () => getTransformationalConnectorOpacity(figures, parentFigure.get("uuid"), selectedFigureUuids),
        [figures, parentFigure.get("uuid"), selectedFigureUuids]
    );

    const arrowCoos = useMemo(() => getPhysicalArrowCoos(parentFigure, figures), [parentFigure, figures]);

    const arrowBoundingBoxCoo = useMemo(() => {
        const [minX, minY] = [Math.min(arrowCoos.from.x, arrowCoos.to.x), Math.min(arrowCoos.from.y, arrowCoos.to.y)];
        return {x: minX, y: minY};
    }, [arrowCoos]);

    const points = useMemo(() => [
        [(arrowCoos.from.x - arrowBoundingBoxCoo.x) * scale, (arrowCoos.from.y - arrowBoundingBoxCoo.y) * scale],
        [(arrowCoos.to.x - arrowBoundingBoxCoo.x) * scale, (arrowCoos.to.y - arrowBoundingBoxCoo.y) * scale]
    ], [arrowCoos, arrowBoundingBoxCoo, scale]);

    return <svg x={transformXForCanvas(arrowBoundingBoxCoo.x) - 12} y={transformYForCanvas(arrowBoundingBoxCoo.y) - 12}>
        {points.map((point, i) =>
            <TransformationalConnector
                key={`${i}-bc`}
                type="base"
                id={`${parentFigure.uuid}|${i}`}
                x={point[0] + 12}
                y={point[1] + 12}
                opacity={opacity}
                hintModeKey="baseTransformationalConnector"
            />
        )}
    </svg>;
}

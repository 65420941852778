import React from "react";
import {useDispatch} from "react-redux";

import tooltipHOC from "../../../../../components/tooltipHOC";
import {useReduxData} from "../../../../customHooks/useContextReduxData";


function ContextMenuButton ({type, disabled=false, image}) {
    const dispatch = useDispatch();
    const {openContextMenuTool} = useReduxData();

    const openContextMenuItem = () => dispatch({type: "openContextMenuTool", value: type});

    return <div
        className={`context-menu-button${disabled ? " disabled" : ""}`}
        style={{
            backgroundImage: image,
            backgroundRepeat: "no-repeat",
            borderBottom: openContextMenuTool === type ? "0.0625rem solid #144066" : "none",
            height: "1.25rem",
            width: "1.25rem",
        }}
        onClick={openContextMenuItem}
    />;
}

export default tooltipHOC(ContextMenuButton);

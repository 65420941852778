import React from "react";

import BaseBlock from "./baseBlock/ToolBlock";
import FigureSelector from "./figureSelector/FigureSelector";
import ActionHistory from "./actionHistory/ActionHistory";
import {useReduxData} from "../../customHooks/useContextReduxData";
import {useCurrentProject} from "../../customHooks/useContextCurrentProject";
import {useShouldHideToolbar} from "../../customHooks/useContextShouldHideToolbar";


export default function() {
    const {isWatchMode} = useReduxData();
    const {get: currentProject} = useCurrentProject();
    const {get: shouldHideToolbar} = useShouldHideToolbar();

    return !shouldHideToolbar ? <div className="figure-bar">
        <BaseBlock/>
        <div className="figure-bar-right-container">
            {isWatchMode
                ? (currentProject.config?.hiddenElements || {}).watchMode !== "all" && <ActionHistory/>
                : (currentProject.config?.hiddenElements || {}).figureSelector !== "all" && <FigureSelector/>
            }
        </div>
    </div> : null;
}

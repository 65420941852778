

export const getRectanglePoint = (figure, index) => {
    let point = {x: figure.get("x"), y: figure.get("y")};
    if ([1, 5].includes(index)) {
        point.x += figure.get("width") / 2;
    } else if ([2, 3, 4].includes(index)) {
        point.x += figure.get("width");
    }
    if ([3, 7].includes(index)) {
        point.y += figure.get("height") / 2;
    } else if ([4, 5, 6].includes(index)) {
        point.y += figure.get("height");
    }
    return point;
}

import React, {useEffect, useState} from 'react';

import Figure from "../figures/Figure";
import SelectedFigureContainer from "./SelectedFigureContainer";
import {useReduxData} from "../../customHooks/useContextReduxData";
import {isItemInArray} from "../../../utils";


export default function FigureContainer({handleMouseEnterOnConnector, isMouseDown}) {
    const {isWatchMode, orderedFigures, unselectedUuids} = useReduxData();
    const [newUnselectedUuids, setNewUnselectedUuids] = useState([]);

    useEffect(() => { //this isn't useless, it prevent figure flashing on selection
        setNewUnselectedUuids(unselectedUuids);
    }, [unselectedUuids]);

    return <>
        {orderedFigures.map(figure =>
            ((isItemInArray(figure.get("uuid"), newUnselectedUuids) || isWatchMode) &&
                <Figure
                    key={figure.get("uuid")}
                    figure={figure}
                    kind={"separate"}
                    isMouseDown={isMouseDown}
                />
            )
        )}
        <SelectedFigureContainer
            isMouseDownOnCanvas={isMouseDown}
            handleMouseEnterOnConnector={handleMouseEnterOnConnector}
        />
    </>;
}

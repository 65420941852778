import Input from "../../Input";


const ProductName = props => {
    const setName = name => props.setFieldValue(props.fieldName, name);

    return <div className="product-form-input-wrapper">
        <Input
            name={props.fieldName}
            type="text"
            placeholder={props.placeholder}
            value={props.formValues[props.fieldName]}
            onChange={setName}
        />
    </div>;
}

export default ProductName;
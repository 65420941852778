import {useDataSource} from "../../hooks/useContextDataSource";


export default function ({selectedNodeId}) {
    const {dataSources, dispatch} = useDataSource();

    const setLocalFileListOfNode = event => dispatch({
        type: "Add or update data source",
        id: selectedNodeId,
        data: {localFiles: event.target.files}
    });

    return <div className="project-analytics-source-node-configurator-local-data-container">
        <input
            type="file"
            id={`project-source-node-configurator-data-loader-${selectedNodeId}`}
            className="project-analytics-source-node-configurator-data-loader"
            multiple
            onChange={setLocalFileListOfNode}
        />
        <label htmlFor={`project-source-node-configurator-data-loader-${selectedNodeId}`}>
            {dataSources[selectedNodeId]?.localFiles
                ? (Array.from(dataSources[selectedNodeId]?.localFiles)?.map(file => file.name))
                    .join(", ").slice(0, 50)
                : ""
            }
        </label>
    </div>;
}
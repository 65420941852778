import {toast} from "react-toastify";

import SocialNetworkIcon from "../../../../components/SocialNetworkIcon";
import {useApiClient} from "../../../../customHooks/useApiClient";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useMap} from "../../../customHooks/useContextMap";
import {useCurrentUser} from "../../../../customHooks/useContextCurrentUser";

import {ReactComponent as ShareIcon} from "../../../../images/social/icon-invite-users.svg";


export default function ({openSignInIfUnauthorized, users, setUsers, style, sharingMode}) {
    const api = useApiClient();
    const locale = useLocalization();
    const map = useMap();
    const user = useCurrentUser();

    const shareMapToUsers = async () => {
        openSignInIfUnauthorized();

        if (!user) {
            toast.warn(locale?.get.studio.sharing.authRequired);
            return undefined;
        }

        await map.shareMap();
        if (map.mapId) {
            try {
                await api.put(`/api/v2/map/${map.mapId}/share_to_someone/?role=${sharingMode}`, {users: users});
                setUsers([]);
            } catch (err) {
                if (err.response.status === 404 && err.response.data.detail) {
                    if (err.response.data.detail.users) {
                        toast.error(locale?.get.studio.sharing.noUsersFound);
                    } else {
                        toast.error(locale?.get.studio.sharing.sendToServerRequired);
                    }
                }
                return;
            }
            toast.success(locale?.get.studio.sharing.sharedSuccessfully);
        }
    };

    return <SocialNetworkIcon
        id="footer-social-share"
        className="modal-share-invite-button"
        SvgIcon={ShareIcon}
        iconSize="1.25rem"
        backgroundColors={{dark: "#9CC6EA", light: "#9CC6EA"}}
        onClick={shareMapToUsers}
        style={style}
    />;
}

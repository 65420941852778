import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import tooltipHOC from "../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";
import {useIconSet} from "../../../../customHooks/useContextIconSet";
import {useReduxData} from "../../../customHooks/useContextReduxData";


function FigureBarItem({figureName, type, setOpenedSubpartitionIndex}) {
    const [iconData, setIconData] = useState("");

    const dispatch = useDispatch();
    const {get: {parameters, icons}} = useIconSet();
    const {color, tool: currentFigureName, isWatchMode, isEditMode} = useReduxData();

    useEffect(()=> {
        const [iconType, productId, iconIndex] = figureName.split("-");
        const iconSetType = iconType === "p"
            ? "polygons"
            : iconType === "c" ? "polylines" : iconType === "i" ? "icons" : "etc";
        if (icons[iconSetType]?.[productId]) {
            if (iconSetType === "etc") {
                setIconData(icons.etc[productId].icons[iconIndex].icon);
            } else {
                setIconData(icons[iconSetType][productId].icons[iconIndex]);
            }
        }
    }, [figureName, icons]);

    const handleFigureClick = () => {
        if (!isWatchMode) {
            dispatch({type: "tool", value: figureName});
            dispatch({type: "isEditMode", value: false});

            if (["c", "a"].includes(figureName.split("-")[0]) && color.toLowerCase() === "#ffffff") {
                dispatch({type: "color", value: parameters[figureName]?.color || "#000000"});
            } else if (parameters[figureName]?.color) {
                dispatch({type: "color", value: parameters[figureName].color});
            }

            if (parameters[figureName]?.volume) {
                dispatch({type: "volume", value: +parameters[figureName].volume});
            }
        }
        if (setOpenedSubpartitionIndex) {
            setOpenedSubpartitionIndex(-1);
        }
    };

    return <div
        className={`figure-bar-tool-wrapper${type}${isWatchMode ? " disabled" : ""}`}
        onClick={handleFigureClick}
        style={{backgroundColor: figureName === currentFigureName && !isEditMode ? "#FF983D" : ""}}
    >
        <div className="figure-bar-tool" style={{backgroundImage: `url(${iconData})`, backgroundSize: "contain"}}/>
    </div>;
}

export default tooltipHOC(hintModeTooltipHOC(FigureBarItem));

import React, {useState} from "react";

import TabTitles from "./TabTitles";
import TabContent from "./TabContent";


export default function() {
    const [tabIndex, setTabIndex] = useState(0);
    const selectTab = (event) => setTabIndex(+event.target.id.split("-")[2]);

    return <>
        <TabTitles tabIndex={tabIndex} selectTab={selectTab}/>
        <TabContent tabIndex={tabIndex}/>
    </>;
}

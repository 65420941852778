import {debounce, select} from 'redux-saga/effects'
import {validate as validateUuid} from "uuid";

import {convertStateToSave} from "./customHooks/utils/map";
import {getProjectUuid} from "./utils/currentProject";
import {saveMap} from "./utils/mapAutosave";


function* autosave() {
    const state = yield select();
    const data = convertStateToSave(state);
    if (!['saveToServer', 'saveToComputer', 'createMap'].includes(data.main.lastAction.type)) {
        const projectUuid = getProjectUuid();
        saveMap(validateUuid(projectUuid) ? projectUuid : "", data);
    }
}

export function* mainSaga() {
    yield debounce(1000, "*", autosave);
}

import {createContext, useContext, useEffect, useReducer, useState} from "react";
import {Map} from "immutable";


const DataSourceContext = createContext();

export function DataSourceProvider({children}) {
    const [dataSources, dispatch] = useReducer(dataSourceReducer, initialState);
    const [dataSourcesConverted, setDataSourcesConverted] = useState({});

    useEffect(() => {
        setDataSourcesConverted(dataSources.toJS());
    }, [dataSources]);

    return <DataSourceContext.Provider value={{dataSources: dataSourcesConverted, dispatch}}>
        {children}
    </DataSourceContext.Provider>;
}

export function useDataSource() {
    return useContext(DataSourceContext);
}

const initialState = Map({});

const dataSourceReducer = (state, action) => {
    switch (action.type) {
        case "Add or update data source": {
            let newState = state.set(action.id, (state.get(action.id) || Map({})));
            Object.keys(action.data).map(dataKey => {
                newState = newState.set(action.id, newState.get(action.id).set(dataKey, action.data[dataKey]));
            });
            newState = newState.set(action.id, newState.get(action.id).set(
                "dataType",
                getDataType(newState.get(action.id))
            ));
            return newState;
        }
        case "Remove data source": {
            return state.delete(action.id);
        }
        default: {
            return state;
        }
    }
}

function getDataType(dataSource) {
    if (dataSource.get("source") === "server" && dataSource.get("serverSource")) {
        if (dataSource.get("serverSource") === "maps") {
            return "List[m]";
        } else if (dataSource.get("serverSource") === "dump") {
            return dataSource.get("serverDumpTable") === "Users"
                ? "ud"
                : dataSource.get("serverDumpTable") === "Surveys" ? "sd" : "";
        }
    } else if (dataSource.get("source") === "local") {
        let inputFiles = dataSource.get("localFiles");
        if (!inputFiles?.length) {
            return "";
        } else {
            const parsedFileName = inputFiles[0].name.split(".");
            const extension =
                `.${parsedFileName[parsedFileName.length - 2]}.${parsedFileName[parsedFileName.length - 1]}`;
            for (let i = 1; i < inputFiles.length; i++) {
                if (!inputFiles[i].name.endsWith(extension)) {
                    return "unknown";
                }
            }
            const dataType = extension.replace(".json", "").replace(".", "", ) || "unknown";
            return inputFiles.length === 1 ? dataType : `List[${dataType}]`;
        }
    }
    return "";
}
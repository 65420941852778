import React, {useState, useContext} from "react";


const CurrentProjectContext = React.createContext();


export const CurrentProjectProvider = ({children}) => {
    const [get, set] = useState({id: undefined, survey: undefined, reward: undefined, config: undefined});

    return (
        <CurrentProjectContext.Provider value={{get, set}}>
            {children}
        </CurrentProjectContext.Provider>
    );
};


export const useCurrentProject = () => useContext(CurrentProjectContext);

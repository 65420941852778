import {useDispatch} from "react-redux";
import {toast} from "react-toastify";

import UserSelector from "../../../../../components/UserSelector";
import {useReduxData} from "../../../../customHooks/useContextReduxData";
import {useMap} from "../../../../customHooks/useContextMap";
import {useMapMembers} from "../../../../customHooks/useContextMapMembers";
import {createOption} from "../../../../../utils";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";


export default function () {
    const {selectedFigureUuids, figures} = useReduxData();
    const dispatch = useDispatch();
    const map = useMap();
    const {get: mapMembers} = useMapMembers();
    const locale = useLocalization();

    const figureUuid = selectedFigureUuids[0];

    const customSearchUserFunction = async userInput => {
        if (!map.mapId) {
            if (userInput.length > 0) {
                toast.warn(locale.get.studio.warnings.cannotBindUserToObject);
            }
            return [];
        }
        return mapMembers.map(item => item.username).map(createOption);
    }

    const setUsers = async usersNames => {
        if (usersNames.length > 0) {
            const usersIds = mapMembers.filter(member => usersNames.includes(member.username)).map(item => item.user_id);
            dispatch({type: "changeUsersRelatedWithObject", uuid: figureUuid, users: usersIds});
        } else {
            dispatch({type: "changeUsersRelatedWithObject", uuid: figureUuid, users: []});
        }
    }

    const figureUsers = mapMembers.filter(member => figures.get(figureUuid).get("users").includes(member.user_id))
        .map(item => item.username);

    return <UserSelector
        users={figureUsers}
        setUsers={setUsers}
        menuPlacement="bottom"
        className="studio-context-menu-related-users"
        fieldName="relatedUsers"
        shouldAddCurrentUser={true}
        customSearchUserFunction={customSearchUserFunction}
    />;
}

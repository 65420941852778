import React, {useState} from "react";
import {useDispatch} from "react-redux";

import ColorPicker from "./ColorPicker";
import EmojiContainer from "./EmojiContainer";
import EmojiPicker from "./EmojiPicker";
import Slider from "./Slider";
import TextBox from "./TextBox";
import TextArea from "./TextArea";
import Flags from "./Flags";
import FloatingMenuItem from "./FloatingMenuItem";
import FloatingMenuSwitcher from "./FloatingMenuSwitcher";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useReduxData} from "../../customHooks/useContextReduxData";
import {useGroupingObjects} from "../../customHooks/useContextGroupingObjects";
import {useCurrentProject} from "../../customHooks/useContextCurrentProject";
import hintModeTooltipHOC from "../../onboarding/hintModeTooltipHOC";
import {getPreferredFontColorByBackgroundColor} from "../../utils/color";

import {ReactComponent as FloatingMenuHeader} from "../../images/controls/tool-menu-header.svg";


const FloatingMenuTitleImage = hintModeTooltipHOC(
    () => <FloatingMenuHeader className="floating-menu-header"/>,
    "toolMenuDnD"
);

const FloatingMenuItemsContainer = hintModeTooltipHOC(
    ({children, id}) => <div className="floating-menu-container" id={id}>{children}</div>
);

export default function () {
    const locale = useLocalization();
    const {groupObjects, ungroupObjects} = useGroupingObjects();
    const {get: currentProject} = useCurrentProject();
    const {
        color,
        dashLength,
        isGridShow,
        isEditMode,
        isWatchMode,
        selectedFigureUuidsWithoutArrows,
        thickness,
        transparency,
        volume,
    } = useReduxData();

    const dispatch = useDispatch();

    const [shiftX, setShiftX] = useState(0);
    const [shiftY, setShiftY] = useState(0);
    const [currentPosition, setCurrentPosition] = useState({x: "4%", y: "30%"});
    const [isFloatingMenuCaptured, setIsFloatingMenuCaptured] = useState(false);

    const [floatingMenuItemRerenderSign, setFloatingMenuItemRerenderSign] = useState(false); //for rerender on add/delete emoji (for prevent window exceeding)

    const rerenderFloatingMenuItem = () => setFloatingMenuItemRerenderSign(!floatingMenuItemRerenderSign);

    const handleMouseDown = event => {
        let className = event.target.className.baseVal;
        if (className === "floating-menu-header" || event.target.tagName === "circle") {
            let target = (
                className === "floating-menu-header" ? event.target : event.target.parentNode.parentNode
            ).getBoundingClientRect();
            setIsFloatingMenuCaptured(true);
            setShiftX(event.pageX - target.left);
            setShiftY(event.pageY - target.top);
        }
    }
    const handleMouseMove = event => isFloatingMenuCaptured && setCurrentPosition({
        x: (event.pageX - shiftX) / window.innerWidth * 100 + "%",
        y: (event.pageY - shiftY) / window.innerHeight * 100 + "%"
    });
    const handleMouseUp = () => setIsFloatingMenuCaptured(false);

    //extra effects
    const switchEditMode = () => dispatch({type: "isEditMode", value: !isEditMode});
    const switchGrid = () => dispatch({type: "isGridShow", value: !isGridShow});

    let hiddenItemsInMenu = currentProject.config?.hiddenElements?.floatingMenu?.items || {};
    let hiddenSwitchersInMenu = currentProject.config?.hiddenElements?.floatingMenu?.switchers || {};

    return !isWatchMode && <>
        <div
            className="floating-menu-background"
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={isFloatingMenuCaptured
                ? {pointerEvents: "auto", cursor: "move"}
                : {pointerEvents: "none"}
            }
        />
        <div
            className="floating-menu"
            id="floating-menu"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={{
                left: `clamp(0%, ${currentPosition.x}, calc(100% - 6.75rem))`,
                top: `clamp(0%, ${currentPosition.y}, calc(100% - 26rem))`
            }}
        >
            <FloatingMenuTitleImage/>
            {hiddenSwitchersInMenu !== "all" && <p className="floating-menu-submenu-title">{locale?.get.studio.floatingMenu.mouse}</p>}
            {hiddenSwitchersInMenu !== "all" && <FloatingMenuItemsContainer id="floating-menu-mode-container" hintModeKey="toolMenuModes">
                {!("modes" in hiddenSwitchersInMenu) && <FloatingMenuSwitcher
                    id="0"
                    images={["/images/toolMenu/icon-editing-mode.svg"]}
                    shouldHighlighted={isEditMode}
                    shouldDisabled={isWatchMode}
                    extraEffect={switchEditMode}
                    tooltipContent={locale?.get.studio.floatingMenu.editingMode}
                    hintModeKey="toolMenuEditMode"
                />}
                {!("group" in hiddenSwitchersInMenu) && <FloatingMenuSwitcher
                    id="2"
                    images={["/images/toolMenu/icon-group.png"]}
                    extraEffect={groupObjects}
                    shouldDisabled={isWatchMode || !isEditMode || selectedFigureUuidsWithoutArrows.length < 2}
                    tooltipContent={locale?.get.studio.floatingMenu.group}
                    hintModeKey="toolMenuGroup"
                />}
                {!("ungroup" in hiddenSwitchersInMenu) && <FloatingMenuSwitcher
                    id="3"
                    images={["/images/toolMenu/icon-ungroup.png"]}
                    extraEffect={ungroupObjects}
                    shouldDisabled={isWatchMode || !isEditMode || selectedFigureUuidsWithoutArrows.length === 0}
                    tooltipContent={locale?.get.studio.floatingMenu.ungroup}
                    hintModeKey="toolMenuUngroup"
                />}
            </FloatingMenuItemsContainer>}
            <div className="floating-menu-divider"/>
            {hiddenItemsInMenu !== "all" && <FloatingMenuItemsContainer id="floating-menu-container" hintModeKey="toolMenuParameters">
                {(!("objectName" in hiddenItemsInMenu) || hiddenItemsInMenu.objectName !== "all") && <FloatingMenuItem
                    id="4"
                    name="objectName"
                    image={"/images/toolMenu/icon-name.svg"}
                    body={<TextBox property="name" hiddenItems={hiddenItemsInMenu?.objectName || {}}/>}
                    title={locale?.get.studio.floatingMenu.name}
                    tooltipContent={locale?.get.studio.floatingMenu.name}
                    hintModeKey="toolMenuName"
                />}
                {!("textArea" in hiddenItemsInMenu) && <FloatingMenuItem
                    id="5"
                    name="textArea"
                    image={"/images/toolMenu/icon-description.svg"}
                    body={<TextArea/>}
                    title={locale?.get.studio.floatingMenu.description}
                    tooltipContent={locale?.get.studio.floatingMenu.description}
                    hintModeKey="toolMenuDescription"
                />}
                {!("link" in hiddenItemsInMenu) && <FloatingMenuItem
                    id="6"
                    name="textBox"
                    image={"/images/toolMenu/icon-link.svg"}
                    body={<TextBox property="link"/>}
                    title={locale?.get.studio.floatingMenu.link}
                    tooltipContent={locale?.get.studio.floatingMenu.link}
                    hintModeKey="toolMenuLink"
                />}
                {!("emojiPicker" in hiddenItemsInMenu) && <FloatingMenuItem
                    id="7"
                    name="emojiPicker"
                    image={"/images/toolMenu/icon-emoji.svg"}
                    body={<>
                        <EmojiContainer rerenderFloatingMenuItem={rerenderFloatingMenuItem}/>
                        <EmojiPicker rerenderFloatingMenuItem={rerenderFloatingMenuItem}/>
                    </>}
                    floatingMenuItemRerenderSign={floatingMenuItemRerenderSign}
                    tooltipContent={locale?.get.studio.floatingMenu.emoji}
                    hintModeKey="toolMenuEmojis"
                />}
                <div className="floating-menu-divider"/>
                {!("figureColorPicker" in hiddenItemsInMenu) && <FloatingMenuItem
                    id="8"
                    name="figureColorPicker"
                    color={color}
                    image={`/images/toolMenu/icon-figure-color-${getPreferredFontColorByBackgroundColor(color)}.svg`}
                    body={<ColorPicker context="color"/>}
                    tooltipContent={locale?.get.studio.floatingMenu.colorHint}
                    hintModeKey="toolMenuColor"
                />}
                {!("transparency" in hiddenItemsInMenu) && <FloatingMenuItem
                    id="9"
                    name="slider"
                    sliderPurpose="transparency"
                    sliderType="any"
                    image={"/images/toolMenu/icon-transparency.svg"}
                    body={<Slider
                        title={locale?.get.studio.floatingMenu.transparency}
                        action={"changeFigureTransparency"}
                        purpose="transparency"
                        value={transparency}
                        menuAction="transparency"
                        type="any"
                        min="0"
                        max="1"
                        step="0.1"
                    />}
                    tooltipContent={locale?.get.studio.floatingMenu.transparencyHint}
                    hintModeKey="toolMenuTransparency"
                />}
                {!("thickness" in hiddenItemsInMenu) && <FloatingMenuItem
                    id="10"
                    name="slider"
                    sliderPurpose="thickness"
                    sliderType="ca"
                    image={"/images/toolMenu/icon-thickness.svg"}
                    body={<Slider
                        title={locale?.get.studio.floatingMenu.thickness}
                        action={"changeFigureThickness"}
                        purpose="thickness"
                        value={thickness}
                        menuAction="thickness"
                        type="ca"
                        min="1"
                        max="5"
                        step="0.5"
                    />}
                    tooltipContent={locale?.get.studio.floatingMenu.thicknessHint}
                    hintModeKey="toolMenuThickness"
                />}
                {!("volume" in hiddenItemsInMenu) && <FloatingMenuItem
                    id="11"
                    name="slider"
                    sliderPurpose="volume"
                    sliderType="i"
                    image={"/images/toolMenu/icon-radius.svg"}
                    body={<Slider
                        title={locale?.get.studio.floatingMenu.size}
                        action="changeFigureVolume"
                        purpose="volume"
                        value={volume}
                        menuAction="volume"
                        type="i"
                        min="5"
                        max="300"
                        step="5"
                    />}
                    tooltipContent={locale?.get.studio.floatingMenu.sizeHint}
                    hintModeKey="toolMenuResize"
                />}
                {!("dashLength" in hiddenItemsInMenu) && <FloatingMenuItem
                    id="12"
                    name="slider"
                    sliderPurpose="dashLength"
                    sliderType="ca"
                    image={"/images/toolMenu/icon-dash-length.svg"}
                    body={<Slider
                        title={locale?.get.studio.floatingMenu.dash}
                        action={"changeFigureDashLength"}
                        purpose="dashLength"
                        value={dashLength}
                        menuAction="dashLength"
                        type="ca"
                        min="0"
                        max="15"
                        step="1"
                    />}
                    tooltipContent={locale?.get.studio.floatingMenu.dashHint}
                    hintModeKey="toolMenuDash"
                />}
                {!("flags" in hiddenItemsInMenu) && <FloatingMenuItem
                    id="13"
                    name="flags"
                    image={"/images/toolMenu/icon-flag.svg"}
                    body={<Flags rerenderFloatingMenuItem={rerenderFloatingMenuItem}/>}
                    floatingMenuItemRerenderSign={floatingMenuItemRerenderSign}
                    tooltipContent={locale?.get.studio.floatingMenu.flagHint}
                    hintModeKey="toolMenuFlag"
                />}
                {
                    // <FloatingMenuItem
                    //     id="14"
                    //     image={"/images/toolMenu/icon-layout.png"}
                    //     body={<Slider
                    //         title="План: "
                    //         action={"changeFigureLayout"}
                    //         purpose="layout"
                    //         value={props.layout}
                    //         menuAction="changeLayout"
                    //         type="any"
                    //         min="0"
                    //         max="99"
                    //         step="1"
                    //     />}
                    //     hintInOpenedState=""
                    //     hintInClosedState={"Толщина контурной фигуры"}
                    //     setHintText={setHintText}
                    //     hintModeKey="S62"
                    // />
                }
            </FloatingMenuItemsContainer>}
        </div>
    </>;
}

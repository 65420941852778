import React, {useEffect, useState} from "react";

import Figure from "../../figures/Figure";
import {useReduxData} from "../../../customHooks/useContextReduxData";


export default function ({shouldShow, positionX, positionY, isMouseDown}) {
    const {figures, selectedFigureUuids} = useReduxData();

    const [figureToRenderUuids, setFigureToRenderUuids] = useState([]);

    useEffect(() => {
        setFigureToRenderUuids(shouldShow ? selectedFigureUuids : []);
    }, [shouldShow, selectedFigureUuids]);

    return <svg x={positionX} y={positionY}>
        {figureToRenderUuids.map(uuid => figures.get(uuid) &&
            <Figure
                key={figures.get(uuid).get("uuid")}
                figure={figures.get(uuid)}
                kind={"separate"}
                isMouseDown={isMouseDown}
            />
        )}
    </svg>;
}
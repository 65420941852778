import React from "react";

import TabContainer from "./TabContainer";
import SocialNetworkIcon from "../../../../components/SocialNetworkIcon";
import usePostcardImage from "./customHooks/usePostcardImage";
import useSharing from "./customHooks/useSharing";
import {useLocalization} from "../../../../customHooks/useContextLocalization";

import {ReactComponent as TGIcon} from "../../../../images/social/icon-telegram.svg";
import {ReactComponent as VKIcon} from "../../../../images/social/icon-vk.svg";


export default function ({close: closeModal}) {
    const locale = useLocalization();
    const {createImageFromDiv, sendImageToServer, resetImageState} = usePostcardImage();
    const {handleShareToVK, handleShareToTelegram} = useSharing();

    const loadPostcard = () => createImageFromDiv(document.getElementById("postcard-container"));

    const sharePostcard = handleShareToSocialNetwork => async () => {
        let newUrl = await sendImageToServer(document.getElementById("postcard-container"));
        await handleShareToSocialNetwork(newUrl);
    };

    const closePostcard = () => {
        resetImageState();
        closeModal();
    }

    return <div className="postcard-editor-container">
        <h4 className="postcard-title">{locale.get.studio.postcard.title}</h4>
        <TabContainer/>
        <input
            type="button"
            className="modal-button-exit"
            onClick={closePostcard}
        />
        <input
            type="button"
            className="modal-button modal-button-postcard-apply"
            value={locale.get.studio.postcard.download}
            onClick={loadPostcard}
        />
        <div className="postcard-sharing-container">
            <span className="postcard-share-on">{locale.get.studio.postcard.shareOn}</span>
            <SocialNetworkIcon
                id="footer-social-telegram"
                className="postcard-share-wrapper"
                SvgIcon={TGIcon}
                iconSize="1.5rem"
                onClick={sharePostcard(handleShareToTelegram)}
            />
            <SocialNetworkIcon
                id="footer-social-vk"
                className="postcard-share-wrapper"
                SvgIcon={VKIcon}
                iconSize="1.5rem"
                onClick={sharePostcard(handleShareToVK)}
            />
        </div>
    </div>;
}

import {useDrop} from "react-dnd";

import dndItems from "../dndItems";


export default function useIconDummyDropTarget() {
    const [{isOver}, drop] = useDrop(() => ({
        accept: [dndItems.icon],
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            result: monitor.getItem(),
            didDrop: monitor.didDrop()
        })
    }));
    // console.log(isOver);
    return [drop, {dummyIsOver: isOver}];
}
import React, {useState, useContext} from "react";


const ShouldLiftZindexUp = React.createContext();


export const ShouldLiftZindexUpProvider = ({children}) => {
    const [get, set] = useState(undefined);

    return <ShouldLiftZindexUp.Provider value={{get, set}}>
        {children}
    </ShouldLiftZindexUp.Provider>;
};


export const useShouldLiftZindexUp = () => useContext(ShouldLiftZindexUp);

import React, {useEffect} from "react";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import {QueryClientProvider} from "react-query";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {ToastContainer} from "react-toastify";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Main from "./components/main/Main";
import Contacts from "./components/Contacts";
import Manual from "./components/Manual";
import Confidentiality from "./components/Confidentiality";
import Conditions from "./components/Conditions";
import ProductPublicationCompleted from "./components/ProductPublicationCompleted";
import ProductPurchased from "./components/ProductPurchased";
import Account from "./components/account/Account";
import IconArrangementWithProviders from "./components/account/IconArrangement";
import IconSetParametersDev from "./components/account/IconSetParametersDev";
import IconSetArrangementDev from "./components/account/IconSetArrangementDev";
import Licenses from "./components/licenses/Licenses";
import Payment from "./components/payment/Payment";
import Verified from "./components/Verified";
import Product from "./components/product/Product";
import ProductForm from "./components/productForm/ProductForm";

import {CurrentUserProvider} from "../customHooks/useContextCurrentUser";
import {useApiClient} from "../customHooks/useApiClient";
import {useModal} from "../customHooks/useContextModal";
import {useCurrentProject} from "../studio/customHooks/useContextCurrentProject";
import {LocalizationProvider} from "../customHooks/useContextLocalization";
import {CategoriesProvider} from "./customHooks/useContextCategories";
import {LicensesProvider} from "./customHooks/useContextLicenses";

import "react-toastify/dist/ReactToastify.css";
import "./Store.css";


const Store = () => {
    const api = useApiClient();
    const modal = useModal();
    const {set: setCurrentProject} = useCurrentProject();

    useEffect(() => {
        setCurrentProject({id: -1, survey: undefined, reward: undefined, config: undefined});
    }, []);

    return (
        <CategoriesProvider>
        <LicensesProvider>
        <LocalizationProvider>
        <QueryClientProvider client={api.queryClient}>
        <CurrentUserProvider>
        <DndProvider backend={HTML5Backend}>
        <BrowserRouter basename = "/store">
            {modal?.getModal()}
            <Header/>
            <div className = "store-container">
                <Switch>
                    <Route path='/contacts' component = {Contacts}/>
                    <Route path='/verified' component = {Verified}/>
                    <Route path='/manual' component = {Manual}/>
                    <Route path='/confidentiality' component = {Confidentiality}/>
                    <Route path='/conditions' component = {Conditions}/>

                    <Route path='/licenses/:id' component = {Licenses}/>
                    <Route path = '/product/add/' component = {ProductForm}/>
                    <Route path = '/product/published/' component = {ProductPublicationCompleted}/>
                    <Route path = '/product/purchased/' component = {ProductPurchased}/>

                    <Route path='/payment/:id' component={Payment}/>
                    <Route path='/product/update/:id' component={ProductForm}/>
                    <Route path='/product/:id' component={Product}/>
                    <Route path='/account/:id/icons/dev-parameters' component={IconSetParametersDev}/>
                    <Route path='/account/:id/icons/dev-arrangement' component={IconSetArrangementDev}/>
                    <Route path='/account/:id/icons' exact component={IconArrangementWithProviders}/>
                    <Route path='/account/:id' exact component={Account}/>

                    <Route path='/:type' exact component = {Main}/>
                    <Route path='/'>
                        <Redirect to="/all"/>
                    </Route>
                </Switch>
                <Footer/>
            </div>
            <ToastContainer theme="colored"/>
        </BrowserRouter>
        </DndProvider>
        </CurrentUserProvider>
        </QueryClientProvider>
        </LocalizationProvider>
        </LicensesProvider>
        </CategoriesProvider>
    );
};

export default Store;

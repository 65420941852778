import React from "react";
import {useDispatch} from "react-redux";

import Slider from "../../common/Slider";
import {useReduxData} from "../../../customHooks/useContextReduxData";


export default function () {
    const {scale} = useReduxData();
    const dispatch = useDispatch();

    const handleScaleChange = event => dispatch({type: "scale", value: +event.target.value});

    return <Slider
        id="scale-slider"
        value={scale}
        min="0"
        max="5.5"
        step="0.01"
        displayValueDenominator={0.01}
        unitsName="%"
        onChange={handleScaleChange}
        hintModeKey="scaleSlider"
        style={{backgroundColor: "#9CC6EA"}}
    />;
}
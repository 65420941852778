import {v4 as uuid, validate as uuidValidate} from "uuid";

import {
    arrowSchema,
    groupSchema,
    pointFeatureSchema,
    polygonSchema,
    polylineSchema,
    rectangleSchema,
    textSchema
} from "./validators";
import {checkIfArrowDependent} from "../components/figures/utils/arrow";


export function setFixedChildrenValues (figures, uuid, parameters, dispatch, newValues, actionType, time, condition=()=>true) {
    let figure = figures.get(uuid);
    if (figure.get("tool") !== "g-s-72") {
        if (parameters.length === 2) {
            if (figure.get(parameters[0]) !== undefined || figure.get(parameters[1]) !== undefined) {
                let actionBody = {
                    uuid: uuid,
                    type: actionType,
                    time: time
                };
                actionBody[parameters[0]] = newValues[0];
                actionBody[parameters[1]] = newValues[1];
                condition(figure) && dispatch(actionBody);
            }
        } else {
            if (figure.get(parameters[0]) !== undefined) {
                let actionBody = {
                    uuid: uuid,
                    type: actionType,
                    time: time
                };
                actionBody[parameters[0]] = newValues[0];
                condition(figure) && dispatch(actionBody);
            }
        }
    } else {
        if (figure.get(parameters[0]) !== undefined && parameters[0] === "layout") {
            let actionBody = {
                uuid: uuid,
                type: actionType,
                time: time,
                layout: newValues[0]
            };
            condition(figure) && dispatch(actionBody);
        }
        figure.get("children").map(u => {
            setFixedChildrenValues(figures, u, parameters, dispatch, newValues, actionType, time, condition);
            return undefined;
        });
    }
    return undefined;
}

export function setDifferentChildrenValues (figures, uuid, parameters, dispatch, newValues, actionType, time) {
    if (parameters.length === 0 || parameters.length !== newValues.length) { //incorrect input
        return;
    }
    let figure = figures.get(uuid);
    if (figure.get("tool") !== "g-s-72") {
        let parametersToSet = {};
        parameters.map((param, i) => {
            if (figure.get(param) !== undefined) {
                parametersToSet[param] = newValues[i];
            }
        });
        if (Object.keys(parametersToSet).length > 0) {
            let actionBody = {...parametersToSet, uuid: uuid, type: actionType, time: time};
            dispatch(actionBody);
        }
    } else {
        figure.get("children").map((u, index) => {
            const valuesToSet = newValues.map(newVal => newVal[index]);
            setDifferentChildrenValues(figures, u, parameters, dispatch, valuesToSet, actionType, time);
        });
    }
}

export function getChildrenValues(figures, uuid, parameter) {
    let figure = figures.get(uuid);
    if (!figure) {
        return [];
    }
    if (figure.get("tool") !== "g-s-72") {
        return figure.get(parameter);
    } else {
        let result = [];
        if (figure.get(parameter) !== undefined) {
            result.push(figure.get(parameter));
        }
        figure.get("children").map(u => result.push(getChildrenValues(figures, u, parameter)));
        return result;
    }
}

export function getChildrenUuidsSatisfiedTheCondition(figures, uuid, condition=()=>true) {
    let figure = figures.get(uuid);
    if (!figure) {
        return null;
    }

    if (figure.get("tool") !== "g-s-72") {
        if (condition(figure)) {
            return uuid;
        } else {
            return [];
        }
    } else {
        let result = [];
        if (condition(figure)) {
            result.push(uuid);
        }
        figure.get("children").map(u => result.push(getChildrenUuidsSatisfiedTheCondition(figures, u, condition)));
        return result;
    }
}

/**
 * Get uuid of the oldest ancestor of figure with given uuid
 * @param  {String}         uuid                 A uuid of source figure
 * @param  {Map}            figures              The figures object from state (immutable Map)
 * @return {String}                              uuid of the oldest ancestor of figure with given uuid
 */
export function getRootUuid(figures, uuid) {
    if (figures.get(uuid).get("parentUuid")) {
        return getRootUuid(figures, figures.get(uuid).get("parentUuid"));
    }
    return uuid;
}

/**
 * Get ordered list of all ancestors of the figure with given uuid (current uuid is the first item,
 * the oldest ancestor is the last one)
 * @param  {String}         uuid                 A uuid of source figure
 * @param  {Map}            figures              The figures object from state (immutable Map)
 * @return {Array<String>}                       List of uuids of ancestors
 */
export function getUuidHierarchy(figures, uuid) {
    if (!uuid || !figures.get(uuid)) {
        return [];
    }
    if (figures.get(uuid).get("parentUuid")) {
        return [uuid].concat(getUuidHierarchy(figures, figures.get(uuid).get("parentUuid")));
    }
    return [uuid];
}

const figureToSchemaMapping = {
    a: {schema: arrowSchema, action: "addArrow"},
    c: {schema: polylineSchema, action: "addPolyline"},
    g: {schema: groupSchema, action: "addGroup"},
    i: {schema: pointFeatureSchema, action: "addPointFeature"},
    p: {schema: polygonSchema, action: "addPolygon"},
    r: {schema: rectangleSchema, action: "addRectangle"},
    t: {schema: textSchema, action: "addText"}
};

export function renderObjectCopy(
    figures,
    oldUuid,
    newParentUuid,
    dispatch,
    time,
    orderIndex,
    xCoo,
    yCoo
) {
    let sourceFigure = figures?.get(oldUuid)?.toJS();
    if (!sourceFigure) {
        return "";
    }

    const objType = sourceFigure.tool.split("-")[0][0]; //we need first symbol of type only

    //dependent arrows will be rendered separately, after creation dependencies' copies
    if (checkIfArrowDependent(figures?.get(oldUuid))) {
        return "dependent";
    }

    let newObject = {};
    Object.keys(sourceFigure).map(key => {
        newObject[key] = sourceFigure[key];
        return undefined;
    });
    let newUuid = uuid();

    newObject["uuid"] = newUuid;
    newObject["parentUuid"] = newParentUuid;
    newObject["orderIndex"] = orderIndex;

    if (objType === "g") {
        newObject["children"] = [];
    } else if (objType === "a") {
        newObject["toX"] = newObject["toX"] + xCoo;
        newObject["toY"] = newObject["toY"] + yCoo;
        newObject["fromX"] = newObject["fromX"] + xCoo;
        newObject["fromY"] = newObject["fromY"] + yCoo;
    } else {
        newObject["x"] = newObject["x"] + xCoo;
        newObject["y"] = newObject["y"] + yCoo;
    }

    if (objType === "g") {
        sourceFigure.children.map(u => {
            let newChildUuid = renderObjectCopy(figures, u, newUuid, dispatch, time, orderIndex, xCoo, yCoo);
            if (newChildUuid !== "") {
                newObject["children"].push(newChildUuid);
            }
            return undefined;
        });
    }

    const validationSchema = figureToSchemaMapping[objType].schema;
    if (!validationSchema.isValidSync(newObject)) {
        return;
    }

    newObject["type"] = figureToSchemaMapping[objType].action;
    newObject["time"] = time;
    dispatch(newObject);

    return newUuid;
}

/**
 * Check whether figure selected or not
 * @param  {String}         uuid                 An item checked whether it in the array or not
 * @param  {Array<String>}  selectedFigureUuids  The array of selected figure uuids
 * @param  {Map}            figures              The figures object from state (immutable Map)
 * @return {Boolean}                             True if figure or one of its ancestors selected, false otherwise
 */
export function isFigureSelected (uuid, selectedFigureUuids, figures) {
    if (!uuidValidate(uuid)) {
        return false;
    }
    const figureUuids = getUuidHierarchy(figures, uuid);
    return selectedFigureUuids.filter(item => figureUuids.includes(item)).length > 0;
}

import Tooltip from "react-tooltip-lite";


const ProductTooltip = ({text}) => {
    return <Tooltip
        content={<span className = "product-form-tooltip">{text}</span>}
        arrow={false}
        direction="bottom"
        tipContentHover={true}
        padding="0"
    ><p className = "product-form-radio-item-hint">?</p></Tooltip>
}

export default ProductTooltip;
import React from 'react';


export default function HintConnector(props) {
    return <circle
        className = "hint-connector"
        cx = {props.x}
        cy = {props.y}
        r = "3"
        stroke = "white"
        strokeWidth = "2"
        fill = "black"
    />;
}

import React from "react";
import {useDispatch} from "react-redux";

import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";


function EditorModeSwitch() {
    const locale = useLocalization();
    const {isWatchMode, lastTime} = useReduxData();

    const dispatch = useDispatch();

    const handleModeSwitch = () => {
        dispatch({type:"isWatchMode", value: !isWatchMode});
        if (lastTime) {
            dispatch({type: "selectedFigureUuids", value: []});
        }
    }

    return <div className="editor-mode-switch">
        <div
            className="editor-mode-item"
            id="editor-redacting-mode"
            title={locale?.get.studio.footer.right.editorModeSwitchHint}
            style={{
                backgroundImage: "url(../../../images/toolMenu/icon-redacting-mode.svg)",
                backgroundSize: "contain",
                backgroundColor: (!isWatchMode ? "#FAFEFF" : "#C4DEF5")
            }}
            onClick={handleModeSwitch}
        />
        <div
            className="editor-mode-item"
            id="editor-watch-mode"
            title={locale?.get.studio.footer.right.editorModeSwitchHint}
            style={{
                backgroundImage: "url(../../../images/toolMenu/icon-watching-mode.svg)",
                backgroundSize: "contain",
                backgroundColor: (isWatchMode ? "#FAFEFF" : "#C4DEF5")
            }}
            onClick={handleModeSwitch}
        />
    </div>;
}

export default hintModeTooltipHOC(EditorModeSwitch, "editorModeSwitch");

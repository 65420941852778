

export default function ({className, id, color, x, y, height, width, onMouseDown}) {
    return <svg
        className={className}
        id={id}
        x={x}
        y={y}
        width={width || "10"}
        height={height || "12"}
        fill={color}
        stroke={color}
        viewBox="0 0 10 12"
        xmlns="http://www.w3.org/2000/svg"
        onMouseDown={onMouseDown}
    >
        <path d="M0,0 L 0,12"/>
        <path d="M0,0 L 10,0 L 8,3 L 10,6 L 0,6  Z"/>
    </svg>;
}
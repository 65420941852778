import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from 'react-redux';

import TopMenu from "./topMenu/TopMenu";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useCurrentUser, UserToolbarView} from "../../../customHooks/useContextCurrentUser";
import {useModal} from "../../../customHooks/useContextModal";
import {useMap} from "../../customHooks/useContextMap";
import {useReduxData} from "../../customHooks/useContextReduxData";
import hintModeTooltipHOC from "../../onboarding/hintModeTooltipHOC";
import getTimeElapsedSince from "../getTimeElapsedSince";


const MapTitle = hintModeTooltipHOC(() => {
    const {isChanged} = useMap();
    const {startTime, mapName} = useReduxData();

    const [editable, setEditable_] = useState(false);
    const [value, setValue_] = useState("");

    const dispatch = useDispatch();

    const setEditable = useCallback(() => setEditable_(true), []);
    const save = useCallback(() => {
        if (value !== "") {
            dispatch({type: "setMapName", name: value, time: getTimeElapsedSince(startTime)});
        }
        setEditable_(false);
    }, [value, dispatch]);

    const setValue = useCallback(e => setValue_(e.target.value), [setValue_]);

    useEffect(() => setValue_(mapName), [mapName]);

    return (
        <span className="map-title-wrapper">
            {editable && <input
                className="map-title-input"
                id="map-title-input"
                type="text"
                value={value}
                onChange={setValue}
                onBlur={save}
                autoFocus={true}
                style={{width: value.length + "rem"}}
            />}
            {!editable && <h4 className="map-title-header" onClick={setEditable}>{mapName}{isChanged && "*"}</h4>}
        </span>
    );
}, "mapName", {display: "flex", flexGrow: 1});


const AuthBlock = () => {
    const modal = useModal();
    const locale = useLocalization();
    const user = useCurrentUser();

    const signIn = () => modal.set("signin");
    const signUp = () => modal.set("signup");

    return user ? <UserToolbarView user={user}/> : <>
        <span className="top-menu-auth-button" onClick={signIn}>{locale?.get.auth.signingin}</span>
        <span className="top-menu-auth-button" onClick={signUp}>{locale?.get.auth.signingup}</span>
    </>;
};


export default function () {
    return <header className="top-block">
        <TopMenu/>
        <MapTitle/>
        <AuthBlock/>
    </header>;
}

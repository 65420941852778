import {useEffect} from "react";
import {useDrag} from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";

import dndItems from "../dndItems";


export default function useIconDragSource(selectedIcons, tabSource, source) {
    const [{}, drag, dragPreview] = useDrag(() => ({
        type: dndItems.icon,
        item: {iconIds: selectedIcons, tabSource: tabSource, source: source},
        collect: () => ({}),
    }), [selectedIcons, source]);

    useEffect(() => { //hide default preview
        dragPreview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    return drag;
}
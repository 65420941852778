import {useDataSource} from "../../hooks/useContextDataSource";
import {useLocalization} from "../../../../../../../customHooks/useContextLocalization";


export default function ({selectedNodeId}) {
    const {dataSources, dispatch} = useDataSource();
    const locale = useLocalization();

    const setServerDataSourceOfNode = event => {
        dispatch({
            type: "Add or update data source",
            id: selectedNodeId,
            data: {serverSource: event.target.value}
        });
    };

    return <div className="project-analytics-source-node-configurator-option-container">
        <div>
            <input
                type="radio"
                id="use-maps"
                name="server-data-type"
                value="maps"
                checked={dataSources[selectedNodeId]?.serverSource === "maps"}
                onChange={setServerDataSourceOfNode}
            />
            <label htmlFor="use-maps">{locale?.get?.project.analytics.mainPage.dfd.configurator.useMaps}</label>
        </div>
        <div>
            <input
                type="radio"
                id="use-dump"
                name="server-data-type"
                value="dump"
                checked={dataSources[selectedNodeId]?.serverSource === "dump"}
                onChange={setServerDataSourceOfNode}
            />
            <label htmlFor="use-dump">{locale?.get?.project.analytics.mainPage.dfd.configurator.useDumpOf}</label>
        </div>
    </div>;
}

import React from "react";

import {useModal} from "../../../../../customHooks/useContextModal";
import {useCurrentUser} from "../../../../../customHooks/useContextCurrentUser";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";

import {ReactComponent as Share} from "../images/first-block/share.svg";


const IconShare = tooltipHOC(
    hintModeTooltipHOC(Share, "firstToolBlockSharing", {marginBottom: "-2px", marginTop: "-2px"}),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () { //TODO: add hint here
    const locale = useLocalization();
    const modal = useModal();
    const user = useCurrentUser();

    const openSharing = () => modal.set(user ? "sharing" : "signin");

    return <div className="tool-block-item" onClick={openSharing}>
        <IconShare tooltipContent={locale.get.studio.header.firstToolBlock.sharing}/>
    </div>;
}

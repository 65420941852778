import {useMenu} from "./hooks/useMenu";
import {getPreferredFontColorByBackgroundColor} from "../../../../../../../studio/utils/color";
import MulticoloredCircle from "./MulticoloredCircle";


export default function ({dataToRender, figuresToShowTransparencies, category}) {
    const {shouldShowCooRanges, figuresToShow, shouldMakeRareObjectsTranslucent} = useMenu();
    // console.log(dataToRender);
    // console.log(figuresToShowTransparencies);
    return Object.keys(dataToRender).map(settlement => {
        const settlementObject = dataToRender[settlement];
        const transparency = shouldMakeRareObjectsTranslucent ? figuresToShowTransparencies[settlement] : "";

        return figuresToShow[category].indexOf(settlement) !== -1 ? <g>
            {shouldShowCooRanges && <rect
                x={settlementObject.minX + 100}
                y={settlementObject.minY + 50}
                width={settlementObject.maxX - settlementObject.minX}
                height={settlementObject.maxY - settlementObject.minY}
                fill={`${settlementObject.color}10`}
                stroke="none"
            />}
            <text
                fill={`${settlementObject.fontColor}${transparency}`}
                x={(settlementObject.x + settlementObject.volume + 100) + 2}
                y={(settlementObject.y + 50) + settlementObject.fontSize * 0.7}
                fontSize={settlementObject.fontSize * 2}
            >{`${settlement} (${settlementObject.orderIndex})`}</text>
            {category === "Населенные пункты" ? <>
                {/*<circle*/}
                {/*    r={settlementObject.volume / 2}*/}
                {/*    cx={(settlementObject.x + 100)}*/}
                {/*    cy={(settlementObject.y + 50)}*/}
                {/*    fill={`${settlementObject.color}${transparency}`}*/}
                {/*    stroke={`#000000${transparency}`}*/}
                {/*/>*/}
                <MulticoloredCircle
                    r={settlementObject.volume / 2}
                    x={(settlementObject.x + 100)}
                    y={(settlementObject.y + 50)}
                    colorDistribution={settlementObject.sourceColor}
                    transparency={transparency}
                />
            </> : <rect
                width={settlementObject.volume}
                height={settlementObject.volume}
                x={(settlementObject.x + 100) - settlementObject.volume / 2}
                y={(settlementObject.y + 50) - settlementObject.volume / 2}
                fill={`${settlementObject.color}${transparency}`}
                stroke={`#000000${transparency}`}
            />}
            <text
                fill={getPreferredFontColorByBackgroundColor(settlementObject.fontColor)}
                x={settlementObject.x + 40 - settlementObject.volume / 6}
                y={(settlementObject.y + 50) + settlementObject.volume * 0.2 - 2}
                fontSize={settlementObject.volume / 2}
            >{Math.round(settlementObject.frequency * 100) + "%"}</text>
        </g> : null
    });
}

import React from "react";

import {useLocalization} from "../../customHooks/useContextLocalization";


export default function () {
    const {get: lang} = useLocalization();
    return {
        menuFile: {
            content: lang.studio.onboarding.hintMode.menuFile,
            placement: "right"
        },
        menuEdit: {
            content: lang.studio.onboarding.hintMode.menuEdit,
            placement: "right"
        },
        menuHelp: {
            content: lang.studio.onboarding.hintMode.menuHelp,
            placement: "right"
        },
        menuOnboarding: {
            content: lang.studio.onboarding.hintMode.menuOnboarding,
            placement: "right"
        },
        menuStore: {
            content: lang.studio.onboarding.hintMode.menuStore,
            placement: "right"
        },

        mapName: {
            content: lang.studio.onboarding.hintMode.mapName,
            placement: "down"
        },

        currentLocale: {
            content: lang.studio.onboarding.hintMode.currentLocale,
            placement: "up"
        },
        editorModeSwitch: {
            content: lang.studio.onboarding.hintMode.editorModeSwitch,
            placement: "up"
        },
        timeSlider: {
            content: lang.studio.onboarding.hintMode.timeSlider,
            placement: "up"
        },
        scaleSlider: {
            content: lang.studio.onboarding.hintMode.scaleSlider,
            placement: "up"
        },
        firstToolBlockUndo: {
            content: lang.studio.onboarding.hintMode.firstToolBlockUndo,
            placement: "down"
        },
        firstToolBlockRedo: {
            content: lang.studio.onboarding.hintMode.firstToolBlockRedo,
            placement: "down"
        },
        firstToolBlockCopy: {
            content: lang.studio.onboarding.hintMode.firstToolBlockCopy,
            placement: "down"
        },
        firstToolBlockPaste: {
            content: lang.studio.onboarding.hintMode.firstToolBlockPaste,
            placement: "down"
        },
        firstToolBlockSave: {
            content: lang.studio.onboarding.hintMode.firstToolBlockSave,
            placement: "down"
        },
        firstToolBlockExport: {
            content: lang.studio.onboarding.hintMode.firstToolBlockExport,
            placement: "down"
        },
        firstToolBlockMyMaps: {
            content: lang.studio.onboarding.hintMode.firstToolBlockMyMaps,
            placement: "down"
        },
        firstToolBlockSharing: {
            content: lang.studio.onboarding.hintMode.firstToolBlockSharing,
            placement: "down"
        },
        secondToolBlockLayers: {
            content: lang.studio.onboarding.hintMode.secondToolBlockLayers,
            placement: "right"
        },
        secondToolBlockCopyStyle: {
            content: lang.studio.onboarding.hintMode.secondToolBlockCopyStyle,
            placement: "right"
        },
        secondToolBlockPasteStyle: {
            content: lang.studio.onboarding.hintMode.secondToolBlockPasteStyle,
            placement: "right"
        },

        figureBarPartitionArrowPrev: {
            content: lang.studio.onboarding.hintMode.figureBarPartitionArrowPrev,
            placement: "right"
        },
        figureBarPartitionArrowNext: {
            content: lang.studio.onboarding.hintMode.figureBarPartitionArrowNext,
            placement: "down"
        },
        figureBarPartitionResizer: {
            content: lang.studio.onboarding.hintMode.figureBarPartitionResizer,
            placement: "up"
        },
        figureBarToolsArrowPrev: {
            content: lang.studio.onboarding.hintMode.figureBarToolsArrowPrev,
            placement: "right"
        },
        figureBarToolsArrowNext: {
            content: lang.studio.onboarding.hintMode.figureBarToolsArrowNext,
            placement: "left"
        },
        figureBarTool: {
            content: lang.studio.onboarding.hintMode.figureBarTool,
            placement: "right"
        },
        figureBarSubpartition: {
            content: lang.studio.onboarding.hintMode.figureBarSubpartition,
            placement: "right"
        },

        baseTransformationalConnector: {
            content: lang.studio.onboarding.hintMode.baseConnector,
            placement: "up"
        },
        directiveTransformationalConnector: {
            content: lang.studio.onboarding.hintMode.directiveConnector,
            placement: "up"
        },
        closingConnector: {
            content: lang.studio.onboarding.hintMode.closingConnector,
            placement: "up"
        },
        snappingConnector: {
            content: lang.studio.onboarding.hintMode.bicolorConnector,
            placement: "up"
        },
        multisnappingConnector: {
            content: lang.studio.onboarding.hintMode.tricolorConnector,
            placement: "up"
        },

        toolMenuDnD: {
            content: lang.studio.onboarding.hintMode.toolMenuDnD,
            placement: "right",
        },
        toolMenuModes: {
            content: lang.studio.onboarding.hintMode.toolMenuModes,
            placement: "right",
        },
        toolMenuEditMode: {
            content: lang.studio.onboarding.hintMode.toolMenuEditMode,
            placement: "right",
        },
        secondToolBlockOutline: {
            content: lang.studio.onboarding.hintMode.secondToolBlockOutline,
            placement: "right",
        },
        secondToolBlockGrid: {
            content: lang.studio.onboarding.hintMode.secondToolBlockGrid,
            placement: "right",
        },
        secondToolBlockCenter: {
            content: lang.studio.onboarding.hintMode.secondToolBlockCenter,
            placement: "right",
        },
        secondToolBlockBackground: {
            content: lang.studio.onboarding.hintMode.secondToolBlockBackground,
            placement: "right",
        },
        toolMenuParameters: {
            content: lang.studio.onboarding.hintMode.toolMenuParameters,
            placement: "right",
        },
        toolMenuGroup: {
            content: lang.studio.onboarding.hintMode.toolMenuGroup,
            placement: "right",
        },
        toolMenuUngroup: {
            content: lang.studio.onboarding.hintMode.toolMenuUngroup,
            placement: "right",
        },
        toolMenuName: {
            content: lang.studio.onboarding.hintMode.toolMenuName,
            placement: "right",
        },
        toolMenuDescription: {
            content: lang.studio.onboarding.hintMode.toolMenuDescription,
            placement: "right",
        },
        toolMenuLink: {
            content: lang.studio.onboarding.hintMode.toolMenuLink,
            placement: "right",
        },
        toolMenuEmojis: {
            content: lang.studio.onboarding.hintMode.toolMenuEmojis,
            placement: "right",
        },
        toolMenuDash: {
            content: lang.studio.onboarding.hintMode.toolMenuDash,
            placement: "right",
        },
        toolMenuResize: {
            content: lang.studio.onboarding.hintMode.toolMenuResize,
            placement: "right",
        },
        toolMenuColor: {
            content: lang.studio.onboarding.hintMode.toolMenuColor,
            placement: "right",
        },
        toolMenuTransparency: {
            content: lang.studio.onboarding.hintMode.toolMenuTransparency,
            placement: "right",
        },
        toolMenuThickness: {
            content: lang.studio.onboarding.hintMode.toolMenuThickness,
            placement: "right",
        },
        toolMenuFlag: {
            content: lang.studio.onboarding.hintMode.toolMenuFlag,
            placement: "right",
        },
        mapSharing: {
            content: lang.studio.onboarding.hintMode.mapSharing,
            placement: "top"
        }
    };
}

import React from "react";
import Tooltip from "react-tooltip-lite";

import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {getFieldContent} from "../../utils";


const PolygonHint = ({icon, children}) => {
    const locale = useLocalization();

    return <Tooltip
        content={<span className = "product-form-tooltip">
            {`${getFieldContent(icon.name, locale.get.abbr.toLowerCase())} ${icon.version ? `v${icon.version}` : ""}`}
        </span>}
        arrow={false}
        direction="bottom"
        tipContentHover={true}
        padding="0"
    >{children}</Tooltip>;
};

export default PolygonHint;
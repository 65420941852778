import React, {useState} from "react";

import {useReduxData} from "../../../customHooks/useContextReduxData";
import {getPhysicalArrowCoos} from "../../figures/utils/arrow";
import Path from "../../../utils/path.js";
import {CANVAS_HEIGHT, CANVAS_WIDTH} from "../Canvas";


export function createIntermediateFigure(figure, newParams) {
    let figureObject = figure;
    Object.keys(newParams).forEach(param => {
        figureObject = figureObject.set(param, newParams[param]);
    });
    return figureObject;
}


export default function useObjectTransformation() {
    const [startPageX, setStartPageX] = useState(0);
    const [startPageY, setStartPageY] = useState(0);
    const [figuresToChangeInfo, setFiguresToChangeInfo] = useState({});
    const [previewPositions, setPreviewPositions] = useState({});

    const {figures, scale, selectedConnectors} = useReduxData();

    const clearTransformationPreview = () => {
        setStartPageX(0);
        setStartPageY(0);
        setFiguresToChangeInfo({});
        setPreviewPositions({});
    };

    const startPreviewTransformation = (mouseX, mouseY) => {
        const figuresInfo = {};
        const defaultFigurePreview = {};
        selectedConnectors.forEach(connectorId => {
            //Format: {uuid: {connectors: [{segmentIndex: 0, connectorIndex: 0}, ], figure: <FigureObject>}, }
            const [figureUuid, segmentIndex, connectorIndex] = connectorId.split("|");
            if (figureUuid in figuresInfo) {
                figuresInfo[figureUuid].connectors.push({segmentIndex, connectorIndex});
            } else {
                defaultFigurePreview[figureUuid] = {};
                figuresInfo[figureUuid] = {};
                figuresInfo[figureUuid].connectors = [{segmentIndex, connectorIndex}];

                let figure = figures.get(figureUuid);
                const figureType = figure.get("tool").split("-")[0];

                if (["p", "pl", "pr", "c"].includes(figureType)) {  //set skew for path-based (for r and a it is useless)
                    const previewOffsetX = (CANVAS_WIDTH - figure.get("x")) * scale / 5.6,
                        previewOffsetY = (CANVAS_HEIGHT - figure.get("y")) * scale / 5.6;
                    let newParams = {x: figure.get("x") - previewOffsetX, y: figure.get("y") - previewOffsetY};
                    newParams.points = Path.transform(figure.get("points"), previewOffsetX, previewOffsetY, 1, 1);
                    figure = createIntermediateFigure(figure, newParams);
                }
                figuresInfo[figureUuid].figure = figure;
            }
        });
        setFiguresToChangeInfo(figuresInfo);

        setPreviewPositions(defaultFigurePreview);
        setStartPageX(mouseX);
        setStartPageY(mouseY);
    };

    const updatePathPreview = (mouseX, mouseY, figure, connectors) => {
        const figureUuid = figure.get("uuid");
        let minNewPointX = 0, minNewPointY = 0, path = figure.get("points");
        const isFigureEnclosed = ["p", "pl", "pr"].includes(figure.get("tool").split("-")[0]) || figure.get("enclosed");
        const cooShiftX = (mouseX - startPageX) / scale,
            cooShiftY = (mouseY - startPageY) / scale;
        connectors.forEach(connector => {
            let {segmentIndex, connectorIndex} = connector;
            const indexWithinCurve = +segmentIndex - 1;
            const realConnectorIndex = connectorIndex === undefined ? "last" : +connectorIndex;
            const connectorCoo = Path.getPoint(figure.get("points"), 0, indexWithinCurve, realConnectorIndex);
            let newPointX = cooShiftX + connectorCoo.x,
                newPointY = cooShiftY + connectorCoo.y;

            if (newPointX < minNewPointX) {
                minNewPointX = newPointX;
            }
            if (newPointY < minNewPointY) {
                minNewPointY = newPointY;
            }

            path = Path.replacePoints(path, [{
                spInd: 0,
                curveInd: indexWithinCurve,
                conInd: realConnectorIndex,
                x: newPointX,
                y: newPointY
            }]);
            if (indexWithinCurve === -1 && connectorIndex === undefined && isFigureEnclosed) {
                path = Path.replacePoints(path, [
                    {spInd: 0, curveInd: "last", conInd: "last", x: newPointX, y: newPointY}
                ]);
            }
        });
        let previewPosition = {};
        previewPosition[figureUuid] = {};
        if (minNewPointX < 0 || minNewPointY < 0) {
            previewPosition[figureUuid].x = figure.get("x") + minNewPointX;
            previewPosition[figureUuid].y = figure.get("y") + minNewPointY;
            path = Path.transform(path, -minNewPointX, -minNewPointY, 1, 1);
        }
        previewPosition[figureUuid].points = path;
        return previewPosition;
    }

    const updateRectanglePreview = (mouseX, mouseY, figure, connectors) => {
        const figureUuid = figure.get("uuid");
        const segmentIndex = +connectors[0].segmentIndex;

        let previewPosition = {};
        previewPosition[figureUuid] = {};
        const cooShiftX = (mouseX - startPageX) / scale,
            cooShiftY = (mouseY - startPageY) / scale;

        if ([1, 2].includes(segmentIndex)) {
            previewPosition[figureUuid].width = figure.get("width") + cooShiftX;
            previewPosition[figureUuid].x = figure.get("x");
        } else {
            previewPosition[figureUuid].width = figure.get("width") - cooShiftX;
            previewPosition[figureUuid].x = figure.get("x") + cooShiftX;
        }
        if (previewPosition[figureUuid].width < 0) {
            previewPosition[figureUuid].x += previewPosition[figureUuid].width;
            previewPosition[figureUuid].width *= -1;
        }

        if ([2, 3].includes(segmentIndex)) {
            previewPosition[figureUuid].height = figure.get("height") + cooShiftY;
            previewPosition[figureUuid].y = figure.get("y")
        } else {
            previewPosition[figureUuid].height = figure.get("height") - cooShiftY;
            previewPosition[figureUuid].y = figure.get("y") + cooShiftY;
        }
        if (previewPosition[figureUuid].height < 0) {
            previewPosition[figureUuid].y += previewPosition[figureUuid].height;
            previewPosition[figureUuid].height *= -1;
        }
        return previewPosition;
    }

    const updateArrowPreview = (mouseX, mouseY, figure, connectors) => {
        const figureUuid = figure.get("uuid");
        const segmentIndex = +connectors[0].segmentIndex;

        let previewPosition = {};
        previewPosition[figureUuid] = {};
        const cooShiftX = (mouseX - startPageX) / scale,
            cooShiftY = (mouseY - startPageY) / scale;

        const arrowCoos = getPhysicalArrowCoos(figure, figures);
        if (segmentIndex === 0) {
            previewPosition[figureUuid].fromX = arrowCoos.from.x + cooShiftX;
            previewPosition[figureUuid].fromY = arrowCoos.from.y + cooShiftY;
        } else {
            previewPosition[figureUuid].toX = arrowCoos.to.x + cooShiftX;
            previewPosition[figureUuid].toY = arrowCoos.to.y + cooShiftY;
        }
        return previewPosition;
    }

    const transformPreview = (mouseX, mouseY) => {
        let localPreviewPositions = {};
        Object.keys(figuresToChangeInfo).forEach((uuid, i) => {
            const figureType = figuresToChangeInfo[uuid].figure.get("tool").split("-")[0];
            const updateFunction = ["p", "pl", "pr", "c"].includes(figureType)
                ? updatePathPreview
                : figureType === "r" ? updateRectanglePreview : figureType === "a" ? updateArrowPreview : undefined;
            if (updateFunction) {
                const previewPosition = updateFunction(
                    mouseX,
                    mouseY,
                    figuresToChangeInfo[uuid].figure,
                    figuresToChangeInfo[uuid].connectors
                );
                if (previewPosition) {
                    localPreviewPositions = Object.assign(localPreviewPositions, previewPosition);
                }
            }
        });
        setPreviewPositions(Object.assign({}, previewPositions, localPreviewPositions));
    };

    let transformationPreviews = {};
    Object.keys(previewPositions).forEach(uuid => {
        if (previewPositions[uuid]) {
            transformationPreviews[uuid] = createIntermediateFigure(
                figuresToChangeInfo[uuid].figure,
                previewPositions[uuid]
            );
        }
    });

    return {startPreviewTransformation, transformPreview, clearTransformationPreview, transformationPreviews};
}

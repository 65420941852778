import React from "react";

import Icon from './Icon';

import styles from "./rating.module.css";

import {ReactComponent as StarHeavyRaw} from "../images/star-heavy.svg";
import {ReactComponent as StarLightRaw} from "../images/star-light.svg";


const StarHeavy = props =>
    <StarHeavyRaw height="1.25rem" width="1.625rem" viewBox="0 0 20 20" onClick={props.onClick} {...props}/>;
const StarLight = props =>
    <StarLightRaw height="1.25rem" width="1.625rem" viewBox="0 0 20 20" onClick={props.onClick} {...props}/>;
const StarHeavySmall = () => <StarHeavyRaw height="1.125rem" width="1.375rem" viewBox="0 0 20 18"/>;
const StarLightSmall = () => <StarLightRaw height="1.125rem" width="1.375rem" viewBox="0 0 20 18"/>;


export default function ({n, shouldRenderNormalStars, viewBox, onClick}) {
    let arr = [];
    for (let i = 1; i <= 5; i++) {
        let star = shouldRenderNormalStars
            ? i <= n ? StarHeavy : StarLight
            : i <= n ? StarHeavySmall : StarLightSmall;
        arr.push(<Icon
            id={`semui-rating-star-${i}`}
            key={i}
            icon={star}
            viewBox={viewBox}
            className={styles.image}
            onClick={onClick}
        />);
    }
    return <div className={styles.rating}>{arr}</div>;
}

import React from "react";
import {useHistory} from "react-router-dom";

import HeaderPattern from "./HeaderPattern";
import Icon from "../Icon";
import Search from "./Search";
import {useCurrentUser, UserToolbarView} from "../../../customHooks/useContextCurrentUser";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useModal} from "../../../customHooks/useContextModal";

import {ReactComponent as StoreLogo} from "../../../images/logo.svg";

import headerStyles from "./header.module.css";


const StoreName = () => {
    return <div className = {headerStyles["name-container"]}>
        <span className = {headerStyles["name-studio"]}>Creative Maps Store</span>
        <span className = {headerStyles["name-semantics"]}> — Human Semantics</span>
    </div>
};


export default function () {
    const user = useCurrentUser();
    const locale = useLocalization();
    const modal = useModal();
    const history = useHistory();

    const signIn = () => modal.set("signin");
    const signUp = () => modal.set("signup");

    const goHome = () => history.push("/");
    const goToAccount = () => history.push(`/account/${user.id}`);

    const icons = [
        <Search key = "header-icon-0"/>,
        user
            ? <UserToolbarView user = {user} prefix = "store-" onClick = {goToAccount} key = "header-icon-1"/>
            : <div className = {headerStyles["auth-button-container"]} key = "header-icon-1">
                <p
                    className = {headerStyles["sign-in"]}
                    onClick = {signIn}
                >{locale?.get?.auth.signingin}</p>
                <p className = {headerStyles["separator"]}>|</p>
                <p
                    className = {headerStyles["sign-up"]}
                    onClick = {signUp}
                >{locale?.get?.auth.signingup}</p>
            </div>
    ];

    return <HeaderPattern
        icons={icons}
        logoViewBox="0 0 40 40"
        renderIconLogo={(props) => <Icon icon={StoreLogo} {...props} />}
        renderIconName={(props) => <Icon icon={StoreName} {...props} />}
        onClick={goHome}
    />;
};

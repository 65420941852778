import {transformDegToRad} from "../../../../../../../studio/customHooks/utils/longPolygonCreation";


const getCooOnCircle = (r, x, y, angle) => ({
    x: x + r * Math.cos(transformDegToRad(angle)),
    y: y + r * Math.sin(transformDegToRad(angle))
});


function createArc (r, x, y, shiftAngle, arcAngle, color) {
    const {x: fromX, y: fromY} = getCooOnCircle(r, x, y, shiftAngle);
    const {x: toX, y: toY} = getCooOnCircle(r, x, y, arcAngle);
    const isAngleGreaterHalf = (arcAngle - shiftAngle) > 180 ? 1 : 0;

    return <path d={`M ${fromX} ${fromY} A ${r} ${r}, 0, ${isAngleGreaterHalf}, 1, ${toX} ${toY} L ${x} ${y} Z`} fill={color}/>;
}


export default function ({r, x, y, colorDistribution, transparency}) {
    const colorUsageCount = Object.keys(colorDistribution)
        .map(colorName => colorDistribution[colorName])
        .reduce((acc, val) => acc + val, 0);
    let processedDistribution = Object.keys(colorDistribution).map(colorName => ({
        color: colorName,
        fraction: colorDistribution[colorName] / colorUsageCount * 360
    }));
    processedDistribution.sort((a, b) => a.fraction - b.fraction);

    const arcs = [];
    let currentAngle = 0;
    for (let i = 0; i < processedDistribution.length; i++) {
        arcs.push(createArc(
            r,
            x,
            y,
            currentAngle,
            currentAngle + processedDistribution[i].fraction,
            `${processedDistribution[i].color}${transparency}`
        ));
        currentAngle += processedDistribution[i].fraction;
    }

    return arcs;
}

import React from "react";
import Tooltip from "react-tooltip-lite";

import {Hint} from "../../components/tooltipHOC";
import {useHintMode} from "../customHooks/useContextHintMode";
import useHintModeParams from "./useHintModeParams";


function Beacon ({content}) {
    return <Hint content={content} style={{pointerEvents: "all"}}>
        <span className="hint-mode-beacon">?</span>
    </Hint>;
}

export default function hintModeTooltipHOC (ChildComponent, hintModeKeyFromHOC, customWrapperStyles) {
    return ({hintModeKey, ...props}) => {
        const hintMode = useHintMode();
        const hintModeParams = useHintModeParams();

        const key = hintModeKey || hintModeKeyFromHOC; //generic components (like a menuItem) receive key via props
        const hint = hintModeParams[key];

        return (hintMode.get && key && hint)
            ? <Tooltip
                content={<Beacon content={hint.content}/>}
                isOpen={true}
                arrow={false}
                padding="0px"
                direction={hint.placement}
                styles={customWrapperStyles || {}}
            >
                <ChildComponent {...props}/>
            </Tooltip>
            : <ChildComponent {...props}/>;
    }
}

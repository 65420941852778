import {useState, useEffect} from "react";
import {Link} from "react-router-dom";

import {useApiClient} from "../../customHooks/useApiClient";
import {useLocalization} from "../../customHooks/useContextLocalization";


export default function ({groupName, endpoint}) {
    const [projects, setProjects] = useState([]);

    const locale = useLocalization();
    const api = useApiClient();

    useEffect(() => {
        const asyncFunction = async () => {
            const data = (await api.get(`/api/v2/projects/my/${endpoint}`)).data;
            setProjects(data);
        };
        asyncFunction();
    }, [api]);

    return <div>
        {projects.length !== 0 && <h2 className="project-page-title">{groupName}</h2>}
        <table className="project-group-table">
            {projects.map(p => <tr>
                <td><Link className="project-link" to={`/project/${p.id}`}>{p.name}</Link></td>
                <td>{locale?.get?.project.certainProject.analysts} {p.analysts.length}</td>
                <td>{locale?.get?.project.certainProject.participants} {p.participants.length}</td>
                <td>{locale?.get?.project.certainProject.maps} {p.maps}</td>
                <td>{locale?.get?.project.certainProject.surveys} {p.surveys}</td>
                <td><Link className="project-link" to={`/project/${p.id}/editing`}>
                    {locale?.get?.project.certainProject.edit}
                </Link></td>
                <td>{locale?.get?.project.certainProject.finish}</td>
            </tr>)}
        </table>
    </div>;
}

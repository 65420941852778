import React, {useMemo} from "react";

import Icon from "../Icon";
import useIconDragSource from "./hooks/useIconDragSource";


const IconDragSource = ({id, idPrefix, icon, checkIcon, selectedIcons, tabSource, source, subpartitionSource}) => {
    const drag = useIconDragSource(selectedIcons, tabSource, source);

    const isIconSelected = useMemo(
        () => selectedIcons?.length > 0 && selectedIcons[0].id
            ? selectedIcons.map(el => `${el.id}-${el.source}`).includes(`${id}-${subpartitionSource}`)
            : selectedIcons?.includes(id),
        [selectedIcons, id]
    );

    return <div ref={isIconSelected ? drag : null} onClick={checkIcon}>
        <Icon id={`${idPrefix}${id}`} icon={icon} isSelected={selectedIcons && isIconSelected}/>
    </div>;
}

export default IconDragSource;

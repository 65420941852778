import React from 'react';

import {replaceAt} from "../../../../utils";


export default function QuestionRadio ({content, lang, answers, setAnswers}) {
    const setValue = event => setAnswers(replaceAt(answers, content.id - 1, event.target.value));

    let component = <>
        {content.title && <p className="survey-p">{content.title[lang]}</p>}
        <div className="survey-answer-radio-container" style={
            {display: "flex", flexDirection: content.direction, margin: content.isQuestionInOneLine ? "0.6rem" : "0rem"}
        }>
            {content.choices.map(choice => <div className="survey-answer-radio-wrapper">
                <input
                    id={choice.value}
                    className="survey-answer-radio-hidden-std"
                    type="radio"
                    name={content.id - 1}
                    value={choice.value}
                    onChange={setValue}
                />
                <label className="survey-answer-radio-label" htmlFor={choice.value}>{choice.text[lang]}</label>
            </div>)}
        </div>
    </>;
    return content.isQuestionInOneLine ? <div style={{display: "flex"}}>{component}</div> : component;
}

import React from "react";
import {useDispatch} from "react-redux";

import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import {useReduxData} from "../../../../customHooks/useContextReduxData";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";

import {ReactComponent as NoOutline} from "../images/second-block/no-outline.svg";
import {ReactComponent as Outline} from "../images/second-block/outline.svg";


const IconOutline = tooltipHOC(
    hintModeTooltipHOC(({icon}) => icon, "secondToolBlockOutline"),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () {
    const locale = useLocalization();
    const {isGridShow, isWatchMode} = useReduxData();
    const dispatch = useDispatch();

    const icon = isGridShow ? <Outline/> : <NoOutline/>;

    const switchOutline = () => dispatch({type: "isGridShow", value: !isGridShow});

    return <div
        className={`tool-block-big-item${isWatchMode ? " disabled" : ""}`}
        id="header-outline-switcher"
        onClick={switchOutline}
    >
        <IconOutline tooltipContent={locale?.get.studio.header.secondToolBlock.outline} icon={icon}/>
    </div>;
}

import React, {useState} from "react";
import {useHistory} from "react-router";

import Scrollbar from "../../../components/Scrollbar";
import LoadingPage from "../LoadingPage";
import Page404 from "../Page404";
import TabContainer from "./iconArrangement/TabContainer";
import PolygonHint from "./iconArrangement/PolygonHint";
import IconDragSource from "./iconArrangement/dnd/IconDragSource";
import RetractableSubsection from "./iconArrangement/RetractableSubsection";
import {CustomDragLayer} from "./iconArrangement/dnd/CustomDragLayer";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import useTabs, {TabProvider} from "./hooks/useContextTabs";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useIconSet} from "../../../customHooks/useContextIconSet";
import useBinDropTarget from "./iconArrangement/dnd/hooks/useBinDropTarget";
import {getFieldContent} from "../utils";

import "./account.css";


function IconArrangement() {
    const history = useHistory();
    const currentUser = useCurrentUser();
    const locale = useLocalization();
    const user = useCurrentUser();
    const {tabs, dispatchTabs, resetTabs, saveTabs} = useTabs();
    const {get: {icons}} = useIconSet();
    const [binDrop, {isOver}] = useBinDropTarget(dispatchTabs);

    const [selectedIcons, setSelectedIcons] = useState([]);

    const saveChanges = () => saveTabs();
    const cancelChanges = () => resetTabs();
    const goOut = () => {
        const answer = confirm(locale.get.store.icons.exitWarn);
        if (answer) {
            history.goBack();
        }
    }; //TODO: add custom warning about unsaved changes

    const checkAll = event => {
        let partitionToCheck = event.target.id.split("-")[event.target.id.split("-").length - 1];
        let iconsToAdd = [];
        if (partitionToCheck === "polygon") {
            Object.keys(icons.polygons).map(id => Object.keys(icons.polygons[id].icons).map(
                index => !selectedIcons.includes(`p-${id}-${index}`) && iconsToAdd.push(`p-${id}-${index}`)
            ));
        } else if (partitionToCheck === "polyline") {
            Object.keys(icons.polylines).map(id => Object.keys(icons.polylines[id].icons).map(
                index => !selectedIcons.includes(`c-${id}-${index}`) && iconsToAdd.push(`c-${id}-${index}`)
            ));
        } else {
            let id = partitionToCheck;
            Object.keys(icons.icons[id].icons).map(
                index => !selectedIcons.includes(`i-${id}-${index}`) && iconsToAdd.push(`i-${id}-${index}`)
            );
        }
        setSelectedIcons(selectedIcons.concat(iconsToAdd));
    };
    const uncheckAll = event => {
        let partitionToUncheck = event.target.id.split("-")[event.target.id.split("-").length - 1];
        let iconsToRemove = [];
        if (partitionToUncheck === "polygon") {
            Object.keys(icons.polygons).map(id => Object.keys(icons.polygons[id].icons).map(
                index => selectedIcons.includes(`p-${id}-${index}`) && iconsToRemove.push(`p-${id}-${index}`)
            ));
        } else if (partitionToUncheck === "polyline") {
            Object.keys(icons.polylines).map(id => Object.keys(icons.polylines[id].icons).map(
                index => selectedIcons.includes(`c-${id}-${index}`) && iconsToRemove.push(`c-${id}-${index}`)
            ));
        } else {
            let id = partitionToUncheck;
            Object.keys(icons.icons[id].icons).map(
                index => selectedIcons.includes(`i-${id}-${index}`) && iconsToRemove.push(`i-${id}-${index}`)
            );
        }
        setSelectedIcons(selectedIcons.filter(el => !iconsToRemove.includes(el)));
    };

    const checkIcon = event => {
        let id = event.target.id.split("-");
        let type = id[2], productId = id[3], iconId = id[4];

        let whereIsThisIcon = selectedIcons.indexOf(`${type}-${productId}-${iconId}`);
        if (whereIsThisIcon !== -1) {
            setSelectedIcons(selectedIcons.slice(0, whereIsThisIcon).concat(selectedIcons.slice(whereIsThisIcon + 1)));
        } else {
            setSelectedIcons(selectedIcons.concat([`${type}-${productId}-${iconId}`]));
        }
    };

    return locale?.get && ([].includes("loading")
        ? <LoadingPage/>
        : ([].includes("error")
            ? <Page404/>
            : <div className = "account-icons-container">
                <CustomDragLayer icons={icons} tabs={tabs}/>
                <div className = "account-icons-sidebar-container">
                    <span className="account-user-name">{locale.get.store.icons.header}</span>
                    <div className = "account-icons-menu-container">
                        <span onClick={saveChanges}>{locale.get.store.icons.saveChanges}</span>
                        <span onClick={cancelChanges}>{locale.get.store.icons.cancelChanges}</span>
                        <span onClick={goOut}>{locale.get.store.icons.goOut}</span>
                    </div>
                    <Scrollbar
                        autohide={true}
                        classNamePrefix="account-icons-"
                        style={{height: "calc(100% - 17rem)", width: "100%"}}
                    >
                        <div className="account-icons-categories-container" ref={binDrop}>
                            <span className="account-icons-category-polygon-title">
                                {locale.get.store.icons.polylines}
                            </span>
                            <div className="account-icons-category-container">
                                <span
                                    className="account-icons-check-span"
                                    id="account-icons-check-span-polyline"
                                    onClick={checkAll}
                                >
                                    {locale.get.store.icons.checkAll}
                                </span>
                                <span
                                    className="account-icons-check-span"
                                    id="account-icons-uncheck-span-polyline"
                                    onClick={uncheckAll}
                                >
                                    {locale.get.store.icons.uncheckAll}
                                </span>
                                <div className="account-icons-product-icons-container">
                                    {icons.polylines && Object.keys(icons.polylines).map(id => Object.keys(icons.polylines[id].icons).map(index =>
                                        <PolygonHint key={`account-icons-hint-${id}-${index}`} icon={icons.polylines[id]}>
                                            <IconDragSource
                                                idPrefix="account-icons-"
                                                id={`c-${id}-${index}`}
                                                icon={icons.polylines[id].icons[index]}
                                                checkIcon={checkIcon}
                                                selectedIcons={selectedIcons}
                                                source="base"
                                            />
                                        </PolygonHint>
                                    ))}
                                </div>
                            </div>

                            <span className="account-icons-category-polygon-title">
                                {locale.get.store.icons.polygons}
                            </span>
                            <div className="account-icons-category-container">
                                <span
                                    className="account-icons-check-span"
                                    id="account-icons-check-span-polygon"
                                    onClick={checkAll}
                                >
                                    {locale.get.store.icons.checkAll}
                                </span>
                                <span
                                    className="account-icons-check-span"
                                    id="account-icons-uncheck-span-polygon"
                                    onClick={uncheckAll}
                                >
                                    {locale.get.store.icons.uncheckAll}
                                </span>
                                <div className="account-icons-product-icons-container">
                                    {icons.polygons && Object.keys(icons.polygons).map(id => Object.keys(icons.polygons[id].icons).map(index =>
                                        <PolygonHint key={`account-icons-hint-${id}-${index}`} icon={icons.polygons[id]}>
                                            <IconDragSource
                                                idPrefix="account-icons-"
                                                id={`p-${id}-${index}`}
                                                icon={icons.polygons[id].icons[index]}
                                                checkIcon={checkIcon}
                                                selectedIcons={selectedIcons}
                                                source="base"
                                            />
                                        </PolygonHint>
                                    ))}
                                </div>
                            </div>

                            <span className="account-icons-category-polygon-title">
                                Прочее
                            </span>
                            <div className="account-icons-category-container">
                                <span
                                    className="account-icons-check-span"
                                    id="account-icons-check-span-polygon"
                                    onClick={checkAll}
                                >
                                    {locale.get.store.icons.checkAll}
                                </span>
                                <span
                                    className="account-icons-check-span"
                                    id="account-icons-uncheck-span-polygon"
                                    onClick={uncheckAll}
                                >
                                    {locale.get.store.icons.uncheckAll}
                                </span>
                                <div className="account-icons-product-icons-container">
                                    {icons.etc && Object.keys(icons.etc).map(id => Object.keys(icons.etc[id].icons).map(index =>
                                        <PolygonHint key={`account-icons-hint-${id}-${index}`} icon={icons.etc[id]}>
                                            <IconDragSource
                                                idPrefix="account-icons-"
                                                id={`${icons.etc[id].icons[index].type}-${id}-${index}`}
                                                icon={icons.etc[id].icons[index].icon}
                                                checkIcon={checkIcon}
                                                selectedIcons={selectedIcons}
                                                source="base"
                                            />
                                        </PolygonHint>
                                    ))}
                                </div>
                            </div>

                            <span className="account-icons-category-icons-title">{locale.get.store.icons.icons}</span>
                            <div className="account-icons-category-container">
                                {icons.icons && Object.keys(icons.icons).map(id => <RetractableSubsection
                                    idSuffix={`-${id}`}
                                    name={`${
                                        getFieldContent(icons.icons[id].name, locale.get.abbr.toLowerCase())
                                    } ${icons.icons[id].version ? `v${icons.icons[id].version}` : ""}`}
                                    key={`retractable-subsection-${id}`}
                                >
                                    <div className="account-icons-product-icons-wrapper">
                                        <span
                                            className="account-icons-check-span"
                                            id={`account-icons-check-span-${id}`}
                                            onClick={checkAll}
                                        >
                                            {locale.get.store.icons.checkAll}
                                        </span>
                                            <span
                                                className="account-icons-check-span"
                                                id={`account-icons-uncheck-span-${id}`}
                                                onClick={uncheckAll}
                                            >
                                            {locale.get.store.icons.uncheckAll}
                                        </span>
                                        <div className="account-icons-product-icons-container">
                                            {Object.keys(icons.icons[id].icons).map(index => <IconDragSource
                                                key={`account-icons-icon-${id}-${index}`}
                                                idPrefix="account-icons-"
                                                id={`i-${id}-${index}`}
                                                icon={icons.icons[id].icons[index]}
                                                checkIcon={checkIcon}
                                                selectedIcons={selectedIcons}
                                                source="base"
                                            />)}
                                        </div>
                                    </div>
                                </RetractableSubsection>)}
                            </div>
                        </div>
                    </Scrollbar>
                </div>
                <div className="account-product-container">
                    <TabContainer/>
                </div>
            </div>
        )
    );
}

function IconArrangementWithProviders() {
    return <TabProvider>
        <IconArrangement/>
    </TabProvider>;
}

export default IconArrangementWithProviders;

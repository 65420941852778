import React from "react";

import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import {useModal} from "../../../../../customHooks/useContextModal";
import {useReduxData} from "../../../../customHooks/useContextReduxData";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";

import {ReactComponent as Layers} from "../images/second-block/layers.svg";


const IconLayers = tooltipHOC(
    hintModeTooltipHOC(Layers, "secondToolBlockLayers"),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () {
    const locale = useLocalization();
    const modal = useModal();
    const {isWatchMode} = useReduxData();

    const openLayers = () => modal.set("layers");

    return <div
        className={`tool-block-big-item disabled`}  /*${isWatchMode ? " disabled" : ""}`}*/
        id="header-layers-button"
        onClick={openLayers}
    >
        <IconLayers tooltipContent={locale.get.studio.header.secondToolBlock.layers}/>
    </div>;
}

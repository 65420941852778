import React, {useState, useEffect} from "react";

import {useLocalization} from "../../customHooks/useContextLocalization";

import {ReactComponent as Logo} from "../../images/logo.svg";

import "./servicePage.css";


export default function LoadingPage() {
    const locale = useLocalization();

    const [shouldShowLoading, setShouldShowLoading] = useState(false);

    useEffect(() => {
        const showContent = () => {
            setShouldShowLoading(true);
        }
        setTimeout(showContent, 500);
    }, []);

    return (
        <div className = "page-404-container">
            {shouldShowLoading ? <>
                <Logo className = "loading-logo-animation" height = "110" width = "110" />
                <h1 className = "page-404-title">{locale?.get?.store.loadingPage.title}</h1>
            </> : null}
        </div>
    );
}

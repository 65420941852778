import React from "react";
import {useDispatch} from "react-redux";
import {SketchPicker} from "react-color";

import {useShouldShowColorPicker} from "./customHooks/useContextShouldShowColorPicker";
import {useReduxData} from "../../../../customHooks/useContextReduxData";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";

import {ReactComponent as Background} from "../images/second-block/background-color.svg";


const IconBackground = tooltipHOC(
    hintModeTooltipHOC(({onClick}) => <Background onClick={onClick}/>, "secondToolBlockBackground"),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () {
    const {get: shouldShowSketchPicker, set: setShouldShowSketchPicker} = useShouldShowColorPicker();
    const dispatch = useDispatch();
    const locale = useLocalization();
    const {backgroundColor, isWatchMode} = useReduxData();

    const showOrHideColorPicker = () => setShouldShowSketchPicker(!shouldShowSketchPicker);
    const setBackgroundColor = color => dispatch({type: "backgroundColor", value: color.hex});

    return <div className={`tool-block-big-item${isWatchMode ? " disabled" : ""}`} id="header-background-button">
        <IconBackground
            tooltipContent={locale?.get.studio.header.secondToolBlock.background}
            onClick={showOrHideColorPicker}
        />
        {shouldShowSketchPicker &&
            <SketchPicker color={backgroundColor} disableAlpha={true} onChangeComplete={setBackgroundColor}/>
        }
    </div>;
}
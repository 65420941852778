
const ProductCardPhoto = props => {
    return <div className="product-form-card-photo-wrapper">
        <div style = {{
            backgroundImage: `url(${"data:image/svg+xml;base64," + btoa(props.image)})`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%"
        }}/>
    </div>;
}

export default ProductCardPhoto;

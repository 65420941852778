import React from "react";


/**
 * Parse the SVG DOM node (icon) from a dataUri object
 * @type {HTMLElement}
 * @param  {string}      dataUri   The the SVG in dataUri format (data:image/svg+xml;base64,...)
 * @return {HTMLElement}           The SVG DOM node
 */
export function parseIconNodeFromDataUri(dataUri) {
    const parser = new DOMParser();
    return parser.parseFromString(
        decodeURIComponent(window.atob(dataUri.replace("data:image/svg+xml;base64,", ""))),
        "image/svg+xml"
    ).documentElement;
}

/**
 * Parse the SVG DOM node (icon) to the react node and its viewBox
 * @type {HTMLElement}
 * @param  {HTMLElement}  iconNode   The the SVG DOM node
 * @return {Object}       The next shape object: {figure, parameters}. parameters contain viewBox, fillRule and other
 */
export function parseIconNode(iconNode) { //TODO: do it more universal (animations et al.)
    const {x: viewBoxX, y: viewBoxY, width: viewBoxWidth, height: viewBoxHeight} = iconNode.viewBox.baseVal;

    let parameters = {viewBox: {x: viewBoxX, y: viewBoxY, width: viewBoxWidth, height: viewBoxHeight}};
    if (iconNode.getAttribute("fill-rule")) {
        parameters.fillRule = iconNode.getAttribute("fill-rule");
    }
    if (iconNode.getAttribute("clip-rule")) {
        parameters.clipRule = iconNode.getAttribute("clip-rule");
    }
    const groupNode = Array.from(iconNode.childNodes).filter(node => node.tagName === "g")[0];
    let children = [];
    Array.from(groupNode.childNodes).map((child, i) => {
        if (child.nodeName !== "#text") {
            let attributes = {key: `parsed-icons-${i}`};
            Array.from(child.attributes).map(attr =>
                attributes[
                    attr.name !== "class" ? camelizeKebab(attr.name) : "className"
                ] = attr.name === "style" ? parseCssFromString(attr.nodeValue) : attr.nodeValue
            );
            const childObject = {tagName: child.tagName, attributes: attributes};
            if (
                child.tagName === "style" //add custom styles for icon, which we will not color
                && !child.textContent.includes("-fill") && !child.textContent.includes("-stroke")
            ) {
                childObject.content = child.textContent;
            }
            if (["radialGradient", "linearGradient"].includes(child.tagName)) { //add gradients
                childObject.children = child.childNodes ? Array.from(child.childNodes)
                    .filter(item => item.tagName).map(childOfChild => {
                        let attrs = {};
                        if (childOfChild.attributes) {
                            Array.from(childOfChild.attributes).forEach(attr =>
                                attrs[
                                    attr.name !== "class" ? camelizeKebab(attr.name) : "className"
                                    ] = attr.name === "style" ? parseCssFromString(attr.nodeValue) : attr.nodeValue
                            );
                        }
                        return {tagName: childOfChild.tagName, attributes: attrs};
                    }) : [];
            }

            children.push(childObject);
        }
        return undefined;
    });

    const figure = <g className="figure">
        {children.map(child => React.createElement(
            child.tagName,
            child.attributes,
            child.content || null,
            ...(child.children || []).map(item => React.createElement(item.tagName, item.attributes))
        ))}
    </g>;

    return {figure, parameters};
}

/**
 * Parse the string-based CSS to the JS-object-based CSS
 * @param  {string} cssString The string-based CSS, like follows: "background-color:#144066;height:100%;"
 * @return {Object}           The JS-object-based CSS, like follows: {backgroundColor: "#144066", height: "100%"}
 */
function parseCssFromString(cssString) {
    let result = {};
    let cssRules = cssString.split(";");
    cssRules.map(rule => {
        if (rule !== "") {
            let [ruleName, ruleValue] = rule.split(":");
            result[camelizeKebab(ruleName.trim())] = ruleValue.trim();
        }
    });
    return result;
}

/**
 * convert kebab-cased string to camel-cased one
 * @param  {string} s The kebab-cased string like this: "background-color"
 * @return {string}   The camel-cased string like this: "backgroundColor"
 */
function camelizeKebab(s) {
    return s.replace(/-./g, x=>x[1].toUpperCase());
}
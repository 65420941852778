

/**
 * Compute age of user (by his birthdate)
 * @param  {Object} user  The user object.
 * @param  {Date}   now   Now (time). If not declared, now = new Date().
 * Note, if user hasn't birthdate, function will return undefined
 * @return {number}       The age of user (integer)
 */
export function getAgeOfUser(user, now = new Date()) {
    const birthdate = user?.birthdate ? new Date(user.birthdate) : undefined;
    let age;
    if (birthdate) {
        age = (now.getFullYear() - birthdate.getFullYear()) - (
            now.getMonth() * 100 + now.getDate() < birthdate.getMonth() * 100 + birthdate.getDate() ? 1 : 0
        );
    }
    return age;
}
import React from "react";

import Arrow from "./Arrow";
import Group from "./Group";
import Polygon from "./Polygon";
import Polyline from "./Polyline";
import PointFeature from "./PointFeature";
import Rectangle from "./Rectangle";
import Text from "./Text";


export default function Figure(props) {
    switch (props.figure?.get("tool").split("-")[0]) {
        case "c":
            return <Polyline key={props.figure.uuid} {...props}/>;
        case "i":
            return <PointFeature key={props.figure.uuid} {...props}/>;
        case "p":
            return <Polygon key={props.figure.uuid} {...props}/>;
        case "pl":
            return <Polygon key={props.figure.uuid} {...props}/>;
        case "pr":
            return <Polygon key={props.figure.uuid} {...props}/>;
        case "g":
            return <Group key={props.figure.uuid} {...props}/>;
        case "r":
            return <Rectangle key={props.figure.uuid} {...props}/>;
        case "a":
            return <Arrow key={props.figure.uuid} {...props}/>;
        case "t":
            return <Text key={props.figure.uuid} {...props}/>;
        default: //if studio doesn't have time to update existing figure list
            return null;
    }
}

import React from "react";


export default function () {
    return <div className="modal-container">
        <h4 className="modal-title">Layers' menu mock</h4>
        <p className="modal-p">
            Here will be layers menu
        </p>
    </div>;
}

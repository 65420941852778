import {useEffect} from "react";
import {useDrag} from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";

import dndItems from "../dndItems";


export default function usePartitionDragSource(tabIndex, partitionId) {
    const [{isDragging}, drag, dragPreview] = useDrag({
        type: dndItems.partition,
        item: () => ({tabIndex, partitionId}),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => { //hide default preview
        dragPreview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    return [{isPartitionDragging: isDragging}, drag];
}
import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {isEqual, flattenDeep} from "lodash";

import {useCanvasBoundingClientRect} from "./useContextCanvasBoundingClientRect";
import {useIconSet} from "../../customHooks/useContextIconSet";
import {
    getChildrenUuidsSatisfiedTheCondition,
    getChildrenValues,
    getUuidHierarchy
} from "../utils/figureHierarchy";
import {compareOrderOfTwoFigures} from "../utils/figureOrder";
import {shouldFiguresUpdate} from "./utils/shouldFiguresUpdate";
import {isItemInArray} from "../../utils";
import {getParameterFromFigures} from "../components/canvas/popups/contextMenu/contextMenuFunctions";
import {checkIfArrowDependent} from "../components/figures/utils/arrow";
import Path from "../utils/path";
import {CANVAS_WIDTH, CANVAS_HEIGHT} from "../components/canvas/Canvas";


const ReduxDataContext = React.createContext();


export const ReduxDataProvider = ({children}) => {
    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const {get: {parameters}} = useIconSet();

    const data = {
        actionHistory: useSelector(state => {
            let actionHistory = [];
            for (let i = 0; i < state.get("main").past.length; i++) {
                actionHistory.push(state.get("main").past[i].get("lastAction"));
            }
            actionHistory.push(state.get("main").present.get("lastAction"));
            return actionHistory;
        }, isEqual),
        backgroundColor: useSelector(state => state.get("menu").get("backgroundColor")),
        canvasScrollX: useSelector(state => state.get("menu").get("canvasScrollX")),
        canvasScrollY: useSelector(state => state.get("menu").get("canvasScrollY")),
        checkIfPointFeatureSelected: figure => useSelector(state => {
            if (state.get("menu").get("isWatchMode")) {
                return false;
            }

            let selectedFigureUuids = state.get("menu").get("selectedFigureUuids");
            let figures = state.get("main").present.get("figures");
            let isPointFeatureSelected = false;

            if (figures) {
                getUuidHierarchy(figures, figure.get("uuid")).map(uuid => {
                    if (selectedFigureUuids.indexOf(uuid) !== -1) {
                        isPointFeatureSelected = true;
                    }
                    return undefined;
                });
            }
            return isPointFeatureSelected;
        }),
        clipboard: useSelector(state => state.get("menu").get("clipboard"), isEqual),
        color: useSelector(state => state.get("menu").get("color") || "#FFFFFF"),
        dashLength: useSelector(state => state.get("menu").get("dashLength")),
        figureCenter: useSelector(state => state.get("menu").get("figureCenter"), isEqual), //center of figure context menu shown for
        figureUuidDescriptionPopupShownFor: useSelector(state => state.get("menu").get("figureUuidDescriptionPopupShownFor")),
        figures: useSelector(
            state => {
                let lastAction = state.get("main").present.get("lastAction");
                if (state.get("menu").get("isWatchMode")) {
                    return [state.get("main").present.get("watch").get("figures"), lastAction];
                } else {
                    return [state.get("main").present.get("figures"), lastAction];
                }
            }, (left, right) => (left[1].time === right[1].time || !shouldFiguresUpdate(left[1].type))
        )[0], //we don't need lastAction. It is only need to delivering into equality checker
        fontColor: useSelector(state => state.get("menu").get("fontColor")),
        fontSize: useSelector(state => state.get("menu").get("fontSize")),
        getFiguresUpdatedOn: actions => useSelector(
            state => {
                let lastAction = state.get("main").present.get("lastAction");
                return [state.get("main").present.get("figures"), lastAction];
            },
            (left, right) => (left[1].time === right[1].time || !shouldFiguresUpdate(left[1].type, actions))
        )[0], //we don't need lastAction. It is only need to delivering into equality checker
        getShouldShowFirstImageOfFloatingMenuSwitcher: name => useSelector(
            state => (name ? state.get("menu").get(name) : true)
        ),
        getShouldFloatingMenuItemDisabled: (name, sliderPurpose, sliderType) => useSelector(state => {
            let isWatchMode = state.get("menu").get("isWatchMode");
            let isEditMode = state.get("menu").get("isEditMode");
            let selectedFigureUuids = state.get("menu").get("selectedFigureUuids");

            if (["textBox", "textArea"].includes(name)) {
                return isWatchMode || !isEditMode || selectedFigureUuids.length !== 1;
            } else if (["objectName", "figureColorPicker"].includes(name)) {
                return isWatchMode || (isEditMode && selectedFigureUuids.length === 0);
            } else if (name === "emojiPicker") {
                if (isWatchMode || !isEditMode) {
                    return true;
                }
                if (isEditMode) {
                    if (selectedFigureUuids.length === 0) {
                        return true;
                    }
                    let figures = state.get("main").present.get("figures");
                    let isEmojiPickerVisible = true;
                    let parameterArray = [];
                    selectedFigureUuids.map(u => {
                        let tempArr = flattenDeep([getChildrenValues(figures, u, "tool")]);
                        if (typeof tempArr === "object") {
                            tempArr.map(el => parameterArray.push(el));
                        } else {
                            parameterArray.push(tempArr);
                        }
                        return undefined;
                    });
                    parameterArray.map(tool_ => tool_.split("-")[0] === "c" ? isEmojiPickerVisible = false : null);
                    return !isEmojiPickerVisible;
                }
            } else if (name === "fontColorPicker") {
                if (isWatchMode) {
                    return true;
                }
                if (isEditMode) {
                    if (selectedFigureUuids.length === 0) {
                        return true;
                    }
                    let figures = state.get("main").present.get("figures");
                    let isColorPickerVisible = true;
                    let parameterArray = [];
                    selectedFigureUuids.map(u => {
                        let tempArr = flattenDeep([getChildrenValues(figures, u, "fontColor")]);
                        if (typeof tempArr === "object") {
                            tempArr.map(el => parameterArray.push(el));
                        } else {
                            parameterArray.push(tempArr);
                        }
                        return undefined;
                    });
                    parameterArray.map(param => (param === undefined ? isColorPickerVisible = false : null));
                    return !isColorPickerVisible;
                }
            } else if (name === "slider") {
                if (isWatchMode) {
                    return true;
                }
                let parameter = state.get("menu").get(sliderPurpose);
                let tool = state.get("menu").get("tool");
                let isSliderVisible = false;

                if (!isEditMode && (sliderType === "any" || sliderType.includes(tool.split("-")[0])) && parameter !== undefined) {
                    isSliderVisible = true;
                } else if (isEditMode && selectedFigureUuids.length !== 0) {
                    let parameterArray = [];
                    let figures = state.get("main").present.get("figures");
                    selectedFigureUuids.map(u => {
                        let tempArr = flattenDeep([getChildrenValues(figures, u, sliderPurpose)]);
                        if (typeof tempArr === "object") {
                            tempArr.map(el => parameterArray.push(el));
                        } else {
                            parameterArray.push(tempArr);
                        }
                        return undefined;
                    });
                    isSliderVisible = true;
                    parameterArray.map(param => (param === undefined ? isSliderVisible = false : null));
                }
                return !isSliderVisible;
            } else if (name === "flags") {
                if (isWatchMode) {
                    return true;
                }

                let shouldDisabled = true;
                let figures = state.get("main").present.get("figures");
                let selectedFigureUuids = state.get("menu").get("selectedFigureUuids");
                for (let i = 0; i < selectedFigureUuids.length; i++) {
                    let uuids = flattenDeep([getChildrenUuidsSatisfiedTheCondition(
                        figures,
                        selectedFigureUuids[i],
                        figure => parameters?.[figure.get("tool")]?.canHoldFlag
                    )]);
                    if (uuids.length > 0) {
                        shouldDisabled = false;
                    }
                }
                return shouldDisabled;
            }
            return false;
        }),
        isContextMenuShow: useSelector(state => state.get("menu").get("isContextMenuShow")),
        isEditMode: useSelector(state => state.get("menu").get("isEditMode")),
        isGridShow: useSelector(state => state.get("menu").get("isGridShow")),
        isThereClipboardContent: useSelector(state => !!state.get("menu").get("clipboard").length),
        isThereFuture: useSelector(state => !!state.get("main").future.length),
        isTherePast: useSelector(state => !!state.get("main").past.length),
        isThereSelectedFigures: useSelector(state => !!state.get("menu").get("selectedFigureUuids").length),
        isThereSelectedPointFeature: useSelector(state => {
            const figures = state.get("main").present.get("figures");
            const selectedFigureUuids = state.get("menu").get("selectedFigureUuids");
            return getParameterFromFigures(figures, selectedFigureUuids, "volume")[1];
        }, isEqual),
        isThereSelectedPolyline: useSelector(state => {
            const figures = state.get("main").present.get("figures");
            const selectedFigureUuids = state.get("menu").get("selectedFigureUuids");
            return getParameterFromFigures(figures, selectedFigureUuids, "thickness")[1];
        }, isEqual),
        isValidationAnimationShow: useSelector(state => state.get("menu").get("isValidationAnimationShow")),
        isWatchMode: useSelector(state => state.get("menu").get("isWatchMode")),
        lastAction: useSelector(state => state.get("main").present.get("lastAction"), isEqual),
        lastTime: useSelector(state => {
            let time = 1;
            if (state.get("main").present.get("lastAction").time) {
                time = state.get("main").present.get("lastAction").time + 100;
            } else {
                for (let i = state.get("main").past.length - 1; i >= 0; i--) {
                    if (state.get("main").past[i].get("lastAction")?.time) {
                        time = state.get("main").past[i].get("lastAction").time;
                        break;
                    }
                }
            }
            return time;
        }),
        mapId: useSelector(state => state.get("main").present.get("mapId")),
        mapName: useSelector(state => state.get("main").present.get("mapName")),
        mouseXAtTheCopyTime: useSelector(state => state.get("menu").get("mouseXAtTheCopyTime")),
        mouseYAtTheCopyTime: useSelector(state => state.get("menu").get("mouseYAtTheCopyTime")),
        canvasScrollXAtCopyTime: useSelector(state => state.get("menu").get("canvasScrollXAtCopyTime")),
        canvasScrollYAtCopyTime: useSelector(state => state.get("menu").get("canvasScrollYAtCopyTime")),
        nextFiguresUuids: useSelector(state => (state.get("main").future.length > 1
            ? Object.keys(state.get("main").future[1].get("figures").toJS())
            : []
        ), isEqual),
        objectDescriptionParameters: useSelector(state => {
            const targetUuid = state.get("menu").get("figureUuidDescriptionPopupShownFor");
            const target = state.get("main").present.get("figures")?.get(targetUuid);
            if (target && canvasBoundingClientRect?.get) {
                const scale = state.get("menu").get("scale");
                let centerX = 0, centerY = 0;

                if (["c", "p"].includes(target.get("tool").split("-")[0])) {
                    const transformedPath = Path.transform(target.get("points"), 0, 0, scale, scale);
                    let center = Path.getCenter(transformedPath);
                    centerX = center.centerX;
                    centerY = center.centerY;
                    centerX += canvasBoundingClientRect?.get.left + (target.get("x") + CANVAS_WIDTH / 2) * scale;
                    centerY += canvasBoundingClientRect?.get.top + (target.get("y") + CANVAS_HEIGHT / 2) * scale;
                } else if (target.get("tool").split("-")[0] === "i") {
                    centerX = (
                        target.get("x") + CANVAS_WIDTH / 2 + 2 * target.get("volume")
                    ) * scale + canvasBoundingClientRect?.get.left;
                    centerY = (
                        target.get("y") + CANVAS_WIDTH / 2 + 1.5 * target.get("volume")
                    ) * scale + canvasBoundingClientRect?.get.top;
                }
                return [target.get("name"), target.get("description"), centerX, centerY];
            }
            return ["", "", 0, 0]; //target doesn't exist
        }, isEqual),
        openContextMenuTool: useSelector(state => state.get("menu").get("openContextMenuTool")),
        openedMenuItemNumber: useSelector(state => state.get("menu").get("openedMenuItemNumber")),
        orderedFigures: useSelector(
            state => {
                let orderedFigures;
                if (state.get("menu").get("isWatchMode")) {
                    orderedFigures = state.get("main").present.get("watch").get("figures").toArray();
                } else {
                    orderedFigures = state.get("main").present.get("figures").toArray();
                }

                orderedFigures.sort(compareOrderOfTwoFigures);

                let lastAction = state.get("main").present.get("lastAction");
                return [orderedFigures, lastAction, state.get("menu").get("isWatchMode")];
            }, (left, right) => ((left[1].time === right[1].time || !shouldFiguresUpdate(left[1].type)) && !right[2])
        )[0], //we don't need lastAction. It is only need to delivering into equality checker
        past: useSelector(state => state.get("main").past, (left, right) => left.length === right.length),
        presentState: useSelector(state => state.get("main").present, isEqual),
        previousFiguresUuids: useSelector(state => (state.get("main").past.length > 1
            ? Object.keys(state.get("main").past[state.get("main").past.length - 2].get("figures").toJS())
            : []
        ), isEqual),
        scale: useSelector(state => state.get("menu").get("scale")),
        selectedConnectors: useSelector(state => state.get("menu").get("selectedConnectors") || [], isEqual),
        selectedConnectorUuids: useSelector(state =>
            [...new Set(state.get("menu").get("selectedConnectors")?.map(id => id.split("|")[0]) || [])],
            isEqual
        ),
        selectedFigureUuids: useSelector(state => state.get("menu").get("selectedFigureUuids"), isEqual),
        selectedFigureUuidsWithoutArrows: useSelector(state => {
            let figures = state.get("main").present.get("figures");
            return state.get("menu").get("selectedFigureUuids")
                .filter(u => figures.get(u).get("tool").split("-")[0] !== "a");
        }, isEqual),
        selectedLeafUuids: useSelector(state => {
            let figures = state.get("main").present.get("figures");
            return [...new Set(flattenDeep(
                state.get("menu").get("selectedFigureUuids").map(uuid => getChildrenValues(figures, uuid, "uuid"))
            ))].filter(uuid => figures.get(uuid).get("tool").split("-")[0] !== "g");
        }, isEqual),
        selectedObject: useSelector(state => {
            let figures = state.get("main").present.get("figures");
            let selectedFigureUuids = state.get("menu").get("selectedFigureUuids");

            let isTheOnlyObjectSelected = selectedFigureUuids.length === 1;
            let figure = isTheOnlyObjectSelected && figures.get(selectedFigureUuids[0]);

            let figureUuid = "", figureName = "", figureDescription = "", figureLink = "";

            if (figure) {
                figureUuid = figure.get("uuid");
                figureName = figure.get("name");
                figureDescription = figure.get("description");
                figureLink = figure.get("link");
            }

            return {isTheOnlyObjectSelected, figureUuid, figureName, figureDescription, figureLink};
        }, isEqual),
        shouldCrossesShow: useSelector(state => {
            let selectedFigureUuids = state.get("menu").get("selectedFigureUuids");
            let figures = state.get("main").present.get("figures");
            let flagArray;

            if (selectedFigureUuids.length !== 0) {
                selectedFigureUuids.map(uuid => {
                    if (!uuid) {
                        return;
                    }
                    let figure = figures.get(uuid);
                    let [type,,] = figure.get("tool").split("-");
                    if (type !== "g") {
                        if (parameters?.[figure.get("tool")]?.canHoldFlag) {
                            if (flagArray === undefined) {
                                flagArray = figure.get("flags");
                            } else {
                                flagArray = flagArray.filter(flag => figure.get("flags").includes(flag));
                            }
                        }
                    } else {
                        let uuids = flattenDeep([getChildrenUuidsSatisfiedTheCondition(
                            figures,
                            uuid,
                            figure => parameters?.[figure.get("tool")]?.canHoldFlag
                        )]);
                        uuids.map(u => {
                            if(u) {
                                let child = figures.get(u);
                                if (flagArray === undefined) {
                                    flagArray = child.get("flags");
                                } else {
                                    flagArray = flagArray.filter(flag => child.get("flags").includes(flag));
                                }
                            }
                        })
                    }
                });
            }
            if (flagArray === undefined) {
                flagArray = [];
            }

            return [
                flagArray.indexOf("pink") !== -1,
                flagArray.indexOf("red") !== -1,
                flagArray.indexOf("green") !== -1,
                flagArray.indexOf("blue") !== -1,
                flagArray.indexOf("black") !== -1
            ];
        }, isEqual),
        startTime: useSelector(state => state.get("menu").get("startTime")),
        state: useSelector(state => state),
        thickness: useSelector(state => state.get("menu").get("thickness")),
        tool: useSelector(state => state.get("menu").get("tool")),
        transparency: useSelector(state => state.get("menu").get("transparency")),
        unselectedUuids: useSelector(state => {
            if (state.get("menu").get("isWatchMode")) {
                return [];
            }

            let selectedFigureUuids = state.get("menu").get("selectedFigureUuids");
            let figures = state.get("main").present.get("figures");

            let selectedDependentUuids = [];  // all parents and children of selected figures are here
            selectedFigureUuids.map(uuid => {
                let parents = getUuidHierarchy(figures, uuid);
                parents.map(u => !isItemInArray(u, selectedDependentUuids) && selectedDependentUuids.push(u));
                let children = flattenDeep([getChildrenValues(figures, uuid, "uuid")]);
                children.map(u => !isItemInArray(u, selectedDependentUuids) && selectedDependentUuids.push(u));
                return undefined;
            });

            let unselectedUuids = [];
            figures.map(figure =>  // get all figures, which not related with selected
                !isItemInArray(figure.get("uuid"), selectedDependentUuids)
                && unselectedUuids.push(figure.get("uuid"))
            );
            unselectedUuids = unselectedUuids.filter(uuid =>  // remove figures, parents of which related with selected
                getUuidHierarchy(figures, uuid).slice(1).filter(u => unselectedUuids.includes(u)).length === 0
            );

            const selectedConnectorUuids = [
                ...new Set(state.get("menu").get("selectedConnectors")?.map(id => id.split("|")[0]) || [])
            ];
            unselectedUuids = unselectedUuids.filter(uuid => !selectedConnectorUuids.includes(uuid));
            unselectedUuids = unselectedUuids.filter(uuid =>
                figures.get(uuid).get("tool").split("-")[0] !== "a" || !checkIfArrowDependent(figures.get(uuid))
            );
            return unselectedUuids;
        }, isEqual),
        volume: useSelector(state => state.get("menu").get("volume")),
    };
    return <ReduxDataContext.Provider value={data}>{children}</ReduxDataContext.Provider>;
};


export const useReduxData = () => useContext(ReduxDataContext);

import {useState, useEffect} from "react";
import {useDrop} from "react-dnd";
import {useReactFlow} from "react-flow-renderer";

import dndItems from "../dndItems";


export default function useNodeDropTarget (newNodeId, setNewNodeId, reactFlowContainerRef) {
    const [isDraggedElementHere, setIsDraggedElementHere] = useState(false);
    const [clientX, setClientX] = useState(0);
    const [clientY, setClientY] = useState(0);

    const [offsetWithinNodeX, setOffsetWithinNodeX] = useState(0);
    const [offsetWithinNodeY, setOffsetWithinNodeY] = useState(0);

    const reactFlowInstance = useReactFlow();

    const [{isOver, node, didDrop, nodeType}, drop] = useDrop(() => ({
        accept: [dndItems.sourceNode, dndItems.intermediateNode, dndItems.finalNode],
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            node: monitor.getItem(),
            didDrop: monitor.didDrop(),
            nodeType: monitor.getItemType()
        }),
        hover(item, monitor) {
            const initialSourceClientOffset = monitor.getInitialSourceClientOffset();
            const initialClientOffset = monitor.getInitialClientOffset();
            const clientOffset = monitor.getClientOffset();

            const offsetWithinNode = {
                x: initialClientOffset.x - initialSourceClientOffset.x,
                y: initialClientOffset.y - initialSourceClientOffset.y
            };

            setClientX(clientOffset.x);
            setClientY(clientOffset.y);
            setOffsetWithinNodeX(offsetWithinNode.x);
            setOffsetWithinNodeY(offsetWithinNode.y);
        }
    }));

    //it is needed to avoid adding item to all targets simultaneously
    useEffect(() => setIsDraggedElementHere(isOver), [isOver, setIsDraggedElementHere]);

    useEffect(() => {
        if (node && didDrop && isDraggedElementHere && reactFlowContainerRef?.current) {
            const reactFlowContainerPosition = reactFlowContainerRef.current.getBoundingClientRect();
            const newNode = {
                id: `${newNodeId}`,
                type: nodeType,
                position: reactFlowInstance.project({
                    x: clientX - reactFlowContainerPosition.x - offsetWithinNodeX * reactFlowInstance.getZoom(),
                    y: clientY - reactFlowContainerPosition.y - offsetWithinNodeY * reactFlowInstance.getZoom()
                }),
                data: {label: node.name, inputs: node.inputs, outputs: node.outputs}
            };
            reactFlowInstance.addNodes(newNode);
            setNewNodeId(newNodeId + 1);
        }
    }, [node, didDrop, newNodeId, setNewNodeId]);

    return [drop];
}
import React, {useCallback, useEffect, useRef} from "react";
import {getIn} from "formik";
import ReCAPTCHA from "react-google-recaptcha";

import {useLocalization} from "../customHooks/useContextLocalization";


export const ErrorView = ({children}) => {
    const locale = useLocalization();
    return <span className="error">{locale?.get?.auth.errorView[children] || children}</span>;
};


export const Checkbox = ({field, form: {setFieldValue}, label}) => {
    const onClick = useCallback(() => setFieldValue(field.name, !field.value), [setFieldValue, field.name, field.value]);
    return <>
        <input {...field} type="checkbox" checked={field.value} onClick={onClick}/>
        <label htmlFor='living' onClick={onClick}>{label}</label>
    </>;
};


export const FileInput = ({field, form: {setFieldValue}, ...props}) => {
    const onChange = useCallback(
        (e) => setFieldValue(field.name, e.currentTarget.files),
        [setFieldValue, field.name, field.value]
    );
    return <input name={field.name} type="file" onChange={onChange} {...props}/>;
};


export const RecaptchaField = ({field, form: {setFieldValue, isSubmitting}, ...props}) => {
    const onChange = useCallback((e) => setFieldValue(field.name, e), [setFieldValue, field.name]);
    const recaptchaRef = useRef(null);

    useEffect(() => {
        if (isSubmitting && recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    }, [isSubmitting, recaptchaRef]);

    return <div className="recaptcha-container" style={props.style}>
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={onChange} ref={recaptchaRef}/>
    </div>;
};


export function getStyles(errors, fieldName) {
    if (getIn(errors, fieldName)) {
        return {border: '1px solid red', marginBottom: "4px"};
    }
}

import React from "react";


export default function Line({fromX, fromY, toX, toY, stroke}) {
    const [minX, maxX] = fromX < toX
        ? [fromX, toX]
        : [fromX, toX];

    const [minY, maxY] = fromY < toY
        ? [fromY, toY]
        : [fromY, toY];

    return <line
        x1={minX}
        y1={minY}
        x2={maxX}
        y2={maxY}
        fill="none"
        stroke={stroke}
        strokeWidth="0.0625rem"
        style={{pointerEvents: "none"}}
    />;
}

import React from "react";
import {useDispatch} from "react-redux";

import CustomMDEditor from "../../../components/CustomMDEditor";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useReduxData} from "../../customHooks/useContextReduxData";
import getTimeElapsedSince from "../getTimeElapsedSince";


export default function TextArea() {
    const locale = useLocalization();
    const {
        isEditMode,
        selectedObject: {isTheOnlyObjectSelected, figureUuid, figureDescription},
        startTime
    } = useReduxData();
    const dispatch = useDispatch();

    const handleMDChanged = value => dispatch({
        type: "changeObjectDescription",
        uuid: figureUuid,
        description: value,
        time: getTimeElapsedSince(startTime)
    });

    return <CustomMDEditor
        autoFocus={true}
        className={!isEditMode || !isTheOnlyObjectSelected ? "floating-menu-md-editor-disabled" : "floating-menu-md-editor"}
        textareaProps={{
            placeholder: locale?.get.studio.floatingMenu.textAreaPlaceholder
        }}
        isShortened={true}
        value={isTheOnlyObjectSelected ? figureDescription : ""}
        onChange={handleMDChanged}
    />;
}

import React, {useMemo} from "react";
import {range} from "lodash";

import {useReduxData} from "../../customHooks/useContextReduxData";
import {CANVAS_HEIGHT, CANVAS_WIDTH} from "./Canvas";
import {getPreferredFontColorByBackgroundColor} from "../../utils/color";


function GridHorizontalLine ({y, color}) {
    const {scale} = useReduxData();
    return <line x1="0" y1={y} x2={CANVAS_WIDTH * scale} y2={y} stroke={color} strokeWidth="2px" strokeDasharray="10"/>;
}


function GridVerticalLine ({x, color}) {
    const {scale} = useReduxData();
    return <line x1={x} y1="0" x2={x} y2={CANVAS_HEIGHT * scale} stroke={color} strokeWidth="2px" strokeDasharray="10"/>;
}

export default function ({userViewBox}) {
    const {backgroundColor, scale} = useReduxData();
    const [x, y, width, height] = userViewBox.split(" ").map(item => +item);

    // don't use useEffect, it will flash grid on scale changing
    const bigCellSize = 1000 * scale;
    const darkBorders = {
        x1: Math.ceil(x / bigCellSize) * bigCellSize,
        y1: Math.ceil(y / bigCellSize) * bigCellSize,
        x2: Math.ceil((x + width) / bigCellSize) * bigCellSize,
        y2: Math.ceil((y + height) / bigCellSize) * bigCellSize
    };

    const smallCellSize = 100 * scale;
    const mediumBorders = {
        x1: Math.ceil(x / smallCellSize) * smallCellSize,
        y1: Math.ceil(y / smallCellSize) * smallCellSize,
        x2: Math.ceil((x + width) / smallCellSize) * smallCellSize,
        y2: Math.ceil((y + height) / smallCellSize) * smallCellSize
    };

    const microCellSize = 10 * scale;
    const lightBorders = {
        x1: Math.ceil(x / microCellSize) * microCellSize,
        y1: Math.ceil(y / microCellSize) * microCellSize,
        x2: Math.ceil((x + width) / microCellSize) * microCellSize,
        y2: Math.ceil((y + height) / microCellSize) * microCellSize
    };

    const gridColors = useMemo(() => (() => {
        const gridBaseColor = getPreferredFontColorByBackgroundColor(backgroundColor, 220);
        return gridBaseColor === "white" ? ["#FFF", "#CCC", "#555"] : ["#ccc", "#ddd", "#eee"];
    })(), [backgroundColor]);

    return <>
        {scale > 3 && range(lightBorders.x1, lightBorders.x2, microCellSize).map(xCoo =>
            <GridVerticalLine key={`vLine-${xCoo}`} x={xCoo} color={gridColors[2]}/>
        )}
        {scale > 3 && range(lightBorders.y1, lightBorders.y2, microCellSize).map(yCoo =>
            <GridHorizontalLine key={`hLine-${yCoo}`} y={yCoo} color={gridColors[2]}/>
        )}
        {scale > 0.5 && range(mediumBorders.x1, mediumBorders.x2, smallCellSize).map(xCoo =>
            <GridVerticalLine key={`vLine-${xCoo}`} x={xCoo} color={gridColors[1]}/>
        )}
        {scale > 0.5 && range(mediumBorders.y1, mediumBorders.y2, smallCellSize).map(yCoo =>
            <GridHorizontalLine key={`hLine-${yCoo}`} y={yCoo} color={gridColors[1]}/>
        )}
        {scale > 0.1 && range(darkBorders.x1, darkBorders.x2, bigCellSize).map(xCoo =>
            <GridVerticalLine key={`vLine-${xCoo}`} x={xCoo} color={gridColors[0]}/>
        )}
        {scale > 0.1 && range(darkBorders.y1, darkBorders.y2, bigCellSize).map(yCoo =>
            <GridHorizontalLine key={`hLine-${yCoo}`} y={yCoo} color={gridColors[0]}/>
        )}
    </>;
}
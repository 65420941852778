import TopMenuItem from "./TopMenuItem";
import useMenuItems from "./customHooks/useMenuItems";


export default function () {
    const menuItems = useMenuItems();

    return <ul className="top-menu-container">
        {menuItems.map(item => <TopMenuItem key={item.key} item={item}/>)}
    </ul>;
}
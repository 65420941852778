import {toast} from "react-toastify";
import Dropzone from "react-dropzone";

import {useLocalization} from "../../../../customHooks/useContextLocalization";


const ProductFile = props => {
    const locale = useLocalization();

    const loadFileOnDrop = acceptedFiles => {
        if (acceptedFiles.length > 1) {
            toast.error(`${locale?.get?.store.product.form.warnings.maxFileNumber} 1`);
            return undefined;
        }
        props.loadFile(acceptedFiles[0]);
    };

    const clearField = event => {
        event.stopPropagation();
        props.clearFile();
    }

    return <Dropzone onDrop = {loadFileOnDrop}>
        {({getRootProps, getInputProps}) => (
            <div
                {...getRootProps()}
                className = {`product-form-archive-${props.isFileLoaded ? "wrapper" : "container"}`}
            >
                {props.isFileLoaded
                    ? <div className = "product-form-archive" style={props.customImage ? {
                        backgroundImage: `url(${props.customImage})`,
                        backgroundRepeat: props.repeatImage,
                        backgroundSize: "contain",
                    } : {}}>
                        <div className = "product-form-archive-button-replace"/>
                        <div className = "product-form-archive-button-remove" onClick = {clearField}/>
                    </div>
                    : <label className = "product-form-add-file-link">
                        {locale?.get?.store.product.form.uploadOrDropFileHere}
                    </label>
                }
                <input {...getInputProps()}/>
            </div>
        )}
    </Dropzone>
}

export default ProductFile;
import React from "react";
import Gravatar from "react-gravatar";

import styles from "./avatar.module.css";


export default function ({imgUrl, imgClassName, wrapperClassName, email, size}) {
    return <div className={styles[wrapperClassName] || wrapperClassName}>
        {imgUrl
            ? <img
                src={imgUrl}
                className={styles[imgClassName] || imgClassName}
                alt="avatar image"
                height={size || "auto"}
                width={size || "auto"}
            />
            : (email && <Gravatar email={email} className={styles[imgClassName]} size={size || 50}/>)
        }
    </div>;
}

import React from "react";
import {Route, BrowserRouter, Switch, Redirect, useHistory} from "react-router-dom";

import Analytics from "./analytics/Analytics";
import MyProjects from "./myProjects/MyProjects";
import CertainProject from "./certainProject/CertainProject";
import CreateProject from "./createProject/CreateProject";
import CertainProjectEditing from "./certainProject/CertainProjectEditing";
import {useLocalization} from "../customHooks/useContextLocalization";

import "./project.css";
import "./myProjects/myProjects.css";
import "./analytics/analytics.css";
import "./analytics/baseAnalytics/processingAndAnanysis/dfd/dfd.css";


export default function () {
    const locale = useLocalization();
    const history = useHistory();

    const openStudio = () => history.push("/");
    const openStore = () => history.push("/store/");

    return <BrowserRouter>
        <div className="project-main-container">
            <header className="project-header">
                <span className="project-link" onClick={openStudio}>
                    {locale?.get?.project.myProjects.goToStudio}
                </span>
                <span className="project-link" onClick={openStore}>
                    {locale?.get?.project.myProjects.goToStore}
                </span>
            </header>
            <Switch>
                <Route path="/project/all" component={MyProjects}/>
                <Route path="/project/new" component={CreateProject}/>
                <Route path="/project/:id/analytics" component={Analytics}/>
                <Route path="/project/:id/editing" component={CertainProjectEditing}/>
                <Route path="/project/:id" component={CertainProject}/>
                <Route path="/project/">
                    <Redirect to="/project/all"/>
                </Route>
            </Switch>
        </div>
    </BrowserRouter>;
}
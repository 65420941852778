import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import * as Sentry from "@sentry/react";
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers/rootReducer';
import {mainSaga} from "./saga";


export default function configureStore() {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore( //TODO: use RTK instead of Redux
        rootReducer,
        composeWithDevTools(applyMiddleware(thunk, sagaMiddleware), sentryReduxEnhancer)
    );

    sagaMiddleware.run(mainSaga)

    return store;
}
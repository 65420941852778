import React, {useContext} from "react";
import {useDispatch} from "react-redux";
import {v4 as createUuid} from "uuid";

import {useReduxData} from "./useContextReduxData";
import {renderObjectCopy} from "../utils/figureHierarchy";
import getTimeElapsedSince from "../components/getTimeElapsedSince";
import {useCanvasBoundingClientRect} from "./useContextCanvasBoundingClientRect";
import {getPhysicalArrowCoos} from "../components/figures/utils/arrow";
import {arrowSchema} from "../utils/validators";


const CopyPasteContext = React.createContext();


export const CopyPasteProvider = ({children}) => {
    const {
        canvasScrollX,
        canvasScrollY,
        canvasScrollXAtCopyTime,
        canvasScrollYAtCopyTime,
        clipboard,
        figures,
        mouseXAtTheCopyTime,
        mouseYAtTheCopyTime,
        scale,
        selectedFigureUuids,
        startTime,
    } = useReduxData();
    const {get: canvasBoundingClientRect} = useCanvasBoundingClientRect();
    const dispatch = useDispatch();

    const copyObjects = (mousePosition) => {
        const {left: canvasLeft, top: canvasTop, width: canvasWidth, height: canvasHeight} = canvasBoundingClientRect;
        const mouseXInCanvas = mousePosition.x - canvasLeft - canvasWidth / 2;
        const mouseYInCanvas = mousePosition.y - canvasTop - canvasHeight / 2;

        dispatch({type: "mouseXAtTheCopyTime", value: mouseXInCanvas / scale});
        dispatch({type: "mouseYAtTheCopyTime", value: mouseYInCanvas / scale});
        dispatch({type: "canvasScrollXAtCopyTime", value: canvasScrollX});
        dispatch({type: "canvasScrollYAtCopyTime", value: canvasScrollY});
        dispatch({type: "clipboard", value: selectedFigureUuids});
    };

    const pasteObjects = (mousePosition) => {
        const {left: canvasLeft, top: canvasTop, width: canvasWidth, height: canvasHeight} = canvasBoundingClientRect;
        const mouseXInCanvas = mousePosition.x - canvasLeft - canvasWidth / 2;
        const mouseYInCanvas = mousePosition.y - canvasTop - canvasHeight / 2;

        let shiftX = 20, shiftY = 20;
        if (![mousePosition.x, mousePosition.y].includes(0)) {
            shiftX = mouseXInCanvas / scale - mouseXAtTheCopyTime + canvasScrollX - canvasScrollXAtCopyTime;
            shiftY = mouseYInCanvas / scale - mouseYAtTheCopyTime + canvasScrollY - canvasScrollYAtCopyTime;
        }

        const newUuids = clipboard.map(u => renderObjectCopy(
            figures,
            u,
            undefined,
            dispatch,
            getTimeElapsedSince(startTime),
            Math.round(new Date().getTime()),
            shiftX,
            shiftY
        ));

        // rendering of dependent arrows
        const uuidMapping = {};
        newUuids.forEach((newUuid, i) => {
            uuidMapping[clipboard[i]] = newUuid;
        });
        const dependentArrowsUuids = Object.keys(uuidMapping).filter(uuid => uuidMapping[uuid] === "dependent");
        dependentArrowsUuids.forEach(uuid => {
            const arrow = figures.get(uuid);
            const fromX = arrow.get("fromX");
            const toX = arrow.get("toX");

            let newFromX, newFromY, newToX, newToY;
            if (typeof fromX === "string") {
                const [dependencyUuid, connectorIndex] = fromX.split("|");
                if (clipboard.includes(dependencyUuid)) {
                    const fromCoo = `${uuidMapping[dependencyUuid]}|${connectorIndex}`;
                    newFromX = fromCoo;
                    newFromY = fromCoo;
                } else {
                    const physicalCoos = getPhysicalArrowCoos(arrow, figures);
                    newFromX = physicalCoos.from.x + shiftX;
                    newFromY = physicalCoos.from.y + shiftY;
                }
            } else {
                newFromX = arrow.get("fromX") + shiftX;
                newFromY = arrow.get("fromY") + shiftY;
            }
            if (typeof toX === "string") {
                const [dependencyUuid, connectorIndex] = toX.split("|");
                if (clipboard.includes(dependencyUuid)) {
                    const toCoo = `${uuidMapping[dependencyUuid]}|${connectorIndex}`;
                    newToX = toCoo;
                    newToY = toCoo;
                } else {
                    const physicalCoos = getPhysicalArrowCoos(arrow, figures);
                    newToX = physicalCoos.to.x + shiftX;
                    newToY = physicalCoos.to.y + shiftY;
                }
            } else {
                newToX = arrow.get("toX") + shiftX;
                newToY = arrow.get("toY") + shiftY;
            }

            const newFigureObject = {
                uuid: createUuid(),
                parentUuid: undefined,
                toX: newToX,
                toY: newToY,
                fromX: newFromX,
                fromY: newFromY,
                tool: arrow.get("tool"),
                color: arrow.get("color"),
                fontColor: arrow.get("fontColor"),
                fontSize: arrow.get("fontSize"),
                transparency: arrow.get("transparency"),
                dashLength: arrow.get("dashLength"),
                thickness: arrow.get("thickness"),
                name: arrow.get("name"),
                link: arrow.get("link"),
                description: arrow.get("description"),
                layout: arrow.get("layout"),
                orderIndex: Math.round(new Date().getTime())
            };

            if (!arrowSchema.isValidSync(newFigureObject)) {
                return;
            }
            dispatch({...newFigureObject, type: "addArrow", time: getTimeElapsedSince(startTime)});
        });
    };

    return <CopyPasteContext.Provider value={{copyObjects, pasteObjects}}>{children}</CopyPasteContext.Provider>;
};


export const useCopyPaste = () => useContext(CopyPasteContext);

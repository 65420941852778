import {useDrop} from "react-dnd";

import dndItems from "../dndItems";


export default function usePartitionDropTarget(ref, tabIndex, dispatchTabs, tabs, setCurrentTabIndex, currentTabIndex) {
    const [{handlerId, sourceTabId}, drop] = useDrop({
        accept: dndItems.tab,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
                sourceTabId: monitor.getItem()?.tabId,
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = tabs.map(child=>child.id).indexOf(item.tabId);
            const hoverIndex = tabIndex;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            const hoverClientX = clientOffset.x - hoverBoundingRect.left;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY && hoverClientX < hoverMiddleX) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY && hoverClientX > hoverMiddleX) {
                return;
            }
            dispatchTabs({type: "moveTab", payload: {dragIndex: dragIndex, hoverIndex: hoverIndex}});
            if (currentTabIndex <= hoverIndex && currentTabIndex > dragIndex) {
                setCurrentTabIndex(currentTabIndex - 1);
            } else if (currentTabIndex >= hoverIndex && currentTabIndex < dragIndex) {
                setCurrentTabIndex(currentTabIndex + 1);
            } else if (currentTabIndex === dragIndex) {
                setCurrentTabIndex(hoverIndex);
            }
        },
    });

    return [{handlerId, sourceTabId}, drop];
}
import React, {useState} from 'react';

import {replaceAt} from "../../../../utils";


export default function QuestionRating ({content, lang, answers, setAnswers}) {
    const [hoveredValue, setHoveredValue] = useState(-1);

    const setRating = event => setAnswers(
        replaceAt(answers, content.id - 1, +event.target.id.split("-")[event.target.id.split("-").length - 1])
    );
    const hoverRating = event => setHoveredValue(event.target.id.split("-")[event.target.id.split("-").length - 1]);
    const unhoverRating = () => setHoveredValue(-1);

    return <>
        {content.title && <p className="survey-p">{content.title[lang]}</p>}
        <div className="survey-answer-rating-container" onMouseLeave={unhoverRating}>
            {content.rateValues.map(ans => <div
                id={`survey-answer-rating-item-${ans.value}`}
                className="survey-answer-rating-item"
                onClick={setRating}
                onMouseEnter={hoverRating}
                style={{
                    backgroundColor: (hoveredValue >= ans.value && answers[content.id - 1] !== null && answers[content.id - 1] >= ans.value)
                        ? "rgba(20, 64, 102, 0.8)"
                        : (hoveredValue >= ans.value
                            ? "rgba(20, 64, 102, 0.3)"
                            : (answers[content.id - 1] !== null && answers[content.id - 1] >= ans.value
                                ? "#144066"
                                : "#9CC6EA"
                            )
                        ),
                    color: (hoveredValue >= ans.value && answers[content.id - 1] !== null && answers[content.id - 1] >= ans.value)
                        ? "#C4DEF5"
                        : (hoveredValue >= ans.value
                            ? "#144066"
                            : (answers[content.id - 1] !== null && answers[content.id - 1] >= ans.value
                                ? "#9CC6EA"
                                : "#144066"
                            )
                        )
                }}
            >{ans.value}</div>)}
        </div>
    </>;
}

import {useEffect, useState} from "react";

import {useCurrentUser} from "../../../../../customHooks/useContextCurrentUser";
import {useApiClient} from "../../../../../customHooks/useApiClient";


export default function () {
    const [projects, setProjects] = useState({});

    const currentUser = useCurrentUser();
    const api = useApiClient();

    useEffect(() => {
        api.get("/api/v2/projects/my/can_be_participant").then(response => {
            setProjects(response.data);
        })
    }, [currentUser, api]);


    return projects;
}

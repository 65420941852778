import {useForm} from "react-hook-form";
import {v4 as uuid} from "uuid";
import {useHistory, Link} from "react-router-dom";

import ForbiddenPage from "../ForbiddenPage";
import {useLocalization} from "../../customHooks/useContextLocalization";
import {useApiClient} from "../../customHooks/useApiClient";
import {useCurrentUser} from "../../customHooks/useContextCurrentUser";


export default function () {
    const {register, handleSubmit, watch, formState: { errors }} = useForm();
    const currentUser = useCurrentUser();
    const api = useApiClient();
    const history = useHistory();
    const locale = useLocalization();

    const onSubmit = async data => {
        const id = (await api.post("/api/v2/projects", data)).data;
        alert(`created project with id ${id}`);
        history.push(`/project/${id}`);
    };

    return ![1, 2].includes(currentUser?.role) ? <ForbiddenPage/> : <div className="project-container">
        <Link to="/project/">{locale?.get?.project.certainProject.returnToList}</Link>
        <h1 className="project-page-title">{locale?.get?.project.newProject.newProject}</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
            <input defaultValue="New project 1" {...register("name", {required: true})}/>
            {errors.name && <span>This field is required</span>}
            <input defaultValue="public" {...register("publicity", { required: true })} />
            {errors.publicity && <span>This field is required</span>}
            <input defaultValue={uuid()} {...register("link", { required: true })} />
            {errors.link && <span>This field is required</span>}
            <input defaultValue="[...]" {...register("tabs", { required: true })} />
            {errors.tabs && <span>This field is required</span>}
            <input defaultValue="{...}" {...register("parameters", { required: true })} />
            {errors.parameters && <span>This field is required</span>}


            {/*<input defaultValue="Analysts" {...register("analysts", { required: true })} />*/}
            {/*{errors.analysts && <span>This field is required</span>}*/}
            {/*{watch("publicity") === "private" ? <>*/}
            {/*    <input defaultValue="Participants" {...register("participants", { required: true })} />*/}
            {/*    {errors.participants && <span>This field is required</span>}*/}
            {/*</> : null}*/}
            {/*<input defaultValue="Analytics can be used" {...register("analytics", { required: true })} />*/}
            {/*{errors.analytics && <span>This field is required</span>}*/}
            {/*<input defaultValue="Survey for user {...}" {...register("survey", { required: true })} />*/}
            {/*{errors.survey && <span>This field is required</span>}*/}
            <input type="submit" />
        </form>
    </div>;
}

import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {useReduxData} from "./useContextReduxData";
import {isItemInArray} from "../../utils";
import getTimeElapsedSince from "../components/getTimeElapsedSince";


const DeleteObjectsContext = React.createContext();


export const DeleteObjectsProvider = ({children}) => {
    const [figureToDeleteUuids, setFigureToDeleteUuids] = useState("");
    const {figureUuidDescriptionPopupShownFor, selectedConnectors, selectedFigureUuids, startTime} = useReduxData();
    const dispatch = useDispatch();

    //if delete will be inside deleteObjects, then delete will be possible to perform before than menu was prepared
    useEffect(() => {
        if (figureToDeleteUuids !== "" && selectedFigureUuids.length === 0) {
            figureToDeleteUuids.split("|").map(
                uuid => dispatch({type: "deleteFigure", uuid, time: getTimeElapsedSince(startTime)})
            );
            setFigureToDeleteUuids("");
        }
    }, [figureToDeleteUuids, selectedFigureUuids]);

    const deleteObjects = () => {
        setFigureToDeleteUuids(selectedFigureUuids.join("|"));

        if (isItemInArray(figureUuidDescriptionPopupShownFor, selectedFigureUuids)) {
            dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});
        }
        let filteredConnectors = selectedConnectors.filter(id => !selectedFigureUuids.includes(id.split("|")[0]));
        dispatch({type: "selectedConnectors", value: filteredConnectors});
        dispatch({type: "selectedFigureUuids", value: []});
    };

    return <DeleteObjectsContext.Provider value={{deleteObjects}}>{children}</DeleteObjectsContext.Provider>;
};


export const useDeleteObjects = () => useContext(DeleteObjectsContext);

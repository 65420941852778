import {useEffect, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {useHistory} from "react-router";
import {Link, useParams} from "react-router-dom";

import UserSelector from "../../components/UserSelector";
import ForbiddenPage from "../ForbiddenPage";
import {useLocalization} from "../../customHooks/useContextLocalization";
import {useApiClient} from "../../customHooks/useApiClient";
import {useCurrentUser} from "../../customHooks/useContextCurrentUser";


function AdoptedUserSelector({className, onChange, onBlur, name, value}) {
    return <UserSelector
        users={value}
        setUsers={onChange}
        className={className}
        fieldName={name}
        onBlur={onBlur}
    />;
}


export default function () {
    const [defaultProjectState, setDefaultProjectState] = useState(undefined);

    const {register, handleSubmit, control, formState: {errors}} = useForm();
    const currentUser = useCurrentUser();
    const api = useApiClient();
    const history = useHistory();
    const locale = useLocalization();
    const {id} = useParams();

    useEffect(() => {
        const asyncFunction = async () => {
            const current_project_state = (await api.get(`/api/v2/projects/editing/${id}`)).data;
            console.log(current_project_state);
            current_project_state.survey_scheme = JSON.stringify(current_project_state.survey_scheme, null, 2);
            current_project_state.reward_scheme = JSON.stringify(current_project_state.reward_scheme, null, 2);
            current_project_state.config = JSON.stringify(current_project_state.config, null, 2);
            current_project_state.tabs = JSON.stringify(current_project_state.tabs, null, 2);
            current_project_state.parameters = JSON.stringify(current_project_state.parameters, null, 2);
            setDefaultProjectState(current_project_state);
        };
        asyncFunction();
    }, []);

    const onSubmit = async data => {
        if (data.survey_scheme === "") {
            data.survey_scheme = "{}";
        }
        if (data.reward_scheme === "") {
            data.reward_scheme = "{}";
        }
        if (data.config === "") {
            data.config = "{}";
        }
        const current_id = (await api.put(`/api/v2/projects/${id}`, data)).data;
        alert(`edited project with id ${current_id}`);
        history.push(`/project/${current_id}`);
    };

    return ![1, 2].includes(currentUser?.role) && !currentUser?.is_analyst ? <ForbiddenPage/>
        : <div style={{display: "flex"}}>
            <div className="project-container">
                <Link to={`/project/${id}`}>{locale?.get?.project.certainProject.returnToView}</Link>
                <h1 className="project-page-title">{locale?.get?.project.certainProject.projectEditing}</h1>
                {defaultProjectState
                    ? <form onSubmit={handleSubmit(onSubmit)}>
                        <input
                            defaultValue={defaultProjectState.name}
                            placeholder="Название проекта"
                            {...register("name", {required: true})}
                        />
                        {errors.name && <span>This field is required</span>}
                        <input
                            defaultValue={defaultProjectState.publicity}
                            placeholder="публичность: public/private"
                            {...register("publicity", {required: true})}
                        />
                        {errors.publicity && <span>This field is required</span>}
                        <input defaultValue={defaultProjectState.link} placeholder="ссылка" {...register("link")}/>
                        {errors.link && <span>This field is required</span>}
                        <Controller
                            render={({field}) => <AdoptedUserSelector {...field} className="studio-footer-share-field"/>}
                            control={control}
                            name="users"
                            defaultValue={defaultProjectState.users}
                        />
                        {errors.users && <span>This field is required</span>}
                        <textarea
                            rows="10"
                            cols="50"
                            defaultValue={defaultProjectState.tabs}
                            placeholder="вкладки: []"
                            {...register("tabs", {required: true})}
                        />
                        {errors.tabs && <span>This field is required</span>}
                        <textarea
                            rows="10"
                            cols="50"
                            defaultValue={defaultProjectState.parameters}
                            placeholder="параметры: {}"
                            {...register("parameters", {required: true})}
                        />
                        {errors.parameters && <span>This field is required</span>}
                        <input
                            defaultValue={defaultProjectState.reward_name}
                            placeholder="название награды"
                            {...register("reward_name")}
                        />
                        {errors.reward_name && <span>This field is required</span>}
                        <textarea
                            rows="10"
                            cols="50"
                            defaultValue={defaultProjectState.reward_scheme}
                            placeholder="схема награды: {}"
                            {...register("reward_scheme")}
                        />
                        {errors.reward_scheme && <span>This field is required</span>}

                        <input
                            defaultValue={defaultProjectState.survey_name}
                            placeholder="название опроса"
                            {...register("survey_name")}
                        />
                        {errors.survey_name && <span>This field is required</span>}
                        <textarea
                            rows="10"
                            cols="50"
                            defaultValue={defaultProjectState.survey_scheme}
                            placeholder="схема опроса: {}"
                            {...register("survey_scheme")}
                        />
                        {errors.survey_scheme && <span>This field is required</span>}
                        <textarea
                            rows="10"
                            cols="50"
                            defaultValue={defaultProjectState.config}
                            placeholder="конфиг проекта: {}"
                            {...register("config")}
                        />
                        {errors.config && <span>This field is required</span>}
                        <input type="submit"/>
                    </form>
                : null}
            </div>
            <div style={{width: "45rem"}}>
                <h1 className="project-page-title">Подсказки по конфигурированию</h1>
                <p>ID проекта: чтобы он был дефолтным, нужно указать в качестве id пробел. Для обычного проекта можно оставить сгененированный uuid</p>
                <p>Конфиг проекта позволяет скрывать некоторые элементы студии в рамках исследования.<br/>Формат такой:</p>
                <code><pre>{"{"}
                    {"\n\t"}"hiddenElements": {"{"}
                    {"\n\t\t"}"floating menu": "all" | {"{"} // спрятать плавающее меню или его часть
                    {"\n\t\t\t"}"switchers": "all" | {"{"} // спрятать все или часть переключателей
                    {"\n\t\t\t\t"}"modes": null, // спрятать переключатель режимов
                    {"\n\t\t\t\t"}"grid": null, // спрятать переключатель контуров
                    {"\n\t\t\t\t"}"group": null, // спрятать функцию группировки
                    {"\n\t\t\t\t"}"ungroup": null // спрятать функцию разгруппировки
                    {"\n\t\t\t"}{"}"},
                    {"\n\t\t\t"}"items": "all" | {"{"} // спрятать все или часть кнопок параметров (items)
                    {"\n\t\t\t\t"}"objectName": "all" | {"{"} // спрятать кнопку названия объекта (всю или только только часть фукнций оттуда)
                    {"\n\t\t\t\t\t"}"name": null, // спрятать поле с названием
                    {"\n\t\t\t\t\t"}"color": null, // спрятать кнопку выбора цвета
                    {"\n\t\t\t\t\t"}"size": null // спрятать кнопку выбора размера шрифта
                    {"\n\t\t\t\t}"},
                    {"\n\t\t\t\t"}"textArea": null, // спрятать кнопку описания объекта
                    {"\n\t\t\t\t"}"link": null, // спрятать кнопку ссылки
                    {"\n\t\t\t\t"}"emojiPicker": null, // спрятать кнопку эмотиконов
                    {"\n\t\t\t\t"}"figureColorPicker": null,  // спрятать кнопку цвета объекта
                    {"\n\t\t\t\t"}"transparency": null, // спрятать кнопку прозрачности объекта
                    {"\n\t\t\t\t"}"thickness": null, // спрятать кнопку толщины контура
                    {"\n\t\t\t\t"}"volume": null, // размера точечной фигуры
                    {"\n\t\t\t\t"}"dashLength": null, // спрятать кнопку пунктирности контура
                    {"\n\t\t\t\t"}"flags": null // спрятать кнопку с флагами
                    {"\n\t\t\t"}{"}"}
                    {"\n\t\t"}{"}"},
                    {"\n\t\t"}"watch mode": "all", // спрятать переключатель режимов и элемент просмотра истории
                    {"\n\t\t"}"figure selector": "all" // спрятать панель фигур
                    {"\n\t}"}
                    {"\n\t"}"extraElements": {"{"}
                    {"\n\t\t"}"extendedFileMenu": true, //показать меню загрузки картинок и пользовательские карты
                    {"\n\t}"},
                    {"\n\t"}"menuState": {"{"}
                    {"\n\t\t"}"shouldShowGrid": false, //спрятать сетку
                    {"\n\t"}{"}"},
                    {"\n\t"}"defaultMap": {"{/* any map */}"}
                {"\n"}{"}"}</pre></code>
                <p>Награда имеет следующий формат:</p>
                <code><pre>{"{"}
                {"\n\t"}"modal": "postcard_editor", // в каком модальном окне находится награда (val: ["postcard_editor"])
                {"\n\t"}"classificationAlgorithm": "none", // в каком модальном окне находится награда (val: ["region", "none", "concept"])
                {"\n\t"}"onSaveMsg": {"{"}
                {"\n\t\t"}"ru": "Сделать открытку", // названия кнопки сохранения и получения открытки на разных языках
                {"\n\t\t"}"en": "Make a postcard",
                {"\n\t\t"}"cn": "Сделать открытку"
                {"\n\t"}{"},"}
                {"\n\t"}"classificationNote": {"{"}
                {"\n\t\t"}"ru": "Насколько ...", // пояснение к результатам калссификации
                {"\n\t\t"}"en": "How ...",
                {"\n\t\t"}"cn": "Насколько ..."
                {"\n\t"}{"}"}
                {"\n"}{"}"}</pre></code>
            </div>
        </div>;
}

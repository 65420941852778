import React from "react";

import {useLocalization} from "../../../../customHooks/useContextLocalization";
import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";
import {languages} from "../../../../localization/languages";


function LanguageSelector () {
    const locale = useLocalization();

    const changeLang = event => locale?.set(event.target.id.split("-")[2]);

    return <div className="lang-selector-container">
        <ul className="lang-selector-item-container">
            {Object.keys(languages).map(langName => <li
                key={`lang-item-${langName}`}
                id={`lang-item-${langName}`}
                className="lang-selector-item"
                onClick={changeLang}
            >{languages[langName].abbr}</li>)}
        </ul>
        <div className="lang-current">{locale?.get.abbr} <span>v</span></div>
    </div>;
}

export default hintModeTooltipHOC(LanguageSelector, "currentLocale");



/**
 * Flatten emojis array recursively (any depth of sub-arrays allowed)
 * @typedef  {emoji: Object}           Emoji
 * @typedef  {Array<EmojiArray|Emoji>} EmojiArray
 * @param    {EmojiArray}              arr          Any depth array of emojis
 * @return   {Array<Emoji>}                         Flat array of emojis
 */
export function flattenEmojis(arr) {
    let result = [];
    if (typeof arr === "object" && (arr.length === 0 || (typeof arr[0] === "object" && arr[0].emoji !== undefined))) {
        return [arr];
    }
    arr.map(el => flattenEmojis(el).map(elem => result.push(elem)));
    return result;
}

import {useState, useEffect} from "react";
import {useDrop} from "react-dnd";

import dndItems from "../dndItems";


export default function useBinDropTarget (dispatchTabs) {
    const [isDraggedElementHere, setIsDraggedElementHere] = useState(false);

    const [{canDrop, isOver, result, didDrop}, drop] = useDrop(() => ({
        accept: [dndItems.icon],
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            result: monitor.getItem(),
            didDrop: monitor.didDrop()
        })
    }));

    //it is needed to avoid adding item to all targets simultaneously
    useEffect(() => setIsDraggedElementHere(isOver), [isOver, setIsDraggedElementHere]);

    useEffect(() => {
        if (result && didDrop && isDraggedElementHere && result.source !== "base") {
            dispatchTabs({type: "deleteIconsFromPartition", payload: {
                tIndex: result.tabSource,
                pIndex: result.source,
                icons: result.iconIds
            }});
        }
    }, [result, didDrop]);

    return [drop, {isOver}];
}
import React from "react";

import hintModeTooltipHOC from "../../onboarding/hintModeTooltipHOC";


function Slider({
    id,
    isSliderHidden,
    value,
    min,
    max,
    step,
    onChange,
    displayValueDenominator,
    unitsName,
    isLabelHidden,
    style
}) {
    return <div className="slider-container" id={id}>
        {!isSliderHidden && <input
            type="range"
            name="size"
            value={value}
            min={min}
            max={max}
            step={step}
            onChange={onChange}
            style={style}
        />}
        {!isLabelHidden && <label htmlFor="size" className="slider-title">
            {Math.round(value / (displayValueDenominator || 1)) + (unitsName || "")}
        </label>}
    </div>;
}

export default hintModeTooltipHOC(Slider);

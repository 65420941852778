import React from "react";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";

import useSurvey from "../../../../customHooks/useSurvey";
import useReward from "../../../../customHooks/useReward";
import {useModal} from "../../../../../customHooks/useContextModal";
import {useCurrentUser} from "../../../../../customHooks/useContextCurrentUser";
import {useMap} from "../../../../customHooks/useContextMap";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import {useCurrentProject} from "../../../../customHooks/useContextCurrentProject";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";

import {ReactComponent as Save} from "../images/first-block/save.svg";


const IconSave = tooltipHOC(
    hintModeTooltipHOC(Save, "firstToolBlockSave", {marginBottom: "-2px", marginTop: "-2px"}),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () {
    const locale = useLocalization();
    const modal = useModal();
    const user = useCurrentUser();
    const map = useMap();
    const {tryToShowSurvey} = useSurvey();
    const {tryToShowReward} = useReward();
    const {get: currentProject} = useCurrentProject();

    const dispatch = useDispatch();

    const sendToServer = async () => {
        if (user) {
            dispatch({type: "saveToServer"});
            map.setSaveMapFlag();
            await tryToShowSurvey();
            tryToShowReward();
        } else {
            modal.set("signin");
        }
    };

    return <div className="tool-block-item" onClick={sendToServer}>
        <IconSave tooltipContent={currentProject.reward?.onSaveMsg?.[locale.get.abbr.toLowerCase()]
            || locale.get.studio.header.firstToolBlock.save
        }/>
    </div>;
}

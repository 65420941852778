import React from "react";

import {useLocalization} from "../../../../customHooks/useContextLocalization";


export default function({tabIndex, selectTab}) {
    const locale = useLocalization();

    return <ul className="figure-bar-tab-title-container">
        <li
            className={`figure-bar-tab-title${tabIndex === 0 ? "-active" : ""}`}
            id={`postcard-tab-0`}
            onClick={selectTab}
        >{locale.get.studio.postcard.albumFormat}</li>
        <li
            className={`figure-bar-tab-title${tabIndex === 1 ? "-active" : ""}`}
            id={`postcard-tab-1`}
            onClick={selectTab}
        >{locale.get.studio.postcard.bookFormat}</li>
    </ul>;
}
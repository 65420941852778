import React from "react";
import {useDispatch} from "react-redux";
import {GithubPicker} from 'react-color';

import {useReduxData} from "../../../../customHooks/useContextReduxData";
import {setFixedChildrenValues} from '../../../../utils/figureHierarchy';
import getTimeElapsedSince from "../../../getTimeElapsedSince";


const colors = [
    "#ffffff", "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#cccccc", "#607d8b", "#000000"
];


export default function ColorPicker() {
    const {getFiguresUpdatedOn, openContextMenuTool, selectedFigureUuids, startTime} = useReduxData();
    const figures = getFiguresUpdatedOn([
        "addPolygon",
        "addPolyline",
        "addPointFeature",
        "addGroup",
        "changeObjectParentUuid",
        "clearState",
        "deleteFigure",
        "disbandGroup"
    ]);

    const dispatch = useDispatch();

    const handleChangeComplete = (color) => {
        selectedFigureUuids.map(uuid => {
            let actionType = openContextMenuTool === "color" ? "changeFigureColor" : "changeFigureFontColor";
            setFixedChildrenValues(
                figures,
                uuid,
                [openContextMenuTool],
                dispatch,
                [color.hex],
                actionType,
                getTimeElapsedSince(startTime)
            )
            return undefined;
        });
    };

    return <div className="context-menu-color-picker" id="context-menu-color-picker">
        <GithubPicker colors={colors} triangle="hide" onChangeComplete={handleChangeComplete}/>
    </div>;
}

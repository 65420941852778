

export default function () {
    const prepareParams = () => {
        const popupLeft = window.screen.height / 2 - 150;
        const popupTop = window.screen.width / 2 - 300;
        const text = "";
        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=300,left=${popupLeft},top=${popupTop}`;
        return {text, params};
    };

    const handleShareToTelegram = async url => {
        if (url) {
            const {text, params} = prepareParams();
            window.open(`https://t.me/share/url?url=${url}&text=${text}`, "tg.share", params);
        }
    };

    const handleShareToVK = async url => {
        if (url) {
            const {params} = prepareParams();
            window.open(`https://vk.com/share.php?url=${url}`, "vk.share", params);
        }
    };

    return {handleShareToVK, handleShareToTelegram};
}

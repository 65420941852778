import React, {useState, useEffect} from "react";
import {Formik} from "formik";
import _ from "lodash";
import {useMutation} from "react-query";
import {Link} from "react-router-dom";
import {useHistory, useParams} from "react-router";
import {ValidationError} from "yup";

import ProductName from "./fields/ProductName";
import ProductTags from "./fields/ProductTags";
import ProductSingleSelect from "./fields/ProductSingleSelect";
import ProductMDEditor from "./fields/ProductMDEditor";
import ProductPrice from "./fields/ProductPrice";
import ProductFile from "./fields/ProductFile";
import ProductCardPhotoType from "./fields/ProductCardPhotoType";
import ProductCardPhoto from "./fields/ProductCardPhoto";
import ProductIconContainer from "./fields/ProductIconContainer";
import ProductPublicity from "./fields/ProductPublicity";
import ProductUpdatingScope from "./fields/ProductUpdatingScope";
import ProductMapLoader from "./fields/ProductMapLoader";
import ProductResolution from "./fields/ProductResolution";
import ProductTooltip from "./ProductTooltip";
import FieldError from "./FieldError";
import Page404 from "../Page404";
import LoadingPage from "../LoadingPage";
import UserSelector from "../../../components/UserSelector";
import createValidationSchema from "./createValidationSchema";
import {useApiClient} from "../../../customHooks/useApiClient";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {languages} from "../../../localization/languages";
import {useCategories} from "../../customHooks/useContextCategories";
import {useLicenses} from "../../customHooks/useContextLicenses";
import {useIconSet} from "../../../customHooks/useContextIconSet";
import {
    getLanguageByAbbr,
    getFileNameFromContentDisposition,
    createImageFromSelectedIcons,
    getFileFieldProps
} from "./utils";
import {extractVectorArchive} from "../utils";

import {ReactComponent as CrossIconRaw} from "../../images/cross-transparent.svg";

import "./productForm.css";


function ProductForm() {
    const {id} = useParams();
    const isNew = !id;
    const history = useHistory();

    const api = useApiClient();
    const locale = useLocalization();
    const currentUser = useCurrentUser();
    const categories = useCategories();
    const licenses = useLicenses();
    const iconSet = useIconSet();

    const [initialProductData, setInitialProductData] = useState({});
    const [initialProductFile, setInitialProductFile] = useState(null);
    const [initialProductCardPhoto, setInitialProductCardPhoto] = useState(null);

    const [additionalFieldLanguages, setAdditionalFieldLanguages] = useState([]);
    const [isLanguageSelector, setIsLanguageSelector] = useState(false);

    const [iconsFromArchive, setIconsFromArchive] = useState([]);
    const [iconsToSetAsSelected, setIconsToSetAsSelected] = useState([]);
    const [selectedIconsFromArchive, setSelectedIconsFromArchive] = useState(null);

    const [categoryNames, setCategoryNames] = useState([]);

    useEffect(() => {
        const asyncFunction = async () => {
            if (locale?.get) {
                // setCategoryNames(categories?.slice(1).map(cat => locale?.get?.store.footer[cat.key]) || []); //tools not allowed yet
                setCategoryNames(categories?.slice(1, 5).map(cat => locale?.get?.store.footer[cat.key]) || []);
            }
        };
        asyncFunction();
    }, [locale]);

    useEffect(() => {
        const asyncFunction = async () => {
            if (
                _.isEmpty(initialProductData)
                && locale?.get
                && categoryNames.length > 0
                && licenses && licenses.imageLocalized
            ) {
                setInitialProductData(await getProduct());
                setInitialProductFile(await getProductFile());
                setInitialProductCardPhoto(await getImageFromServer());

            }
        };
        asyncFunction();
    }, [locale, categoryNames, licenses]);

    useEffect(() => {
        if (iconsToSetAsSelected.length > 0 && iconsFromArchive.length > 0) {
            // console.log("setNewData");
            let indices = [];
            for (let i = 0; i < iconsToSetAsSelected.length; i++) {
                 let index = iconsFromArchive.findIndex(item => item.name === iconsToSetAsSelected[i]);
                 if (typeof(index) === "number") {
                     indices.push(index);
                 }
            }
            setSelectedIconsFromArchive(indices);
            setIconsToSetAsSelected([]);
        }
    }, [iconsToSetAsSelected, iconsFromArchive]);

    const openLanguageSelector = () => setIsLanguageSelector(true);
    const closeLanguageSelector = () => setIsLanguageSelector(false);

    const addNewLangToForm = event => {
        setIsLanguageSelector(false);
        setAdditionalFieldLanguages(
            additionalFieldLanguages.concat([event.target.id.split("-")[event.target.id.split("-").length - 1]])
        );
    }
    const removeLangFromForm = event => setAdditionalFieldLanguages(additionalFieldLanguages
        .slice(0, +event.target.id.split("-")[event.target.id.split("-").length - 1])
        .concat(additionalFieldLanguages.slice(event.target.id.split("-")[event.target.id.split("-").length - 1] + 1))
    );

    const getImageFromServer = async () => {
        if (isNew) {
            return null;
        }
        try {
            let blob = await api.get(`/api/v2/store/product_version/${id}/card_photo`, {responseType: 'blob'});
            blob = blob.data;
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return await new Promise(resolve => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
            });
        } catch (e) {
            return await new Promise(resolve => resolve(""));
        }
    };

    const extractArchive = async (file, setFieldValue) => {
        let icons = await extractVectorArchive(file, locale, true);

        if (icons?.length > 0) {
            setIconsFromArchive(icons);
            setSelectedIconsFromArchive([]); //reset selected icons from archive

            if (setFieldValue) {
                setFieldValue("file", file);
                setFieldValue("card_photo", "");
                setFieldValue("card_photo_icon_names", "");
            }
        }
    };

    const {mutateAsync} = useMutation((data) => {
        try {
            // console.log("sending start");
            let formData = new FormData();

            let nameObj = {};
            let tagsObj = {};
            let descriptionObj = {};
            let reviewObj = {};

            Object.keys(data).map(key => {
                if (key.startsWith("name")) {
                    nameObj[key.slice(5)] = data[key];
                } else if (key.startsWith("tags")) {
                    tagsObj[key.slice(5)] = data[key];
                } else if (key.startsWith("desc")) {
                    descriptionObj[key.slice(12)] = data[key];
                } else if (key.startsWith("updating_review")) {
                    reviewObj[key.slice(16)] = data[key];
                } else if (key === "category") {
                    // console.log(categoryNames, data[key]);
                    formData.append(key, categoryNames.indexOf(data[key]) + "");
                } else if (key === "license_type") {
                    formData.append(
                        key,
                        licenses[`${data.category === 0 ? "software": "image"}Localized`].indexOf(data[key]) + ""
                    );
                } else {
                    formData.append(key, data[key]);
                }
                return undefined;
            });

            formData.append("name", JSON.stringify(nameObj));
            formData.append("tags", JSON.stringify(tagsObj));
            formData.append("description", JSON.stringify(descriptionObj));
            formData.append("updating_review", JSON.stringify(reviewObj));
            formData.append("product_version_id", id);

            // console.log("sending");

            return (isNew
                ? api.post(`/api/v1/store/product/`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                : api.put(
                    `/api/v1/store/product/${initialProductData.product_id}/`,
                    formData,
                    {headers: {'Content-Type': 'multipart/form-data'}}
                )
            );
        } catch (e) {
            console.log(e);
        }
    });

    const getProduct = async () => {
        if (isNew) {
            return {};
        }
        try {
            let raw_data = await api.get(`/api/v2/store/product_version/${id}/update`);
            raw_data = raw_data.data;
            if (!raw_data || _.isEmpty(raw_data)) {
                return {"error": "yes"};
            }

            let tags = {"ru": [], "en": [], "cn": []};
            raw_data.tags.map(tag => tags[tag.language].push(tag.name));

            let transformed_data = {
                "product_id": raw_data.product_id || -1,
                "name_ru": raw_data.name.ru || "",
                "name_en": raw_data.name.en || "",
                "name_cn": raw_data.name.cn || "",
                "coauthors": raw_data.coauthors || [],
                "tags_ru": tags.ru || [],
                "tags_en": tags.en || [],
                "tags_cn": tags.cn || [],
                "category": categoryNames[raw_data.type] || "",
                "description_ru": raw_data.description.ru || "",
                "description_en": raw_data.description.en || "",
                "description_cn": raw_data.description.cn || "",
                "license_type": licenses[`${raw_data.category === -1 ? "software" : "image"}Localized`]
                    [raw_data.license_type] || "",
                "price": (raw_data.price || raw_data.price === 0) ? raw_data.price : "",
                "card_photo_type": 1,
                "card_photo_icon_names": raw_data.card_photo || "",
                "publicity": +raw_data.publicity,
                "users_able_see": raw_data.users_able_see || [],
                "updating_scope": "",
                "updating_review_ru": "",
                "updating_review_en": "",
                "updating_review_cn": "",
                "prohibited_updates": raw_data.prohibited_updates,
            };

            let additionalLanguages = [];
            Object.keys(raw_data.name).map(lang =>
                raw_data.name[lang] !== ""
                && locale?.get?.abbr.toLowerCase() !== lang
                && additionalLanguages.push(getLanguageByAbbr(languages, lang))
            );
            if (additionalLanguages.length > 0) {
                setAdditionalFieldLanguages(additionalLanguages);
            }

            if (raw_data.card_photo && raw_data.card_photo.length > 0) {
                setIconsToSetAsSelected(raw_data.card_photo.split("/"));
            }
            return transformed_data;
        } catch (e) {
            return {};
        }
    };

    const getProductFile = async () => {
        if (isNew) {
            return null;
        }
        try {
            let raw_data = await api.get(`/api/v2/store/product_version/${id}/file`, {responseType: 'blob'});

            if (!raw_data.data || !(raw_data.data instanceof Blob)) {
                return {"error": "yes"};
            }

            let filename = getFileNameFromContentDisposition(raw_data.headers["content-disposition"]);
            raw_data = raw_data.data;

            if (raw_data.size < 10) { //file incorrect
                return {"error": "yes"};
            }

            let file = new File([raw_data], filename, {type: raw_data.type});
            if (raw_data.type === "application/zip") {
                await extractArchive(file);
            }
            return file;
        } catch (e) {
            // return nothing
        }
    };

    // console.log(getProduct?.data);
    // console.log(productArchiveData?.data);
    // console.log(initialProductData);
    // console.log(initialProductFile);

    return (currentUser && [1, 2].includes(currentUser.role) && (isNew || (licenses
        && categoryNames
        && (
            (categoryNames.indexOf(initialProductData.category) === 0 &&  selectedIconsFromArchive)
                || categoryNames.indexOf(initialProductData.category) !== 0
            )
        && (
            (categoryNames.indexOf(initialProductData.category) === 0 &&  iconsFromArchive)
                || categoryNames.indexOf(initialProductData.category) !== 0
            )
        && (
            (categoryNames.indexOf(initialProductData.category) !== 0 &&  initialProductCardPhoto)
                || categoryNames.indexOf(initialProductData.category) === 0
            )
        && typeof(initialProductData?.product_id) === "number"
        && initialProductFile
    )) ? <Formik
            enableReinitialize = {false}
            initialValues = {{
                ...createValidationSchema(additionalFieldLanguages, locale, id, categoryNames).cast(),
                ...initialProductData,
                file: initialProductFile || null,
                card_photo: isNew
                    ? ""
                    : categoryNames.indexOf(initialProductData.category) === 0
                        ? createImageFromSelectedIcons(selectedIconsFromArchive, iconsFromArchive).image
                        : initialProductCardPhoto,
                update_price: "",
            }}
            onSubmit = {async (values, {setSubmitting, setStatus}) => {
                try {
                    // console.log(values);
                    await mutateAsync(values);
                    iconSet.loadAllData();
                    history.push(`/product/published`);
                } catch (err) {
                    console.log(err);//TODO: 404
                    if (err instanceof ValidationError) {
                        setStatus(err.errors);
                        // toast.warn(JSON.stringify(err.errors))
                    }
                } finally {
                    setSubmitting(false);
                }
            }}
            validationSchema = {createValidationSchema(additionalFieldLanguages, locale, id, categoryNames)}
        >
            {(props) => {
            // console.log(props.values);
            // console.log(props.errors);
            // console.log(selectedIconsFromArchive);
            return (
            <form onSubmit = {props.handleSubmit}>
            <div className = "product-form-container">
                <h1>{locale?.get?.store.product.form[isNew ? "newProduct" : "productUpdating"]}</h1>
                <span className = "product-form-field-name">{locale?.get?.store.product.form.name}</span>
                <div className = "product-form-field-container">
                    <ProductName
                        fieldName={`name_${locale?.get?.abbr.toLowerCase()}`}
                        placeholder={locale?.get?.store.product.form.namePlaceholder}
                        formValues={props.values}
                        setFieldValue={props.setFieldValue}
                    />
                    {Object.keys(languages).length > additionalFieldLanguages.length + 1
                        ? <div
                            className = "product-form-language-selector-container"
                            onMouseEnter = {openLanguageSelector}
                            onMouseLeave = {closeLanguageSelector}
                        >
                            <CrossIconRaw className = "product-form-add-field-button"/>
                            {isLanguageSelector
                                ? <ul className = "product-form-lang-selector-item-container">
                                    {Object.keys(languages).map(langName =>
                                        !additionalFieldLanguages.includes(langName)
                                        && langName !== locale?.get?.name
                                        && (<li
                                            key={`product-form-lang-selector-item-${langName}`}
                                            id = {`product-form-item-${langName}`}
                                            className = "product-form-lang-selector-item"
                                            onClick = {addNewLangToForm}
                                        >{languages[langName].abbr}</li>)
                                    )}
                                </ul>
                                : undefined
                            }
                        </div>
                        : null
                    }
                    <FieldError name={`name_${locale?.get?.abbr.toLowerCase()}`}/>
                </div>
                {additionalFieldLanguages.map((l, i) => <React.Fragment key={`product-form-name-input-${i}`}>
                    <span className = "product-form-field-name"/>
                    <div className = "product-form-field-container">
                        <ProductName
                            fieldName={`name_${languages[l].abbr.toLowerCase()}`}
                            placeholder={languages[l].store.product.form.namePlaceholder}
                            formValues={props.values}
                            setFieldValue={props.setFieldValue}
                        />
                        <CrossIconRaw
                            className = "product-form-delete-field-button"
                            id = {`product-form-delete-field-button-${i}`}
                            onClick = {removeLangFromForm}
                        />
                        <FieldError name={`name_${languages[l].abbr.toLowerCase()}`}/>
                    </div>
                </React.Fragment>)}
                <span className = "product-form-field-name">{locale?.get?.store.product.form.coauthors}</span>
                <div className = "product-form-field-container">
                    <div className = "product-form-input-wrapper">
                        <UserSelector
                            users={props.values.coauthors}
                            setUsers={preparedOptions => props.setFieldValue("coauthors", preparedOptions)}
                            className="store-select"
                            fieldName="coauthors"
                        />
                    </div>
                    <FieldError name="coauthors"/>
                </div>
                <span className = "product-form-field-name">{locale?.get?.store.product.form.tags}</span>
                <div className = "product-form-field-container">
                    <ProductTags
                        formValues={props.values}
                        lang={locale?.get?.abbr.toLowerCase()}
                        setFieldValue={props.setFieldValue}
                    />
                    <FieldError name={`tags_${locale?.get?.abbr.toLowerCase()}`}/>
                </div>
                {additionalFieldLanguages.map((l, i) => <React.Fragment key={`product-form-tags-input-${i}`}>
                    <span className = "product-form-field-name"/>
                    <div className = "product-form-field-container">
                        <ProductTags
                            formValues={props.values}
                            lang={languages[l].abbr.toLowerCase()}
                            setFieldValue={props.setFieldValue}
                        />
                        <FieldError name={`tags_${languages[l].abbr.toLowerCase()}`}/>
                    </div>
                </React.Fragment>)}
                {isNew ? <><span className = "product-form-field-name">{locale?.get?.store.product.form.category}</span>
                <div className = "product-form-field-container">
                    <ProductSingleSelect
                        fieldName="category"
                        items={categoryNames}
                        formValues={props.values}
                        setFieldValue={props.setFieldValue}
                        executeExtraEffect={()=>{
                            props.setFieldValue("file", null);
                            props.setFieldValue("card_photo", "");
                            props.setFieldValue("card_photo_type", undefined);
                            props.setFieldValue("card_photo_icon_names", "");
                            setIconsFromArchive([]);
                            setSelectedIconsFromArchive(null);
                        }}
                    />
                    <FieldError name="category"/>
                </div></> : null}
                <span className = "product-form-field-name">{locale?.get?.store.product.form.description}</span>
                <div className = "product-form-field-container">
                    <ProductMDEditor
                        fieldName={`description_${locale?.get?.abbr.toLowerCase()}`}
                        formValues={props.values}
                        setFieldValue={props.setFieldValue}
                    />
                    <FieldError name={`description_${locale?.get?.abbr.toLowerCase()}`}/>
                </div>
                {additionalFieldLanguages.map((l, i) => <React.Fragment key={`product-form-description-input-${i}`}>
                    <span className = "product-form-field-name"/>
                    <div className = "product-form-field-container">
                        <ProductMDEditor
                            fieldName={`description_${languages[l].abbr.toLowerCase()}`}
                            formValues={props.values}
                            setFieldValue={props.setFieldValue}
                        />
                        <FieldError name={`description_${languages[l].abbr.toLowerCase()}`}/>
                    </div>
                </React.Fragment>)}
                <span className = "product-form-field-name">{locale?.get?.store.product.form
                    [categoryNames.indexOf(props.values.category) === 0 ? "productArchive" : "productFile"]}
                </span>
                <div className = "product-form-field-container">
                    {categoryNames.indexOf(props.values.category) === 0 && <ProductFile
                        loadFile={getFileFieldProps(0).loadFile(props.setFieldValue, extractArchive)}
                        clearFile={getFileFieldProps(0).clearFile(
                            props.setFieldValue,
                            setIconsFromArchive,
                            setSelectedIconsFromArchive
                        )}
                        isFileLoaded={iconsFromArchive.length > 0}
                    />}
                    {[1,2].includes(categoryNames.indexOf(props.values.category)) && <ProductFile
                        loadFile={getFileFieldProps(categoryNames.indexOf(props.values.category)).loadFile(
                            props.setFieldValue,
                            locale?.get?.store.product.form.validation.fileCorrupted
                        )}
                        clearFile={getFileFieldProps(
                            categoryNames.indexOf(props.values.category)
                        ).clearFile(props.setFieldValue)}
                        isFileLoaded={!!props.values.file}
                        customImage={props.values.card_photo}
                        repeatImage={categoryNames.indexOf(props.values.category) === 1 ? "no-repeat" : "repeat"}
                    />}
                    {categoryNames.indexOf(props.values.category) === 3 && <ProductMapLoader
                        setFieldValue={props.setFieldValue}
                        initialImage={!isNew && props.values.card_photo}
                    />}
                    <FieldError name="file"/>
                </div>

                {categoryNames.indexOf(props.values.category) === 1 && props.values.file && <>
                    <span className = "product-form-field-name">{locale?.get?.store.product.form.resolution}</span>
                    <div className = "product-form-field-container">
                        <ProductResolution setFieldValue={props.setFieldValue} file={props.values.file}/>
                        <ProductTooltip text={locale?.get?.store.product.form.resolutionHint}/>
                    </div>
                </>}

                <span className = "product-form-field-name">{locale?.get?.store.product.form.licenseType}</span>
                <div className = "product-form-field-container">
                    <ProductSingleSelect
                        fieldName="license_type"
                        items={licenses[`${props.values.category === -1 ? "software" : "image"}Localized`]}
                        formValues={props.values}
                        setFieldValue={props.setFieldValue}
                    />
                    <Link
                        to = "/licenses/list"
                        className = "product-form-licenses-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >{locale?.get?.store.product.form.moreAboutLicenses}</Link>
                    <FieldError name="license_type"/>
                </div>
                <span className = "product-form-field-name">{locale?.get?.store.product.form.price}</span>
                <div className = "product-form-field-container">
                    <ProductPrice
                        fieldName="price"
                        formValues={props.values}
                        setFieldValue={props.setFieldValue}
                    />
                    <FieldError name="price"/>
                </div>
                {categoryNames.indexOf(props.values.category) === 0 ? <>
                    <span className = "product-form-field-name">{locale?.get?.store.product.form.cardPhoto}</span>
                    <div className = "product-form-field-container">
                        <ProductCardPhotoType
                            formValues={props.values}
                            setFieldValue={props.setFieldValue}
                            selectedIcons={selectedIconsFromArchive}
                            icons={iconsFromArchive}
                        />
                        <FieldError name="card_photo_type"/>
                        <FieldError name="card_photo"/>
                        <FieldError name="card_photo_icon_names"/>
                    </div>
                    {props.values.card_photo_type === 1 ? (<>
                        <ProductCardPhoto image={props.values.card_photo}/>
                        <ProductIconContainer
                            icons={iconsFromArchive}
                            selectedIcons={selectedIconsFromArchive}
                            setSelectedIcons={setSelectedIconsFromArchive}
                            setFieldValue={props.setFieldValue}
                        />
                    </>) : null}
                </> : null
                }
                <span className = "product-form-field-name">{locale?.get?.store.product.form.publicity}</span>
                <div className = "product-form-field-container">
                    <ProductPublicity defaultValue={props.values.publicity} setFieldValue={props.setFieldValue}/>
                    <FieldError name="publicity"/>
                </div>
                {props.values.publicity === 0 ? (<>
                    <span className = "product-form-field-name"/>
                    <div className = "product-form-field-container">
                        <div className = "product-form-input-wrapper">
                            <UserSelector
                                users={props.values.users_able_see}
                                setUsers={preparedOptions => props.setFieldValue("users_able_see", preparedOptions)}
                                className="store-select"
                                fieldName="users_able_see"
                            />
                        </div>
                        <FieldError name="users_able_see"/>
                    </div>
                </>) : null}
                {!isNew ? <>
                    <span className = "product-form-field-name">{locale?.get?.store.product.form.updatingScope}</span>
                    <div className = "product-form-field-container">
                        <ProductUpdatingScope setFieldValue={props.setFieldValue} formValues={props.values}/>
                        <FieldError name="updating_scope"/>
                    </div>
                    {props.values.updating_scope === 0 ? <>
                        <span className = "product-form-field-name">{locale?.get?.store.product.form.updatePrice}</span>
                        <div className = "product-form-field-container">
                            <ProductPrice
                                fieldName="update_price"
                                formValues={props.values}
                                setFieldValue={props.setFieldValue}
                            />
                            <FieldError name="update_price"/>
                        </div>
                    </> : null}
                    <span className = "product-form-field-name">{locale?.get?.store.product.form.updatingReview}</span>
                    <div className = "product-form-field-container">
                        <ProductMDEditor
                            fieldName={`updating_review_${locale?.get?.abbr.toLowerCase()}`}
                            formValues={props.values}
                            setFieldValue={props.setFieldValue}
                        />
                        <FieldError name={`updating_review_${locale?.get?.abbr.toLowerCase()}`}/>
                    </div>
                    {additionalFieldLanguages.map((l, i) => <React.Fragment key={`product-form-review-input-${i}`}>
                        <span className = "product-form-field-name"/>
                        <div className = "product-form-field-container">
                            <ProductMDEditor
                                fieldName={`updating_review_${languages[l].abbr.toLowerCase()}`}
                                formValues={props.values}
                                setFieldValue={props.setFieldValue}
                            />
                            <FieldError name={`updating_review_${languages[l].abbr.toLowerCase()}`}/>
                        </div>
                    </React.Fragment>)}
                </> : null}

                <div className = "product-form-submit-container">
                    <button type="submit" className="store-button">{locale?.get?.store.product.form.publish}</button>
                    <div className = "product-form-agreement-container">
                        <span>
                            {locale?.get?.store.product.form.publicationWarning}
                        </span>&nbsp;
                        <Link
                            to = "/store/pub_rules"
                            className = "product-form-licenses-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >{locale?.get?.store.product.form.publicationRules}</Link>
                        &nbsp;<span>
                            {locale?.get?.store.product.form.publicationWarningUnion}
                        </span>&nbsp;
                        <Link
                            to ={`/licenses/${licenses[props.values.category === -1 ? "software" : "image"][
                                licenses[`${props.values.category === -1 ? "software" : "image"}Localized`]
                                    .indexOf(props.values.license_type)
                            ] || "list"}`}
                            className = "product-form-licenses-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {locale?.get?.store.product.form.publicationLicense}
                        </Link>
                    </div>
                </div>
            </div>
            </form>
            )}}
        </Formik>
        : (initialProductData?.error || initialProductFile?.error || ![1, 2].includes(currentUser?.role)
            ? <Page404/>
            : <LoadingPage/>
        )
    );
}

export default ProductForm;

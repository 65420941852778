import React, {useContext} from "react";
import {Formik, Field, Form, ErrorMessage, getIn} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";

import Avatar from "../components/Avatar";
import {CurrentUserContext} from "../customHooks/useContextCurrentUser";
import {ErrorView, getStyles} from "./form_fields";
import {useLocalization} from "../customHooks/useContextLocalization";
import {useApiClient} from "../customHooks/useApiClient";
import {useModal} from "../customHooks/useContextModal";
import {useCurrentProject} from "../studio/customHooks/useContextCurrentProject";


export const EditProfileForm = () => {
    const locale = useLocalization();
    const api = useApiClient();
    const {user, fetchUser} = useContext(CurrentUserContext);
    const modal = useModal();
    const {get: currentProject} = useCurrentProject();

    const changeCustomAvatar = (setFieldValue) => () => {
        const input = document.createElement('input');
        input.type = 'file';

        input.onchange = e => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = readerEvent => {
                let data = readerEvent.target.result;
                setFieldValue("custom_avatar", data);
            }
        };
        input.click();
    };

    return user && locale?.get
        ? <Formik
            initialValues={{
                custom_avatar: user.custom_avatar || "",
                full_name: user.full_name || "",
                username: user.username,
                interests: user.interests || "",
                email: user.email,
                country: user.country || "",
                region: user.region || "",
                settlement: user.settlement || "",
                languages: user.languages || "",
                birthdate: user.birthdate || "",
                gender: locale.get.abbr !== "EN" ? (user.gender === "F" ? "Ж" : "М") : user.gender || "",
            }}
            validationSchema={Yup.object({
                custom_avatar: Yup.string(),
                full_name: Yup.string(),
                username: Yup.string()
                    .max(30, locale?.get.auth.errorView.tooLongUsername)
                    .required(locale?.get.auth.errorView.required),
                interests: Yup.string(),
                email: Yup.string()
                    .email(locale?.get.auth.errorView.invalidEmailAddress)
                    .required(locale?.get.auth.errorView.required),
                country: Yup.string(),
                region: Yup.string(),
                settlement: Yup.string(),
                languages: Yup.string(),
                birthdate: Yup.string().test(
                    "birthdate test",
                    locale?.get?.auth.birthdateTestFailed,
                    value => {
                        if (!value) {
                            return true;
                        }
                        if (/\d\d\d\d-\d\d-\d\d/.test(value)) {
                            const [year, month, day] = value.split("-");
                            if (+year > (new Date()).getFullYear() || +month > 12 || +day > 31) {
                                return false;
                            }
                        } else {
                            return false;
                        }
                        return true;
                    }
                ),
                gender: Yup.string().oneOf(["м", "ж", "М", "Ж", "m", "f", "M", "F"]),
            })}
            onSubmit={async (values, {setSubmitting, setErrors}) => {
                try {
                    let data = {};
                    Object.assign(data, values);
                    data.locale = locale?.get?.abbr.toLowerCase();
                    const userGender = locale.get.abbr !== "EN" ? (user.gender === "F" ? "Ж" : "М") : user.gender || "";
                    if (
                        data.custom_avatar === user.custom_avatar &&
                        data.full_name === user.full_name &&
                        data.username === user.username &&
                        data.interests === user.interests &&
                        data.email === user.email &&
                        data.country === user.country &&
                        data.region === user.region &&
                        data.settlement === user.settlement &&
                        data.languages === user.languages &&
                        !!data.birthdate === !!user.birthdate &&
                        data.gender === userGender
                    ) {
                        modal.set("");
                        return undefined;
                    }

                    await api.put('/api/v1/user/edit_profile', data, {params: {current_project_id: currentProject.id}});
                    modal.set("");
                    toast.success(locale?.get.editProfile.profileChangedSuccessfully);
                    if (user.email !== data.email) {
                        toast.info(locale?.get.editProfile.confirmEmail);
                    }
                    await fetchUser();
                } catch (err) {
                    if (err.response.status === 409 && err.response.data.detail) {
                        setErrors(err.response.data.detail);
                    }
                }
                setSubmitting(false);
            }}
            render={formProps => {
                return (
                    <Form className="edit-profile-form">
                        <h4 className="auth-title edit-profile-title">{locale?.get.editProfile.profileEditing}</h4>
                        <div className="edit-profile-avatar-container">
                            <div className="edit-profile-avatar-wrapper">
                                <Avatar
                                    imgUrl={formProps.values.custom_avatar || user.social_avatar}
                                    imgClassName="avatar-flex"
                                    wrapperClassName="avatar-wrapper"
                                    email={user && user.email}
                                />
                            </div>
                            <span onClick={changeCustomAvatar(formProps.setFieldValue)}>
                                {locale.get.editProfile.changePhoto}
                            </span>
                            <span onClick={() => formProps.setFieldValue("custom_avatar", "")}>
                                {locale.get.editProfile.removePhoto}
                            </span>
                        </div>
                        <div className="field-wrapper edit-profile-full-name-container">
                            <span className="edit-profile-form-field-name">
                                {locale.get.editProfile.fullNamePlaceholder}
                            </span>
                            <Field
                                className="edit-profile-text-field-short"
                                name='full_name'
                                type='full_name'
                                style={getIn(formProps.touched, 'full_name') && getStyles(formProps.errors, 'full_name')}
                            />
                            <ErrorMessage component={ErrorView} name='full_name'/>
                        </div>
                        <div className="field-wrapper edit-profile-username-container">
                            <span className="edit-profile-form-field-name">
                                {locale.get.editProfile.usernamePlaceholder}
                            </span>
                            <Field
                                className="edit-profile-text-field-short"
                                name='username'
                                type='username'
                                style={getIn(formProps.touched, 'username') && getStyles(formProps.errors, 'username')}
                            />
                            <ErrorMessage component={ErrorView} name='username'/>
                        </div>
                        <div className="field-wrapper edit-profile-interests-container">
                            <span className="edit-profile-form-field-name">
                                {locale.get.editProfile.interestsPlaceholder}
                            </span>
                            <Field
                                className="edit-profile-text-field"
                                name='interests'
                                type='interests'
                                style={getIn(formProps.touched, 'interests') && getStyles(formProps.errors, 'interests')}
                            />
                            <ErrorMessage component={ErrorView} name='interests'/>
                        </div>
                        <div className="field-wrapper edit-profile-email-container">
                            <span className="edit-profile-form-field-name">
                                {locale.get.editProfile.emailPlaceholder}
                            </span>
                            <Field
                                className="edit-profile-text-field"
                                name='email'
                                type='email'
                                style={getIn(formProps.touched, 'email') && getStyles(formProps.errors, 'email')}
                            />
                            <ErrorMessage component={ErrorView} name='email'/>
                        </div>
                        <div className="field-wrapper edit-profile-country-container">
                            <span className="edit-profile-form-field-name">
                                {locale.get.editProfile.countryPlaceholder}
                            </span>
                            <Field
                                className="edit-profile-text-field"
                                name='country'
                                type='country'
                                style={getIn(formProps.touched, 'country') && getStyles(formProps.errors, 'country')}
                            />
                            <ErrorMessage component={ErrorView} name='country'/>
                        </div>
                        <div className="field-wrapper edit-profile-region-container">
                            <span className="edit-profile-form-field-name">
                                {locale.get.editProfile.regionPlaceholder}
                            </span>
                            <Field
                                className="edit-profile-text-field"
                                name='region'
                                type='region'
                                style={getIn(formProps.touched, 'region') && getStyles(formProps.errors, 'region')}
                            />
                            <ErrorMessage component={ErrorView} name='region'/>
                        </div>
                        <div className="field-wrapper edit-profile-settlement-container">
                            <span className="edit-profile-form-field-name">
                                {locale.get.editProfile.settlementPlaceholder}
                            </span>
                            <Field
                                className="edit-profile-text-field"
                                name='settlement'
                                type='settlement'
                                style={getIn(formProps.touched, 'settlement') && getStyles(formProps.errors, 'settlement')}
                            />
                            <ErrorMessage component={ErrorView} name='settlement'/>
                        </div>
                        <div className="field-wrapper edit-profile-languages-container">
                            <span className="edit-profile-form-field-name">
                                {locale.get.editProfile.languagesPlaceholder}
                            </span>
                            <Field
                                className="edit-profile-text-field"
                                name='languages'
                                type='languages'
                                style={getIn(formProps.touched, 'languages') && getStyles(formProps.errors, 'languages')}
                            />
                            <ErrorMessage component={ErrorView} name='languages'/>
                        </div>
                        <div className="field-wrapper edit-profile-birthdate-container">
                            <span className="edit-profile-form-field-name">
                                {locale.get.editProfile.birthdatePlaceholder}
                            </span>
                            <Field
                                className="edit-profile-text-field"
                                name='birthdate'
                                type='birthdate'
                                style={getIn(formProps.touched, 'birthdate') && getStyles(formProps.errors, 'birthdate')}
                            />
                            <ErrorMessage component={ErrorView} name='birthdate'/>
                        </div>
                        <div className="field-wrapper edit-profile-gender-container">
                            <span className="edit-profile-form-field-name">
                                {locale.get.editProfile.genderPlaceholder}
                            </span>
                            <Field
                                className="edit-profile-text-field"
                                name='gender'
                                type='gender'
                                style={getIn(formProps.touched, 'gender') && getStyles(formProps.errors, 'gender')}
                            />
                            <ErrorMessage component={ErrorView} name='gender'/>
                        </div>
                        <div className="edit-profile-apply-container">
                            <div className="edit-profile-apply-wrapper">
                                <button type='submit' className='button alt'>{locale?.get.editProfile.saveChanges}</button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        />
        : <span>{locale?.get.editProfile.signInBefore}</span>;
};

import React from "react";

import {useHintMode} from "../customHooks/useContextHintMode";


export default function HintModeOverlay() {
    const hintMode = useHintMode();

    return hintMode.get ? <div
        className="hint-mode-overlay"
        id="hint-mode-overlay"
        tabIndex="0"
        style={{
            opacity: "0",
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: "900"
        }}
    /> : <></>;
}

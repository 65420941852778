import {useState} from "react";
import {useDispatch} from "react-redux";
import {Map} from "immutable";

import {useReduxData} from "../../../customHooks/useContextReduxData";


export default function ({plotHeight, plotLeft, plotWidth, setCurrentTime}) {
    const [xCoo, setXCoo] = useState(0);
    const [shouldTrackMouse, setShouldTrackMouse] = useState(0);

    const {lastAction, lastTime, past} = useReduxData();
    const dispatch = useDispatch();

    const startToTrackMouse = event => {
        setShouldTrackMouse(true);
        setWatchMoment(event, true);
    }

    const setWatchMoment = (event, shouldTrackMouse_) => {
        if (shouldTrackMouse || shouldTrackMouse_) {
            setXCoo(event.clientX - plotLeft);
            const currentTime = (event.clientX - plotLeft) / (plotWidth - 5) * lastTime;
            setCurrentTime(currentTime / 1000);
            dispatch({
                currentTime: currentTime,
                past: lastAction.type !== "loadStateByTime" ? past.concat([Map({lastAction})]) : past,
                type: "loadStateByTime"
            });
        }
    };

    const stopToTrackMouse = () => setShouldTrackMouse(false);

    return <>
        <rect
            x="0"
            y="0"
            width={plotWidth}
            height={plotHeight}
            fill="transparent"
            onMouseDown={startToTrackMouse}
            onMouseMove={setWatchMoment}
            onMouseUp={stopToTrackMouse}
            onMouseLeave={stopToTrackMouse}
        />
        <line x1={xCoo} x2={xCoo} y1="0" y2={plotHeight} stroke="red" strokeWidth="5" style={{pointerEvents: "none"}}/>
    </>;
}
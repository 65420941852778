import React, {useEffect, useState} from "react";

import SvgText from "./SvgText";
import Link from "./Link";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useIconSet} from "../../../customHooks/useContextIconSet";
import {useReduxData} from "../../customHooks/useContextReduxData";
import useCooTransformation from "../canvas/customHooks/useCooTransformation";
import {getPhysicalArrowCoos} from "./utils/arrow";


function ArrowView({id, tool, x1, y1, x2, y2, strokeDasharray, strokeWidth}) {
    switch (tool) {
        case "a-s-75": {
            return <>
                <defs><marker
                    id={`arrow-ending-${id}`}
                    viewBox="0 0 15 15"
                    refX="10"
                    refY="5"
                    markerUnits="strokeWidth"
                    markerWidth="10"
                    markerHeight="10"
                    orient="auto"
                ><polyline points="1,1 10,5 1,9" fill="none"/></marker></defs>
                <line
                    x1={x1}
                    y1={y1}
                    x2={x2}
                    y2={y2}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    markerEnd={`url(#arrow-ending-${id})`}
                />
            </>;
        }
        default:
            return <line x1={x1} y1={y1} x2={x2} y2={y2} strokeWidth={strokeWidth} strokeDasharray={strokeDasharray}/>;
    }
}


export default function Arrow({figure, kind}) {
    const locale = useLocalization();
    const {get: {parameters}} = useIconSet();
    const {figures, scale} = useReduxData();
    const {transformXForCanvas, transformYForCanvas} = useCooTransformation();

    const [arrowFromX, setArrowFromX] = useState(0);
    const [arrowFromY, setArrowFromY] = useState(0);
    const [arrowToX, setArrowToX] = useState(0);
    const [arrowToY, setArrowToY] = useState(0);

    useEffect(() => {
        let arrowCoos_ = getPhysicalArrowCoos(figure, figures);
        setArrowFromX(arrowCoos_.from.x);
        setArrowFromY(arrowCoos_.from.y);
        setArrowToX(arrowCoos_.to.x);
        setArrowToY(arrowCoos_.to.y);
    }, [figure, figures]);

    const langCoefficient = locale?.get.name === "chinese" ? 40/23 : 1;

    const [minX, maxX] = arrowFromX < arrowToX
        ? [arrowFromX, arrowToX]
        : [arrowToX, arrowFromX];
    const [minY, maxY] = arrowFromY < arrowToY
        ? [arrowFromY, arrowToY]
        : [arrowToY, arrowFromY];

    const centerX = (minX + maxX) / 2;
    const centerY = (minY + maxY) / 2;

    const x1 = (arrowFromX - minX) * scale + 10 * figure.get("thickness");
    const y1 = (arrowFromY - minY) * scale + 10 * figure.get("thickness");
    const x2 = (arrowToX - minX) * scale + 10 * figure.get("thickness");
    const y2 = (arrowToY - minY) * scale + 10 * figure.get("thickness");

    const radianRotationAngle = Math.asin((x2 - x1) / Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)));
    const rotationAngle = radianRotationAngle / 2 / Math.PI * 360;

    const textRotation = y1 > y2
        ? (rotationAngle < 0 ? rotationAngle + 90 : rotationAngle - 90)
        : (rotationAngle < 0 ? 90 - rotationAngle + 180 : 90 - rotationAngle);
    const halfTextLength = figure.get("name").length * figure.get("fontSize") * langCoefficient / 2;
    const svgTextX = transformXForCanvas(centerX);
    const svgTextY = transformYForCanvas(centerY);
    const rotatedSvgTextX = y1 > y2
        ? (rotationAngle < 0
            ? svgTextX - (halfTextLength) * Math.cos(radianRotationAngle + Math.PI / 2)
            : svgTextX - (halfTextLength) * Math.cos(radianRotationAngle - Math.PI / 2)
        )
        : (rotationAngle < 0
            ? svgTextX - (halfTextLength) * Math.cos(Math.PI / 2 - radianRotationAngle + Math.PI)
            : svgTextX - (halfTextLength) * Math.cos(Math.PI / 2 - radianRotationAngle)
        );
    const rotatedSvgTextY = y1 > y2
        ? (rotationAngle < 0
            ? svgTextY - (halfTextLength) * Math.sin(radianRotationAngle + Math.PI / 2)
            : svgTextY - (halfTextLength) * Math.sin(radianRotationAngle - Math.PI / 2)
        )
        : (rotationAngle < 0
            ? svgTextY - (halfTextLength) * Math.sin(Math.PI / 2 - radianRotationAngle + Math.PI)
            : svgTextY - (halfTextLength) * Math.sin(Math.PI / 2 - radianRotationAngle)
        );

    return (figure && parameters) ? <g className="figure">
        <svg
            id={figure.get("uuid")}
            className={kind === "separate" ? "figure" : "group"}
            xmlns="http://www.w3.org/2000/svg"
            x={transformXForCanvas(minX) - 10 * figure.get("thickness")}
            y={transformYForCanvas(minY) - 10 * figure.get("thickness")}
            width={(maxX - minX) * scale + 20 * figure.get("thickness")}
            height={(maxY - minY) * scale + 20 * figure.get("thickness")}
            style={{
                stroke: figure.get("color") == null ? "black" : figure.get("color"),
                strokeWidth: "2px",
                opacity: figure.get("transparency")
            }}
        >
            <line x1={x1} y1={y1} x2={x2} y2={y2} strokeWidth={20 * scale} opacity="0"/>
            <ArrowView
                id={figure.get("uuid")}
                tool={figure.get("tool")}
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                strokeWidth={figure.get("thickness")}
                strokeDasharray={figure.get("dashLength")}
            />
        </svg>
        <SvgText
            id={figure.get("uuid")}
            className={kind === "separate" ? "text" : "group"}
            strokeColor={figure.get("fontColor")}
            fillColor="black"
            fontSize={figure.get("fontSize") === "standard" ? "18px" : figure.get("fontSize") / 2 + "mm"}
            text={figure.get("name")}
            x={rotatedSvgTextX || 0}
            y={rotatedSvgTextY || 0}
            opacity={figure.get("transparency")}
            rotationAngle={textRotation}
        />
        {figure.get("link") && <Link
            id={figure.get("uuid")}
            x={transformXForCanvas(centerX)}
            y={transformYForCanvas(centerY)}
            height={15}
            width={15}
            linkAddress={figure.get("link")}
        />}
    </g> : null;
}

import React from "react";
import {useHistory, useParams} from "react-router-dom";
import {useQuery} from "react-query";

import ProductCard from "../productCard/ProductCard";
import LoadingPage from "../LoadingPage";
import Page404 from "../Page404";
import Avatar from "../../../components/Avatar";
import {useApiClient} from "../../../customHooks/useApiClient";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useModal} from "../../../customHooks/useContextModal";

import {ReactComponent as Logo} from "../../../images/logo.svg";

import "./account.css";


function ProductsCategory({title, endpoint, isPubDependent=false}) {
    const currentUser = useCurrentUser();
    const api = useApiClient();

    let defaultKey = isPubDependent ? ["publicationDependent"] : [];
    const {isSuccess, isLoading, data} = useQuery(
        [...defaultKey, endpoint, currentUser],
        () => api.get(endpoint)
    );
    const products = isSuccess ? data.data : [];

    return (products?.length > 0 || isLoading) && <>
        <span className="account-product-title">{title}</span>
        <div className='cards'>
            {isLoading
                ? <Logo className="loading-logo-animation" height="50" width="50"/>
                : products.map((product, i) => <ProductCard key={i} product={product}/>)
            }
        </div>
    </>;
}


export default function Account() {
    const {id} = useParams();
    const history = useHistory();
    const api = useApiClient();
    const currentUser = useCurrentUser();
    const locale = useLocalization();
    const modal = useModal();

    const editProfile = () => modal.set("edit_profile");
    const deleteProfile = () => modal.set("delete_profile");
    const addNewProduct = () => history.push("/product/add");
    const editIconArrangement = () => history.push(`/account/${id}/icons`);
    const editIconArrangementDev = () => history.push(`/account/${id}/icons/dev-arrangement`);
    const editIconParametersDev = () => history.push(`/account/${id}/icons/dev-parameters`);
    const goToStudio = () => {
        window.location = "/";
        return undefined;
    };

    const {isLoading: isUserLoading, error: userLoadingError, isSuccess: isUserLoadingSuccess, data: userData} = useQuery(
        [`store/user/${id}`, currentUser],
        () => api.get(`/api/v1/user/${id}`)
    );
    const user = isUserLoadingSuccess ? userData.data : {};

    return locale?.get && (isUserLoading ? <LoadingPage/> : (userLoadingError ? <Page404/>
        : <div className="account-container">
            <div className="account-user-info-container">
                <span className="account-user-name">
                    {(user && user.id !== currentUser?.id && (user.full_name || user.username))
                        || locale.get.store.account.myProfile}
                </span>
                <div className="account-avatar-wrapper">
                    <Avatar
                        imgUrl={user && (user.custom_avatar || user.social_avatar)}
                        imgClassName="avatar-flex"
                        wrapperClassName="avatar-wrapper"
                        email={user && user.email}
                    />
                </div>
                {user && user.id === currentUser?.id ? <span className="account-user-name-black">
                    {user && (user.full_name || user.username)}
                </span> : null}
                {user && <label>{user.interests}</label>}
                {user && <label>{user.email}</label>}
                {user && <label>{user.phone_number}</label>}

                {user && user.id === currentUser?.id
                    ? <div className="account-user-restricted-info-container">
                        <span onClick={editProfile}>{locale.get.store.account.editProfile}</span>
                        <span onClick={deleteProfile}>{locale.get.store.account.deleteProfile}</span>
                        {[1, 2].includes(currentUser?.role) && <span onClick={addNewProduct}>
                            {user.authored_product_count > 0
                                ? locale.get.store.account.addProduct
                                : locale.get.store.account.becomeAnAuthor}
                        </span>}
                        <span onClick={editIconArrangement}>
                            {locale.get.store.account.editIconArrangement}
                        </span>
                        {[1, 2].includes(currentUser?.role) && <span onClick={editIconArrangementDev}>
                            {locale.get.store.account.editIconArrangementDev}
                        </span>}
                        {[1, 2].includes(currentUser?.role) && <span onClick={editIconParametersDev}>
                            {locale.get.store.account.editIconParametersDev}
                        </span>}
                        <span onClick={goToStudio}>
                            {locale.get.store.account.creativeMapsStudio} <span className="account-studio-arrow">￫</span>
                        </span>
                    </div>
                    : null
                }
            </div>
            <div className="account-product-container">
                {user && user.id === currentUser?.id && <ProductsCategory
                    title={locale.get.store.account.favouriteProducts}
                    endpoint={`/api/v2/store/account/${id}/wanted_product_versions`}
                    isPubDependent={true}
                />}
                <ProductsCategory
                    title={locale.get.store.account.publishedProducts}
                    endpoint={`/api/v2/store/account/${id}/published_product_versions`}
                    isPubDependent={true}
                />
                <ProductsCategory
                    title={locale.get.store.account.unpublishedProducts}
                    endpoint={`/api/v2/store/account/${id}/unpublished_product_versions`}
                    isPubDependent={true}
                />
                <ProductsCategory
                    title={locale.get.store.account[user && user.id === currentUser?.id
                        ? "myCoauthoredProducts"
                        : "usersCoauthoredProducts"]
                    }
                    endpoint={`/api/v2/store/account/${id}/coauthored_product_versions`}
                />
                {user && user.id === currentUser?.id && <ProductsCategory
                    title={locale.get.store.account.purchasedProducts}
                    endpoint={`/api/v2/store/account/${id}/purchased_product_versions`}
                />}
            </div>
        </div>
    ));
}

import React, {useEffect} from "react";
import {validate as validateUuid} from "uuid";
import queryString from "query-string";
import {ActionCreators} from "redux-undo";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";

import {useMap} from "./useContextMap";
import {useApiClient} from "../../customHooks/useApiClient";
import {useModal} from "../../customHooks/useContextModal";
import {useCurrentProject} from "./useContextCurrentProject";
import {useCurrentUser} from "../../customHooks/useContextCurrentUser";
import {useIconSet} from "../../customHooks/useContextIconSet";
import {getProjectUuid} from "../utils/currentProject";
import {getFirstToolFromTabSet} from "../utils/tabSet";
import {useGrid} from "./useContextGrid";
import {hasMap, removeMap} from "../utils/mapAutosave";


export default function () {
    const map = useMap();
    const api = useApiClient();
    const modal = useModal();
    const location = useLocation();
    const {set: setCurrentProject} = useCurrentProject();
    const user = useCurrentUser();
    const dispatch = useDispatch();
    const {get: {tabSet}} = useIconSet();
    const {set: setShouldShowGrid} = useGrid();

    useEffect(async () => {
        const preloadMap = async () => {
            const projectUuid = getProjectUuid();
            const projectValidUuid = validateUuid(projectUuid) ? projectUuid : "";
            if (location.pathname.endsWith("/share") && map) {  //load shared map
                await removeMap(projectValidUuid);
                const {share_token} = queryString.parse(location.search);
                dispatch({
                    type: "clearMenuState",
                    menuParamsToSet: {tool: getFirstToolFromTabSet(tabSet), color: "#000000"}
                });
                dispatch({type: "clearState"});
                dispatch(ActionCreators.clearHistory());
                dispatch({type: "loadFromLink"});
                await map.loadSharedMap(share_token);
                modal.set("");
            }

            let currentProjectId = -1;
            let config;
            if (projectValidUuid) {
                const currentProject = (
                    await api.put(`/api/v2/projects/${projectUuid}/add_participant_to_public_project`)
                ).data;
                currentProjectId = currentProject.id;
                config = currentProject.config;
            }
            const projectData = {
                id: currentProjectId,
                survey: undefined,
                reward: undefined,
                config: config ? JSON.parse(config) : undefined
            };

            try {
                let response = await api.get(`/api/v2/surveys/scheme/?current_project_id=${currentProjectId}`);
                projectData.survey = response.data;
            } catch (err) {}

            try {
                let response = await api.get(`/api/v2/reward/scheme/?current_project_id=${currentProjectId}`);
                projectData.reward = response.data;
            } catch (err) {}

            setCurrentProject(projectData);

            if (!location.pathname.endsWith("/share")) {
                if (await hasMap(projectValidUuid)) {
                    await map.loadMapFromLocalStorage();
                } else if (projectData?.config?.defaultMap) {
                    dispatch({
                        type: "clearMenuState",
                        menuParamsToSet: {tool: getFirstToolFromTabSet(tabSet), color: "#000000"}
                    });
                    dispatch({type: "clearState"});
                    dispatch(ActionCreators.clearHistory());
                    await map.loadMap(projectData.config.defaultMap);
                    if (projectData.config.menuState?.shouldShowGrid === false) {
                        setShouldShowGrid(false);
                    }
                }
            }
        }
        if (user !== undefined) { // if user is null, then it just anonymous
            await preloadMap();
        }
    }, [location, user]);
    return null;
};

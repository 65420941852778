import {useEffect, useState} from "react";
import {useApiClient} from "../../../../../../customHooks/useApiClient";


export default function useAvailableConverters () {
    const api = useApiClient();

    const [availableConverters, setAvailableConverters] = useState([]);

    useEffect(() => {
        const asyncFunction = async () => {
            const available_converters = (await api.get("/api/v2/projects/analytics/converters/")).data;
            setAvailableConverters(available_converters);
        };
        asyncFunction();
    }, []);

    return availableConverters;
}
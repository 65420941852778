import React from "react";

import Maps from "./Maps";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useCurrentProject} from "../../customHooks/useContextCurrentProject";


export default function MyMaps() {
    const locale = useLocalization();
    const {get: currentProject} = useCurrentProject();
    return <Maps
        mapsLink={`/api/v2/map/?current_project_id=${currentProject.id}`}
        title={locale?.get?.studio.myMaps.myMaps}
        noMapsMessage={locale?.get?.studio.myMaps.youHaveNoMaps}
        showSpace={true}
    />;
}

import {Record} from 'immutable';

class Polygon extends Record({
    uuid: undefined,
    parentUuid: undefined,
    x: undefined,
    y: undefined,
    points: undefined,
    tool: "empty",
    color: null,
    emoji: [],
    fontColor: undefined,
    fontSize: undefined,
    transparency: undefined,
    name: "",
    link: "",
    description: "",
    layout: undefined,
    orderIndex: undefined,
    users: []
}) {
}

export default Polygon;

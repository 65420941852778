import {Record} from 'immutable';


class Text extends Record({
    x: undefined,
    y: undefined,
    tool: "empty",
    uuid: undefined,
    parentUuid: undefined,
    fontColor: undefined,
    fontSize: undefined,
    transparency: undefined,
    name: "",
    link: "",
    description: "",
    layout: undefined,
    orderIndex: undefined,
    users: []
}) {
}

export default Text;

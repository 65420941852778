import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {v4 as uuid} from "uuid";

import {useReduxData} from "./useContextReduxData";
import {getRootUuid} from "../utils/figureHierarchy";
import getTimeElapsedSince from "../components/getTimeElapsedSince";
import {groupSchema} from "../utils/validators";
import {GROUP_LAYOUT} from "../components/canvas/Canvas";


const GroupingObjectsContext = React.createContext();


export const GroupingObjectsProvider = ({children}) => {
    const [figureToDeleteUuids, setFigureToDeleteUuids] = useState("");
    const {getFiguresUpdatedOn, selectedFigureUuidsWithoutArrows, startTime} = useReduxData();
    const figures = getFiguresUpdatedOn([
        "addPolygon",
        "addPolyline",
        "addPointFeature",
        "addGroup",
        "changeObjectParentUuid",
        "clearState",
        "deleteFigure",
        "disbandGroup"
    ]);
    const dispatch = useDispatch();

    //if disband will be inside ungroupObjects, then disband will be possible to perform before than menu was prepared
    useEffect(() => {
        if (figureToDeleteUuids !== "" && selectedFigureUuidsWithoutArrows.length === 0) {
            figureToDeleteUuids.split("|").map(uuid => figures.get(uuid).get("children") &&
                dispatch({
                    type: "disbandGroup",
                    uuid: uuid,
                    children: figures.get(uuid).get("children"), //TODO: useless param, I can get it inside reducer
                    time: getTimeElapsedSince(startTime)
                })
            );
            setFigureToDeleteUuids("");
        }
    }, [figureToDeleteUuids, selectedFigureUuidsWithoutArrows]);

    const groupObjects = () => {
        if (selectedFigureUuidsWithoutArrows.length > 1) {
            let newGroupChildUuids = [...new Set(selectedFigureUuidsWithoutArrows.map(uuid => getRootUuid(figures, uuid)))];
            let newGroupUuid = uuid();
            const newGroupObject = {
                uuid: newGroupUuid,
                parentUuid: undefined,
                tool: "g-s-72",
                children: newGroupChildUuids,
                name: "",
                description: "",
                layout: GROUP_LAYOUT,
                orderIndex: Math.round(new Date().getTime()),
            };
            if (!groupSchema.isValidSync(newGroupObject)) {
                return;
            }

            dispatch({...newGroupObject, type: "addGroup", time: getTimeElapsedSince(startTime)});
            dispatch({type: "selectedFigureUuids", value: [newGroupUuid]});
        }
    };

    const ungroupObjects = () => {
        if (selectedFigureUuidsWithoutArrows.length > 0) {
            setFigureToDeleteUuids(selectedFigureUuidsWithoutArrows.join("|"));
            dispatch({type: "selectedFigureUuids", value: []});
        }
    };

    return <GroupingObjectsContext.Provider value={{groupObjects, ungroupObjects}}>
        {children}
    </GroupingObjectsContext.Provider>;
};


export const useGroupingObjects = () => useContext(GroupingObjectsContext);

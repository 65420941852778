import RadioGroup from "../../RadioGroup";
import {useLocalization} from "../../../../customHooks/useContextLocalization";



const ProductPublicity = props => {
    const locale = useLocalization();

    const setValue = option => props.setFieldValue("publicity", option);

    return <RadioGroup groupName="publicity" defaultValue={props.defaultValue} onChange={setValue}>
        <span className="product-form-radio-item">{locale?.get?.store.product.form.restricted}</span>
        <span className="product-form-radio-item">{locale?.get?.store.product.form.anybody}</span>
    </RadioGroup>;
}

export default ProductPublicity;
import React, {useState, useContext} from "react";


const HintModeContext = React.createContext();


export const HintModeProvider = ({children}) => {
    const [get, set] = useState(false);

    return (
        <HintModeContext.Provider value={{get, set}}>
            {children}
        </HintModeContext.Provider>
    );
};


export const useHintMode = () => useContext(HintModeContext);

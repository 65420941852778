import useAvailableNodeTypes from "./hooks/useAvailableNodeTypes";
import {useNodeFilter} from "./hooks/useNodeFilter";
import {deleteAt} from "../../../../../../utils";
import {useLocalization} from "../../../../../../customHooks/useContextLocalization";


export default function () {
    const {inputTypes, outputTypes} = useAvailableNodeTypes();
    const {inputTypesToShow, setInputTypesToShow, outputTypesToShow, setOutputTypesToShow} = useNodeFilter();
    const locale = useLocalization();

    const changeInputTypesDefault = event => event.preventDefault();

    const changeInputTypes = event => {
        const currentOption = event.target.value;
        if (event.ctrlKey) {
            const currentItemIndex = inputTypesToShow.indexOf(currentOption);
            if (currentItemIndex !== -1) {
                setInputTypesToShow(deleteAt(inputTypesToShow, currentItemIndex));
            } else {
                setInputTypesToShow(inputTypesToShow.concat(currentOption));
            }
        } else {
            setInputTypesToShow([currentOption]);
        }
    }

    const changeOutputTypesDefault = event => event.preventDefault();

    const changeOutputTypes = event => {
        const currentOption = event.target.value;
        if (event.ctrlKey) {
            const currentItemIndex = outputTypesToShow.indexOf(currentOption);
            if (currentItemIndex !== -1) {
                setOutputTypesToShow(deleteAt(outputTypesToShow, currentItemIndex));
            } else {
                setOutputTypesToShow(outputTypesToShow.concat(currentOption));
            }
        } else {
            setOutputTypesToShow([currentOption]);
        }
    }

    const resetFilter = () => {
        setInputTypesToShow([]);
        setOutputTypesToShow([]);
    };

    return <div className="project-node-menu-filter-container">
        <select multiple value={inputTypesToShow} size={4} onChange={changeInputTypesDefault} onClick={changeInputTypes}>
            {inputTypes.map((inputType, i) => <option key={i}>{inputType}</option>)}
        </select>
        ->
        <select multiple value={outputTypesToShow} size={4} onChange={changeOutputTypesDefault} onClick={changeOutputTypes}>
            {outputTypes.map((outputType, i) => <option key={i}>{outputType}</option>)}
        </select>
        <input
            type="button"
            className="project-node-menu-filter-reset"
            value={locale?.get?.project.analytics.mainPage.dfd.reset}
            onClick={resetFilter}
        />
    </div>;
}

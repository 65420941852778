import React, {useState, useEffect, useRef} from "react";
import {useDispatch} from 'react-redux';

import {useReduxData} from "../../customHooks/useContextReduxData";
import tooltipHOC from "../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../onboarding/hintModeTooltipHOC";


const FloatingMenuItemImageContainer = tooltipHOC( //hint on hover
    hintModeTooltipHOC( //hint in hintMode
        ({id, styles, children}) =>
            <div className="floating-menu-item" id={`floating-menu-item-${id}`} style={styles}>{children}</div>
    ), undefined, {direction: "right", padding: "10px"}
);


export default function ({
    id,
    images,
    actions,
    switchHandlerName,
    shouldHighlighted,
    shouldDisabled,
    extraEffect,
    tooltipContent,
    hintModeKey
}) {
    const {getShouldShowFirstImageOfFloatingMenuSwitcher, openedMenuItemNumber} = useReduxData();
    const shouldShowFirstImage = getShouldShowFirstImageOfFloatingMenuSwitcher(switchHandlerName);

    const [bodyOverflowX, setBodyOverflowX] = useState("0");

    const dispatch = useDispatch();

    const bodyRef = useRef(null);

    useEffect(() => {
        if (bodyRef?.current?.getBoundingClientRect().right > document.body.clientWidth) {
            setBodyOverflowX(document.body.clientWidth - bodyRef?.current?.getBoundingClientRect().right + "px");
        } else if (bodyOverflowX !== "0px") {
            setBodyOverflowX("0px");
        }
    }, [openedMenuItemNumber !== id]);

    const executeExtraEffect = () => !shouldDisabled && extraEffect && extraEffect();

    const showAllModes = event => {
        event.preventDefault();
        if (!shouldDisabled && openedMenuItemNumber !== id && images.length > 1) {
            dispatch({type: "openedMenuItemNumber", value: openedMenuItemNumber !== id ? id : "-1"});
        }
    };

    const chooseMode = event => {
        event.stopPropagation(); //prevents executing 'executeExtraEffect'
        let i = +event.target.id.split("-")[3];
        dispatch(actions[i]);
        dispatch({type: "openedMenuItemNumber", value: "-1"});
    };

    let highlighted = shouldHighlighted ? " floating-menu-item-wrapper-active" : "";
    let disabled = shouldDisabled ? " disabled" : "";

    return <div
        className={`floating-menu-switcher-wrapper${highlighted}${disabled}`}
        onClick={executeExtraEffect}
        onContextMenu={showAllModes}
        style={{borderBottomRightRadius: images.length > 1 ? "0px" : "10px"}}
    >
        <FloatingMenuItemImageContainer
            id={id}
            hintModeKey={hintModeKey}
            tooltipContent={tooltipContent}
            styles={{backgroundImage: `url(${shouldShowFirstImage ? images[0] : images[1]})`}}
        >
            {openedMenuItemNumber === id && !shouldDisabled && <div
                className="floating-menu-switcher-body"
                id={`floating-menu-switcher-body-${id}`}
                ref={bodyRef}
                style={{marginLeft: `calc(-10px + ${bodyOverflowX})`}}
            >
                {images.map((image, i) => <div className="floating-menu-switcher-item-wrapper">
                    <div
                        className="floating-menu-item"
                        id={`tool-menu-switcher-${i}`}
                        style={{backgroundImage: `url(${image})`}}
                        onClick={chooseMode}
                    />
                </div>)}
            </div>}
        </FloatingMenuItemImageContainer>
    </div>;
}

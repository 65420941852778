import React, {useCallback, useMemo, useState, useEffect} from "react";
import {flatten, uniq} from "lodash";
import {useHistory, useLocation} from "react-router-dom";
import ReactSelect from "react-select";
import queryString from "query-string";

import {createOption} from "../../../utils";
import Input from "../Input";
import Rating from "../Rating";
import Icon from "../Icon";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {
    SearchIconTransparent,
    ClearIndicator,
    IndicatorSeparator,
    DropdownIndicator,
    ValueContainer
} from "../CustomIcons";
import {languageAbbrs} from "../../../localization/languages";

import styles from "./filter.module.css";


function Filter({data, onFilterChange}) {
    const [state, setState] = useState({languages: [], tags: []});

    const locale = useLocalization();
    const history = useHistory();
    const location = useLocation();

    const filterChangeHandler = useCallback(() => onFilterChange(state), [state, onFilterChange]);

    useEffect(() => {
        if (queryString.parse(location.search)["search_text"]) {
            setState({filter: queryString.parse(location.search)["search_text"]});
            history.replace(location.pathname);
        }
    }, []);

    useEffect(() => {
        filterChangeHandler();
    }, [state]);

    const tagsOptions = useMemo(
        () => uniq(flatten(data.map(lib => lib?.tags)).map(tag => tag.name.trim())),
        [data]
    );

    const languagesOptions = useMemo(
        () => uniq(flatten(data.map(lib => Object.keys(lib?.name)))),
        [data]
    );

    const changeNameField = val => setState({...state, filter: val});
    const changeCostFrom = val => setState({...state, min_price: val});
    const changeCostTo = val => setState({...state, max_price: val});
    const changeTags = options => setState({...state, tags: options.map(option => option.value)});
    const changeLanguages = options => setState({...state, languages: options.map(option => option.value)});
    const ratingChanged = event => setState({...state, min_rating: +event.target.parentNode.id.split("-")[3]});
    const clearRating = () => setState({...state, min_rating: 0});

    return (
        <div className={styles.filter}>
            <div>
                <Input
                    type="text"
                    renderIcon={(props) => <Icon icon={SearchIconTransparent}  {...props} />}
                    placeholder={locale.get?.store.filter.search}
                    value={state.filter || ""}
                    onChange={changeNameField}
                />
            </div>
            <label>{locale.get?.store.filter.cost}</label>
            <div className={styles["filter-price-container"]}>
                <label>{locale.get?.store.filter.costFrom}</label>
                <Input
                    type="number"
                    placeholder={0}
                    value={state.min_price}
                    onChange={changeCostFrom}
                />
                <label className={styles["left-margin"]}>{locale.get?.store.filter.costTo}</label>
                <Input
                    type="number"
                    placeholder={Math.max(...data.map(lib => lib.price))}
                    value={state.max_price}
                    onChange={changeCostTo}
                />
            </div>

            <label>{locale.get?.store.filter.tags}</label>
            <div>
                <ReactSelect
                    isClearable
                    isMulti
                    className="store-select"
                    options={tagsOptions.map(createOption)}
                    placeholder={locale?.get?.store.product.form.tagsPlaceholder}
                    noOptionsMessage={() => locale?.get?.store.product.form.noOptions}
                    value={state.tags.map(createOption)}
                    components={{ClearIndicator, IndicatorSeparator, DropdownIndicator, ValueContainer}}
                    onChange={changeTags}
                />
            </div>
            <label className={styles["filter-top-margin"]}>{locale.get?.store.filter.languages}</label>
            <ReactSelect
                isClearable
                isMulti
                className="store-select"
                options={languagesOptions.map(abbr => languageAbbrs[abbr].endonym).map(createOption)}
                placeholder={locale?.get?.store.filter.languagesPlaceholder}
                noOptionsMessage={() => locale?.get?.store.product.form.noOptions}
                value={state.languages.map(createOption)}
                components={{ClearIndicator, IndicatorSeparator, DropdownIndicator, ValueContainer}}
                onChange={changeLanguages}
            />
            <label className={styles["filter-top-margin"]}>{locale.get?.store.filter.rating}</label>
            <div className={styles["filter-rating-container"]}>
                <label>{locale.get?.store.filter.ratingFrom}</label>
                <Rating n={state.min_rating || 0} shouldRenderNormalStars={true} onClick={ratingChanged}/>
                <input
                    type="button"
                    className ={styles["filter-rating-clear-button"]}
                    value={locale.get?.store.filter.ratingClear}
                    onClick={clearRating}
                />
            </div>
        </div>
    );
}

export default Filter;

import {useRef} from "react";
import {useParams} from "react-router-dom";

import {useApiClient} from "../../../customHooks/useApiClient";
import {useLocalization} from "../../../customHooks/useContextLocalization";


export default function () {
    const locale = useLocalization();
    const api = useApiClient();
    const {id: currentProjectId} = useParams();

    const idFromRef = useRef();
    const idToRef = useRef();

    const downloadArchive = archive => {
        const now = new Date();
        const formattedDate = [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()]
            .join(".");

        const filename = prompt(locale?.get?.project.analytics.additionalPage.archiveName, `Архив_от_${formattedDate}`);
        const blob = new Blob([archive], {type: "application/zip"});
        filename && saveAs(blob, filename + ".zip");
    }

    const downloadCSV = archive => {
        const now = new Date();
        const formattedDate = [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()]
            .join(".");

        const filename = prompt(locale?.get?.project.analytics.additionalPage.fileName, `CSV_от_${formattedDate}`);
        const blob = new Blob([archive], {type: "text/csv"});
        filename && saveAs(blob, filename + ".csv");
    }

    const loadAllMaps = async () => {
        downloadArchive((await api.get(
            `/api/v2/projects/analytics/loader/?project_id=${currentProjectId}`,
            {responseType: 'blob'}
        )).data);
    };

    const loadSomeMaps = async () => downloadArchive((await api.get(
        `/api/v2/projects/analytics/loader/?id_1=${idFromRef?.current?.value || 0}&id_2=${idToRef?.current?.value || 1}&project_id=${currentProjectId}`,
        {responseType: 'blob'}
    )).data);

    const loadLinks = async () => { //TODO: update function
        let links = (await api.put(
            `/api/v2/projects/analytics/loader/links/?id_1=${idFromRef?.current?.value || 0}&id_2=${idToRef?.current?.value || 1}&project_id=${currentProjectId}`,
            {responseType: 'blob'}
        )).data;

        if (links === "") {
            return;
        }

        links = links.split("\n");
        links = links.map(linkEntry => {
            let [link, projectLink, ...entry] = linkEntry.split(","); //TODO: add supporting of project_uuid
            link = `${location.origin}${projectLink ? "/" + projectLink : ""}/share?share_token=${encodeURIComponent(link.slice(2, link.length - 1))}`;
            return [link].concat(entry).join(",");
        });
        links = links.join("\n")
        downloadCSV(links);
    };

    return <div className="project-analytics-dashed-container">
        <h3>{locale?.get?.project.analytics.additionalPage.downloadMapsAndLinks}</h3>
        <div className="project-analytics-data-downloader-partition-container">
            <input
                type="button"
                value={locale?.get?.project.analytics.additionalPage.loadAllMaps}
                onClick={loadAllMaps}
            />
        </div>
        <div className="project-analytics-data-downloader-partition-container">
            <input
                ref={idFromRef}
                type="text"
                placeholder={locale?.get?.project.analytics.additionalPage.startUserId}
            />
            <input
                ref={idToRef}
                type="text"
                placeholder={locale?.get?.project.analytics.additionalPage.lastUserId}
            />
            <input
                type="button"
                value={locale?.get?.project.analytics.additionalPage.loadMaps}
                onClick={loadSomeMaps}
            />
            <input
                type="button"
                value={locale?.get?.project.analytics.additionalPage.loadLinks}
                onClick={loadLinks}
            />
        </div>
    </div>;
}
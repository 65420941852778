import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {v4 as uuid} from "uuid";

import {useReduxData} from "../../../customHooks/useContextReduxData";
import {useCanvasBoundingClientRect} from "../../../customHooks/useContextCanvasBoundingClientRect";
import {useIconSet} from "../../../../customHooks/useContextIconSet";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useUserViewBox} from "../../../customHooks/useContextUserViewBox";
import useCooTransformation from "./useCooTransformation";
import getTimeElapsedSince from "../../getTimeElapsedSince";
import {transformDegToRad} from "../../../customHooks/utils/longPolygonCreation";
import {polygonSchema} from "../../../utils/validators";
import Path from "../../../utils/path";


function ConfigurationMenu({createRegularPolygon, x, y}) {
    const MENU_WIDTH = 150;
    const MENU_HEIGHT = 370;

    const locale = useLocalization();
    const {get: userViewBox} = useUserViewBox();
    const [viewBoxX, viewBoxY, viewBoxWidth, viewBoxHeight] = userViewBox.split(" ").map(item => +item);

    const [radius, setRadius] = useState(150);
    const [vertexCount, setVertexCount] = useState(8);
    const [initRotation, setInitRotation] = useState(0);
    const [adjustedX, setAdjustedX] = useState(x);
    const [adjustedY, setAdjustedY] = useState(y);

    useEffect(() => {
        const viewBoxEndX = viewBoxX + viewBoxWidth, viewBoxEndY = viewBoxY + viewBoxHeight;
        if (x + MENU_WIDTH > viewBoxEndX) {
            setAdjustedX(viewBoxEndX - MENU_WIDTH);
        }
        if (y + MENU_HEIGHT > viewBoxEndY) {
            setAdjustedY(viewBoxEndY - MENU_HEIGHT);
        }
    }, [x, y]);

    const isValidNumberInput = event => event.target.value.match(/^\d*$/)?.[0] || event.target.value === "";

    const changeRadiusOnValueChange = event => isValidNumberInput(event) && setRadius(+event.target.value);
    const changeVertexCountOnValueChange = event => isValidNumberInput(event) && setVertexCount(+event.target.value);
    const changeInitRotationOnValueChange = event => isValidNumberInput(event) && setInitRotation(+event.target.value);

    const createFigure = () => createRegularPolygon(radius, vertexCount, initRotation);

    return <foreignObject x={adjustedX} y={adjustedY} width={MENU_WIDTH} height={MENU_HEIGHT}>
        <div className="long-polygon-configurator-container">
            <p>{locale?.get?.studio.prConfigurator.radius}</p>
            <input type="number" value={radius} onChange={changeRadiusOnValueChange}/>
            <p>{locale?.get?.studio.prConfigurator.vertexCount}</p>
            <input type="number" value={vertexCount} onChange={changeVertexCountOnValueChange}/>
            <p>{locale?.get?.studio.prConfigurator.initRotation}</p>
            <input type="number" value={initRotation} onChange={changeInitRotationOnValueChange}/>
            <input type="button" value={locale?.get?.studio.prConfigurator.set} onClick={createFigure}/>
        </div>
    </foreignObject>;
}


export default function useRegularPolygonDrawing() {
    const dispatch = useDispatch();
    const {color, fontColor, fontSize, startTime, tool, transparency} = useReduxData();
    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const {get: {parameters}} = useIconSet();
    const {transformYForMap, transformXForMap} = useCooTransformation();
    const [mode, setMode] = useState("drawing");
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    const createRegularPolygon = (radius, vertexCount, initRotation) => {
        if (+radius === 0 || +vertexCount < 3 || +vertexCount > 100) {
            setMode("drawing");
            setX(0);
            setY(0);
            return;
        }

        const centerX = transformXForMap(x);
        const centerY = transformYForMap(y);

        const adjustedInitRotation = transformDegToRad(initRotation - 90);
        const radStep = 2 * Math.PI / vertexCount;

        let points = [];
        let minX = Infinity, minY = Infinity;
        for (let i = 0; i < vertexCount; i++) {
            let currentX = centerX + radius * Math.cos(adjustedInitRotation + radStep * i),
                currentY = centerY + radius * Math.sin(adjustedInitRotation + radStep * i);
            points.push([currentX, currentY]);
            minX = minX > currentX ? currentX : minX;
            minY = minY > currentY ? currentY : minY;
        }
        points.push([points[0][0], points[0][1]]);

        let path = "M " + points.join(" L ");
        path = Path.transform(path, -minX, -minY, 1, 1);
        let newFigureUuid = uuid();
        let newFigureObject = {
            uuid: newFigureUuid,
            parentUuid: undefined,
            name: "",
            link: "",
            description: "",
            emoji: [],
            x: minX,
            y: minY,
            points: path,
            tool,
            color,
            fontColor,
            fontSize,
            transparency,
            layout: +parameters[tool]?.layout || 50,
            orderIndex: Math.round(new Date().getTime())
        };
        if (!polygonSchema.isValidSync(newFigureObject)) {
            return;
        }
        dispatch({...newFigureObject, type: "addPolygon", time: getTimeElapsedSince(startTime)});
        dispatch({type: "selectedFigureUuids", value: [newFigureUuid]});
        setMode("drawing");
    }

    const showConfigurationMenu = event => {
        let canvasX = canvasBoundingClientRect.get.left;
        let canvasY = canvasBoundingClientRect.get.top;
        if (typeof canvasX !== "number" || typeof canvasY !== "number") {
            return;
        }
        setX(event.clientX - canvasX);
        setY(event.clientY - canvasY);
        setMode("configuration");
    };

    return {
        showConfigurationMenu,
        regularPolygonConfigurationMenu: mode === "configuration"
            ? <ConfigurationMenu createRegularPolygon={createRegularPolygon} x={x} y={y}/>
            : null
    };
}

import React, {useContext, useLayoutEffect, useState} from "react";


const IsMouseDownContext = React.createContext();


export function IsMouseDownProvider ({children}) {
    const [isMouseDown, setIsMouseDown] = useState(false);

    useLayoutEffect(() => { // to catch key up everywhere
        window.addEventListener("mousedown", onMouseDown);
        return () => window.removeEventListener("mousedown", onMouseDown);
    });

    useLayoutEffect(() => { // to catch key up everywhere
        window.addEventListener("mouseup", onMouseUp);
        return () => window.removeEventListener("mouseup", onMouseUp);
    });

    const onMouseDown = () => setIsMouseDown(true);
    const onMouseUp = () => setIsMouseDown(false);

    return <IsMouseDownContext.Provider value={isMouseDown}>
        {children}
    </IsMouseDownContext.Provider>;
}

export const useIsMouseDown = () => useContext(IsMouseDownContext);
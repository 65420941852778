import {useState, useEffect} from "react";

import JsonDataRenderer from "./JsonDataRenderer";
import WfpmPVisualizer from "./WfpmPVisualizer";
import GeneralMapVisualizer from "./generalMapVisualizer/GeneralMapVisualizer";


export default function ({dataType, dataToRender}) {
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (dataType === "studio/text" && dataToRender) {
            setResult(<JsonDataRenderer data={dataToRender}/>);
        } else if (dataType === "studio/wfpm-pc-visual") {
            setResult(<WfpmPVisualizer data={dataToRender}/>);
        } else if (dataType === "studio/gm-visual") {
            setResult(<GeneralMapVisualizer data={dataToRender}/>);
        }
    }, [dataType, dataToRender]);

    return result;
}
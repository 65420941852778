import React from "react";

import {useModal} from "../../../../../customHooks/useContextModal";
import {useCurrentUser} from "../../../../../customHooks/useContextCurrentUser";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";

import {ReactComponent as MyMaps} from "../images/first-block/my-maps.svg";


const IconMyMaps = tooltipHOC(
    hintModeTooltipHOC(MyMaps, "firstToolBlockMyMaps", {marginBottom: "-2px", marginTop: "-2px"}),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () {
    const locale = useLocalization();
    const modal = useModal();
    const user = useCurrentUser();

    const openMyMaps = () => modal.set(user ? "my_maps" : "signin");

    return <div className="tool-block-item" onClick={openMyMaps}>
        <IconMyMaps tooltipContent={locale.get.studio.header.firstToolBlock.myMaps}/>
    </div>;
}

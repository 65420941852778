
/**
 * Comparator. Uses layout as the first key, and orderIndex as the second one
 * @typedef {Arrow|Group|PointFeature|Polygon|Polyline|Rectangle|Text}   Figure
 * @param  {Figure}   left     A uuid of source figure
 * @param  {Figure}   right    The figures object from state (immutable Map)
 * @return {Number}            > 0 if left figure has precedence, = 0, if precedence equal, < 0 otherwise
 */
export function compareOrderOfTwoFigures(left, right) {
    return +left.get("layout") - +right.get("layout") || +left.get("orderIndex") - +right.get("orderIndex");
}

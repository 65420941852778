import React from "react";

import SvgText from "./SvgText";
import Link from "./Link";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useIconSet} from "../../../customHooks/useContextIconSet";
import {useReduxData} from "../../customHooks/useContextReduxData";
import useCooTransformation from "../canvas/customHooks/useCooTransformation";
import {getPreferredFontColorByBackgroundColor} from "../../utils/color";


export default function Rectangle({figure, kind}) {
    const locale = useLocalization();
    const {get: {parameters}} = useIconSet();
    const {scale} = useReduxData();
    const {transformYForCanvas, transformXForCanvas} = useCooTransformation();

    const transformedFigureX = transformXForCanvas(figure.get("x")),
        transformedFigureY = transformYForCanvas(figure.get("y"));

    const langCoefficient = locale?.get.name === "chinese" ? 40/23 : 1;

    return (figure && parameters) ? <g className="figure">
        {figure.get("image") ? <defs>
            <pattern
                id={`rect-image-${figure.get("uuid")}`}
                patternUnits="userSpaceOnUse"
                height={figure.get("height") * scale}
                width={figure.get("width") * scale}
            >
                <image
                    href={figure.get("image")}
                    preserveAspectRatio="none"
                    x="0"
                    y="0"
                    width={figure.get("width") * scale}
                    height={figure.get("height") * scale}
                />
            </pattern>
        </defs> : null}
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x={transformedFigureX}
            y={transformedFigureY}
            height={figure.get("height") * scale}
            width={figure.get("width") * scale}
            style={{
                fill: figure.get("image")
                    ? `url(#rect-image-${figure.get("uuid")})`
                    : figure.get("color") == null ? "white" : figure.get("color"),
                stroke: "black",
                opacity: figure.get("transparency")
            }}
        >
            <rect
                id={figure.get("uuid")}
                className={kind === "separate" ? "figure" : "group"}
                x="0"
                y="0"
                width={figure.get("width") * scale}
                height={figure.get("height") * scale}
            />
        </svg>
        <SvgText
            id={figure.get("uuid")}
            className={kind === "separate" ? "text" : "group"}
            strokeColor={getPreferredFontColorByBackgroundColor(figure.get("fontColor"))}
            fillColor={figure.get("fontColor")}
            fontSize={figure.get("fontSize") === "standard" ? "18px" : figure.get("fontSize") / 2 + "mm"}
            text={figure.get("name")}
            x={transformedFigureX}
            y={transformedFigureY}
            opacity={figure.get("transparency")}
            renderApproach="html"
            htmlHeight={figure.get("height") * scale}
            htmlWidth={figure.get("width") * scale}
        />
        {figure.get("link") && <Link
            id={figure.get("uuid")}
            x={transformedFigureX + 0.5 * figure.get("width") * scale}
            y={transformedFigureY + 0.5 * figure.get("height") * scale}
            height={15}
            width={15}
            linkAddress={figure.get("link")}
        />}
    </g> : null;
}

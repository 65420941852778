import {useHistory} from "react-router-dom";

import ProjectGroup from "./ProjectGroup";
import ForbiddenPage from "../ForbiddenPage";
import {useLocalization} from "../../customHooks/useContextLocalization";
import {useCurrentUser} from "../../customHooks/useContextCurrentUser";


export default function () {
    const currentUser = useCurrentUser();
    const locale = useLocalization();
    const history = useHistory();

    const createProject = () => history.push("/project/new");

    return ![1, 2].includes(currentUser?.role) && !currentUser?.is_analyst ? <ForbiddenPage/>
        : <div className="project-container">
            <div className="project-title-container">
                <h1 className="project-page-title">{locale?.get?.project.myProjects.myProjects}</h1>
                {[1, 2].includes(currentUser?.role) && <input
                    type="button"
                    className="my-projects-create-project-btn"
                    value={locale?.get?.project.myProjects.createNewProject}
                    onClick={createProject}
                />}
            </div>
            <ProjectGroup groupName={locale?.get?.project.myProjects.iAmAnAuthor} endpoint="as_author"/>
            <ProjectGroup groupName={locale?.get?.project.myProjects.iAmAnInvitedAnalyst} endpoint="as_analyst"/>
            <ProjectGroup groupName={locale?.get?.project.myProjects.iAmAParticipant} endpoint="as_participant"/>
        </div>;
}

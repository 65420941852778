import JSZip from "jszip";


export const compressFiles = async (files, setCompressionProgress = () => {}) => {
    let archive = JSZip();
    for (let i = 0; i < files.length; i++) {
        archive.file(files[i].name, files[i]);
    }
    return await archive.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {level: 9}
    }, setCompressionProgress);
};

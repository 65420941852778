import React from "react";

import SocialNetworkIcon from "../../../components/SocialNetworkIcon";

import {ReactComponent as TGIcon} from "../../../images/social/icon-telegram.svg";
import {ReactComponent as VKIcon} from "../../../images/social/icon-vk.svg";

import socialComponentStyles from './socialComponent.module.css';


export default function SocialComponent() {
    const handleShareToTelegram = async () => {
        const popupTop = window.screen.height / 2 - 150,
            popupLeft = window.screen.width / 2 - 300;

        const url = "creativemaps.studio/store";
        const text = "";
        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=300,left=${popupLeft},top=${popupTop}`;

        window.open(`https://t.me/share/url?url=${url}&text=${text}`, "tg.share", params);
    };

    const handleShareToVK = async () => {
        const popupTop = window.screen.height / 2 - 150,
            popupLeft = window.screen.width / 2 - 300;

        const url = "creativemaps.studio/store";
        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=300,left=${popupLeft},top=${popupTop}`;

        window.open(`https://vk.com/share.php?url=${url}`, "vk.share", params);
    };

    return <div className = {socialComponentStyles["share-container"]}>
        <SocialNetworkIcon
            id="store-footer-social-telegram"
            className={socialComponentStyles["footer-icon-wrapper"]}
            SvgIcon={TGIcon}
            iconSize="24"
            onClick={handleShareToTelegram}
        />
        <SocialNetworkIcon
            id="store-footer-social-vk"
            className={socialComponentStyles["footer-icon-wrapper"]}
            SvgIcon={VKIcon}
            iconSize="24"
            onClick={handleShareToVK}
        />
    </div>;
}

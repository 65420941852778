import React, {useContext} from "react";
import {Formik, Field, Form, ErrorMessage, getIn} from "formik";
import {useLocation} from "react-router-dom";
import * as Yup from "yup";
import queryString from "query-string";
import {toast} from "react-toastify";

import {CurrentUserContext} from "../customHooks/useContextCurrentUser";
import {ErrorView, RecaptchaField, getStyles} from "./form_fields";
import {useLocalization} from "../customHooks/useContextLocalization";
import {useApiClient} from "../customHooks/useApiClient";
import {useModal} from "../customHooks/useContextModal";


export const ResetPasswordForm = () => {
    const locale = useLocalization();
    const api = useApiClient();
    const modal = useModal();
    const {user} = useContext(CurrentUserContext);
    const {token} = queryString.parse(useLocation().search);

    return user
        ? <span>{locale?.get.auth.alreadyLoggedIn}</span>
        : <Formik
            initialValues={{password: '', recaptcha: '', token}}
            validationSchema={Yup.object({
                password: Yup.string()
                    .min(6, locale?.get.auth.errorView.tooShortPassword)
                    .max(20, locale?.get.auth.errorView.tooLongPassword)
                    .required(locale?.get.auth.errorView.required),
                passwordConfirmation: Yup.string()
                    .oneOf([Yup.ref('password'), null], locale?.get.auth.errorView.passwordsMustMatch),
                recaptcha: Yup.string()
                    .required(locale?.get.auth.errorView.arentYouRobot),
            })}
            onSubmit={async (values, {setSubmitting, setErrors}) => {
                try {
                    await api.post('/api/v1/user/reset', values);
                    modal.set('signin');
                    toast.success(locale?.get.auth.passwordSuccessfullyChanged);
                } catch (err) {
                    if ([404, 409].includes(err.response.status) && err.response.data.detail) {
                        setErrors({email: locale?.get.auth.errorView[err.response.data.detail.user]});
                    } else {
                        toast.error(locale?.get.auth.cantResetPassword);
                    }
                }
                setSubmitting(false);
            }}
            render={formProps => {
                return <Form className='row gtr-uniform gtr-50'>
                    <h4 className="auth-title">{locale?.get.auth.resetPassword}</h4>
                    <div className="field-wrapper">
                        <Field
                            className="auth-text-field"
                            name='password'
                            type='password'
                            placeholder={locale?.get.auth.password}
                            style={getIn(formProps.touched, 'password') && getStyles(formProps.errors, 'password')}
                        />
                        <ErrorMessage component={ErrorView} name='password'/>
                    </div>
                    <div className="field-wrapper">
                        <Field
                            className="auth-text-field"
                            name='passwordConfirmation'
                            type='password'
                            placeholder={locale?.get.auth.confirmPassword}
                            style={getIn(formProps.touched, 'passwordConfirmation') && getStyles(formProps.errors, 'passwordConfirmation')}
                        />
                        <ErrorMessage component={ErrorView} name='passwordConfirmation'/>
                    </div>
                    <div className="field-wrapper">
                        <Field
                            name='recaptcha'
                            component={RecaptchaField}
                            style={getIn(formProps.touched, 'recaptcha') && getStyles(formProps.errors, 'recaptcha')}
                        />
                        <ErrorMessage component={ErrorView} name='recaptcha'/>
                    </div>
                    <div className='col-12'>
                        <button type='submit' className='button alt'>{locale?.get.auth.reset}</button>
                    </div>
                </Form>;
            }}
        />;
};

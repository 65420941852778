import {useEffect, useState} from "react";
import {useApiClient} from "../../../../../../customHooks/useApiClient";


export default function useAvailableMetrics () {
    const api = useApiClient();

    const [availableMetrics, setAvailableMetrics] = useState([]);

    useEffect(() => {
        const asyncFunction = async () => {
            const available_metrics = (await api.get("/api/v2/projects/analytics/metrics/")).data;
            setAvailableMetrics(available_metrics);
        };
        asyncFunction();
    }, []);

    return availableMetrics;
}
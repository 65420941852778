import React from "react";

import {useReduxData} from "../../../../customHooks/useContextReduxData";


export default function ContextMenuToolWrapper ({tools, children}) {
    const {openContextMenuTool} = useReduxData();
    return <div
        className="context-menu-tool-wrapper"
        style={{display: tools?.includes(openContextMenuTool) ? "block": "none"}}
    >{children}</div>;
}

import React from "react";

import {useGrid} from "../../../../customHooks/useContextGrid";
import {useReduxData} from "../../../../customHooks/useContextReduxData";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";

import {ReactComponent as Grid} from "../images/second-block/grid.svg";
import {ReactComponent as Ungrid} from "../images/second-block/ungrid.svg";


const IconGrid = tooltipHOC(
    hintModeTooltipHOC(({icon}) => icon, "secondToolBlockGrid"),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () {
    const locale = useLocalization();
    const grid = useGrid();
    const {isWatchMode} = useReduxData();

    const switchGrid = () => grid.set(!grid.get);

    const icon = grid.get ? <Grid/> : <Ungrid/>;

    return <div
        className={`tool-block-big-item${isWatchMode ? " disabled" : ""}`}
        id="header-grid-switcher"
        onClick={switchGrid}
    >
        <IconGrid tooltipContent={locale?.get.studio.header.secondToolBlock.grid} icon={icon}/>
    </div>;
}

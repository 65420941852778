import React from "react";

import AdditionalTools from "./additionalTools/AdditionalTools";
import BaseAnalytics from "./baseAnalytics/BaseAnalytics";
import ForbiddenPage from "../ForbiddenPage";
import {useCurrentUser} from "../../customHooks/useContextCurrentUser";
import {useLocalization} from "../../customHooks/useContextLocalization";


export default function () {
    const user = useCurrentUser();
    const locale = useLocalization();

    return ![1, 2].includes(user?.role) && !user?.is_analyst ? <ForbiddenPage/> : <>
        <a href="/" className="project-analytics-link-to-studio">{locale?.get?.project.analytics.returnToStudio}</a>
        <input
            type="radio"
            className="project-analytics-tab-hidden-input"
            id="project-analytics-tab-hidden-input-main"
            name="project-analytics-tab-hidden-input"
            defaultChecked
        />
        <label className="project-analytics-tab-label" htmlFor="project-analytics-tab-hidden-input-main">
            {locale?.get?.project.analytics.mainPage.mainPage}
        </label>
        <input
            type="radio"
            className="project-analytics-tab-hidden-input"
            id="project-analytics-tab-hidden-input-wfpm"
            name="project-analytics-tab-hidden-input"
        />
        <label className="project-analytics-tab-label" htmlFor="project-analytics-tab-hidden-input-wfpm">
            {locale?.get?.project.analytics.additionalPage.additionalPage}
        </label>

        <BaseAnalytics/>
        <AdditionalTools/>
    </>;
}

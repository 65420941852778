import React from "react";

import Figure from "./Figure";
import {useReduxData} from "../../customHooks/useContextReduxData";
import {compareOrderOfTwoFigures} from "../../utils/figureOrder";


export default function Group(props) {
    const {figures} = useReduxData();

    const orderedFigures = props.figure.children.map(u => figures.get(u));
    orderedFigures.sort(compareOrderOfTwoFigures);

    return <g className="group" key={props.figure.uuid} id={props.figure.uuid}>
        {orderedFigures.map(figure => <Figure {...props} key={figure.get("uuid")} figure={figure} kind={"inGroup"}/>)}
    </g>;
}

import React, {useState} from "react";
import {useDispatch} from "react-redux";

import {useReduxData} from "../../../customHooks/useContextReduxData";


export default function() {
    const dispatch = useDispatch();
    const {canvasScrollX, canvasScrollY, scale} = useReduxData();

    const [scrollStartX, setScrollStartX] = useState(0);
    const [scrollStartY, setScrollStartY] = useState(0);
    const [scrollShiftX, setScrollShiftX] = useState(0);
    const [scrollShiftY, setScrollShiftY] = useState(0);
    const [isScrollActive, setIsScrollActive] = useState(false);

    const resetHooks = () => {
        setIsScrollActive(false);
        setScrollStartX(0);
        setScrollStartY(0);
        setScrollShiftX(0);
        setScrollShiftY(0);
    };

    const startScroll = (xCoo, yCoo) => {
        setIsScrollActive(true);
        setScrollStartX(xCoo);
        setScrollStartY(yCoo);
    };

    const scrollCanvas = (newXCoo, newYCoo) => {
        if (isScrollActive) {
            setScrollShiftX(newXCoo - scrollStartX);
            setScrollShiftY(newYCoo - scrollStartY);
        }
    };

    const finishScroll = () => {
        resetHooks();
        dispatch({type: "canvasScrollX", value: canvasScrollX - scrollShiftX / scale});
        dispatch({type: "canvasScrollY", value: canvasScrollY - scrollShiftY / scale});
    };

    return {startScroll, scrollCanvas, finishScroll, scrollShiftX, scrollShiftY};
}

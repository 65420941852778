import {useState, useEffect} from "react";
import {useForm} from "react-hook-form";

import {useApiClient} from "../../../customHooks/useApiClient";
import {useLocalization} from "../../../customHooks/useContextLocalization";


export default function ({iconSetSection}) {
    const [currentIconConfig, setCurrentIconConfig] = useState("");

    const api = useApiClient();
    const locale = useLocalization();
    const {register, handleSubmit} = useForm();

    useEffect(() => {
        const asyncFunction = async () => {
            setCurrentIconConfig(JSON.stringify(
                (await api.get("/api/v2/store/account/tab_set?current_project_id=-1")).data[iconSetSection],
                null,
                4
            ));
        };
        asyncFunction();
    }, [currentIconConfig]);

    const sendNewIconsParameters = data => {
        api.post(`/api/v2/store/account/${iconSetSection}`, data);
        setCurrentIconConfig("");  // reset current icon parameters
    };

    return <div className="account-container">
        <div className="account-icons-parameters-container">
            <div className="account-icons-current-parameters-container">
                <textarea
                    cols="110"
                    rows="15"
                    className="account-icons-current-parameters"
                    value={currentIconConfig}
                />
            </div>
            <form className="account-icons-parameters-form" onSubmit={handleSubmit(sendNewIconsParameters)}>
                <textarea
                    className="account-icons-new-parameters"
                    cols="100"
                    rows="20"
                    {...register(iconSetSection)}
                />
                <input
                    type="submit"
                    className="account-icons-parameters-update-btn"
                    value={locale?.get?.store.account.updateIcons}
                />
            </form>
        </div>
    </div>;
}

import React from "react";


const Icon = ({id, icon, isSelected}) => {
    return <div
        className="account-icons-product-icon"
        id={id}
        style={{
            backgroundImage: `url(${icon})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            border: `0.125rem solid ${isSelected ? "#FF983D" : "transparent"}`,
            borderRadius: isSelected ? "0.625rem" : "0",
        }}
    />
}

export default Icon;
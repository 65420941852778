import React, {useMemo} from "react";

import HintConnector from "../connectors/HintConnector";
import SnappingConnector from "../connectors/SnappingConnector";
import useCooTransformation from "../../canvas/customHooks/useCooTransformation";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import {useSnappingMode} from "../../../customHooks/useContextSnappingMode";
import {checkIsTheOnlyArrowSelected} from "../../figures/utils/arrow";
import {getModifiedCurvesForTransformationalConnectors} from "../utils/transformationalConnector";
import {checkIsFigureEnclosed} from "../../figures/utils/figure";
import Path from "../../../utils/path";


export default function ({parentFigure, isMouseDown, handleMouseEnter}) {
    const {get: isSnappingMode} = useSnappingMode();
    const {figures, isEditMode, scale, selectedFigureUuids, tool} = useReduxData();
    const {transformXForCanvas, transformYForCanvas} = useCooTransformation();

    const isTheOnlyArrowSelected = useMemo(
        () => checkIsTheOnlyArrowSelected(figures, selectedFigureUuids),
        [figures, selectedFigureUuids]
    );

    const curves = useMemo(
        () => getModifiedCurvesForTransformationalConnectors(parentFigure.get("points"), scale),
        [parentFigure.get("points"), scale]
    );

    const outline = useMemo(() => {
        return Path.transform(parentFigure.get("points"), 20, 20, scale, scale);
    }, [parentFigure.get("points"), scale]);

    const isEnclosed = checkIsFigureEnclosed(parentFigure.get("tool"), parentFigure.get("enclosed"));

    return <svg x={transformXForCanvas(parentFigure.get("x") - 20)} y={transformYForCanvas(parentFigure.get("y") - 20)}>
        {!isEditMode && curves.map((curve, i) => <HintConnector
            key={`${i}-hc`}
            x={curve.points[curve.points.length - 1].x - 1.5}
            y={curve.points[curve.points.length - 1].y - 1.5}
        />)}
        {isSnappingMode && ((!isEditMode && tool.split("-")[0] !== "r") || (isEditMode && isTheOnlyArrowSelected))
            && <>
                <path d={outline} fill="none" stroke="#FF983D" strokeWidth="2"/>
                {curves.map((curve, i) => <SnappingConnector
                    key={`${i}-sc`}
                    id={`${parentFigure.uuid}|${(isEnclosed && i === curves.length - 1) ? 0 : i}|snapping`}
                    x={curve.points[curve.points.length - 1].x}
                    y={curve.points[curve.points.length - 1].y}
                    isMouseDown={isMouseDown}
                    handleMouseEnter={handleMouseEnter}
                />)}
            </>
        }
    </svg>;
}

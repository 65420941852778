import React from 'react';


export default function CustomCheckbox ({fieldName, labelText, fieldValue, setFieldValue, style}) {
    const setValue = () => setFieldValue(fieldName, !fieldValue);

    return <div className="control-checkbox-wrapper" style={style}>
        <input
            id="custom-checkbox-input"
            className="control-checkbox-hidden-input"
            type="checkbox"
            name="custom-checkbox-input"
            value={fieldValue}
            onChange={setValue}
        />
        <label className="control-checkbox-label" htmlFor="custom-checkbox-input">{labelText}</label>
    </div>;
}

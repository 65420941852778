import RadioGroup from "../../RadioGroup";
import {getAllowedOptions, getUpdateIndex} from "../utils";
import {useLocalization} from "../../../../customHooks/useContextLocalization";


const ProductUpdatingScope = props => {
    const locale = useLocalization();

    const setValue = option => props.setFieldValue(
        "updating_scope",
        getUpdateIndex(option, props.formValues.prohibited_updates)
    );

    return <RadioGroup groupName="updating_scope" defaultValue={props.formValues.updating_scope} onChange={setValue}>
        {getAllowedOptions(props.formValues.prohibited_updates).map(name =>
            <span key={`update-scope_span-${name}`} className="product-form-radio-item">
                {locale?.get?.store.product.form[name]}
            </span>
        )}
    </RadioGroup>;
}

export default ProductUpdatingScope;
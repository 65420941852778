import React from 'react';


export default function SurveyNavigator({
    turnPage,
    turnPageBack,
    closeSurvey,
    currentPage,
    content,
    lang,
    pagesCount,
    sendSurvey
}) {
    const _closeSurvey = () => closeSurvey("onSurveyRefuseText");

    switch (currentPage) {
        case 0: {
            return <>
                <input
                    type="button"
                    className="modal-button modal-button-survey"
                    value={content.startSurveyText[lang]}
                    onClick={turnPage}
                />
                <a className="auth-link-survey" onClick={_closeSurvey}>{content.sendMapText[lang]}</a>
            </>;
        }
        case 1: {
            return <input
                type="button"
                className={`modal-button modal-button-survey-question-next`}
                value={content.pageNextText[lang]}
                onClick={turnPage}
            />;
        }
        case pagesCount - 1: {
            return <div>
                <input
                    type="button"
                    className={`modal-button modal-button-survey-question-prev`}
                    value={content.pagePrevText[lang]}
                    onClick={turnPageBack}
                />
                <input
                    type="button"
                    className={`modal-button modal-button-survey-question-next`}
                    value={content.completeText[lang]}
                    onClick={sendSurvey}
                />
            </div>;
        }
        default: {
            return <div>
                <input
                    type="button"
                    className={`modal-button modal-button-survey-question-prev`}
                    value={content.pagePrevText[lang]}
                    onClick={turnPageBack}
                />
                <input
                    type="button"
                    className={`modal-button modal-button-survey-question-next`}
                    value={content.pageNextText[lang]}
                    onClick={turnPage}
                />
            </div>;
        }
    }
}

import React, {useState} from "react";
import {useDispatch} from "react-redux";

import {useReduxData} from "../../../customHooks/useContextReduxData";
import {getChildrenValues, setDifferentChildrenValues} from "../../../utils/figureHierarchy";
import {transformArrayValues} from "../../../../utils";
import getTimeElapsedSince from "../../getTimeElapsedSince";
import {checkIfArrowDependent} from "../../figures/utils/arrow";


export default function useObjectMoving() {
    const [startMouseX, setStartMouseX] = useState(undefined);
    const [startMouseY, setStartMouseY] = useState(undefined);

    const dispatch = useDispatch();
    const {figures, scale, selectedFigureUuids, startTime} = useReduxData();

    const resetHooks = () => {
        setStartMouseX(undefined);
        setStartMouseY(undefined);
    }

    const startObjectMoving = (target, mouseX, mouseY) => {
        let targetUuid = target.id.split("|")[0];
        if (!figures.get(targetUuid)) { //not an object
            return undefined;
        }
        setStartMouseX(mouseX);
        setStartMouseY(mouseY);
        return "success";
    };

    const finishObjectMoving = (mouseX, mouseY) => {
        if (typeof startMouseX === "number" && typeof startMouseY === "number") {
            selectedFigureUuids.forEach(uuid => {
                const figure = figures.get(uuid);
                const figureType = figure.get("tool").split("-")[0];
                let newCoosX, newCoosY;
                let newArrowCoosFromX, newArrowCoosFromY, newArrowCoosToX, newArrowCoosToY;

                const shiftX = (mouseX - startMouseX) / scale;
                const shiftY = (mouseY - startMouseY) / scale;
                if (figureType === "g") {
                    newCoosX = transformArrayValues(getChildrenValues(figures, uuid, "x"), 1, shiftX);
                    newCoosY = transformArrayValues(getChildrenValues(figures, uuid, "y"), 1, shiftY);
                } else if (figureType === "a" && !checkIfArrowDependent(figure)) {
                    newArrowCoosFromX = figure.get("fromX") + shiftX;
                    newArrowCoosToX = figure.get("toX") + shiftX;
                    newArrowCoosFromY = figure.get("fromY") + shiftY;
                    newArrowCoosToY = figure.get("toY") + shiftY;
                } else if (["r", "p", "pl", "pr", "c", "i", "t"].includes(figureType)) {
                    newCoosX = figure.get("x") + shiftX;
                    newCoosY = figure.get("y") + shiftY;
                }

                if (figureType === "a" && !checkIfArrowDependent(figure)) {
                    dispatch({
                        type: "changeArrowGeometry",
                        uuid: uuid,
                        fromX: newArrowCoosFromX,
                        toX: newArrowCoosToX,
                        fromY: newArrowCoosFromY,
                        toY: newArrowCoosToY,
                        time: getTimeElapsedSince(startTime)
                    });
                } else {
                    setDifferentChildrenValues(
                        figures,
                        uuid,
                        ["x", "y"],
                        dispatch,
                        [newCoosX, newCoosY],
                        "moveFigure",
                        getTimeElapsedSince(startTime)
                    );
                }
            });
        }
        resetHooks();
    };

    return {startObjectMoving, finishObjectMoving};
}

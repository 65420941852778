import {isUUID} from "../../../../utils/validators";


export const getRealTarget = target => {
    if (isUUID(target.id.split("|")[0])) {
        return target;
    }
    if (isUUID(target.parentNode.id.split("|")[0])) {
        return target.parentNode;
    }
    if (isUUID(target.parentNode.parentNode.id.split("|")[0])) {
        return target.parentNode.parentNode;
    }
    return undefined;
};

export const getTargetCoo = target => {
    let targetX = typeof target.x === "object" ? target.x : target.cx,
        targetY = typeof target.y === "object" ? target.y : target.cy;
    if (!targetX || !targetY) {
        return [undefined, undefined];
    }
    targetX = targetX.baseVal.value;
    targetY = targetY.baseVal.value;
    return [targetX, targetY];
};

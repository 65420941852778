import React, {useEffect, useMemo} from "react";
import {useDispatch} from "react-redux";

import Path from "../../utils/path.js";
import SvgEmojis from "./SvgEmojis";
import SvgText from "./SvgText";
import Link from "./Link";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useIconSet} from "../../../customHooks/useContextIconSet";
import {useReduxData} from "../../customHooks/useContextReduxData";
import useCooTransformation from "../canvas/customHooks/useCooTransformation";
import {getPreferredFontColorByBackgroundColor} from "../../utils/color";


export default function Polygon({figure, kind, isMouseDown}) {
    const locale = useLocalization();
    const {get: {parameters, icons}} = useIconSet();
    const {isGridShow, isValidationAnimationShow, scale} = useReduxData();
    const {transformYForCanvas, transformXForCanvas} = useCooTransformation();

    const transformedFigureX = transformXForCanvas(figure.get("x")),
        transformedFigureY = transformYForCanvas(figure.get("y"));

    const [path, {centerX, centerY}] = useMemo(() => {
            if (!figure) {
                return ["", {centerX: 0, centerY: 0}];
            }
            const transformedPath = Path.transform(figure.get("points"), 0, 0, scale, scale);
            return [transformedPath, Path.getCenter(transformedPath)];
        }, [figure, scale]
    );

    const dispatch = useDispatch();

    useEffect(() => {
        function func() {
            if (isValidationAnimationShow)
                dispatch({type: "isValidationAnimationShow", value: false});
        }
        setTimeout(func, 1000);
    });

    //show and hide description window
    const handleMouseEnterOnPolygon = () => !isMouseDown
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: figure.get("uuid")});

    const handleMouseLeaveOnPolygon = () => !isMouseDown
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});

    //hide description on figure move
    const handleMouseDownOnPolygon = event => event.button === 0
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});

    //show description on moving ended up
    const handleMouseUpOnPolygon = event => event.button === 0
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: figure.get("uuid")});

    let langCoefficient = locale?.get.name === "chinese" ? 40/23 : 1;

    const [, productId, imageIndex] = figure.get("tool").split("-");

    return icons.polygons ? <g
        className="figure"
        onMouseEnter={handleMouseEnterOnPolygon}
        onMouseLeave={handleMouseLeaveOnPolygon}
        onMouseDown={handleMouseDownOnPolygon}
        onMouseUp={handleMouseUpOnPolygon}
    >
        <symbol id={`${figure.get("uuid")}-symbol-figure`}>
            <path
                fill={figure.get("color") == null ? "white" : figure.get("color")}
                opacity={figure.get("transparency")}
                d={path}
            />
            <path
                stroke={figure.get("name") === "" && parameters[figure.get("tool")]?.isNameRequired
                    ? "yellow"
                    : (isGridShow ? "black" : (icons.polygons[productId]?.icons?.[imageIndex] ? null : "gray"))
                }
                strokeWidth={figure.get("name") === "" && parameters[figure.get("tool")]?.isNameRequired ? "0.8mm" : "0.26mm"}
                strokeLinecap="round"
                style={{
                    animationName: (isValidationAnimationShow && parameters[figure.get("tool")]?.isNameRequired
                        && (figure.get("name") === ""
                            || (figure.get("description") === "" && figure.get("emoji").length === 0)
                        )
                    ) ? "highlight" : "",
                    animationDuration: "0.5s",
                    animationFillMode: "backwards",
                    strokeMiterlimit: "10"
                }}
                fill={`url(#pattern-${figure.get("tool")})`}
                opacity={figure.get("transparency")}
                d={path}
            />
        </symbol>
        <use
            id={figure.get("uuid")}
            className={kind === "separate" ? "figure" : "group"}
            href={`#${figure.get("uuid")}-symbol-figure`}
            x={transformedFigureX - 2}
            y={transformedFigureY - 2}
        />
        <SvgText
            id={figure.get("uuid")}
            className={kind === "separate" ? "text" : "group"}
            strokeColor={getPreferredFontColorByBackgroundColor(figure.get("fontColor"))}
            fillColor={figure.get("fontColor")}
            fontSize={figure.get("fontSize") === "standard" ? "18px" : figure.get("fontSize") / 2 + "mm"}
            text={figure.get("name")}
            x={transformedFigureX + centerX - figure.get("name").length * langCoefficient * (
                figure.get("fontSize") === "standard"
                    ? 5
                    : (figure.get("fontSize") !== "" ? figure.get("fontSize")/2 : 4)
            )}
            y={transformedFigureY + centerY - 20}
            opacity={figure.get("transparency")}
        />
        <SvgEmojis
            id={figure.get("uuid")}
            className={kind === "separate" ? "figure" : "group"}
            x={transformedFigureX + centerX - (figure.get("emoji")?.length <= 5 ? figure.get("emoji").length * 10 : 50)}
            y={transformedFigureY + centerY - 35 - (
                figure.get("fontSize") === "standard" ? 20 : figure.get("fontSize") * 1.5 + 5
            )}
            opacity={figure.get("transparency")}
            text={figure.get("emoji")?.map(em => em.emoji.native) || []}
            width="160"
            height="48"
            fontSize={16}
            scaleCoefficient={1}
        />
        {figure.get("link") && <Link
            id={figure.get("uuid")}
            x={transformedFigureX + centerX - 10}
            y={transformedFigureY + centerY + 10}
            height={15}
            width={15}
            linkAddress={figure.get("link")}
        />}
    </g> : null;
}

import {combineReducers} from 'redux-immutablejs';
import {Map} from 'immutable';
import objectReducers from './objectReducer';
import menuReducers from './menuReducer';
import undoable from 'redux-undo';
import {ignoreActions} from "redux-ignore";

const jointReducer = (state, action) => {
    switch (action.type) {
        case 'loadNewState':
            return Map({main: action.data.main, menu: Map(action.data.menu)});
        default:
            return state;
    }
};


const combinedReducer = combineReducers({
    main: ignoreActions( //menu reducers affects to main state updating
        undoable(objectReducers, {
            filter: (action) => (action.type !== "loadStateByTime" && action.type !== "setMapId")
        }),
        [
            "backgroundColor",
            "canvasScrollX",
            "canvasScrollY",
            "scale",
            "prevScale",
            "clipboard",
            "color",
            "dashLength",
            "figureCenter",
            "figureUuidDescriptionPopupShownFor",
            "fontColor",
            "fontSize",
            "isContextMenuShow",
            "isEditMode",
            "isGridShow",
            "isValidationAnimationShow",
            "isWatchMode",
            "loadMenuState",
            "mouseXAtTheCopyTime",
            "mouseYAtTheCopyTime",
            "canvasScrollXAtCopyTime",
            "canvasScrollYAtCopyTime",
            "openContextMenuTool",
            "openedMenuItemNumber",
            "scale",
            "selectedConnectors",
            "selectedFigureUuids",
            "startTime",
            "thickness",
            "tool",
            "transparency",
            "volume"
        ]
    ),
    menu: menuReducers
});

//I know about the reduce-reducers library, but it has no maintainers:(
export default (state, action) => {
  const intermediateState = combinedReducer(state, action);
  const finalState = jointReducer(intermediateState, action);
  return finalState;
}

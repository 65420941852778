import React, {useState, useContext} from "react";


const ShouldShowColorPickerContext = React.createContext();


export const ShouldShowColorPickerProvider = ({children}) => {
    const [get, set] = useState(false);

    return <ShouldShowColorPickerContext.Provider value={{get, set}}>
        {children}
    </ShouldShowColorPickerContext.Provider>;
};


export const useShouldShowColorPicker = () => useContext(ShouldShowColorPickerContext);

import Path from "../../../../../../../studio/utils/path";


/**
 * Get center of path using only base points (Barycenter used)
 * @param  {[{x: number, y: number}]}  points    The source list of points
 * @return {{x: number, y: number}}              returns average point
 * @example
 * getAveragePoint([{x: 0, y: 1}, {x: 10, y: 21}]);
 * // returns: {x: 5, y: 11}
 */
function getAveragePoint(points) {
    if (points.length === 0) {
        return {x: undefined, y: undefined};
    }
    let averagePoint = {x: points[0].x, y: points[0].y};
    for (let i = 1; i < points.length; i++) {
        averagePoint.x += points[i].x;
        averagePoint.y += points[i].y;
    }
    averagePoint.x /= points.length;
    averagePoint.y /= points.length;
    return averagePoint;
}

/**
 * Get center of path using only base points (Barycenter used)
 * @typedef {{x: number, y: number}}  Point
 * @param  {[{a: number, b: number}]}        lineEquation    Coefficients of a line (y = ax + b)
 * @param  {{a: Point, b: Point, c: Point}}  bezierCurve     Coefficients of a line (y = ax + b)
 * @return {{x: number, y: number}}                          returns average point
 */
function getPointOfIntersectionLineAndQuadraticBezierCurve(lineEquation, bezierCurve) {
    let {a, b} = lineEquation;
    let {a: {x: ax, y: ay}, b: {x: bx, y: by}, c: {x: cx, y: cy}} = bezierCurve;

    let firstStatement = by - a * bx - ay + a * ax;
    let secondStatement = ay - a * ax - 2 * by + 2 * a * bx + cy - a * cx;

    let discriminant = 4 * (Math.pow(firstStatement, 2) - secondStatement * (ay - a * ax - b));

    if (discriminant < 0) {
        // console.log("Discriminant below zero");
        return {x: undefined, y: undefined};
    }

    // TODO: there is may be multiple correct t. I need to return array of point here
    let t = (-2 * firstStatement + Math.sqrt(discriminant)) / (2 * secondStatement);

    if (t > 1 || t < 0) {
        t = (-2 * firstStatement - Math.sqrt(discriminant)) / (2 * secondStatement);
    }
    if (t > 1 || t < 0) {
        // console.log("There's no roots");
        return {x: undefined, y: undefined};
    }
    let x = Math.pow(1 - t, 2) * ax + 2 * (1 - t) * t * bx + t * t * cx;
    let y = a * x + b;
    return {x, y};
}

/**
 * Get minimum and maximum degrees, between which curve lies in relation to barycenter
 * @typedef {{x: number, y: number}}  Point
 * @param  {[Point]}                  curvePoints         The set of points which is constrains the curve
 * @param  {Point}                    barycenter          The barycenter coordinates
 * @return {{startDegree: number, endDegree: number}}     returns degree interval on which curve lies
 * Note that these frontiers is not exact. Curve may fill lesser area, because not every point lies on the curve itself
 */
function getDegreesCurveLiesInRelationToBarycenter(curvePoints, barycenter) {
    let angles = curvePoints.map(currentPoint => {
        // console.log(`Barycenter: (${barycenter.x}, ${barycenter.y})`);
        // console.log(`Point: (${currentPoint.x}, ${currentPoint.y})`);
        let alpha = Math.atan2(currentPoint.y - barycenter.y, currentPoint.x - barycenter.x) / Math.PI * 180;
        if (alpha < 0) {
            alpha = 360 + alpha;
        }
        // console.log(`Angle: ${alpha}`);
        return alpha;
    });

    let anglePairs = [];
    for (let i = 1; i < angles.length; i++) {
        anglePairs.push([angles[i - 1], angles[i]]);
    }

    let intervals = [];
    for (let i = 0; i < anglePairs.length; i++) {
        let [maxAngle, minAngle] = anglePairs[i][0] > anglePairs[i][1]
            ? [anglePairs[i][0], anglePairs[i][1]]
            : [anglePairs[i][1], anglePairs[i][0]];
        if (maxAngle - minAngle <= 180) {  //this part of curve cannot intersect 0 degree
            intervals.push({start: minAngle, end: maxAngle});
        } else {
            intervals.push({start: 0, end: minAngle});
            intervals.push({start: maxAngle, end: 360});
        }
    }

    return intervals;
}

/**
 * Extract all points from the given curve
 * @typedef {{x: number, y: number}}                             Point
 * @typedef {Array<{command:('L'|'Q'|'C'),points:Array<Point>}>} Offcut
 * @typedef {Array<{mCoo:Point,curves:Offcut}>}                  Path
 * @param  {Path}                     contour             An initial contour, parsed using Path library
 * @return {Array<Array<Point>>}      return array of curves, each item of which is an array of points
 * Notes: we use only the first simple contour (M ... Z) without additional M commands
 */
function extractPointArrayFromContour(contour) {
    contour = contour[0]; //we will process only simple contour (From M to Z without intermediate M's)
    let points = [];
    let lastPoint = contour.mCoo;
    for (let i = 0; i < contour.curves.length; i++) {
        points.push([lastPoint].concat(contour.curves[i].points));
        lastPoint = contour.curves[i].points[contour.curves[i].points.length - 1];
    }
    return points;
}

/**
 * Get a and b coefficients of line by its slope angle and point, which lies on it
 * @param  {number}                   alpha     A slope engle of line
 * @param  {{x: number, y: number}}   point     A point which lies on the given point
 * @return {{a: number, b: number}}             return a nd b coefficient of the given line
 * Notes: angles should be in degrees
 */
function getLineCoefficientsByPointAndAngle(alpha, point) {
    let a = Math.tan(alpha / 180 * Math.PI);
    let b = point.y - a * point.x;
    return {a, b};
}

export default function getAverageContour(sourceContours) {
    for (let i = 0; i < sourceContours.length; i++) {
        if (!Path.isEnclosed(sourceContours[i], 0)) {
            console.log("has not only enclosed paths!");
            return "";
        }
    }

    let contoursInfo = sourceContours.map(sourceContour => ({"parsed": Path._parse(sourceContour)}));

    contoursInfo.forEach(contourInfo => {
        let barycenter = Path.getCenter(contourInfo.parsed);
        contourInfo.barycenter = {x: barycenter.centerX, y: barycenter.centerY};
        contourInfo.points = extractPointArrayFromContour(contourInfo.parsed);
        contourInfo.frontiers = contourInfo.points.map(
            curvePoints => getDegreesCurveLiesInRelationToBarycenter(curvePoints, contourInfo.barycenter)
        );
        contourInfo.intersections = {};
        contourInfo.averageIntersections = [];
    });

    // console.log(contoursInfo);


    let averageBarycenter = getAveragePoint(contoursInfo.map(contourInfo => contourInfo.barycenter));
    // console.log("Average barycenter below");
    // console.log(averageBarycenter);
    let averageIntersections = [];
    for (let i = 0; i < 360; i+=2) {  //make measure for each degree
        if ([90, 270].includes(i)) {
            continue;
        }
        for (let j = 0; j < contoursInfo.length; j++) {
            let lineEquation = getLineCoefficientsByPointAndAngle(i, contoursInfo[j].barycenter);
            // console.log(`angle: ${i}. a = ${lineEquation.a}, b = ${lineEquation.b}`);
            for (let k = 0; k < contoursInfo[j].frontiers.length; k++) { //it seems frontiers are empty
                let isRayIntersectsCurve = false;
                contoursInfo[j].frontiers[k].forEach(interval => {
                    if (interval.start <= i && i <= interval.end) {
                        isRayIntersectsCurve = true;
                    }
                });
                if (isRayIntersectsCurve) {
                    // console.log(`appropriate step: ${i}, ${contoursInfo[j].frontiers[k]}`);
                    let intersectionPoint;
                    if (contoursInfo[j].points[k].length === 3) {
                        intersectionPoint = getPointOfIntersectionLineAndQuadraticBezierCurve(lineEquation, {
                            a: contoursInfo[j].points[k][0],
                            b: contoursInfo[j].points[k][1],
                            c: contoursInfo[j].points[k][2]
                        });
                        // console.log(lineEquation);
                        // console.log(contoursInfo[j].points[k]);
                        // console.warn(intersectionPoint);
                    } else {  // else this is just a line
                        let {x: x1, y: y1} = contoursInfo[j].points[k][0];
                        let {x: x2, y: y2} = contoursInfo[j].points[k][1];
                        let {a, b} = lineEquation;

                        let kOfCurrentLine = (y1 - y2) / (x1 - x2);
                        let x = (b + x2 * kOfCurrentLine - y2) / (k - a);
                        let y = a * x + b;

                        if ((x1 < x && x < x2 || x2 < x && x < x1) && (y1 < y && y < y2 || y2 < y && y < y1)) {
                            intersectionPoint = {x, y};
                        } else {
                            intersectionPoint = {x: undefined, y: undefined};
                        }
                    }
                    if ((intersectionPoint.x || intersectionPoint.x === 0)
                            && (intersectionPoint.y || intersectionPoint.y === 0)) {
                        if (contoursInfo[j].intersections[i]) {
                            contoursInfo[j].intersections[i].push(intersectionPoint);
                        } else {
                            contoursInfo[j].intersections[i] = [intersectionPoint];
                        }
                    }
                }
            }
            // maybe we should take here max or min intersection points
            if (contoursInfo[j].intersections[i]) {
                contoursInfo[j].averageIntersections[i] = getAveragePoint(contoursInfo[j].intersections[i]);
            }
        }

        let averageIntersectionPointForCurrentDegree = [];
        for (let j = 0; j < contoursInfo.length; j++) {
            if (contoursInfo[j].averageIntersections[i]) {
                averageIntersectionPointForCurrentDegree.push({
                    x: contoursInfo[j].averageIntersections[i].x - contoursInfo[j].barycenter.x + averageBarycenter.x,
                    y: contoursInfo[j].averageIntersections[i].y - contoursInfo[j].barycenter.y + averageBarycenter.y
                });
            }
        }
        if (averageIntersectionPointForCurrentDegree.length) {
            averageIntersections.push(getAveragePoint(averageIntersectionPointForCurrentDegree));
        }
    }

    let points = averageIntersections.map(point => `${Math.round(point.x)},${Math.round(point.y)}`)
    // return "M " + points.join(" L ");
    // return "M " + points.join(" L ");
    return Path.createReducedPath(points, 2.5, 8);
}

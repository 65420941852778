import React from "react";
import {useDispatch} from "react-redux";

import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import {useReduxData} from "../../../../customHooks/useContextReduxData";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";
import {setFixedChildrenValues} from "../../../../utils/figureHierarchy";
import getTimeElapsedSince from "../../../getTimeElapsedSince";

import {ReactComponent as PasteObjectStyle} from "../images/second-block/paste.svg";


const IconPasteStyle = tooltipHOC(
    hintModeTooltipHOC(PasteObjectStyle, "secondToolBlockPasteStyle"),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () {
    const locale = useLocalization();
    const {
        color,
        dashLength,
        figures,
        fontColor,
        fontSize,
        isEditMode,
        isWatchMode,
        selectedFigureUuids,
        startTime,
        thickness,
        transparency,
        volume
    } = useReduxData();
    const dispatch = useDispatch();

    const updateFiguresParameters = () => {
        selectedFigureUuids.forEach(uuid => {
            const figure = figures.get(uuid);
            setFixedChildrenValues(
                figures, uuid, ["color"], dispatch, [color], "changeFigureColor", getTimeElapsedSince(startTime)
            );
            setFixedChildrenValues(
                figures,
                uuid,
                ["transparency"],
                dispatch,
                [transparency],
                "changeFigureTransparency",
                getTimeElapsedSince(startTime)
            );
            if (figure.get("fontColor")) {
                setFixedChildrenValues(
                    figures,
                    uuid,
                    ["fontColor"],
                    dispatch,
                    [fontColor],
                    "changeFigureFontColor",
                    getTimeElapsedSince(startTime)
                );
                setFixedChildrenValues(
                    figures,
                    uuid,
                    ["fontSize"],
                    dispatch,
                    [fontSize],
                    "changeFigureFontSize",
                    getTimeElapsedSince(startTime)
                );
            }
            if (figure.get("thickness")) {
                setFixedChildrenValues(
                    figures,
                    uuid,
                    ["thickness"],
                    dispatch,
                    [thickness],
                    "changeFigureThickness",
                    getTimeElapsedSince(startTime)
                );
                setFixedChildrenValues(
                    figures,
                    uuid,
                    ["dashLength"],
                    dispatch,
                    [dashLength],
                    "changeFigureDashLength",
                    getTimeElapsedSince(startTime)
                );
            }
            if (figure.get("volume")) {
                setFixedChildrenValues(
                    figures, uuid, ["volume"], dispatch, [volume], "changeFigureVolume", getTimeElapsedSince(startTime)
                );
            }
        });
    };

    return <div className={`tool-block-big-item${
        !isWatchMode && isEditMode && selectedFigureUuids.length > 0 ? "" : " disabled"
    }`} id="header-paste-style-button" onClick={updateFiguresParameters}>
        <IconPasteStyle tooltipContent={locale?.get.studio.header.secondToolBlock.pasteStyle}/>
    </div>;
}

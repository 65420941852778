

/**
 * Compute, what color (black or white) to use for the foreground, based on background color.
 * @param  {string} rgbBackgroundColor  The background color in RGB format, like: "#144066"
 * Note, that reduced colors like "#ccc" or color names like "green" are not allowed!
 * @param  {number} brightnessThreshold The brightness, which divide black and white colors
 * @return {"black"|"white"}            The color name. "black" or "white".
 * If source color is incorrect "black" will be returned
 */
export function getPreferredFontColorByBackgroundColor(rgbBackgroundColor, brightnessThreshold=125) {
    try {
        let hexBackground = rgbBackgroundColor.slice(1);
        let rgb = [
            parseInt(hexBackground.slice(0,2), 16),
            parseInt(hexBackground.slice(2,4), 16),
            parseInt(hexBackground.slice(4,6), 16)
        ];

        // http://www.w3.org/TR/AERT#color-contrast
        const brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);

        return (brightness > brightnessThreshold) ? "black" : "white";
    } catch (e) {
        if (e.name !== "TypeError") {
            console.log(e.name);
        }
        return "black";
    }
}
import {getWeightedAverage} from "../../utils/getKeyOfMaxVal";
import {getHexCodeBySeparateChannels} from "../../utils/color";
import getAverageContour from "../../generalMapVisualizerV2/utils";


// TODO: add somewhere here points processing
export function processFigure(figure, creationTimesOfSettlements) {
    const processedFigure = {
        color: getHexCodeBySeparateChannels(
            getWeightedAverage(figure.params_distribution.colorR),
            getWeightedAverage(figure.params_distribution.colorG),
            getWeightedAverage(figure.params_distribution.colorB)
        ),
        sourceColor: figure.params_distribution.color,
        orderIndex: creationTimesOfSettlements.indexOf(
            getWeightedAverage(figure.params_distribution.orderIndex)
        ) + 1,
        frequency: figure.frequency,
    };

    if (figure.params_distribution.path !== undefined) {
        processedFigure.path = getAverageContour(Object.keys(figure.params_distribution.path));
    }
    if (figure.params_distribution.x !== undefined && figure.params_distribution.y !== undefined) {
        processedFigure.minX = Math.min(...Object.keys(figure.params_distribution.x).map(coo => +coo));
        processedFigure.maxX = Math.max(...Object.keys(figure.params_distribution.x).map(coo => +coo));
        processedFigure.minY = Math.min(...Object.keys(figure.params_distribution.y).map(coo => +coo));
        processedFigure.maxY = Math.max(...Object.keys(figure.params_distribution.y).map(coo => +coo));
        processedFigure.x = getWeightedAverage(figure.params_distribution.x);
        processedFigure.y = getWeightedAverage(figure.params_distribution.y);
    }
    if (figure.params_distribution.fontColorR !== undefined && figure.params_distribution.fontSize !== undefined) {
        processedFigure.fontColor = getHexCodeBySeparateChannels(
            getWeightedAverage(figure.params_distribution.fontColorR),
            getWeightedAverage(figure.params_distribution.fontColorG),
            getWeightedAverage(figure.params_distribution.fontColorB)
        );
        processedFigure.fontSize = getWeightedAverage(figure.params_distribution.fontSize);
    }
    if (figure.params_distribution.volume !== undefined) {
        processedFigure.volume = getWeightedAverage(figure.params_distribution.volume);
    }
    if (figure.params_distribution.x1 !== undefined) {
        processedFigure.x = getWeightedAverage(figure.params_distribution.x1);
        processedFigure.y = getWeightedAverage(figure.params_distribution.y1);
        processedFigure.width = getWeightedAverage(figure.params_distribution.x2) - processedFigure.x;
        processedFigure.height = getWeightedAverage(figure.params_distribution.y2) - processedFigure.y;

        processedFigure.minX = Math.min(...Object.keys(figure.params_distribution.x1).map(coo => +coo));
        processedFigure.maxX = Math.max(...Object.keys(figure.params_distribution.x2).map(coo => +coo));
        processedFigure.minY = Math.min(...Object.keys(figure.params_distribution.y1).map(coo => +coo));
        processedFigure.maxY = Math.max(...Object.keys(figure.params_distribution.y2).map(coo => +coo));
    }

    return processedFigure;
}
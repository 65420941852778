import React from "react";

import Flag from "./Flag";


export default function SvgFlags({id, className, flagsToRender, flagInterval, x, y, width, height}) {
    return <>
        <symbol id={`${id}-symbol-flags`}>
            {flagsToRender.map((flag, i) => (
                <Flag
                    key={`${id}-flag-${i}`}
                    className={`point-feature-flag-${flag}`}
                    color={flag}
                    x={0}
                    y={i * flagInterval}
                    height={height}
                    width={width}
                />
            ))}
        </symbol>
        <use id={id} className={className} href={`#${id}-symbol-flags`} x={x} y={y} style={{pointerEvents: "none"}}/>
    </>;
}

import {mapSchema} from "./validators";

export default async function validateMap(map) {
    // const validity = await mapSchema.validate(map);

    // console.log(validity);
    // if (validity) {
    if (true) {
        // console.log("map valid");
        return map;
    }
    // console.log("map invalid");
    return "invalid";
}
import React, {useState, useEffect, useContext} from "react";
import {useDispatch} from "react-redux";
import navigatorLanguages from "navigator-languages";

import {languages} from "../localization/languages";


const LocalizationContext = React.createContext();

export const LocalizationProvider = ({children}) => {
    const [get, set] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => { //set localization on app start
        let currentLocalization = localStorage.getItem("localization");
        if (currentLocalization !== null) {
            set(languages[currentLocalization]);
        } else {
            let langs = navigatorLanguages();
            langs = langs.map(lang => lang.split("-")[0].toUpperCase());

            const availableLanguages = Object.keys(languages).map(lang => languages[lang].abbr);

            for (let i = 0; i < langs.length; i++) {
                if (availableLanguages.includes(langs[i])) {
                    let langName = "";
                    Object.keys(languages).map(lang => {
                        if (languages[lang].abbr === langs[i]) {
                            langName = lang;
                        }
                    })
                    set(languages[langName]);
                    dispatch({type: "clearState", customName: languages[langName].studio.stdMapName});
                    break;
                }
            }
        }
    }, []);

    const setLocalization = (value) => {
        localStorage.setItem("localization", value);
        set(languages[value]);
    };

    return (
        <LocalizationContext.Provider value={{get, set: setLocalization}}>
            {children}
        </LocalizationContext.Provider>
    );
};

export const useLocalization = () => useContext(LocalizationContext);

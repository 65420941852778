import {useState, useEffect} from "react";
import {toPng} from "html-to-image";

import {useApiClient} from "../../../../../customHooks/useApiClient";
import {useCurrentProject} from "../../../../customHooks/useContextCurrentProject";


const convertBase64ToFile = (base64String, fileName) => {
     let arr = base64String.split(',');
     let mime = arr[0].match(/:(.*?);/)[1];
     let bstr = window.atob(arr[1]);
     let n = bstr.length;
     let uint8Array = new Uint8Array(n);
     while (n--) {
        uint8Array[n] = bstr.charCodeAt(n);
     }
     return new File([uint8Array], fileName, { type: mime });
}


export default function () {
    const [image, setImage] = useState("");
    const [shouldSave, setShouldSave] = useState(false);
    const [postcardToken, setPostcardToken] = useState("");

    const api = useApiClient();
    const {get: current_project_id} = useCurrentProject();

    useEffect(() => {
        if (shouldSave) {
            const filename = prompt("Название открытки:", "Моя открытка");
            filename && saveAs(convertBase64ToFile(image), filename + ".png");
        }
    }, [image, shouldSave]);

    const resetImageState = () => {
        setImage("");
        setShouldSave(false);
        setPostcardToken("");
    };

    const createImageFromDiv = async (div, shouldSaveImage = true) => {
        const divBoundingBox = div.getBoundingClientRect();
        let myImage = await toPng(
            div,
            {canvasWidth: divBoundingBox.width * 2, canvasHeight: divBoundingBox.height * 2, backgroundColor: "#FAFEFF"}
        );
        setImage(myImage);
        if (shouldSaveImage) {
            setShouldSave(true);
        }
        return myImage;  // to use image in other functions
    };

    const sendImageToServer = async div => {
        let localImage = await createImageFromDiv(div, false);
        localImage = convertBase64ToFile(localImage);

        let formData = new FormData();
        formData.append("image", localImage);
        formData.append("current_project_id", current_project_id);

        let link = "";
        if (postcardToken) {
            link = location.origin + (await api.put(
                `/api/v2/reward/${postcardToken}`,
                formData,
                {headers: {'Content-Type': 'multipart/form-data'}}
            )).data.link;
        } else {
            const resp = (await api.post(
                "/api/v2/reward/",
                formData,
                {headers: {'Content-Type': 'multipart/form-data'}}
            )).data;
            setPostcardToken(resp.token);
            link = location.origin + resp.link;
        }

        return link;
    };

    return {createImageFromDiv, sendImageToServer, resetImageState};
}

import {useEffect, useState} from "react";
import {flattenDeep} from "lodash";

import useAvailableConverters from "../../hooks/useAvailableConverters";
import useAvailableMetrics from "../../hooks/useAvailableMetrics";


export default function useAvailableNodeTypes() {
    const availableConverters = useAvailableConverters();
    const availableMetrics = useAvailableMetrics();

    const [availableInputTypes, setAvailableInputTypes] = useState([]);
    const [availableOutputTypes, setAvailableOutputTypes] = useState([]);

    useEffect(() => {
        setAvailableInputTypes(Array.from(new Set(
            flattenDeep(availableConverters.concat(availableMetrics).map(el => el.inputs.map(input => input.data_type)))
        )));
    }, [availableConverters, availableMetrics]);

    useEffect(() => {
        setAvailableOutputTypes(Array.from(new Set(
            flattenDeep(availableConverters.map(el => el.outputs.map(output => output.data_type)))
        )));
    }, [availableConverters]);


    return {inputTypes: availableInputTypes, outputTypes: availableOutputTypes};
}
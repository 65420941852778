
const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export function generateNiceColor () {
    let h = randomInt(0, 360);
    let s = randomInt(42, 98);
    let l = randomInt(40, 70);
    return `hsl(${h},${s}%,${l}%)`;
}


function convertColorChannel(channel) {
    return Math.round(channel).toString(16).length === 3  //there is some data with value 260
        ? "ff"
        : (Math.round(channel).toString(16).length === 1 ? "0" : "") + Math.round(channel).toString(16)
}

export function getHexCodeBySeparateChannels(r, g, b) {
    return `#${convertColorChannel(r)}${convertColorChannel(g)}${convertColorChannel(b)}`;
}

import React, {useState} from "react";
import ReactDOMServer from "react-dom/server";
import MDEditor from "@uiw/react-md-editor";

import Avatar from "../../../components/Avatar";
import Datetime from "./Datetime";
import Rating from "../Rating";

import styles from "./comment.module.css";

import {ReactComponent as DefaultAvatar} from "../../images/default-avatar.svg";


export default function ({
    authorName,
    authorRating,
    commentText,
    date,
    langCode,
    onClick,
    user,
    expandMessage,
    foldMessage
}) {
    const [short, setShort] = useState(false);

    const changeCommentVerbosity = () => {
        setShort(!short);
    }

    const authorPageLink = user.id ? `/store/account/${user.id}` : "/";

    return <div className={styles["comment-container"]}>
        <div className={styles.avatar}>
            <Avatar
                email={user.email || ""}
                imgUrl={user.custom_avatar || user.social_avatar || (user.email
                    ? undefined
                    : "data:image/svg+xml," + encodeURIComponent(ReactDOMServer.renderToStaticMarkup((<DefaultAvatar/>)))
                )}
                wrapperClassName="avatar-wrapper"
                href={authorPageLink}
            />
        </div>
        <div><a className={styles.name} href={authorPageLink}>{authorName}</a></div>
        <Datetime date={date} langCode={langCode}/>
        <Rating n={authorRating} shouldRenderNormalStars={false} onClick={onClick}/>
        <div
            className={styles["comment-text-container"]}
            style={{height: commentText.length > 200 ? (short ? "10.5rem" : "max-content") : "initial"}}>
            <MDEditor.Markdown source={commentText} className={styles["comment-text"]}/>
        </div>
        <div
            style={{height: commentText.length > 200 ? "1.5rem" : "0"}}
            id="btn"
            className={styles["comment-verbosity-button"]}
            onClick={changeCommentVerbosity}>
            {short ? expandMessage : foldMessage}
        </div>
    </div>;
}

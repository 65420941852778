

export default function () {
    return <>
        <line x1="1" x2="1" y1="0" y2="675" stroke="rgba(0, 0, 0, 0.3)" strokeDasharray="5" strokeWidth="2"/>
        <line x1="1200" x2="1200" y1="0" y2="675" stroke="rgba(0, 0, 0, 0.3)" strokeDasharray="5" strokeWidth="2"/>
        <line x1="0" x2="1200" y1="1" y2="1" stroke="rgba(0, 0, 0, 0.3)" strokeDasharray="5" strokeWidth="2"/>
        <line x1="0" x2="1200" y1="675" y2="675" stroke="rgba(0, 0, 0, 0.3)" strokeDasharray="5" strokeWidth="2"/>

        <line x1="0" x2="1200" y1="0" y2="675" stroke="rgba(0, 0, 0, 0.3)" strokeDasharray="5" strokeWidth="2"/>
        <line x1="0" x2="1200" y1="675" y2="0" stroke="rgba(0, 0, 0, 0.3)" strokeDasharray="5" strokeWidth="2"/>
    </>;
}

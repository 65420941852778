import React from 'react';

import {replaceAt} from "../../../../utils";


export default function QuestionNumber ({content, lang, answers, setAnswers}) {
    const changeValue = event => +event.target.value <= content.maxValue
        && +event.target.value >= content.minValue
        && setAnswers(replaceAt(answers, content.id - 1, +event.target.value));

    const increaseValue = () => answers[content.id - 1] + content.valueStep <= content.maxValue && setAnswers(
        replaceAt(answers, content.id - 1, answers[content.id - 1] + content.valueStep)
    );

    const decreaseValue = () => answers[content.id - 1] - content.valueStep >= content.minValue && setAnswers(
        replaceAt(answers, content.id - 1, answers[content.id - 1] - content.valueStep)
    );

    let component = <>
        {content.title && <p className="survey-p">{content.title[lang]}</p>}
        <div className="survey-answer-number-container" style={{margin: content.isQuestionInOneLine ? "0.6rem" : "0rem"}}>
            <input
                type="number"
                className="survey-answer-number-input"
                value={answers[content.id - 1] !== null ? answers[content.id - 1] : ""}
                onChange={changeValue}
            />
            <div className="survey-answer-number-button-container">
                <input
                    type="button"
                    className="survey-answer-number-button survey-answer-number-button-increase"
                    value="▲"
                    onClick={increaseValue}
                />
                <input
                    type="button"
                    className="survey-answer-number-button survey-answer-number-button-decrease"
                    value="▼"
                    onClick={decreaseValue}
                />
            </div>
        </div>
    </>;
    return content.isQuestionInOneLine ? <div style={{display: "flex"}}>{component}</div> : component;
}

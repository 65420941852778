import {useEffect} from "react";
import {useDrag} from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";

import dndItems from "../dndItems";


export default function useSubpartitionDragSource(tabIndex, partitionIndex, subpartitionId) {
    const [{isDragging}, drag, dragPreview] = useDrag({
        type: dndItems.subpartition,
        item: () => {
            return {tabIndex, partitionIndex, subpartitionId};
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => { //hide default preview
        dragPreview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    return [{isSubpartitionDragging: isDragging}, drag];
}
import React from "react";
import {SketchPicker} from 'react-color';
import {useDispatch} from "react-redux";
import {flattenDeep} from "lodash";

import {useReduxData} from "../../customHooks/useContextReduxData";
import {setFixedChildrenValues, getChildrenValues} from "../../utils/figureHierarchy";
import {areAllItemsEqual} from "../../../utils";
import getTimeElapsedSince from "../getTimeElapsedSince";


export default function ColorPicker({context}) {
    const {color, fontColor, getFiguresUpdatedOn, isEditMode, selectedFigureUuids, startTime} = useReduxData();
    const figures = getFiguresUpdatedOn([
        "addPolygon",
        "addPolyline",
        "addPointFeature",
        "addGroup",
        "changeObjectParentUuid",
        "changeFigureColor",
        "changeFigureFontColor",
        "clearState",
        "deleteFigure",
        "disbandGroup"
    ]);

    const dispatch = useDispatch();

    const handleChangeComplete = color => isEditMode
        ? selectedFigureUuids.map(uuid => {
            let type = figures.get(uuid).get("tool").split("-")[0];
            let actionType = (type !== "c" && context !== "color") ? "changeFigureFontColor" : "changeFigureColor";
            setFixedChildrenValues(
                figures,
                uuid,
                [actionType === "changeFigureColor" ? "color" : "fontColor"],
                dispatch,
                [color.hex],
                actionType,
                getTimeElapsedSince(startTime)
            );
            return undefined;
        })
        : dispatch({type: context, value: color.hex});

    let currentValue = '#FFFFFF';

    if (!isEditMode) {
        currentValue = context === "color" ? color : fontColor;
    } else if (isEditMode && selectedFigureUuids.length !== 0) {
        let parameterArray = [];
        selectedFigureUuids.map(u => {
            let tempArr = flattenDeep([getChildrenValues(figures, u, context)]);
            if (typeof tempArr === "object") {
                tempArr.map(el => parameterArray.push(el));
            } else {
                parameterArray.push(tempArr);
            }
            return undefined;
        });
        if (areAllItemsEqual(parameterArray) && parameterArray[0] !== undefined) {
            currentValue = parameterArray[0];
        }
    }

    return <SketchPicker color={currentValue} disableAlpha={true} onChangeComplete={handleChangeComplete}/>;
}

import React from "react";

import StaticFigurePreview from "./previews/StaticFigurePreview";
import MovingFigurePreview from "./previews/MovingFigurePreview";
import ConnectorEventCatchBackground from "./previews/ConnectorEventCatchBackground";
import ConnectorPreview from "./previews/ConnectorPreview";
import TransformingFigurePreview from "./previews/TransformingFigurePreview";
import DependentArrowPreview from "./previews/DependentArrowPreview";
import {useSelectedFigureContainerEvents} from "./customHooks/useSelectedFigureContainerEvents";
import {useReduxData} from "../../customHooks/useContextReduxData";


export default function SelectedFigureContainer({isMouseDownOnCanvas, handleMouseEnterOnConnector}) {
    const {
        handleMouseDownOnSelectedFiguresContainer,
        handleMouseUpOnSelectedFiguresContainer,
        setInitialFigureMovingPreviewCoo,
        updateConnectorTransformationPreview,
        movementType,
        position,
        transformationPreviews
    } = useSelectedFigureContainerEvents();
    const {isWatchMode} = useReduxData();

    const isObjectsMoved = position.x !== 0 || position.y !== 0;

    return <g id="selectedFigureContainer"
        onMouseDown={handleMouseDownOnSelectedFiguresContainer}
        onMouseMove={updateConnectorTransformationPreview}
        onMouseUp={handleMouseUpOnSelectedFiguresContainer}
    >
        <ConnectorEventCatchBackground shouldShow={["connector", "figure"].includes(movementType)}/>

        <StaticFigurePreview
            movementType={movementType}
            isObjectsMoved={isObjectsMoved}
            setInitialPreviewCoo={setInitialFigureMovingPreviewCoo}
        />
        <MovingFigurePreview
            shouldShow={movementType === "figure" && isObjectsMoved}
            positionX={position.x}
            positionY={position.y}
            isMouseDown={isMouseDownOnCanvas}
        />
        <DependentArrowPreview
            movementType={movementType}
            movementShiftX={position.x}
            movementShiftY={position.y}
            transformationPreviews={transformationPreviews}
        />
        <TransformingFigurePreview shouldShow={movementType === "connector"} previews={transformationPreviews}/>

        {!isWatchMode && <ConnectorPreview
            movementType={movementType}
            transformingConnectorParents={transformationPreviews}
            isMouseDownOnCanvas={isMouseDownOnCanvas}
            handleMouseEnterOnConnector={handleMouseEnterOnConnector}
        />}
    </g>;
}

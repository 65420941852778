import React, {useMemo} from "react";

import PathTransformationalConnectorContainer from "./transformationalConnectorContainers/PathTransformationalConnectorContainer";
import RectangleTransformationalConnectorContainer from "./transformationalConnectorContainers/RectangleTransformationalConnectorContainer";
import ArrowTransformationalConnectorContainer from "./transformationalConnectorContainers/ArrowTransformationalConnectorContainer";


export default function ({figure}) {
    const figureType = useMemo(() => {
        const figureToolType = figure.get("tool")?.split("-")?.[0];
        return ["p", "pl", "pr", "c"].includes(figureToolType)
            ? "path"
            : figureToolType === "r" ? "rectangle" : figureToolType === "a" ? "arrow" : "";
    }, [figure.get("tool")]);

    return useMemo(() => {
        const ConnectorContainerComponent = figureType === "path"
            ? PathTransformationalConnectorContainer
            : figureType === "rectangle"
                ? RectangleTransformationalConnectorContainer
                : figureType === "arrow" ? ArrowTransformationalConnectorContainer : null;
        return ConnectorContainerComponent && <ConnectorContainerComponent parentFigure={figure}/>
    }, [figure, figureType]);
}

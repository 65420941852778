import React, {useState} from "react";
import {useDispatch} from "react-redux";

import {useReduxData} from "../../../customHooks/useContextReduxData";
import getTimeElapsedSince from "../../getTimeElapsedSince";
import Path from '../../../utils/path.js';
import {getPhysicalArrowCoos} from "../../figures/utils/arrow";
import {isActiveSnappingConnector} from "../../../customHooks/utils/snappingConnector";


export default function useObjectTransformation() {
    const [startPageX, setStartPageX] = useState(0);
    const [startPageY, setStartPageY] = useState(0);

    const dispatch = useDispatch();
    const {figures, scale, selectedConnectors, startTime} = useReduxData();

    const resetState = () => {
        setStartPageX(0);
        setStartPageY(0);
    };

    const startObjectTransformation = (mouseX, mouseY) => {
        setStartPageX(mouseX);
        setStartPageY(mouseY);
    };

    const transformFigurePath = (mouseX, mouseY, figure, connectors) => {
        if (mouseX === startPageX && mouseY === startPageY) {
            return;
        }

        const figureUuid = figure.get("uuid");
        let minNewPointX = 0, minNewPointY = 0, path = figure.get("points");
        const isFigureEnclosed = ["p", "pl", "pr"].includes(figure.get("tool").split("-")[0]) || figure.get("enclosed");
        const cooShiftX = (mouseX - startPageX) / scale,
            cooShiftY = (mouseY - startPageY) / scale;
        connectors.forEach(connector => {
            const {segmentIndex, connectorIndex} = connector;
            const indexWithinCurve = +segmentIndex - 1;
            const realConnectorIndex = connectorIndex === undefined ? "last" : +connectorIndex;
            const connectorCoo = Path.getPoint(figure.get("points"), 0, indexWithinCurve, realConnectorIndex);
            const newPointX = cooShiftX + connectorCoo.x,
                newPointY = cooShiftY + connectorCoo.y;
            if (newPointX < minNewPointX) {
                minNewPointX = newPointX;
            }
            if (newPointY < minNewPointY) {
                minNewPointY = newPointY;
            }

            path = Path.replacePoints(path, [{
                spInd: 0,
                curveInd: indexWithinCurve,
                conInd: realConnectorIndex,
                x: newPointX,
                y: newPointY
            }]);
            if (indexWithinCurve === -1 && connectorIndex === undefined && isFigureEnclosed) {
                path = Path
                    .replacePoints(path, [{spInd: 0, curveInd: "last", conInd: "last", x: newPointX, y: newPointY}]);
            }
        });

        let action = {type: "changeFigurePoints", uuid: figureUuid, points: path, time: getTimeElapsedSince(startTime)};
        if (minNewPointX < 0 || minNewPointY < 0) {
            action.x = figure.get("x") + minNewPointX;
            action.y = figure.get("y") + minNewPointY;
            action.points = Path.transform(path, -minNewPointX, -minNewPointY, 1, 1);
        }
        dispatch(action);

        resetState();
    }

    const transformRectangle = (mouseX, mouseY, figure, connectors) => {
        if (mouseX === startPageX && mouseY === startPageY) {
            return;
        }

        const cooShiftX = (mouseX - startPageX) / scale,
            cooShiftY = (mouseY - startPageY) / scale;
        let action = {type: "changeRectangleSize", uuid: figure.get("uuid"), time: getTimeElapsedSince(startTime)};
        const segmentIndex = +connectors[0].segmentIndex;

        if ([1, 2].includes(segmentIndex)) {
            action.width = figure.get("width") + cooShiftX;
            action.x = figure.get("x");
        } else {
            action.width = figure.get("width") - cooShiftX;
            action.x = figure.get("x") + cooShiftX;
        }
        if (action.width < 0) {
            action.x += action.width;
            action.width *= -1;
        }

        if ([2, 3].includes(segmentIndex)) {
            action.height = figure.get("height") + cooShiftY;
            action.y = figure.get("y")
        } else {
            action.height = figure.get("height") - cooShiftY;
            action.y = figure.get("y") + cooShiftY;
        }
        if (action.height < 0) {
            action.y += action.height;
            action.height *= -1;
        }
        dispatch(action);

        resetState();
    }

    const transformArrow = (event, figure, connectors) => {
        const mouseX = event.pageX, mouseY = event.pageY;
        if (mouseX === startPageX && mouseY === startPageY) {
            return;
        }

        const segmentIndex = +connectors[0].segmentIndex;
        let action = {type: "changeArrowGeometry", uuid: figure.get("uuid"), time: getTimeElapsedSince(startTime)};

        if (isActiveSnappingConnector(event.target)) {
            const [figureUuid, figurePoint, ] = event.target.id.split("|");
            const linkToFigureClipped = `${figureUuid}|${figurePoint}`;
            if (segmentIndex === 0) {
                action.fromX = linkToFigureClipped;
                action.fromY = linkToFigureClipped;
            } else {
                action.toX = linkToFigureClipped;
                action.toY = linkToFigureClipped;
            }
        } else {
            const cooShiftX = (mouseX - startPageX) / scale,
                cooShiftY = (mouseY - startPageY) / scale;
            const arrowCoos = getPhysicalArrowCoos(figure, figures);
            if (segmentIndex === 0) {
                action.fromX = arrowCoos.from.x + cooShiftX;
                action.fromY = arrowCoos.from.y + cooShiftY;
            } else {
                action.toX = arrowCoos.to.x + cooShiftX;
                action.toY = arrowCoos.to.y + cooShiftY;
            }
        }

        dispatch(action);
        resetState();
    }

    const transformObjects = event => {
        const mouseX = event.pageX, mouseY = event.pageY;
        const figuresToChangeInfo = {};
        selectedConnectors.forEach(connectorId => {
            //Format: {uuid: [{segmentIndex: 0, connectorIndex: 0}, ], }
            const [figureUuid, segmentIndex, connectorIndex] = connectorId.split("|");
            figuresToChangeInfo[figureUuid] = (figuresToChangeInfo[figureUuid] || [])
                .concat([{segmentIndex, connectorIndex}]);
        });

        Object.keys(figuresToChangeInfo).forEach(figureUuid => {
            let figure = figures.get(figureUuid);
            if (!figure) {
                return undefined;
            }
            const figureType = figure.get("tool").split("-")[0];
            const connectors = figuresToChangeInfo[figureUuid];
            if (["p", "pl", "pr", "c"].includes(figureType)) {
                transformFigurePath(mouseX, mouseY, figure, connectors);
            } else if (figureType === "r") {
                transformRectangle(mouseX, mouseY, figure, connectors);
            } else if (figureType === "a") {
                transformArrow(event, figure, connectors);
            }
        });
    };

    return {startObjectTransformation, transformObjects};
}

import {useState, useEffect} from "react";

import UserSelector from "../components/UserSelector";
import {useCurrentUser} from "../customHooks/useContextCurrentUser";
import {useApiClient} from "../customHooks/useApiClient";

import "./adminPanel.css";


export default function () {
    const [superadmins, setSuperadmins] = useState([]);
    const [admins, setAdmins] = useState([]);

    const currentUser = useCurrentUser();
    const api = useApiClient();


    const updateAdminList = () => {  //get default admins list
        api.get("/api/v2/user/admin").then(data => {
            setAdmins(data.data);
        });
    };

    const updateSuperadminList = () => {  //get default super admins list
        api.get("/api/v2/user/superadmin").then(data => {
            setSuperadmins(data.data);
        });
    };

    useEffect(updateSuperadminList, []);
    useEffect(updateAdminList, []);

    const saveNewSuperadminsList = async () => {
        await api.put("/api/v2/user/superadmin/", {data: superadmins});
        updateAdminList();
        updateSuperadminList();
    };

    const saveNewAdminsList = async () => {
        await api.put("/api/v2/user/admin/", {data: admins});
        updateAdminList();
        updateSuperadminList();
    };

    return currentUser?.role === 1
        ? <div className="admin-panel-container">
            <h1>Панель администратора</h1>
            <h2>Управление суперадминами</h2>
            <UserSelector
                users={superadmins}
                setUsers={setSuperadmins}
                menuPlacement="bottom"
                className="admin-panel-multiselect"
                fieldName="superadmins"
            />
            <input type="button" value="Сохранить" onClick={saveNewSuperadminsList}/>
            <h2>Управление админами</h2>
            <UserSelector
                users={admins}
                setUsers={setAdmins}
                menuPlacement="bottom"
                className="admin-panel-multiselect"
                fieldName="admins"
            />
            <input type="button" value="Сохранить" onClick={saveNewAdminsList}/>
        </div>
        : "403";
}

import React from "react";
import MDEditor from "@uiw/react-md-editor";
import {useParams, useHistory} from "react-router";

import {useLocalization} from "../../../customHooks/useContextLocalization";

import {ReactComponent as Arrow} from "../../../images/md-editor/arrow.svg";

import "./licenses.css";


export default function Licenses() {
    const {id} = useParams();
    const locale = useLocalization();
    const history = useHistory();

    const goToList = () => history.push("list");
    const readLicense = key => () => history.push(key);

    return locale?.get
        ? (!["licenseAgreement", "CCBY", "CCBYSA", "CCBYND", "CCBYNC", "CCBYNCND", "CCBYNCSA"].includes(id)
            ? <div className = "licenses-list-body">
                <h2>{locale?.get?.store.product.form.licenses.imageLicensesTitle}</h2>
                <ul>
                    {Object.keys(locale?.get?.store.product.form.licenses.image).map(
                        key => <li onClick = {readLicense(key)}>{locale?.get?.store.product.form.licenses.image[key].name}</li>
                    )}
                </ul>
                {/*<h2>{locale?.get?.store.product.form.licenses.softwareLicensesTitle}</h2>*/}
                {/*<ul>*/}
                {/*    {Object.keys(locale?.get?.store.product.form.licenses.software).map(*/}
                {/*        key => <li onClick = {readLicense(key)}>{locale?.get?.store.product.form.licenses.software[key].name}</li>*/}
                {/*    )}*/}
                {/*</ul>*/}
            </div>
            : <div className = "license-body">
                <button onClick={goToList}><span><Arrow transform="scale(1.4)"/></span> {locale?.get?.store.product.form.licenses.return}</button>
                <MDEditor.Markdown
                    className ={`license-container${id === "licenseAgreement" ? "-digits-only" : ""}`}
                    source = {`${locale?.get?.store.product.form.licenses.image[id].text}`}
                />
            </div>
        ) : null;
}
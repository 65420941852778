import React, {useEffect, useState} from "react";

import Figure from "../../figures/Figure";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import {getRectanglePoint} from "../../figures/utils/rectangle";
import {getPointFeaturePoint} from "../../figures/utils/pointFeature";
import {checkIfArrowDependent} from "../../figures/utils/arrow";
import {createIntermediateFigure} from "../customHooks/useTransformationPreview";
import Path from "../../../utils/path";


export default function ({movementType, movementShiftX, movementShiftY, transformationPreviews}) {
    const {figures, scale, selectedLeafUuids} = useReduxData();

    const [figuresToRender, setFiguresToRender] = useState([]);

    useEffect(() => {
        const dependentArrowsToRenderUuids = Object.keys(figures.toJS()).filter(uuid =>
            figures.get(uuid).get("tool").split("-")[0] === "a" && checkIfArrowDependent(figures.get(uuid))
        );
        let dependentArrowsToRender = [];
        if (movementType === "") {
            dependentArrowsToRender = dependentArrowsToRenderUuids.map(uuid => figures.get(uuid));
        } else if (movementType === "figure") {
            dependentArrowsToRender = dependentArrowsToRenderUuids.map(uuid => {
                let newFigure = figures.get(uuid);
                ["from", "to"].map(label => {
                    let xCooName = `${label}X`,
                        yCooName = `${label}Y`;
                    if (typeof newFigure.get(xCooName) === "string"
                        && selectedLeafUuids.includes(newFigure.get(xCooName).split("|")[0])
                    ) {
                        let [depUuid, depSegmentIndex, depConnectorIndex] = newFigure.get(xCooName).split("|");
                        let realArrowCoo = {x: 0, y: 0};
                        const depType = figures.get(depUuid).get("tool").split("-")[0];
                        if (["p", "pl", "pr", "c"].includes(depType)) {
                            realArrowCoo = Path.getPoint(
                                figures.get(depUuid).get("points"),
                                0,
                                +depSegmentIndex - 1,
                                depConnectorIndex === undefined ? "last" : +depConnectorIndex
                            );
                            realArrowCoo.x += figures.get(depUuid).get("x");
                            realArrowCoo.y += figures.get(depUuid).get("y");
                        } else if (depType === "r") {
                            realArrowCoo = getRectanglePoint(figures.get(depUuid), +depSegmentIndex);
                        } else if (depType === "i") {
                            realArrowCoo = getPointFeaturePoint(figures.get(depUuid), +depSegmentIndex);
                        }
                        let arrowParams = {};
                        arrowParams[xCooName] = realArrowCoo.x + movementShiftX / scale;
                        arrowParams[yCooName] = realArrowCoo.y + movementShiftY / scale;
                        newFigure = createIntermediateFigure(newFigure, arrowParams);
                    }
                })
                return newFigure;
            });
        } else {
            let previewUuids = Object.keys(transformationPreviews);
            dependentArrowsToRender = dependentArrowsToRenderUuids.map(uuid => {
                let newArrow = figures.get(uuid);
                ["from", "to"].map(label => {
                    let xCooName = `${label}X`,
                        yCooName = `${label}Y`;
                    if (typeof newArrow.get(xCooName) === "string"
                        && previewUuids.includes(newArrow.get(xCooName).split("|")[0])
                    ) {
                        let [depUuid, depSegmentIndex, depConnectorIndex] = newArrow.get(xCooName).split("|");
                        let realArrowCoo = {x: 0, y: 0};
                        const depType = figures.get(depUuid).get("tool").split("-")[0];
                        if (["p", "pl", "pr", "c"].includes(depType)) {
                            realArrowCoo = Path.getPoint(
                                transformationPreviews[depUuid].get("points"),
                                0,
                                +depSegmentIndex - 1,
                                depConnectorIndex === undefined ? "last" : +depConnectorIndex
                            );
                            realArrowCoo.x += transformationPreviews[depUuid].get("x");
                            realArrowCoo.y += transformationPreviews[depUuid].get("y");
                        } else if (depType === "r") {
                            realArrowCoo = getRectanglePoint(transformationPreviews[depUuid], +depSegmentIndex);
                        } else if (depType === "i") {
                            realArrowCoo = getPointFeaturePoint(transformationPreviews[depUuid], +depSegmentIndex);
                        }
                        let arrowParams = {};
                        arrowParams[xCooName] = realArrowCoo.x + movementShiftX / scale;
                        arrowParams[yCooName] = realArrowCoo.y + movementShiftY / scale;
                        newArrow = createIntermediateFigure(newArrow, arrowParams);
                    }
                })
                return newArrow;
            });
        }
        dependentArrowsToRender = dependentArrowsToRender.filter(
            el => !Object.keys(transformationPreviews).includes(el.get("uuid"))
        );
        setFiguresToRender(dependentArrowsToRender);
    }, [figures, selectedLeafUuids, movementType, movementShiftX, movementShiftY, transformationPreviews]);

    return figuresToRender.map(figure => <Figure key={figure.get("uuid")} figure={figure} kind={"separate"}/>);
}

import React from "react";

import {useLocalization} from "../customHooks/useContextLocalization";
import {useCurrentUser} from "../customHooks/useContextCurrentUser";
import {useModal} from "../customHooks/useContextModal";


export const BuyError = () => {
    const locale = useLocalization();
    const user = useCurrentUser();
    const modal = useModal();

    const signIn = () => modal.set("signin");
    const signUp = () => modal.set("signup");

    return !user ? <div className="buy-error-container">
        <p>{locale?.get.buyError.explanation}</p>
        <button onClick={signIn}>{locale?.get.auth.signingin}</button>
        <button onClick={signUp}>{locale?.get.auth.signingup}</button>
    </div> : null;
};

import React, {useState} from "react";
import queryString from "query-string";

import {useApiClient} from "../../../customHooks/useApiClient";
import {useLocalization} from "../../../customHooks/useContextLocalization";


export default function () {
    const [currentUserContent, setCurrentUserContent] = useState("");
    const [mapId, setMapId] = useState("");

    const api = useApiClient();
    const locale = useLocalization();

    const updateUserContent = event => setCurrentUserContent(event.target.value);

    const getMapId = async () => {
        let {share_token} = queryString.parse(currentUserContent.split("?")[1]);
        if (!share_token) {
            share_token = decodeURIComponent(currentUserContent);
        }
        const response = await api.get("/api/v2/projects/analytics/id_by_shared_token", {params: {share_token}});

        if (response.data) {
            setMapId(response.data);
        }
    }

    return <div className="project-analytics-dashed-container">
        <h3>{locale?.get?.project.analytics.additionalPage.shareTokenParser}</h3>
        <p>{locale?.get?.project.analytics.additionalPage.enterLinkOrToken}</p>
        <input type="text" onChange={updateUserContent}/>
        <input
            type="button"
            value={locale?.get?.project.analytics.additionalPage.send}
            onClick={getMapId}
        />
        <p>{mapId}</p>
    </div>;
}

import React from "react";

import {useReduxData} from "../../../../customHooks/useContextReduxData";
import {useUndoRedo} from "../../../../customHooks/useContextUndoRedo";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";

import {ReactComponent as Redo} from "../images/first-block/redo.svg";


const IconRedo = tooltipHOC(
    hintModeTooltipHOC(Redo, "firstToolBlockRedo", {marginBottom: "-2px", marginTop: "-2px"}),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () {
    const locale = useLocalization();
    const {redo} = useUndoRedo();
    const {isThereFuture, isWatchMode} = useReduxData();

    return <div className="tool-block-item" onClick={redo}>
        <IconRedo
            className={!isWatchMode && isThereFuture ? "" : "disabled"}
            tooltipContent={locale.get.studio.header.firstToolBlock.redo}
        />
    </div>;
}

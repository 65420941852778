import _ from "lodash";

import {insertAt, replaceAt} from "../../../utils";


export function getLeftwardVector(vector, THICKNESS) {
    const quarterVectorDirected = getQuarterVectorDirected(vector);
    let {x: newPointXShift, y: newPointYShift} = getAbsolutePointShift(vector, THICKNESS);
    if (quarterVectorDirected === "I") {
        newPointXShift *= -1;
    } else if (quarterVectorDirected === "II") {
        newPointXShift *= -1;
        newPointYShift *= -1;
    } else if (quarterVectorDirected === "III") {
        newPointYShift *= -1;
    }

    return {
        start: {x: vector.start.x + newPointXShift, y: vector.start.y + newPointYShift},
        finish: {x: vector.finish.x + newPointXShift, y: vector.finish.y + newPointYShift}
    };
}


export function getRightwardVector(vector, THICKNESS) {
    const quarterVectorDirected = getQuarterVectorDirected(vector);
    let {x: newPointXShift, y: newPointYShift} = getAbsolutePointShift(vector, THICKNESS);
    if (quarterVectorDirected === "I") {
        newPointYShift *= -1;
    } else if (quarterVectorDirected === "III") {
        newPointXShift *= -1;
    } else if (quarterVectorDirected === "IV") {
        newPointXShift *= -1;
        newPointYShift *= -1;
    }

    return {
        start: {x: vector.start.x + newPointXShift, y: vector.start.y + newPointYShift},
        finish: {x: vector.finish.x + newPointXShift, y: vector.finish.y + newPointYShift}
    };
}


function getAbsolutePointShift(vector, THICKNESS) {
    const segmentXLength = vector.finish.x - vector.start.x;
    const segmentYLength = vector.finish.y - vector.start.y;
    const segmentLength = Math.sqrt(Math.pow(segmentXLength, 2) + Math.pow(segmentYLength, 2));
    let newPointXShift = Math.abs((segmentYLength / segmentLength) * THICKNESS / 2); //swapped out for 90 turn
    let newPointYShift = Math.abs((segmentXLength / segmentLength) * THICKNESS / 2);
    return {x: newPointXShift, y: newPointYShift};
}


function getQuarterVectorDirected(vector) {
    if (vector.finish.x - vector.start.x < 0 && vector.finish.y - vector.start.y < 0) {
        return "I";
    } else if (vector.finish.x - vector.start.x >= 0 && vector.finish.y - vector.start.y < 0) {
        return "II";
    } else if (vector.finish.x - vector.start.x >= 0 && vector.finish.y - vector.start.y >= 0) {
        return "III";
    } else {
        return "IV";
    }
}


function getVectorSlope(v) {
    return (v.finish.y - v.start.y) / (v.finish.x - v.start.x);
}


function getAngleBetweenSecondAndFirstBySlopesLineBySlopes(k1, k2) {
    return (Math.atan((k2 - k1) / (1 + k1 * k2)) + Math.PI) % Math.PI;
}


export function getVectorSlopeToAbscissaAngle (v, measure = "rad") {
    const vectorCoo = {
        x: v.finish.x - v.start.x,
        y: v.finish.y - v.start.y,
    }

    const rawAngle = Math.acos(vectorCoo.x / Math.sqrt(Math.pow(vectorCoo.x, 2) + Math.pow(vectorCoo.y, 2)));
    if (measure === "deg") {
        if (vectorCoo.y > 0) {
            return 360 - transformRadToDeg(
                rawAngle
            );
        }
        return transformRadToDeg(rawAngle);
    } else {
        if (vectorCoo.y > 0) {
            return Math.PI * 2 - rawAngle;
        }
        return rawAngle;
    }
}
// TODO: normalize all angles (including case, when canvas ordinate is flipped)

export function getAngleBetweenVectors(v1, v2) {
    const k1 = getVectorSlope(v1);
    const k2 = getVectorSlope(v2);
    return (Math.atan((k1 - k2) / (1 + k1 * k2)) + Math.PI) % Math.PI;
}


export function transformRadToDeg(radVal) {
    return radVal / Math.PI / 2 * 360;
}


export function transformDegToRad(degVal) {
    return degVal / 360 * Math.PI * 2;
}


export function isVectorsIntersect(v1, v2) {
    const v1Slope = getVectorSlope(v1);
    const v2Slope = getVectorSlope(v2);

    if (v1Slope === v2Slope) {
        return false;
    }

    // point, lines intersect
    const x = (v2.start.y - v1.start.y + v1.start.x * v1Slope - v2.start.x * v2Slope) / (v1Slope - v2Slope);
    const y = (x - v1.start.x) * v1Slope + v1.start.y;
    const [v1MinX, v1MaxX] = v1.start.x < v1.finish.x ? [v1.start.x, v1.finish.x] : [v1.finish.x, v1.start.x];
    const [v1MinY, v1MaxY] = v1.start.y < v1.finish.y ? [v1.start.y, v1.finish.y] : [v1.finish.y, v1.start.y];
    const [v2MinX, v2MaxX] = v2.start.x < v2.finish.x ? [v2.start.x, v2.finish.x] : [v2.finish.x, v2.start.x];
    const [v2MinY, v2MaxY] = v2.start.y < v2.finish.y ? [v2.start.y, v2.finish.y] : [v2.finish.y, v2.start.y];
    return v1MinX <= x && x <= v1MaxX && v1MinY <= y && y <= v1MaxY && v2MinX <= x && x <= v2MaxX && v2MinY <= y && y <= v2MaxY;
}


export function reduceIntersectedVectors(v1, v2) {
    const k1 = getVectorSlope(v1);
    const k2 = getVectorSlope(v2);
    const v1SlopeInRad = Math.atan(k1);
    const v2SlopeInRad = Math.atan(k2);

    const segmentBetweenVectors = Math.sqrt(
        Math.pow(v1.finish.x - v2.start.x, 2) + Math.pow(v1.finish.y - v2.start.y, 2)
    );

    const angleBetweenVectors = getAngleBetweenSecondAndFirstBySlopesLineBySlopes(k1, k2);

    const segmentToReduceLen = Math.abs(segmentBetweenVectors / Math.cos((Math.PI - angleBetweenVectors) / 2)) / 2;
    const segmentToReduceV1XLen = Math.abs(segmentToReduceLen * Math.cos(v1SlopeInRad));
    const segmentToReduceV1YLen = Math.abs(segmentToReduceLen * Math.sin(v1SlopeInRad));
    const segmentToReduceV2XLen = Math.abs(segmentToReduceLen * Math.cos(v2SlopeInRad));
    const segmentToReduceV2YLen = Math.abs(segmentToReduceLen * Math.sin(v2SlopeInRad));

    return {
        v1: {
            start: {
                x: v1.start.x,
                y: v1.start.y
            },
            finish: {
                x: v1.finish.x > v1.start.x ? v1.finish.x - segmentToReduceV1XLen : v1.finish.x + segmentToReduceV1XLen,
                y: v1.finish.y > v1.start.y ? v1.finish.y - segmentToReduceV1YLen : v1.finish.y + segmentToReduceV1YLen
            }
        },
        v2: {
            start: {
                x: v2.finish.x > v2.start.x ? v2.start.x + segmentToReduceV2XLen : v2.start.x - segmentToReduceV2XLen,
                y: v2.finish.y > v2.start.y ? v2.start.y + segmentToReduceV2YLen : v2.start.y - segmentToReduceV2YLen
            },
            finish: {
                x: v2.finish.x,
                y: v2.finish.y
            }
        },
    };
}


export function getIntersectionOfTwoLines(l1, l2) {
    const k1 = getVectorSlope(l1);
    const k2 = getVectorSlope(l2);
    const x = (l1.start.x * k1 - l2.start.x * k2 - l1.start.y + l2.start.y) / (k1 - k2);
    const y = (x - l1.start.x) * k1 + l1.start.y;
    return {x, y};
}


export function enrichPointsWithDirectiveOnes(sourcePoints, THICKNESS, CURVATURE=2 / 3) {
    let newPoints = _.cloneDeep(sourcePoints);
    for (let i = 0; i < newPoints.length - 3; i+=3) {
        const v1p1 = newPoints[i];
        const v1p2 = newPoints[i + 1];
        const v2p1 = newPoints[i + 2];
        const v2p2 = newPoints[i + 3];
        const firstVector = {start: {x: v1p1[0], y: v1p1[1]}, finish: {x: v1p2[0], y: v1p2[1]}};
        const secondVector = {start: {x: v2p1[0], y: v2p1[1]}, finish: {x: v2p2[0], y: v2p2[1]}};

        const areVectorsConnectedDirectly =
            Math.round(firstVector.finish.x * 100) / 100 === Math.round(secondVector.start.x * 100) / 100
            && Math.round(firstVector.finish.y * 100) / 100 === Math.round(secondVector.start.y * 100) / 100;
        const angleBetweenVectors = transformRadToDeg(getAngleBetweenVectors(firstVector, secondVector));

        let bPoint;
        if (areVectorsConnectedDirectly) {
            bPoint = {x: firstVector.finish.x, y: firstVector.finish.y};

            const REDUCTIONFACTOR = THICKNESS / 2;

            let angle = getVectorSlopeToAbscissaAngle(firstVector);
            let xToReduceLen = REDUCTIONFACTOR * Math.abs(Math.cos(angle));
            let yToReduceLen = REDUCTIONFACTOR * Math.abs(Math.sin(angle));
            let xDeltaSign = Math.sign(firstVector.start.x - firstVector.finish.x);
            let yDeltaSign = Math.sign(firstVector.start.y - firstVector.finish.y);
            let newX = firstVector.finish.x + xDeltaSign * xToReduceLen;
            let newY = firstVector.finish.y + yDeltaSign * yToReduceLen;
            newPoints = replaceAt(newPoints, i + 1, [newX, newY]);

            angle = getVectorSlopeToAbscissaAngle(secondVector);
            xToReduceLen = REDUCTIONFACTOR * Math.abs(Math.cos(angle));
            yToReduceLen = REDUCTIONFACTOR * Math.abs(Math.sin(angle));
            xDeltaSign = -Math.sign(secondVector.start.x - secondVector.finish.x);
            yDeltaSign = -Math.sign(secondVector.start.y - secondVector.finish.y);
            newX = secondVector.start.x + xDeltaSign * xToReduceLen;
            newY = secondVector.start.y + yDeltaSign * yToReduceLen;
            newPoints = replaceAt(newPoints, i + 2, [newX, newY]);
        } else {
            if (angleBetweenVectors < 90) {
                const d = Math.sqrt(
                    Math.pow(firstVector.finish.x - secondVector.start.x, 2)
                    + Math.pow(firstVector.finish.y - secondVector.start.y, 2)
                );
                const hPoint = {
                    x: (firstVector.finish.x + secondVector.start.x) / 2,
                    y: (firstVector.finish.y + secondVector.start.y) / 2
                };
                const alphaAngle = getVectorSlopeToAbscissaAngle(
                    {start: firstVector.finish, finish: secondVector.start}
                );
                let deltaX = d * -Math.sin(alphaAngle) * Math.sqrt(CURVATURE * CURVATURE - 0.25);
                let deltaY = d * -Math.cos(alphaAngle) * Math.sqrt(CURVATURE * CURVATURE - 0.25);
                bPoint = {x: hPoint.x + deltaX, y: hPoint.y + deltaY};
            } else {
                bPoint = getIntersectionOfTwoLines(firstVector, secondVector);
            }
        }
        newPoints = insertAt(newPoints, i + 2, [bPoint.x,  bPoint.y]);
    }
    return newPoints;
}

import {getRectanglePoint} from "./rectangle";
import {getPointFeaturePoint} from "./pointFeature";
import Path from "../../../utils/path";


export function getPhysicalArrowCoos(figure, figures) {
    let arrowCoos = {
        from: {x: figure.get("fromX"), y: figure.get("fromY")},
        to: {x: figure.get("toX"), y: figure.get("toY")}
    }
    Object.keys(arrowCoos).map(arrowKey => {
        if (typeof arrowCoos[arrowKey].x === "string" && typeof arrowCoos[arrowKey].y === "string") {
            const [depUuid, depPoint, ] = arrowCoos[arrowKey].x.split("|");
            const depFigure = figures.get(depUuid);
            if (depFigure) {
                let depFigurePoint = {x: 0, y: 0};
                const depFigureType = depFigure.get("tool").split("-")[0];
                if (depFigureType === "i") {
                    depFigurePoint = getPointFeaturePoint(depFigure, +depPoint);
                } else if (depFigureType === "r") {
                    depFigurePoint = getRectanglePoint(depFigure, +depPoint);
                } else if (["p", "pl", "pr", "c"].includes(depFigureType)) {
                    depFigurePoint = Path.getPoint(depFigure.get("points"), 0, +depPoint - 1, "last");
                    depFigurePoint.x += depFigure.get("x");
                    depFigurePoint.y += depFigure.get("y");
                }
                arrowCoos[arrowKey].x = depFigurePoint.x;
                arrowCoos[arrowKey].y = depFigurePoint.y;
            } else {
                arrowCoos[arrowKey].x = 0;
                arrowCoos[arrowKey].y = 0;
            }
        }
    });
    return arrowCoos;
}

export function checkIsTheOnlyArrowSelected(figures, selectedFigureUuids) {
    return selectedFigureUuids.length === 1 && figures.get(selectedFigureUuids[0]).get("tool").split("-")[0] === "a";
}

export const checkIfArrowDependent = figure =>
    typeof figure.get("fromX") === "string" || typeof figure.get("toX") === "string";

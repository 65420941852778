import React from "react";
import {useDispatch} from "react-redux";

import tooltipHOC from "../../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../../onboarding/hintModeTooltipHOC";

import {ReactComponent as Center} from "../images/second-block/center.svg";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";


const IconCenter = tooltipHOC(
    hintModeTooltipHOC(Center, "secondToolBlockCenter"),
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function () {
    const locale = useLocalization();
    const dispatch = useDispatch();

    const goToCenter = () => {
        dispatch({type: "canvasScrollX", value: 0});
        dispatch({type: "canvasScrollY", value: 0});
    };

    return <div className="tool-block-big-item" id="header-center-button" onClick={goToCenter}>
        <IconCenter tooltipContent={locale?.get.studio.header.secondToolBlock.center}/>
    </div>;
}
import SourceNodeConfigurator from "./sourceNodeConfigurator/SourceNodeConfigurator";
import IntermediateNodeConfigurator from "./IntermediateNodeConfigurator";


export default function ({selectedNode, handleServerMapTableShowing}) {
    return selectedNode?.type === "source"
        ? <SourceNodeConfigurator
            selectedNodeId={selectedNode.id}
            handleServerMapTableShowing={handleServerMapTableShowing}
        />
        : selectedNode?.type ? <IntermediateNodeConfigurator/> : null
}
